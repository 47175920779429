import React, { FC, useEffect, useMemo, useRef } from 'react';

import { Form, Formik, FormikConfig, isObject } from 'formik';

import { BiCopy } from 'react-icons/bi';
import { MdError, MdCheckCircle } from 'react-icons/md';

import {
  Box,
  Text,
  Image,
  Flex,
  Stack,
  Button,
  Divider,
  Center,
  Spinner,
  Heading,
  useToast,
  useClipboard,
  CircularProgress,
} from '@chakra-ui/react';

import { useQuery, useQueryCache } from 'react-query';

import { selectors } from 'effector/stripe';

import { detailedDiff } from 'deep-object-diff';

import logo from 'res/cms-logo.png';

import { CMS } from 'types/cms';

import { Panel } from './Panel';

import { isEmpty } from 'ramda';

import {
  initialValues,
  checkFormFilled,
  businessDetailsSchema,
} from './WebsiteForm.utils';

import { TemplateDetails } from './TemplateDetails';
import { BusinessDetails } from './BusinessDetails';
import { PackagesSection } from './PackageSection';
import { IndustriesSection } from './IndustriesSection';
import { EquipmentsSection } from '../EquipmentsSection';
import { AdditionalServices } from './AdditionalServices';
import { PreviousWorkSection } from '../PreviousWorkSection';

import { WebsiteFormProps } from './WebsiteForm.props';

import * as session from 'effector/session';
import { getIsAccountActivatedStatus } from 'effector/session/store';
import { getMyCMS } from 'api/cms';

function findFirstString(obj: any): string {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      return obj[key];
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      const result = findFirstString(obj[key]);
      if (result) return result;
    }
  }
  return '';
}

export const RefetchCmsQueryHandler: FC = () => {
  const queryCache = useQueryCache();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const isAccountActivated = getIsAccountActivatedStatus();

      if (!isAccountActivated) {
        return;
      }

      const cms = await queryCache.refetchQueries(`fetch-my-cms`);

      if (cms) {
        clearTimeout(intervalId);
      }
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Center flex={1}>
      <Spinner />
    </Center>
  );
};

const PremiumSiteCopyButton: FC = () => {
  const toast = useToast();

  const { data, isLoading } = useQuery(
    `fetch-my-cms-published`,
    () => getMyCMS('PUBLISH'),
    {
      retry: 0,
      onError: () => console.log(''),
    },
  );

  const { onCopy } = useClipboard(
    data?.username ? `${process.env.REACT_APP_URL}/cms/${data?.username}` : '',
  );

  return (
    <Button
      isDisabled={!data}
      isLoading={isLoading}
      variant="outline"
      rightIcon={<BiCopy size="22px" />}
      onClick={() => {
        onCopy();

        toast({ title: 'copied to clipboard' });
      }}>
      COPY URL
    </Button>
  );
};

const WebsiteFormView: FC<WebsiteFormProps> = ({
  name,
  isNewCMS,
  data,
  ...props
}) => {
  const toast = useToast();

  const user = session.selectors.useUser();
  const isAccConnected = selectors.useIsConnected();

  const formSubmitRef = useRef<'SAVE' | 'PUBLISH'>('SAVE');

  // Temp URL
  const url = useMemo(
    () =>
      props.initialValues
        ? `${process.env.REACT_APP_URL}/cms/preview/${
            (props.initialValues as any)?.username
          }`
        : '',
    [props.initialValues],
  );

  const convertObjToArr = (obj: any, values: any) => {
    for (const key in obj) {
      const value = obj[key];

      if (typeof value === 'object') {
        const isNumber = Object.keys(value).some((k) => !isNaN(Number(k)));

        if (
          [
            'dayAvailability',
            'socials',
            'industries',
            'additionalServices',
            'testimonials',
          ].includes(key)
        ) {
          switch (key) {
            case 'dayAvailability': {
              const modifiedValue = values?.about?.dayAvailability;

              obj[key] = modifiedValue;

              break;
            }
            case 'socials': {
              const modifiedValue = values?.socials;

              obj[key] = modifiedValue;

              break;
            }
            case 'industries': {
              const modifiedValue = values?.industries;

              obj[key] = modifiedValue;

              break;
            }
            case 'additionalServices': {
              const modifiedValue = values?.additionalServices;

              obj[key] = modifiedValue;

              break;
            }
            case 'testimonials': {
              const modifiedValue = values?.testimonials;

              obj[key] = modifiedValue;

              break;
            }
            default:
              break;
          }
        } else if (isNumber) {
          const array = Object.values(value).map((i) =>
            isNaN(Number(i)) ? i : Number(i),
          );

          obj[key] = array;
        } else {
          convertObjToArr(value, values);
        }
      }
    }
  };

  if (isNewCMS) {
    return <RefetchCmsQueryHandler />;
  }

  const onFormSubmit: FormikConfig<CMS>['onSubmit'] = async (
    values,
    helpers,
  ) => {
    helpers.setSubmitting(true);

    switch (formSubmitRef.current) {
      case 'PUBLISH': {
        const { companyName, ...about } = values.about;

        if ('title' in about) {
          delete (about as any)?.title;
        }
        // const testimonyPayload = values?.testimonials.map((testimony) => ({
        //   name: testimony.name,
        //   position: testimony.position,
        //   testimony: testimony.testimony,
        //   imageUrl: testimony.imageUrl.name,
        //   testimonyFile: testimony.imageUrl,
        // }));

        const payload = {
          gps: {
            coordinates: values.gps.coordinates,
          },
          location: values.location,
          stylings: values.stylings,
          about: {
            ...about,
            dayAvailability: (about?.dayAvailability || [])?.map((d: any) => {
              if ('_id' in d) {
                delete d._id;
              }

              return d;
            }),
          },
          industries: values.industries,
          socials: values.socials,
          radius: values.radius,
          phone: values?.phone,

          bannerImage: values?.bannerImage,
          enableTestimonials: values?.enableTestimonials,
          templateType: values?.templateType,
          testimonials: values?.testimonials,
        };

        await props.onSubmit({
          ...payload,
          status: 'PUBLISH',
        } as any);

        break;
      }
      case 'SAVE': {
        const result: any = detailedDiff(props.initialValues as any, values);

        const updatedFields = {
          ...result.added,
          ...result.updated,
          ...result?.deleted,
        };

        /** Used as a temporary variable in the form building process */
        delete updatedFields['testimony'];

        convertObjToArr(updatedFields, values);

        await props.onSubmit({
          ...updatedFields,
          about: {
            ...updatedFields?.about,
            alwaysAvailable: values?.about?.alwaysAvailable,
          },
          enableTestimonials: values?.enableTestimonials,
          /**
           * PATCH is working as a PUT on the BE.
           */
          location: values.location,
          gps: {
            coordinates: values.gps.coordinates,
          },
        });

        break;
      }
      default:
        break;
    }
  };

  return (
    <Formik<CMS>
      enableReinitialize
      onSubmit={onFormSubmit}
      initialValues={props.initialValues || initialValues}
      validationSchema={businessDetailsSchema}>
      {({ submitForm, validateForm, isSubmitting }) => {
        const onSave = async (shouldPublish?: boolean) => {
          formSubmitRef.current = 'SAVE';

          const errors = await validateForm();

          if (!isEmpty(errors)) {
            Object.values(errors).forEach((err) => {
              if (typeof err === 'string') {
                toast({
                  status: 'warning',
                  title: err,
                  isClosable: true,
                  duration: 3000,
                });
              } else if (isObject(err)) {
                const msg = findFirstString(err);

                if (msg) {
                  toast({
                    status: 'warning',
                    title: String(msg),
                    isClosable: true,
                    duration: 3000,
                  });
                }
              }
            });

            return;
          }

          await submitForm();

          if (shouldPublish) {
            formSubmitRef.current = 'PUBLISH';

            submitForm();
          }
        };

        const formValues = props.initialValues ?? ({} as CMS);
        const isSectionOneValid = checkFormFilled(formValues, 0);
        const isSectionTwoValid = checkFormFilled(formValues, 1);
        const isSectionThreeValid = checkFormFilled(formValues, 2);
        const isSectionFourValid = checkFormFilled(formValues, 3);
        const isSectionFiveValid = checkFormFilled(formValues, 4);

        return (
          <Form>
            <Flex
              mb="10px"
              w="100%"
              p={5}
              justify="center"
              borderBottom="1px solid #ccc">
              <Flex w="100%" maxW="900px">
                <Image h="200px" w="250px" src={logo} objectFit="contain" />
                <Box w={['10px', '20px', '100px']} h="20px" />
                <Stack justify="center">
                  <Heading>My Premium Website</Heading>
                  <Text whiteSpace="pre-line">
                    We want to help you{' '}
                    <span style={{ color: '#E4C72C', fontWeight: 'bold' }}>
                      grow!
                    </span>
                    {'\n'}Create a customized website for your services.
                  </Text>
                </Stack>
              </Flex>
            </Flex>
            <Flex
              px="30px"
              pt="20px"
              pb="200px"
              gridGap="35px"
              direction="column"
              position="relative">
              <Flex justify="space-between" align="flex-end">
                <Stack spacing={1}>
                  <Text fontSize="26px" fontWeight="bold" color="#707070">
                    Let’s create your website
                  </Text>
                  <Text>
                    You need to fill all the different sections to be able to
                    publish your website
                  </Text>
                </Stack>
                <Flex gridGap="10px">
                  <PremiumSiteCopyButton />
                  <Button
                    variant="outline"
                    onClick={() => {
                      // if (!isAccConnected) {
                      //   toast({
                      //     status: 'warning',
                      //     title:
                      //       'Please Connect your Stripe account in order to Preview',
                      //     isClosable: true,
                      //   });

                      //   return;
                      // }

                      window.open(url, '_blank');
                    }}>
                    PREVIEW
                  </Button>
                  <Button
                    onClick={() => {
                      // if (!isAccConnected) {
                      //   toast({
                      //     status: 'warning',
                      //     title:
                      //       'Please Connect your Stripe account in order to Publish',
                      //     isClosable: true,
                      //   });

                      //   return;
                      // }
                      // if (!Boolean(user?.pilotCertNumber)) {
                      //   toast({
                      //     status: 'warning',
                      //     title:
                      //       'Add your Remote Pilot Certificate number to publish your website. Go to User menu > Profile > Edit',
                      //     isClosable: true,
                      //   });
                      // } else {
                      //   onSave(true);
                      // }

                      onSave(true);
                    }}>
                    PUBLISH
                  </Button>
                </Flex>
              </Flex>
              <Panel
                title={
                  <SectionValidation
                    isValid={isSectionOneValid}
                    title="Website name and template details"
                  />
                }
                buttonProps={{
                  border: isSectionOneValid
                    ? '2px solid #52B968'
                    : '2px solid #C83434',
                }}>
                <TemplateDetails onSave={onSave} />
              </Panel>

              <Panel
                title={
                  <SectionValidation
                    isValid={isSectionTwoValid}
                    title="Business details"
                  />
                }
                buttonProps={{
                  border: isSectionTwoValid
                    ? '2px solid #52B968'
                    : '2px solid #C83434',
                }}>
                <BusinessDetails onSave={onSave} />
              </Panel>

              <Panel
                title={
                  <SectionValidation
                    isValid={isSectionThreeValid}
                    title="Previous work"
                  />
                }
                buttonProps={{
                  border: isSectionThreeValid
                    ? '2px solid #52B968'
                    : '2px solid #C83434',
                }}>
                <PreviousWorkSection />
              </Panel>

              <Panel
                title={
                  <SectionValidation
                    isValid={isSectionFourValid}
                    title="Industries, Services, Packages & Add-Ons"
                  />
                }
                buttonProps={{
                  border: isSectionFourValid
                    ? '2px solid #52B968'
                    : '2px solid #C83434',
                }}>
                <Flex direction="column">
                  <IndustriesSection onSave={onSave} />

                  <Divider mt="20px" mb="40px" />

                  <PackagesSection isNewCMS={!!isNewCMS} />

                  <AdditionalServices onSave={onSave} />
                </Flex>
              </Panel>

              <Panel
                title={
                  <SectionValidation
                    isValid={isSectionFiveValid}
                    title="Equipment and Testimonials"
                  />
                }
                buttonProps={{
                  border: isSectionFiveValid
                    ? '2px solid #52B968'
                    : '2px solid #C83434',
                }}>
                <EquipmentsSection isNewCMS={isNewCMS} onSave={onSave} />
              </Panel>

              {isSubmitting && <LoadingOverlay />}
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default WebsiteFormView;

const LoadingOverlay: FC = () => (
  <Flex
    position="fixed"
    zIndex={20}
    top={0}
    left={0}
    right={0}
    bottom={0}
    bg="#00000050"
    align="center"
    justify="center">
    <Center bg="#fff" borderRadius="20px" p={12} flexDirection="column">
      <Text
        mt={6}
        textAlign="center"
        fontFamily="Monument Extended"
        fontWeight="200"
        fontSize="20px"
        color="#4f4f4f">
        Saving your progress...{'\n'}Please Wait.
      </Text>
      <CircularProgress isIndeterminate my={6} color="brand.500" />
    </Center>
  </Flex>
);

const SectionValidation: FC<{ title: string; isValid: boolean }> = ({
  title,
  isValid,
}) => {
  return (
    <Flex justify="space-between" align="center" mr="10px">
      <Text>{title}</Text>
      {isValid ? (
        <MdCheckCircle size="24px" color="#52B968" />
      ) : (
        <Flex gridGap="10px" align="center">
          <Text fontSize="12px" color="#C83434" fontWeight="bold">
            This section has some missing information
          </Text>
          <MdError size="24px" color="#C83434" />
        </Flex>
      )}
    </Flex>
  );
};
