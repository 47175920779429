import React, { FC } from 'react';

import { Button } from '@chakra-ui/button';
import { Box, Text, Flex, Stack, Center, Heading } from '@chakra-ui/layout';

import { MdCheck } from 'react-icons/md';

import { PackageLineItems } from 'types/mission';
import { PackageBoxProps } from './PackageBox.props';

const PackageBox: FC<PackageBoxProps> = ({
  bg,
  pack,
  submit,
  isValid,
  packageID,
  setFieldValue,
  buttonText = 'SELECT',
}) => {
  const { price, description, lineItems, name } = pack;

  return (
    <Box
      pos="relative"
      p={5}
      w={['100%', 1 / 4.2, '100%', 1 / 4.2]}
      maxWidth={['100%', '300px', '400px', '400px']}
      minH="450px"
      mb="10px"
      borderRadius="10px"
      boxShadow="0 0 12px 0 rgba(0, 0, 0, 0.1)"
      display="flex"
      flexDirection="column"
      bg={bg}>
      <Heading mb="8px" textAlign="center" fontSize="18px">
        {name}
      </Heading>
      <Text
        mb="8px"
        color="brand.500"
        textAlign="center"
        fontSize="30px"
        fontWeight="700">
        {price && `$${price}`}
      </Text>
      <Text
        minH="60px"
        mb="12px"
        textAlign="center"
        fontSize="12px"
        color="#838383">
        {description}
      </Text>
      <Box>
        <Box
          h="1px"
          left={0}
          right={0}
          bg="#979797"
          opacity={0.3}
          position="absolute"
        />
      </Box>
      <Flex flexDir="column" mt="12px">
        {lineItems?.map((lineItem: PackageLineItems, index: number) => (
          <Stack
            mb={2}
            isInline
            align="center"
            key={`${lineItem.name}-${index}`}>
            <Center
              w="22px"
              h="22px"
              minW="22px"
              overflow="hidden"
              borderRadius="20px"
              position="relative">
              <Box
                w="100%"
                h="100%"
                opacity={0.1}
                bg="brand.500"
                position="absolute"
              />
              <MdCheck color="brand.500" />
            </Center>
            <Text fontSize="14px" color="#838383">
              {`(${lineItem.count})`} {lineItem?.name || ''}
            </Text>
          </Stack>
        ))}
      </Flex>
      <Flex alignItems="center" mt="auto" flexDirection="column">
        <Flex justifyContent="center" mt="10px">
          <Button
            fontSize="sm"
            w="120px"
            height="30px"
            isDisabled={!isValid}
            onClick={() => {
              setFieldValue('package', packageID);
              setTimeout(() => {
                submit();
              }, 500);
            }}>
            {buttonText}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default PackageBox;
