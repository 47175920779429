import React, { memo } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Avatar,
} from '@chakra-ui/react';
import { HiOutlineDotsCircleHorizontal } from 'react-icons/hi';
import { Testimony } from 'types/cms';

type contentProps = {
  testimony: Testimony;
  color: any;
  onDelete: (_testimony: Testimony) => void;
};

const TestimonialsContent = (props: contentProps) => {
  const { testimony, color, onDelete } = props;

  return (
    <>
      <Popover placement="right-end">
        <PopoverTrigger>
          <Flex justifyContent="end" pt={5} pr={5}>
            <HiOutlineDotsCircleHorizontal color={color} />
          </Flex>
        </PopoverTrigger>
        <PopoverContent w="6rem" h={10} justifyContent="center" zindex={20}>
          <PopoverArrow />
          <PopoverBody border="none">
            <div onClick={() => onDelete(testimony)}>
              <Text fontSize="12px" textColor={color} fontWeight="bold">
                Delete
              </Text>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Flex
        gridGap={8}
        fontSize="12px"
        p={3}
        mt={-6}
        justifyContent="flex-start"
        alignItems="center">
        <Stack
          minW={40}
          justifyContent="center"
          alignItems="center"
          textAlign="center">
          <Box>
            <Avatar src={testimony?.signedUrl} w="6rem" h="6rem" />
          </Box>
          <Text fontSize="14px" textColor="#707070" fontWeight="bold">
            {testimony?.name}
          </Text>
          <Text fontSize="12px" textColor="#707070">
            {testimony?.position}
          </Text>
        </Stack>
        <Text fontSize="1rem" pr={4}>
          "{testimony?.testimony}"
        </Text>
      </Flex>
    </>
  );
};

export default memo(TestimonialsContent);
