import React from 'react';
import Icon from '@chakra-ui/icon';
import { Box, Flex, Text } from '@chakra-ui/layout';
import GoogleMaps from 'components/primitives/GoogleMaps';
import { AiFillFire, AiTwotoneCalendar } from 'react-icons/ai';
import { IoIosFilm } from 'react-icons/io';

import { MissionSideBarProps } from './MissionSideBar.props';
import { TitleWithIcon } from 'components/primitives/TitleWithIcon';
import { SiGooglemaps } from 'react-icons/si';
import { useStore } from 'effector-react';
import { FormValues } from 'routes/client/Dashboard/Dashboard.utils';
import { getIndustryName } from 'constants/industriesMap';
import { MdCameraAlt } from 'react-icons/md';
import { format } from 'date-fns';
import usePackagesList from 'utils/hooks/usePackages';

const MissionSideBarView = (props: MissionSideBarProps): JSX.Element => {
  const {
    eventStart,
    dueDate,
    rush,
    package: pack,
    location,
    gps,
    missionIndustry,
    additionalServices,
  } = useStore(FormValues);
  const { data } = usePackagesList();

  const missionPackage = props.packages?.find((i) => i.id === pack);
  const packName = missionPackage?.name ?? '';
  const additionalServicesCost =
    additionalServices?.map((i) => i.price)?.reduce((a, b) => a + b, 0) || 0;

  const totalCharge = missionPackage?.price ?? 0;
  const packItems = data?.results?.find((p) => p.id === pack)?.lineItems ?? [];
  const computedTotal = totalCharge + additionalServicesCost;
  const lat = gps?.coordinates?.[1];
  const lng = gps?.coordinates?.[0];

  return (
    <Flex
      w="280px"
      maxH="750px"
      px="30px"
      py="10px"
      mb="20px"
      background="#fff"
      borderRadius="10px"
      border="1px"
      borderColor="#92835C"
      flexDirection="column">
      <Flex flexDirection="column" borderBottom="1px solid #ccc" pb="10px">
        <Text
          fontSize="lg"
          mb="14px"
          textAlign="center"
          fontFamily="Monument Extended">
          MISSION DETAILS
        </Text>
        <TitleWithIcon
          text="Location"
          TextIcon={<Icon as={SiGooglemaps} h="15px" w="15px" />}
        />
        <Text ml="4px" fontSize="xs">
          {location}
        </Text>
        <Box
          w="110%"
          h="130px"
          my="10px"
          mx="-5%"
          borderWidth="1px"
          borderColor="#707070"
          bg="inputColor">
          <GoogleMaps
            mapContainerStyle={{
              width: '100%',
              height: '100%',
            }}
            initialCenter={{ lat, lng }}
            marker={{ lat, lng }}
            zoom={15}
            disableClickMarkerChange
          />
        </Box>
        {/* <TitleWithIcon
          text="Mission Type"
          TextIcon={<Icon as={MdCameraAlt} h="15px" w="15px" />}
        /> */}
        <Text fontSize="sm" mb={2} ml="4px">
          {getIndustryName(missionIndustry || '')}
        </Text>
        <TitleWithIcon
          text={packName}
          TextIcon={<Icon as={IoIosFilm} h="15px" w="15px" />}
        />
        {packItems?.length &&
          packItems.map((item: any, index: number) => (
            <Text fontSize="xs" key={index} my="3px">
              - {item.count ? `(${item.count})` : ''} {item.name}
            </Text>
          ))}
      </Flex>
      {dueDate && eventStart && (
        <Flex
          flexDirection="column"
          mt="10px"
          pb="10px"
          borderBottomWidth="1px"
          borderColor="#ccc">
          <TitleWithIcon
            text="Due Date"
            TextIcon={<Icon as={AiTwotoneCalendar} h="15px" w="15px" />}
          />
          {format(new Date(eventStart), 'MM/dd/yyyy') ===
          format(new Date(dueDate), 'MM/dd/yyyy') ? (
            <Text fontSize="xs">
              {format(new Date(eventStart), 'MM/dd/yyyy')}
            </Text>
          ) : (
            <Text fontSize="xs">
              {format(new Date(eventStart), 'MM/dd/yyyy')} -{' '}
              {format(new Date(dueDate), 'MM/dd/yyyy')}
            </Text>
          )}
        </Flex>
      )}
      {rush && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          pb="10px">
          <TitleWithIcon
            text="Rush Mission"
            TextIcon={
              <Icon as={AiFillFire} h="15px" w="15px" color="#E9243F" />
            }
          />
        </Flex>
      )}
      {Boolean(additionalServicesCost) && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          pb="10px">
          <TitleWithIcon
            text="Additional services cost"
            TextIcon={
              <Icon as={AiFillFire} h="15px" w="15px" color="#E9243F" />
            }
          />
          <Text fontSize="xs" fontWeight="bold">
            ${additionalServicesCost.toFixed(2)}
          </Text>
        </Flex>
      )}
      <Flex
        mt="auto"
        py="15px"
        borderTop="1px solid #ccc"
        fontWeight="bold"
        justifyContent="space-between"
        alignItems="center">
        <Text color="primaryColor" fontSize="sm">
          Total Amount
        </Text>
        <Text fontWeight="700" fontSize="24px">
          ${computedTotal}
        </Text>
      </Flex>
    </Flex>
  );
};
export default MissionSideBarView;
