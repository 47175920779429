import React, { FC, useState } from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton,
} from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';
import { getUserType } from 'effector/session/store';

import { MissionInfo } from './MissionInfo';
import { AcceptMission } from './AcceptMission';
import { RejectMission } from './RejectMission';
import { RequestChange } from './RequestChange';

type Props = {};

type Status = 'accept_mission' | 'request_changes' | 'reject';

const useIsOpen = () => {
  const userType = getUserType();
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const isPilot = userType === 'PILOT';

  const customMissionId = search.get('custom-mission-id');
  const isCustomMissionId = Boolean(customMissionId);

  const disclosure = useDisclosure({
    defaultIsOpen: isCustomMissionId && !isPilot,
  });

  return { ...disclosure, customMissionId };
};

export const CustomQuoteAccept: FC<Props> = () => {
  const [status, setStatus] = useState<Status>();

  const { isOpen, onClose, customMissionId } = useIsOpen();

  const isSmall = status === 'reject' || status === 'request_changes';

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size={isSmall ? 'xl' : '6xl'}
      motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent borderRadius="20px">
        <ModalCloseButton />
        <ModalBody>
          {status === undefined ? (
            <MissionInfo
              onClose={onClose}
              onSelect={setStatus}
              customMissionId={customMissionId || ''}
            />
          ) : status === 'accept_mission' ? (
            <AcceptMission
              onClose={onClose}
              onSelect={setStatus}
              customMissionId={customMissionId || ''}
            />
          ) : status === 'request_changes' ? (
            <RequestChange
              onSelect={setStatus}
              customMissionId={customMissionId || ''}
            />
          ) : (
            <RejectMission
              onSelect={setStatus}
              customMissionId={customMissionId || ''}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
