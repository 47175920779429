import { Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';

export const MotionFlex = motion.custom(Flex);

export const animationConfig = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    type: 'tween',
  },
  direction: 'column',
  gridGap: '16px',
  mt: '20px',
} as const;
