import React from 'react';
import { useMissionAssets } from 'utils/hooks/useMissionAssets';

import { MissionAssetsProps } from './MissionAssets.props';
import MissionAssetsView from './MissionAssets.view';

const MissionAssetsContainer = (props: MissionAssetsProps) => {
  const { data, isLoading } = useMissionAssets(props.missionId);

  return (
    <MissionAssetsView
      missionId={props.missionId}
      assets={data?.results ?? []}
      isLoading={isLoading}
      package={props.package}
      type={props.type}
      containerProps={props.containerProps}
    />
  );
};

export default MissionAssetsContainer;
