import React, { useRef, useState } from 'react';
import {
  List,
  Flex,
  Text,
  Modal,
  Image,
  Button,
  Center,
  Spinner,
  Divider,
  ListItem,
  ModalBody,
  ListIcon,
  SimpleGrid,
  ModalOverlay,
  ModalContent,
  CircularProgress,
} from '@chakra-ui/react';

import { motion } from 'framer-motion';
import { MdCheckCircle } from 'react-icons/md';

import { selectors } from 'effector/session';
import * as session from 'effector/session';

import DALogo from 'res/logos/logo1.png';
import vectorImage from 'res/vector.png';

import proLogo from 'res/subs/pro.png';
import freeLogo from 'res/subs/free.png';
import premiumLogo from 'res/subs/premium.png';

import FreePlanModal from 'components/modules/FreePlanModal';

import { WelcomePageGeneratedProps } from './WelcomePage.props';
import PlanSelectionEmailAlertModal from './PlanSelectionEmailAlertModal';
import { AddPaymentButton } from './AddPaymentButton';
import { Link, useHistory } from 'react-router-dom'; // Cambiado de useNavigate a useHistory

const MotionFlex = motion.custom(Flex);

const animation = (delay: number) => ({
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  transition: {
    duration: 0.5,
    delay,
    ease: 'easeInOut',
  },
});

const WelcomePageView = ({
  isLoading,
  isPending,
  selectedPlan,
  isBtnDisabled,
  isSubmitting,
  onPlanSelect,
  isMonthlyPlan,
  isYearlyPlan,
  isSubExist,
  onReactivate,
  onPlanUpgrade,
  isTrialPeriod,
  setSelectedPlan,
  isFreePlan,
  subscription,
  createFreeSub,
  isValidCard,
}: WelcomePageGeneratedProps) => {
  const freePlanModalRef = useRef<any>(null);
  const finalRef = React.useRef(null);

  const isUserFetching = selectors.useFetching();
  const email = session.selectors.useUser()?.email;
  const [isEmailAlertOpen, setIsEmailAlertOpen] = useState(false);

  const history = useHistory();

  const onPlanChoose = (plan: 'Pro-Annually') => {
    if (!isSubExist) {
      return onPlanSelect(plan);
    }

    if (!isYearlyPlan || !isMonthlyPlan) {
      return onPlanUpgrade(plan);
    }
    return onReactivate();
  };

  const renderProButton = () => {
    if (selectedPlan === 0) {
      return (
        <>
          {isTrialPeriod && isMonthlyPlan ? (
            <>
              <Text bg="#DDCEF270" px={4} py={2} borderRadius="5px">
                trial period on
              </Text>
              {!isValidCard && <AddPaymentButton />}
            </>
          ) : isMonthlyPlan &&
            subscription?.subscription?.status === 'active' ? (
            <Text bg="#DDCEF270" px={4} py={2} borderRadius="5px">
              current plan
            </Text>
          ) : (
            <Button
              fontSize="12px"
              w="200px"
              isDisabled={isBtnDisabled || isYearlyPlan}
              variant={isMonthlyPlan ? 'outline' : 'solid'}
              onClick={() => {
                if (subscription?.subscription?.status === 'cancelled') {
                  onReactivate();
                } else {
                  createFreeSub();
                }

                //setIsEmailAlertOpen(true);
              }}
              isLoading={isSubmitting}>
              {isMonthlyPlan ? 'Reactivate' : 'GO PRO'}
            </Button>
          )}
        </>
      );
    }

    return (
      <>
        {isYearlyPlan && isTrialPeriod ? (
          <>
            <Text bg="#DDCEF270" px={4} py={2} borderRadius="5px">
              trial period on
            </Text>
            {!isValidCard && <AddPaymentButton />}
          </>
        ) : isYearlyPlan && subscription?.subscription?.status === 'active' ? (
          <Text bg="#DDCEF270" px={4} py={2} borderRadius="5px">
            current plan
          </Text>
        ) : (
          <Button
            fontSize="12px"
            w="200px"
            variant={
              subscription?.subscription?.status === 'cancelled'
                ? 'outline'
                : 'solid'
            }
            isDisabled={isBtnDisabled}
            onClick={() => {
              if (subscription.subscription.status === 'cancelled') {
                onReactivate();
              } else {
                onPlanChoose('Pro-Annually');
              }

              setIsEmailAlertOpen(true);
            }}
            isLoading={isSubmitting}>
            {subscription?.subscription?.status === 'cancelled'
              ? 'Reactivate'
              : 'GO PRO'}
          </Button>
        )}
      </>
    );
  };

  return (
    <Flex
      minW="99vw"
      direction="column"
      alignSelf="flex-start"
      flex={1}
      position="relative"
      h="100%"
      zIndex={0}
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url("${vectorImage}")`,
        opacity: 0.5,
        zIndex: -1,
      }}>
      <Modal
        size="xl"
        isCentered
        isOpen={isPending || isUserFetching}
        onClose={() => null}>
        <ModalOverlay zIndex={0} />
        <ModalContent>
          <ModalBody display="flex" flexDirection="column" alignItems="center">
            <Text
              mt={6}
              textAlign="center"
              fontFamily="Monument Extended"
              fontWeight="200"
              fontSize="20px"
              color="#4f4f4f">
              Confirming your subscription...{'\n'}Please Wait.
            </Text>
            <CircularProgress isIndeterminate my={6} color="brand.500" />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Center bg="#efedea" p="20px" shadow="md">
        <Link to={'/dashboard'}>
          <Image src={DALogo} h="40px" />
        </Link>
      </Center>

      {isLoading ? (
        <Center flex={1}>
          <Spinner size="xl" />
        </Center>
      ) : (
        <>
          <Text
            my="20px"
            textAlign="center"
            fontFamily="Kanit"
            fontSize="60px"
            fontWeight="bold"
            letterSpacing="1px"
            color="primaryColor">
            Select your plan
          </Text>
          <Center gridGap="20px" flexWrap="wrap">
            <Button
              variant={selectedPlan === 0 ? 'solid' : 'outline'}
              w="200px"
              fontSize="12px"
              onClick={() => setSelectedPlan(0)}>
              BILLED MONTHLY
            </Button>
            <Button
              w="200px"
              fontSize="12px"
              variant={selectedPlan === 1 ? 'solid' : 'outline'}
              onClick={() => setSelectedPlan(1)}>
              BILLED YEARLY
            </Button>
          </Center>
          <SimpleGrid
            alignSelf="center"
            maxW="1000px"
            w="100%"
            justifyItems="center"
            alignItems="center"
            justifyContent="center"
            px="20px"
            py="50px"
            columns={[1, 1, 2, 3]}
            gap="20px">
            <MotionFlex
              {...animation(0)}
              align="center"
              w="100%"
              alignSelf="center"
              maxW="400px"
              flex={1}
              direction="column"
              bg="#fff"
              borderRadius="20px"
              shadow="md"
              p="20px"
              minH="650px"
              gridGap="10px">
              <Image h="50px" w="50px" src={freeLogo} />
              <Text
                mt="10px"
                color="#222"
                textAlign="center"
                fontSize="16px"
                fontWeight="bold">
                Free account
              </Text>
              <Text
                mt="20px"
                color="#838383"
                textAlign="center"
                fontSize="12px">
                Sign up today to access all of the perks below completely free.
                Upgrade at any time to unlock all of the amazing features that
                DroneAdair offers.
              </Text>
              <List pb="50px" mt="15px" spacing={2} fontSize="12px">
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="#afafaf" />
                  <Text>Receive leads based on your area/expertise</Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="#afafaf" />
                  <Text>View Available missions in your area</Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="#afafaf" />
                  <Text>See all past missions you’ve flown</Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="#afafaf" />
                  <Text>
                    Access free online resources inside the drone industry
                  </Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="#afafaf" />
                  <Text>Instant backups</Text>
                </ListItem>
              </List>
              <Divider borderColor="#ccc" />
              <Flex
                gridGap="20px"
                justify="flex-end"
                flex={1}
                direction="column"
                align="center">
                <Text fontSize="22px" fontWeight="bold" color="brand.500">
                  Free
                </Text>
                <Button
                  w="200px"
                  fontSize="12px"
                  isLoading={isSubmitting}
                  // isDisabled={isSubExist || isFreePlan}
                  isDisabled={isMonthlyPlan || isYearlyPlan}
                  onClick={() => {
                    freePlanModalRef.current?.onOpen();
                  }}>
                  GO REGULAR
                </Button>

                <FreePlanModal
                  ref={freePlanModalRef}
                  onProceed={() => {
                    createFreeSub();
                  }}
                />
              </Flex>
            </MotionFlex>

            <MotionFlex
              {...animation(0.25)}
              minH="700px"
              align="center"
              w="100%"
              alignSelf="center"
              maxW="400px"
              flex={1}
              bg="#fff"
              borderRadius="20px"
              shadow="md"
              direction="column"
              p="20px"
              border="1px solid #6728BB"
              gridGap="10px">
              <Image h="50px" w="50px" src={proLogo} />
              <Text
                mt="10px"
                color="#222"
                textAlign="center"
                fontSize="16px"
                fontWeight="bold">
                Pro subscription
              </Text>
              <Text
                mt="20px"
                color="#838383"
                textAlign="center"
                fontSize="12px">
                With DroneAdair PRO subscription your one step closer to
                automating your operations. Get back to flying and start booking
                for jobs!
              </Text>
              <List pb="50px" mt="15px" spacing={2} fontSize="12px">
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="brand.500" />
                  <Text>
                    Your own public booking website to share with clients
                  </Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="brand.500" />
                  <Text>
                    Create and send custom missions/proposals to clients
                  </Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="brand.500" />
                  <Text>Clients receive login for accessing missions</Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="brand.500" />
                  <Text>Show off all previous work</Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="brand.500" />
                  <Text>Outline Availability for easy booking</Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="brand.500" />
                  <Text>Discounts on educational courses.</Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="brand.500" />
                  <Text>3TB of Storage included</Text>
                </ListItem>
              </List>
              <Divider borderColor="#ccc" />
              <Flex
                gridGap="20px"
                justify="flex-end"
                flex={1}
                direction="column"
                align="center">
                <Text fontSize="22px" fontWeight="bold" color="brand.500">
                  {selectedPlan === 0 ? '$29/Month' : '$299/Year'}
                </Text>
                {renderProButton()}
              </Flex>
            </MotionFlex>

            <MotionFlex
              {...animation(0.5)}
              minH="650px"
              align="center"
              w="100%"
              alignSelf="center"
              maxW="400px"
              flex={1}
              bg="#fff"
              borderRadius="20px"
              shadow="md"
              direction="column"
              p="20px"
              border="1px solid #92835C"
              gridGap="10px">
              <Image h="50px" w="50px" src={premiumLogo} />
              <Text
                mt="10px"
                color="#222"
                textAlign="center"
                fontSize="16px"
                fontWeight="bold">
                Premium subscription
              </Text>
              <Text
                mt="20px"
                color="#838383"
                textAlign="center"
                fontSize="12px">
                We are currently in development of the Premium plan and will be
                releasing it later this year. If you have any suggestions or
                features you’d like to see, please email us at
                hello@droneadair.com.
              </Text>
              <List pb="50px" mt="15px" spacing={2} fontSize="12px">
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="#908361" />
                  <Text>
                    Your own public profile website to share with potential
                    clients
                  </Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="#908361" />
                  <Text>Clients receive login for accessing missions</Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="#908361" />
                  <Text>Completely White label your booking page</Text>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="row">
                  <ListIcon mt="4px" as={MdCheckCircle} color="#908361" />
                  <Text>Allow Client To Leave Reviews</Text>
                </ListItem>
              </List>
              <Divider borderColor="#ccc" />
              <Flex
                gridGap="20px"
                justify="flex-end"
                flex={1}
                direction="column"
                align="center">
                <Text fontSize="22px" fontWeight="bold" color="#908361">
                  $TBA
                </Text>
                <Button w="200px" bg="primaryColor" fontSize="12px">
                  COMING SOON
                </Button>
              </Flex>
            </MotionFlex>
          </SimpleGrid>
        </>
      )}
      <PlanSelectionEmailAlertModal
        isOpen={isEmailAlertOpen}
        onClose={() => setIsEmailAlertOpen(false)}
        userEmail={`${email}`}
        ref={finalRef}
      />
      <Flex justify={'center'} h={32}>
        <Button
          w="200px"
          fontSize="12px"
          isLoading={isSubmitting}
          onClick={() => {
            history.push('/dashboard');
          }}>
          GO TO DASHBOARD
        </Button>
      </Flex>
    </Flex>
  );
};

export default WelcomePageView;
