import React from 'react';

import { MissionAssetsUploadProps } from './MissionAssetsUpload.props';
import MissionAssetsUploadView from './MissionAssetsUpload.view';

const MissionAssetsUploadContainer = (props: MissionAssetsUploadProps) => {
  return <MissionAssetsUploadView {...props} />;
};

export default MissionAssetsUploadContainer;
