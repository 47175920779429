import React from 'react';

import { PremiumMissionCreateProps } from './PremiumMissionCreate.props';

import PremiumMissionCreateView from './PremiumMissionCreate.view';

const PremiumMissionCreateContainer = (
  props: PremiumMissionCreateProps,
): JSX.Element => {
  const pilotData = props.location.state;

  return <PremiumMissionCreateView data={pilotData} />;
};

export default PremiumMissionCreateContainer;
