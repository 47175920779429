import React, { useState } from 'react';

import {
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Image,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';

import { UserAvatarGeneratedProps, FileType } from './UserAvatar.props';
import { MdEdit } from 'react-icons/md';
import { motion } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import placeholder from 'res/placeholder.png';

const MotionAvatar = motion.custom(Avatar);

const iconStyles: any = {
  right: 10,
  bottom: 5,
  width: 25,
  height: 25,
  padding: 5,
  color: '#fff',
  borderRadius: 20,
  position: 'absolute',
  backgroundColor: '#4f4f4f',
};

const UserAvatarView = ({
  profileImage,
  isLoading,
  upload,
  localFile,
  progress,
  setLocalFile,
  givenHeight,
  givenWidht,
  borderRadius,
  reset,
  disclosure,
}: UserAvatarGeneratedProps): JSX.Element => {
  const [isDisabled, setIsDisabled] = useState(true);

  const { isOpen, onOpen, onClose } = disclosure;

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        const file = Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        });
        console.log(file);

        setLocalFile(file);
        setIsDisabled(false);
      }
    },
  });

  return (
    <>
      <MotionAvatar
        cursor="pointer"
        borderRadius={borderRadius}
        h={givenHeight}
        w={givenWidht}
        bg="#C4C4C4"
        borderColor="#ccc"
        borderWidth="1px"
        onClick={onOpen}
        src={profileImage}
        icon={<MdEdit style={iconStyles} />}
      />
      <Modal
        isCentered
        onClose={() => {
          setLocalFile({
            preview: profileImage,
          } as FileType);
          onClose();
          reset();
          setIsDisabled(true);
        }}
        isOpen={isOpen}
        motionPreset="scale">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Profile Picture</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" justifyContent="center">
            <Box
              {...getRootProps()}
              h="200px"
              w="200px"
              display="flex"
              position="relative"
              justifyContent="center">
              <Image
                h="100%"
                w="100%"
                objectFit="cover"
                src={localFile.preview}
                fallbackSrc={placeholder}
                opacity={isLoading ? 0.2 : 1}
              />
              {isLoading ? (
                <CircularProgress
                  value={progress}
                  color="buttonBgColor"
                  position="absolute"
                  alignSelf="center">
                  <CircularProgressLabel fontWeight="bold">
                    {progress}%
                  </CircularProgressLabel>
                </CircularProgress>
              ) : null}
              <input {...getInputProps()} />
            </Box>
          </ModalBody>
          <ModalFooter flexDirection="column" alignItems="center">
            <Button size="md" w="150px" mb={4} variant="outline" onClick={open}>
              SELECT IMAGE
            </Button>
            <Button
              size="md"
              w="150px"
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={upload}>
              UPLOAD
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserAvatarView;
