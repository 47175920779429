import React, { FC } from 'react';

import { Button, useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { getSubscriptions, updatePaymentMethod } from 'api/chargebee';
import { useChargebee } from 'utils/hooks/useChargebee';
import * as chargebee from 'effector/chargebee';

export const AddPaymentButton: FC = () => {
  const toast = useToast();
  const cbInstance = useChargebee();

  const [getMySubs, { isLoading: isFetching }] = useMutation(getSubscriptions, {
    onSuccess: (data) => {
      const subscriptionItem = data?.[0];
      const customer = subscriptionItem?.customer;
      const isValidCard = customer?.card_status === 'valid';

      if (isValidCard) {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Your Payment Details have been updated',
          isClosable: true,
          duration: 5000,
        });
      } else {
        toast({
          status: 'warning',
          title: 'Warning',
          description: 'Please add a Payment Method!',
          isClosable: true,
          duration: 5000,
        });
      }
    },
  });

  const [submit, { isLoading: isSubmitting }] = useMutation(
    updatePaymentMethod,
    {
      onSuccess: (options) => {
        cbInstance.openCheckout({
          hostedPage: async () => options,
          close: () => {
            cbInstance.closeAll();
            chargebee.fetchCbSubsEv();
            getMySubs();
          },
        });
      },
    },
  );

  return (
    <Button
      fontSize="12px"
      onClick={() => submit()}
      isLoading={isFetching || isSubmitting}>
      ADD PAYMENT METHOD
    </Button>
  );
};
