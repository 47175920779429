import React, { FC } from 'react';

import {
  Box,
  Text,
  Flex,
  Stack,
  Button,
  Center,
  Tooltip,
} from '@chakra-ui/react';

import { MdInfo } from 'react-icons/md';

import { Form, Formik } from 'formik';

import {
  Services,
  TotalCost,
  SubmitButton,
  Deliverables,
} from './Deliverables';
import { Header } from '../CustomMission/Header';
import { LocationDetails } from './LocationDetails';
import { LiveOnsiteSection } from './LiveOnsiteSection';

import FormikInput from 'components/primitives/FormikInput';
import FormikTextArea from 'components/primitives/FormikTextArea';
import FormikCalendar from 'components/primitives/FormikCalendar';
import BackButtonWithTitleView from 'components/primitives/BackButtonWithTitle/BackButtonWithTitle.view';

import { initialValues, validationSchema } from './CreateCustomMission.utils';

import { CustomStatus } from 'types/mission';
import { CreateCustomMissionGeneratedProps } from './CreateCustomMission.props';

const fontSize = '14px';
const brandColor = '#6728BB';

const labelProps = {
  color: '#4f4f4f',
  fontSize: '12px',
};

const css = {
  '::placeholder': {
    fontSize: '12px',
    fontStyle: 'italic',
  },
};

const CreateCustomMissionView: FC<CreateCustomMissionGeneratedProps> = ({
  isLoading,
  onCancelPress,
  onCreateMission,
}) => {
  return (
    <Flex direction="column" flex={1}>
      <Box ml={3}>
        <BackButtonWithTitleView title="" />
      </Box>
      <Flex
        mx={4}
        mt={2}
        mb={20}
        bg="#fff"
        flex={1}
        minH="90vh"
        direction="column"
        borderRadius="20px"
        overflow="hidden"
        boxShadow="0 0 10px 0 rgba(0,0,0,0.1)">
        <Header
          isEdit={true}
          isClient={false}
          isCreateMission
          status={CustomStatus.REQUESTED}
        />
        <Formik
          onSubmit={onCreateMission}
          initialValues={initialValues}
          validationSchema={validationSchema}>
          <Form>
            <Flex
              p={6}
              pb={10}
              flexWrap="wrap"
              borderBottom="1px solid #ccc"
              gridGap="30px">
              <LocationDetails />
              <Stack flex={1} spacing={6} mt={{ base: 5, sm: '0' }}>
                <Flex
                  flexWrap="wrap"
                  fontSize={fontSize}
                  fontWeight="bold"
                  gridGap="20px"
                  align="flex-start">
                  <FormikInput
                    name="clientDetails.name"
                    type="text"
                    label="Client Name"
                    labelProps={labelProps}
                    containerProps={{ flex: 1 }}
                  />
                  <FormikInput
                    type="text"
                    name="clientDetails.industry"
                    label="Industry"
                    labelProps={labelProps}
                    containerProps={{ flex: 1 }}
                  />
                  <FormikInput
                    name="package"
                    type="text"
                    label="Mission Name"
                    labelProps={labelProps}
                    containerProps={{ flex: 1 }}
                  />
                </Flex>

                <Flex
                  flexWrap="wrap"
                  fontSize={fontSize}
                  fontWeight="bold"
                  gridGap="20px"
                  align="flex-start">
                  <FormikInput
                    name="clientDetails.email"
                    type="email"
                    label="Email"
                    labelProps={labelProps}
                    containerProps={{ flex: 1, mt: 5 }}
                  />

                  <Flex flex={2}>
                    <FormikCalendar
                      css={css}
                      fontSize={fontSize}
                      brandColor={brandColor}
                      labelProps={labelProps}
                      inputSize="md"
                      checkBoxProps={{ size: 'sm', alignItems: 'center' }}
                      calendarProps={{ minDate: undefined }}
                    />
                  </Flex>
                </Flex>

                <Flex
                  flexWrap="wrap"
                  fontSize={fontSize}
                  fontWeight="bold"
                  gridGap="20px"
                  align="flex-start">
                  <FormikInput
                    name="clientDetails.phone"
                    label="Phone"
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    labelProps={labelProps}
                    containerProps={{ flex: 1, mt: 0 }}
                  />
                  <Flex flex={2}>
                    <FormikInput
                      name="workSampleURL"
                      label="URL Work Samples"
                      labelProps={labelProps}
                      fontSize={fontSize}
                      containerProps={{ flex: 1, mt: 0 }}
                    />
                  </Flex>
                </Flex>
                <FormikTextArea
                  name="missionDetails"
                  label="Mission Details"
                  placeholder="Mission details here, e.g., number of pictures, type of pictures and project details"
                  labelProps={labelProps}
                  size="lg"
                  bg="#F3F3F3"
                  css={css}
                  minH="150px"
                  fontSize={fontSize}
                />
                <LiveOnsiteSection />
              </Stack>
            </Flex>
            <Stack>
              <Flex flexWrap="wrap" borderBottom="1px solid #ccc">
                <Stack
                  minW="300px"
                  py={6}
                  flex={1.5}
                  borderRight="1px solid #ccc">
                  <Stack mb={4} px={6} spacing={4} flex={1}>
                    <Stack align="center" isInline>
                      <Text fontSize="18px" fontWeight="bold">
                        Deliverables
                      </Text>
                      <Tooltip
                        p={2}
                        px={4}
                        borderRadius="5px"
                        hasArrow
                        bg="#E1E1E1"
                        color="#000"
                        placement="top"
                        label="Photos, videos, travel expenses, etc."
                        fontSize="md">
                        <Box>
                          <MdInfo size="24px" color="#6728BB" />
                        </Box>
                      </Tooltip>
                    </Stack>
                    <Deliverables />
                  </Stack>
                  <TotalCost />
                </Stack>
                <Stack minW="300px" px={6} spacing={4} py={6} flex={1}>
                  <Stack align="center" isInline>
                    <Text fontSize="18px" fontWeight="bold">
                      Additional Services
                    </Text>
                    <Tooltip
                      p={2}
                      px={4}
                      borderRadius="5px"
                      hasArrow
                      bg="#E1E1E1"
                      color="#000"
                      placement="top"
                      label="Create Add-ons for your client to choose from"
                      fontSize="md">
                      <Box>
                        <MdInfo size="24px" color="#6728BB" />
                      </Box>
                    </Tooltip>
                  </Stack>
                  <Services />
                </Stack>
              </Flex>
              <Center pt={10} pb={4} flexWrap="wrap">
                <Button
                  isDisabled={isLoading}
                  w="240px"
                  variant="outline"
                  mr={2}
                  onClick={onCancelPress}>
                  CANCEL
                </Button>
                <SubmitButton isLoading={isLoading} />
              </Center>
            </Stack>
          </Form>
        </Formik>
      </Flex>
    </Flex>
  );
};

export default CreateCustomMissionView;
