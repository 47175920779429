import api from 'api/instance';
import {
  ChargebeeKeys,
  Coupon,
  HostedPage,
  Plan,
  SubscriptionItem,
} from 'types/chargebee';

const URL = process.env.REACT_APP_API_URL;
const PRO_MONTHLY_PLAN_ID = 'Pro-Subscription-Annually-USD-Monthly';

type ResponseType = {
  list: Array<{
    item: Plan;
  }>;
};

export const getAllPlans = async (): Promise<Plan[]> => {
  const { data } = await api.get<ResponseType>(`${URL}/chargebee/plans`);

  const plans = data.list.map(({ item }) => item);

  return plans;
};

export const getPublishableKey = async (): Promise<
  Pick<ChargebeeKeys, 'publicKey'>
> => {
  const { data } = await api.get<{ publicKey: string }>(
    `${URL}/chargebee/publishable-key`,
  );

  return data;
};

export const getSiteName = async (): Promise<
  Pick<ChargebeeKeys, 'siteName'>
> => {
  const { data } = await api.get<{ siteName: string }>(
    `${URL}/chargebee/site-name`,
  );

  return data;
};

export const getChargebeekeys = async (): Promise<ChargebeeKeys> => {
  return Promise.all([getPublishableKey(), getSiteName()]).then((response) => ({
    publicKey: response?.[0]?.publicKey,
    siteName: response?.[1]?.siteName,
  }));
};

export const chargebeeCheckout = async (
  planId: string,
  trialEnd?: number,
): Promise<HostedPage> => {
  const {
    data: { hosted_page },
  } = await api.post<{ hosted_page: HostedPage }>(`${URL}/chargebee/checkout`, {
    planId,
    trialEnd,
  });

  return hosted_page;
};

export const checkoutAcknowledge = async (
  hostedPageId: string,
): Promise<HostedPage> => {
  const {
    data: { hosted_page },
  } = await api.patch<{ hosted_page: HostedPage }>(
    `${URL}/chargebee/checkout/acknowledge/${hostedPageId}`,
  );

  return hosted_page;
};

export const generateCheckoutURL = async (
  subscriptionId: string,
): Promise<HostedPage> => {
  const {
    data: { hosted_page },
  } = await api.get<{ hosted_page: HostedPage }>(
    `${URL}/chargebee/checkout/${subscriptionId}`,
  );

  return hosted_page;
};

export const cancelSubscription = async (
  subscriptionId: string,
): Promise<any> => {
  const { data } = await api.patch(
    `${URL}/chargebee/subscription/cancel/${subscriptionId}`,
  );

  return data;
};

export const reactivateSubscription = async (
  subscriptionId: string,
): Promise<any> => {
  const { data } = await api.patch(
    `${URL}/chargebee/subscription/reactivate/${subscriptionId}`,
  );

  return data;
};

export const getSubscriptions = async (): Promise<SubscriptionItem[]> => {
  const {
    data: { list },
  } = await api.get<{ list: SubscriptionItem[] }>(
    `${URL}/chargebee/subscriptions`,
  );

  return list;
};

export const upgradePlan = async ({
  planId,
  subscriptionId,
  trialEnd,
}: {
  planId: string;
  subscriptionId: string;
  trialEnd?: number;
}) => {
  const { data } = await api.get<{ hosted_page: HostedPage }>(
    `${URL}/chargebee/checkout/update/${subscriptionId}/${planId}`,
    {
      params: {
        trialEnd,
      },
    },
  );

  return data?.hosted_page;
};

export const deleteSubscription = async (subId: string) => {
  const { data } = await api.get(
    `${URL}/chargebee/subscription/delete/${subId}`,
  );

  return data;
};

export const updatePaymentMethod = async () => {
  const { data } = await api.get<{ hosted_page: HostedPage }>(
    `${URL}/chargebee/payments/update-payment-method`,
  );

  return data.hosted_page;
};

export const getCouponDetails = async (coupon: string) => {
  const { data } = await api.get(`${URL}/chargebee/coupons/${coupon}`);

  return data?.coupon as Coupon;
};

export const getPortalSession = async () => {
  const { data } = await api.get(`${URL}/chargebee/portal/session`);

  return data;
};

export const createFreeSubscription = async () => {
  const { data } = await api.get(`${URL}/chargebee/create-free-subscription`);

  return data;
};

export const createProMonthlySubscription = async () => {
  const { data } = await api.get(
    `${URL}/chargebee/subscription/create/${PRO_MONTHLY_PLAN_ID}`,
  );

  return data;
};

export const addCouponCode = async ({
  couponCode,
  subscriptionId,
}: {
  couponCode: string;
  subscriptionId: string;
}) => {
  const { data } = await api.post(
    `${URL}/chargebee/subscription/add-coupon/${subscriptionId}`,
    {
      couponCode,
    },
  );

  return data;
};
