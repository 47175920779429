import React, {
  FC,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/modal';
import { Image } from '@chakra-ui/image';
import { Button } from '@chakra-ui/button';
import { Box, Center, Stack, Text } from '@chakra-ui/layout';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/progress';

import { useToast } from '@chakra-ui/toast';
import { useDisclosure } from '@chakra-ui/hooks';

import { useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone';

import { uploadProfilePic } from 'api/cms';
import { useMutation } from 'react-query';

import { MdCloudUpload } from 'react-icons/md';

import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {
  ImageType,
  CropperRef,
  CropperProps,
  ProfilePickerProps,
} from './ProfilePicker.props';

import { getCroppedImg } from './ProfilePicker.utils';

const colorScheme = '#6728BB';

const getInitialCrop = (ratio = 0): Crop => {
  const crop: Crop = {
    unit: 'px',
    x: 25,
    y: 25,
    width: 200,
    height: 200,
  };

  if (ratio >= 4) {
    crop.height = 100;
    crop.width = 400;
  }

  return crop;
};

const Cropper = forwardRef<CropperRef, CropperProps>((props, ref) => {
  const [image, setImage] = useState<ImageType>();
  const [crop, setCrop] = useState<Crop>(getInitialCrop(props.aspectRatio));
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const imageRef = useRef<HTMLImageElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(
    ref,
    () => ({
      onOpen: (file, fileUrl) => {
        setImage({ file, fileUrl });
        onOpen();
      },
    }),
    [],
  );

  const onSave = async () => {
    if (!completedCrop || !imageRef?.current) {
      return;
    }

    const { file, fileUrl } = await getCroppedImg(
      imageRef.current,
      completedCrop,
      image?.file?.name,
    );

    onClose();
    setImage(undefined);
    setCrop(getInitialCrop(props.aspectRatio));

    props.onSuccess(file, fileUrl);
  };

  const onModalClose = () => {
    onClose();
    setCrop(getInitialCrop(props.aspectRatio));
    setImage(undefined);
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      scrollBehavior="inside"
      onClose={onModalClose}
      onOverlayClick={onModalClose}>
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody m={2}>
          <Center border="1px dashed" borderRadius="5px" p={2}>
            <ReactCrop
              crop={crop}
              aspect={props.aspectRatio}
              circularCrop={props.circularCrop}
              onChange={(_, parentCrop) => setCrop(parentCrop)}
              onComplete={(c) => setCompletedCrop(c)}>
              <Image ref={imageRef} src={image?.fileUrl} />
            </ReactCrop>
          </Center>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Stack my={4}>
            <Button size="sm" w="100px" alignSelf="center" onClick={onSave}>
              SAVE
            </Button>
            <Button
              size="sm"
              w="100px"
              variant="ghost"
              alignSelf="center"
              onClick={onModalClose}>
              CANCEL
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

const View: FC<ProfilePickerProps> = ({
  type,
  aspectRatio,
  uploadFileToStripe,
}) => {
  const toast = useToast();
  const cropperRef = useRef<CropperRef>(null);

  const { values } = useFormikContext<any>();

  const isProfileImage = type === 0;

  const imageUrl = isProfileImage ? values?.signedUrl : values?.bannerSignedUrl;

  const [progress, setProgress] = useState(0);
  const [localFile, setLocalFile] = useState<string>(imageUrl);

  const [upload, { isLoading }] = useMutation(
    async (file: File) => {
      const response = await uploadProfilePic(file, type, (e) => {
        setProgress(Math.round((e.loaded / e.total) * 100));
      });

      try {
        await uploadFileToStripe?.(file);
      } catch (error) {
        console.log(error);
      }

      return response;
    },
    {
      onSuccess: (message) => {
        toast({
          status: 'success',
          title: message,
          duration: 2000,
          isClosable: true,
        });

        setProgress(0);
      },
    },
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    onDrop: (files) => {
      if (files.length) {
        const file = files[0];

        const fileUrl = URL.createObjectURL(file);

        cropperRef.current?.onOpen(file, fileUrl);
      }
    },
  });

  const onSuccess = (file: File, fileUrl: string) => {
    setLocalFile(fileUrl);

    upload(file);
  };

  return (
    <Stack>
      <Box
        w={isProfileImage ? '120px' : '480px'}
        h="120px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="10px"
        borderWidth="2px"
        borderStyle="dashed"
        cursor="pointer"
        overflow="hidden"
        position="relative"
        bg={`${colorScheme}10`}
        objectFit="contain"
        borderColor={colorScheme}
        {...getRootProps()}>
        {Boolean(!localFile) ? (
          <>
            <Stack
              isInline={!isProfileImage}
              p="10px"
              spacing={isProfileImage ? 0 : 4}
              align="center"
              color={colorScheme}>
              <MdCloudUpload size="60px" />
              <Text
                textAlign={isProfileImage ? 'center' : 'left'}
                fontSize="12px"
                fontWeight="bold"
                maxW="100px">
                Upload your {isProfileImage ? 'profile' : 'banner'} picture
                1080x1080
              </Text>
            </Stack>
          </>
        ) : (
          <Image
            opacity={isLoading ? 0.5 : 1}
            src={localFile}
            h="100%"
            w="100%"
            onError={() => setLocalFile('')}
          />
        )}
        {isLoading && (
          <CircularProgress
            value={progress}
            color="buttonBgColor"
            position="absolute">
            <CircularProgressLabel fontWeight="bold">
              {progress}%
            </CircularProgressLabel>
          </CircularProgress>
        )}
        <input {...getInputProps()} />
      </Box>
      <Cropper
        ref={cropperRef}
        circularCrop={false}
        onSuccess={onSuccess}
        aspectRatio={aspectRatio}
      />
    </Stack>
  );
};

export default View;
