import React, { FC, useEffect, useRef, useState } from 'react';

import {
  Box,
  Stack,
  useToast,
  Input,
  Button,
  Popover,
  Tooltip,
  FormLabel,
  IconButton,
  InputGroup,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';

import { HiDotsHorizontal } from 'react-icons/hi';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { MdAdd, MdCheck, MdDelete, MdEdit } from 'react-icons/md';

import { useField } from 'formik';

import { useMutation, useQueryCache } from 'react-query';
import { deleteEquipmentItem, updateEquipmentItem } from 'api/cms';

import { GeneratedProps } from './EquipmentInput.props';

const colorScheme = '#6728BB';

const DeleteButton = ({
  id,
  onSuccess,
}: {
  id?: string;
  onSuccess: Function;
}) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const [submit, { isLoading }] = useMutation(deleteEquipmentItem, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Item Deleted',
        isClosable: true,
        duration: 2000,
      });

      queryCache.invalidateQueries(`fetch-my-cms`, {
        exact: true,
      });

      queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
        exact: true,
      });

      onSuccess();
    },
  });

  if (!id) {
    return null;
  }

  return (
    <Button
      isLoading={isLoading}
      color="red.500"
      leftIcon={<MdDelete />}
      size="xs"
      variant="ghost"
      onClick={() => submit(id)}>
      Delete
    </Button>
  );
};

const UpdateButton = ({
  value,
  type,
  id,
  eventHandler,
  onSuccess,
}: {
  id?: string;
  type: string;
  value: string;
  eventHandler: () => void;
  onSuccess: () => void;
}) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const { value: stylings } = useField<any>('stylings')[0];

  const color = stylings?.colorScheme || colorScheme;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [submit, { isLoading }] = useMutation(
    () => updateEquipmentItem(id || '', type, value),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Item Updated',
          isClosable: true,
          duration: 2000,
        });

        queryCache.invalidateQueries(`fetch-my-cms`, {
          exact: true,
        });

        onSuccess();
      },
    },
  );

  useEffect(() => {
    buttonRef.current?.addEventListener('click', eventHandler);

    return () => {
      buttonRef.current?.removeEventListener('click', eventHandler);
    };
  }, [buttonRef.current]);

  if (!id) {
    return null;
  }

  return (
    <IconButton
      ref={buttonRef}
      isDisabled={!value}
      variant="ghost"
      size="xs"
      isRound
      aria-label="update-eqp"
      icon={<MdCheck />}
      onClick={() => submit()}
      isLoading={isLoading}
      color={color}
    />
  );
};

const View: FC<GeneratedProps> = (props) => {
  const {
    id,
    name,
    value,
    label,
    text,
    isLoading,
    placeholder,
    onChange,
    onCreate,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const isSubmitPressRef = useRef(false);

  const { value: stylings } = useField<any>('stylings')[0];

  const color = stylings?.colorScheme || colorScheme;

  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  if (value) {
    return (
      <InputGroup maxW="225px">
        <InputLeftElement pointerEvents="none" w="10px">
          <Box>
            <Box w="6px" h="6px" bg={color} borderRadius="6px" />
          </Box>
        </InputLeftElement>
        <Input
          ref={inputRef}
          variant="flushed"
          value={localValue}
          isReadOnly={!isEditing}
          onBlur={() => {
            setTimeout(() => {
              if (isSubmitPressRef.current) {
                return;
              }

              setIsEditing(false);
            }, 200);
          }}
          onChange={({ target }) => setLocalValue(target.value)}
        />
        <InputRightElement color={color} zIndex={10}>
          <Box>
            {isEditing ? (
              <UpdateButton
                id={id}
                value={localValue || ''}
                type={name}
                eventHandler={() => {
                  isSubmitPressRef.current = true;
                }}
                onSuccess={() => {
                  isSubmitPressRef.current = false;
                  setIsEditing(false);
                }}
              />
            ) : (
              <Popover placement="right-start">
                <PopoverTrigger>
                  <Box>
                    <HiDotsHorizontal />
                  </Box>
                </PopoverTrigger>
                <PopoverContent w="100px" p={2} bg="#fff">
                  <PopoverArrow />
                  <Stack>
                    <Button
                      onClick={() => {
                        setIsEditing(true);
                        inputRef.current?.focus();
                      }}
                      leftIcon={<MdEdit />}
                      size="xs"
                      variant="ghost"
                      color={color}>
                      Edit
                    </Button>
                    <DeleteButton
                      id={id}
                      onSuccess={() => inputRef.current?.focus?.()}
                    />
                  </Stack>
                </PopoverContent>
              </Popover>
            )}
          </Box>
        </InputRightElement>
      </InputGroup>
    );
  }

  return (
    <Stack id="equipment-input">
      <Stack isInline align="flex-start" spacing={1}>
        <FormLabel
          htmlFor={name}
          color="textColor"
          fontSize="12px"
          fontWeight="bold">
          {label}
        </FormLabel>
        {['OTHERS', 'SENSOR'].includes(name) && (
          <Tooltip
            placement="top"
            hasArrow
            label="E.g. Sensors, Lenses, Ground Gear">
            <Box>
              <AiFillQuestionCircle color={color} />
            </Box>
          </Tooltip>
        )}
      </Stack>
      <Stack isInline mr={4} align="center">
        <Input
          id={name}
          minW="225px"
          value={text}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
        <IconButton
          size="sm"
          isRound
          aria-label="eqp-icon"
          icon={<MdAdd size="20px" />}
          isDisabled={text.length < 1}
          isLoading={isLoading}
          onClick={onCreate}
          bg={color}
        />
      </Stack>
    </Stack>
  );
};

export default View;
