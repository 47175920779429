import React, { FC, useState } from 'react';

import {
  Flex,
  Text,
  Center,
  Stack,
  Button,
  CircularProgress,
} from '@chakra-ui/react';

import { selectors } from 'effector/session';

import { DetailsTabs } from './DetailsTabs';
import { RejectMission } from './RejectMission';
import { ChangesRequest } from './ChangesRequest';
import { EditDetailsForm } from './EditDetailsForm';
import { MissionClientInfo } from './MissionClientInfo';

import { CustomStatus } from 'types/mission';
import { MobileCustomMissionDetailsGeneratedProps } from './MobileCustomMissionDetails.props';

const View: FC<MobileCustomMissionDetailsGeneratedProps> = ({
  mission,
  missionId,
  isLoading,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showChangeRequest, setShowChangeRequest] = useState(true);

  const userType = selectors.useUser();

  const isClient = userType?.accountType === 'CLIENT';

  if (isLoading) {
    return (
      <Center minW="100vw" flex={1}>
        <CircularProgress isIndeterminate size="50px" color="brand.500" />
      </Center>
    );
  }

  if (mission === undefined) {
    return (
      <Center minW="100vw" flex={1}>
        <Text fontSize="22px" fontWeight="bold">
          Mission Not Found
        </Text>
      </Center>
    );
  }

  const isNewMission = [
    CustomStatus.REQUESTED,
    CustomStatus.PILOT_REJECTED,
  ].includes(mission.status);

  if (showReject) {
    return (
      <RejectMission
        missionId={missionId}
        onClose={() => setShowReject(false)}
      />
    );
  }

  if (
    mission.status === CustomStatus.CLIENT_CHANGE_REQUESTED &&
    showChangeRequest
  ) {
    return (
      <Flex direction="column">
        <ChangesRequest mission={mission}>
          <Text color="#908361" fontSize="16px" fontWeight="bold">
            Changes requested
          </Text>
        </ChangesRequest>
        <Flex direction="column" p="50px 20px">
          <Button onClick={() => setShowChangeRequest(false)}>
            SEE MISSION
          </Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex direction="column" transition="1s easeInOut">
      {isNewMission && isEdit === false && (
        <MissionClientInfo mission={mission}>
          {isClient ? null : mission.status === CustomStatus.REQUESTED ? (
            <Stack spacing={4} mb="100px" align="center">
              <Button
                fontSize="12px"
                w="80vw"
                variant="outline"
                onClick={() => {
                  setShowReject(true);
                }}>
                REJECT
              </Button>
              <Button fontSize="12px" w="80vw" onClick={() => setIsEdit(true)}>
                SUBMIT CUSTOM MISSION
              </Button>
            </Stack>
          ) : (
            <Center flex={1} py={10}>
              <Text>You rejected this mission request</Text>
            </Center>
          )}
        </MissionClientInfo>
      )}

      {!isNewMission && isEdit === false && (
        <DetailsTabs
          mission={mission}
          setShowReject={setShowReject}
          setIsEdit={setIsEdit}
        />
      )}

      {isEdit === true && (
        <EditDetailsForm
          mission={mission}
          missionId={missionId}
          setIsEdit={setIsEdit}
        />
      )}
    </Flex>
  );
};

export default View;
