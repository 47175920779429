import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';

import {
  Text,
  Stack,
  Modal,
  Input,
  Button,
  FormLabel,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import Flatpickr from 'react-flatpickr';

import { format, isBefore, parse } from 'date-fns';

import Calendar from 'components/primitives/Calendar';

import {
  SelectDayTimeRef,
  SelectDayTimeModalProps,
} from './SelectDayTimeModal.props';

import 'flatpickr/dist/themes/confetti.css';

const PICKER_OPTIONS = {
  enableTime: true,
  noCalendar: true,
  dateFormat: 'h:i K',
  time_24hr: false,
};

const DEFAULT_DATETIME = {
  date: '',
  from: '12:00 AM',
  to: '12:00 AM',
};

const SelectDayTimeModalView = forwardRef<
  SelectDayTimeRef,
  SelectDayTimeModalProps
>((props, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [error, setError] = useState('');
  const [datetime, setDatetime] = useState(DEFAULT_DATETIME);

  useImperativeHandle(
    ref,
    () => ({
      onOpen: () => {
        onOpen();
        setDatetime(DEFAULT_DATETIME);
      },
    }),
    [],
  );

  if (!props.mission) {
    return null;
  }

  const minDate = props.mission?.eventStart
    ? new Date(props.mission.eventStart)
    : undefined;
  const maxDate = props.mission?.dueDate
    ? new Date(props.mission.dueDate)
    : undefined;

  const getTimeFormat = (length: number) =>
    length === 7 ? 'h:mm aa' : 'hh:mm aa';

  const validateTime = (from: string, to: string) => {
    if (!datetime.date) {
      setError('Please select a date first.');

      return;
    }

    const referenceDate = datetime.date ? new Date(datetime.date) : new Date();

    const fromTime = parse(from, getTimeFormat(from.length), referenceDate);
    const toTime = parse(to, getTimeFormat(to.length), referenceDate);

    setError(
      isBefore(fromTime, toTime)
        ? ''
        : `The 'To' time cannot be before the 'From' time.`,
    );
  };

  const onAddEvent = () => {
    if (!datetime.date || !datetime.from || !datetime.to) {
      return;
    }

    const referenceDate = datetime.date ? new Date(datetime.date) : new Date();

    const startDate = parse(
      datetime.from,
      getTimeFormat(datetime.from.length),
      referenceDate,
    )?.toISOString();

    const endDate = parse(
      datetime.to,
      getTimeFormat(datetime.to.length),
      referenceDate,
    )?.toISOString();

    props.onSave(startDate, endDate);

    onClose();
  };

  return (
    <Modal
      size="2xl"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent py="20px" borderRadius="30px">
        <ModalHeader textAlign="center">Select Date and Time</ModalHeader>
        <ModalCloseButton />
        <ModalBody px="50px">
          <Stack align="center" gridGap="20px">
            <Calendar
              boxShadow="none"
              minDate={minDate}
              maxDate={maxDate}
              onChange={(date) => {
                if (Array.isArray(date)) {
                  return;
                }

                setDatetime((prev) => ({
                  ...prev,
                  date: format(date, 'yyyy-MM-dd'),
                }));

                if (error === 'Please select a date first.') {
                  setError('');
                }
              }}
            />

            <Stack gridGap="30px" isInline>
              <Stack spacing={0}>
                <FormLabel htmlFor="from">From</FormLabel>
                <Flatpickr
                  id="from"
                  name="from"
                  value={datetime.from}
                  options={PICKER_OPTIONS}
                  render={(_, ref) => <Input value={datetime.from} ref={ref} />}
                  onChange={(_, date) => {
                    const fromTime = date;
                    const toTime = datetime.to;

                    validateTime(fromTime, toTime);

                    setDatetime((prev) => ({ ...prev, from: date }));
                  }}
                />
              </Stack>

              <Stack spacing={0}>
                <FormLabel htmlFor="to">To</FormLabel>
                <Flatpickr
                  id="to"
                  name="to"
                  value={datetime.to}
                  options={PICKER_OPTIONS}
                  render={(_, ref) => (
                    <Input
                      value={datetime.to}
                      ref={ref}
                      isInvalid={Boolean(error)}
                    />
                  )}
                  onChange={(_, date) => {
                    const fromTime = datetime.from;
                    const toTime = date;
                    validateTime(fromTime, toTime);

                    setDatetime((prev) => ({ ...prev, to: date }));
                  }}
                />
              </Stack>
            </Stack>
            {Boolean(error) && (
              <Text fontSize="sm" color="red.500">
                {error}
              </Text>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter mt="20px" justifyContent="center">
          <Button onClick={onAddEvent}>Add Event</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

const MemoizedSelectDayTimeModal = memo(SelectDayTimeModalView);

export default MemoizedSelectDayTimeModal;
