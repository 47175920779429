import { CropperRef } from 'components/modules/PreviousWorkSection/Cropper';
import { useRef } from 'react';

const useCropFilesQueue = (
  store: File[] | undefined,
  onAddMediaInStore: (files: File[]) => void,
) => {
  const selectedFiles = useRef<File[]>([]);
  const cropperRef = useRef<CropperRef>(null);
  const totalFilesRef = useRef<number>(0);
  const croppedFileRef = useRef<Array<File & { preview: string }>>([]);

  const cropFile = (file: any) => {
    cropperRef.current?.onOpen(file, URL.createObjectURL(file));
  };

  const processNextSelectedFile = () => {
    const checkForDulpicatedFile = (fileWithUrl: any) => {
      const isDuplicated = store?.map((i) => i.name).includes(fileWithUrl.name);

      return isDuplicated;
    };

    if (selectedFiles.current.length === 0) return;

    const pendingFiles = [...selectedFiles.current];
    const nextFileToProcess = pendingFiles.pop();
    selectedFiles.current = pendingFiles;

    if (!Boolean(nextFileToProcess)) return;
    if (checkForDulpicatedFile(nextFileToProcess)) return;

    cropFile(nextFileToProcess);
  };

  const getSelectedFiles = (files: File[] = []) => {
    totalFilesRef.current = totalFilesRef.current + files.length;
    const pendingFiles = files.concat(selectedFiles.current);
    selectedFiles.current = pendingFiles;

    processNextSelectedFile();
  };

  const onCroppedSuccess = (file: File, preview: string) => {
    const fileWithPreview = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    croppedFileRef.current = [...croppedFileRef.current, fileWithPreview];

    if (croppedFileRef.current.length === totalFilesRef.current) {
      onAddMediaInStore(croppedFileRef.current);

      croppedFileRef.current = [];
      totalFilesRef.current = 0;
      selectedFiles.current = [];

      return;
    }

    processNextSelectedFile();
  };

  return { cropperRef, getSelectedFiles, onCroppedSuccess };
};

export default useCropFilesQueue;
