import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Image,
  Text,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

import { RefType } from './PilotCertificationModal.props';

import alertCertification from 'res/logout-img.png';

const PilotCertificationModalView = forwardRef<RefType>((props, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    [],
  );
  return (
    <Modal isCentered size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex align="center" direction="column" p="30px">
            <Image maxH="200px" objectFit="contain" src={alertCertification} />
            <Text
              mt="20px"
              textAlign="center"
              fontSize="22px"
              fontWeight="bold">
              Don't have a pilot certificate?
            </Text>
            <Text
              mt="20px"
              textAlign="center"
              fontSize="14px"
              fontWeight="normal">
              Proceed with account creation, but please upload your pilot
              certificate in your profile to publish your website and book jobs.
              Safety first!
            </Text>
            <Button w="200px" mt="10px" variant="solid" onClick={onClose}>
              GOT IT!
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export default PilotCertificationModalView;
