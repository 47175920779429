import React, { useEffect, useRef, useState } from 'react';

import {
  Text,
  Flex,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  IconButton,
} from '@chakra-ui/react';

import {
  MdEdit,
  MdDelete,
  MdLogout,
  MdHeadsetMic,
  MdStarOutline,
  MdChevronLeft,
} from 'react-icons/md';
import { BiDotsVertical } from 'react-icons/bi';

import { useHistory } from 'react-router-dom';

import { useQueryCache } from 'react-query';

import { logoutEvent } from 'effector/session/events';

import { useIsMobile } from 'utils/hooks';

import { EventRegister } from 'utils/customEvent';

import { UserDataForm } from './UserDataForm';
import { StripeConnect } from './StripeConnect';
import { SubscriptionInfo } from './SubscriptionInfo';
import { DeleteAccountButton } from 'components/modules/DeleteAccountButton';

import { SettingsGeneratedProps } from './Settings.props';
import LogoutModal from 'components/modules/LogoutModal/LogoutModal';

const SettingsView = (props: SettingsGeneratedProps) => {
  const isMobile = useIsMobile();
  const queryCache = useQueryCache();

  const [showSubscription, setShowSubscription] = useState(false);
  const logoutModalRef = useRef<any>(null);

  const history = useHistory();

  useEffect(() => {
    const onSubscriptionClick = () => {
      setShowSubscription(true);

      EventRegister.emit('render_header_content', {
        renderContent: () => (
          <Flex align="center">
            <IconButton
              size="xs"
              color="#fff"
              aria-label="dash-back"
              icon={<MdChevronLeft size="30px" />}
              variant="ghost"
              onClick={() => {
                EventRegister.emit('render_header_content', {
                  renderContent,
                  renderRightIcon,
                });

                setShowSubscription(false);
              }}
            />
            <Text fontSize="20px" fontWeight="bold" maxW="90%" noOfLines={1}>
              Your subscriptions
            </Text>
          </Flex>
        ),
      });
    };

    const renderContent = () => {
      return (
        <Text fontSize="20px" fontWeight="bold" maxW="90%" noOfLines={1}>
          Your Profile
        </Text>
      );
    };

    const renderRightIcon = () => {
      const onEditClick = () => {
        EventRegister.emit('change_is_editing', {
          isEditing: true,
        });
      };

      const onLogout = () => {
        logoutEvent();

        queryCache.removeQueries();

        history.push('/login');

        localStorage.removeItem('doNotShowMobileViewModal');
      };

      const onSupportClick = () => {
        window.open('https://help.droneadair.com/', '_blank');
      };

      return (
        <Menu>
          <MenuButton
            colorScheme="brand"
            as={IconButton as any}
            aria-label="settings-options"
            icon={<BiDotsVertical size="22px" fill="#fff" />}
            variant="ghost"
            bg="transparent"
            color="brand.500"
            size="xs"
          />
          <MenuList color="#6728BB">
            <MenuItem
              onClick={onEditClick}
              icon={<MdEdit size="20px" />}
              fontWeight="bold">
              Edit Profile
            </MenuItem>
            <MenuItem
              icon={<MdHeadsetMic size="20px" />}
              fontWeight="bold"
              onClick={onSupportClick}>
              Support
            </MenuItem>
            <MenuItem
              icon={<MdStarOutline size="20px" />}
              fontWeight="bold"
              onClick={onSubscriptionClick}>
              Your Subscription
            </MenuItem>
            {history.location.pathname === '/settings' ? (
              <>
                <MenuItem
                  onClick={() => {
                    logoutModalRef.current.onOpen();
                  }}
                  icon={<MdLogout size="20px" />}
                  color="#000"
                  fontWeight="bold">
                  Log out
                </MenuItem>
                <LogoutModal ref={logoutModalRef} onLogout={onLogout} />
              </>
            ) : null}
          </MenuList>
        </Menu>
      );
    };

    EventRegister.emit('render_header_content', {
      renderContent,
      renderRightIcon,
    });
  }, []);

  if (isMobile && showSubscription) {
    return <SubscriptionInfo {...props} />;
  }

  if (isMobile) {
    return (
      <Flex direction="column" h="100%" flex={1} maxW="100vw">
        {props.user && <UserDataForm />}
        <StripeConnect />
        <Flex
          justifyContent={'center'}
          flexDir={'column'}
          alignItems={'center'}
          gridGap={'1rem'}
          marginBottom={'2rem'}>
          <Text
            fontSize={'1.5rem'}
            color={'#4F4F4F'}
            fontFamily={'Monument Extended'}>
            Danger Zone
          </Text>
          <DeleteAccountButton />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex direction="column" h="100%" flex={1}>
      {props.user && <UserDataForm />}
      <StripeConnect />
      <SubscriptionInfo {...props} />
    </Flex>
  );
};

export default SettingsView;
