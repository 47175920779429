import React from 'react';
import { Flex, Text } from '@chakra-ui/layout';

import { TitleWithIconProps } from './TitleWithIcon.props';

const TitleWithIconView = ({
  text,
  TextIcon,
  ...props
}: TitleWithIconProps): JSX.Element => (
  <Text
    fontWeight="700"
    fontSize="xs"
    display="flex"
    alignItems="center"
    ml="-15px"
    mb="5px"
    color="primaryColor"
    py="3px"
    lineHeight="1"
    {...props}>
    <Flex mr="3px" as="span">
      {TextIcon}
    </Flex>
    <Text as="span">{text}</Text>
  </Text>
);

export default TitleWithIconView;
