import React, { useState } from 'react';

import { Box, Button, Flex, Text } from '@chakra-ui/react';

import { selectors } from 'effector/session';

import { useHistory } from 'react-router-dom';

import PilotHeader from 'components/modules/PilotHeader';

import { MissionQuoteTabs } from 'components/modules/MissionQuoteTabs';
import { NotificationsIcon } from 'components/modules/NotificationsIcon';
import { PremiumMissionTabs } from 'components/modules/PremiumMissionTabs';

import { ProMissionsGeneratedProps } from './ProMissions.props';

const useIsCustomOpen = () => {
  const history = useHistory();
  const location = history.location;
  const search = new URLSearchParams(location.search);

  const custom = search.get('type');

  return custom == '1';
};

const ProMissionsView = (_: ProMissionsGeneratedProps) => {
  const history = useHistory();
  const isCustomOpen = useIsCustomOpen();

  const firstname = selectors.useUser()?.firstName || '';

  const [index, setIndex] = useState<0 | 1>(isCustomOpen ? 1 : 0);

  const handleChangeIndex = (index: 0 | 1) => {
    const location = history.location;

    setIndex(index);
    history.push({
      state: location.state,
      search: `?type=${index}`,
      pathname: location.pathname,
    });
  };

  return (
    <Flex
      w="100%"
      h="100%"
      alignSelf="flex-start"
      py={[10, 20]}
      px={[5, 10, 20]}
      flex={1}
      direction="column">
      <PilotHeader name={firstname} />
      <Text
        color="#92835C"
        fontFamily="Monument Extended"
        fontSize="22px"
        fontWeight="600">
        Welcome {firstname}!
      </Text>
      <Flex py={4}>
        <Button
          mr={3}
          variant="outline"
          isActive={index === 0}
          onClick={() => handleChangeIndex(0)}>
          Missions
          <Box position="absolute" right="0" top="-2">
            <NotificationsIcon type={0} />
          </Box>
        </Button>
        <Button
          variant="outline"
          isActive={index === 1}
          onClick={() => handleChangeIndex(1)}>
          Custom Missions Requests
          <Box position="absolute" right="0" top="-2">
            <NotificationsIcon type={1} />
          </Box>
        </Button>
      </Flex>
      {index === 0 && <PremiumMissionTabs />}
      {index === 1 && <MissionQuoteTabs />}
    </Flex>
  );
};

export default ProMissionsView;
