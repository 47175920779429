import { createEvent, createStore } from 'effector';
import { FormValuesProps } from './Dashboard.props';

export const tabs = [
  'Mission Location',
  'Mission Details',
  'Checkout',
  'Confirmation',
];

const initialValues = {} as FormValuesProps;

export const clearStore = createEvent();

export const setFormValues = createEvent<Partial<FormValuesProps>>();

export const FormValues = createStore(initialValues, {
  name: 'mission-create-form',
})
  .on(setFormValues, (state, values) => ({ ...state, ...values }))
  .reset(clearStore);

export const setTabSelected = createEvent<number>();

export const Tabselected = createStore(0)
  .on(setTabSelected, (_, index) => index)
  .reset(clearStore);

const initialFormFilledState: { [key: number]: boolean } = {
  0: true,
  1: false,
  2: false,
  3: false,
};

export const TabFormFilled = createStore(initialFormFilledState)
  .on(setTabSelected, (state, index) =>
    index !== 3 ? { ...state, [index]: true } : { ...state },
  )
  .reset(clearStore);
