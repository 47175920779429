const convertTextFromHtml = (myString: string) => {
  let el: any = document.createElement('div');
  el.innerHTML = myString;

  const decoded = el.textContent || el.innerText || '';
  el = undefined;
  return decoded;
};

export default convertTextFromHtml;
