import { chakra } from '@chakra-ui/system';

export const PickerContainer = chakra('div', {
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    mx: [4, 4, 10],
    flex: 1,
    w: '100%',
    h: '120px',
    border: '2px dashed #6728BB',
    borderRadius: '10px',
    position: 'relative',
    overflow: 'hidden',
    p: 4,
  },
});

export const Bg = chakra('div', {
  baseStyle: {
    display: 'flex',
    w: '100%',
    h: '120px',
    bg: '#6728BB',
    position: 'absolute',
    opacity: 0.1,
    borderRadius: 10,
  },
});

export const IconBg = chakra('div', {
  baseStyle: {
    w: '80px',
    h: '80px',
    bg: '#6728BB',
    position: 'absolute',
    opacity: 0.1,
    borderRadius: '50px',
  },
});

export const scrollBarStyles = {
  '&::-webkit-scrollbar': {
    height: '10px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#F6EEFF',
    borderRadius: '20px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#6728BB',
    borderRadius: '24px',
    width: '20px',
  },
};
