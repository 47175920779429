import React, { useState } from 'react';

import { useToast } from '@chakra-ui/react';

import { checkCMS, getMyCMS, updateCMS } from 'api/cms';
import { useMutation, useQuery, useQueryCache } from 'react-query';

import { CmsCompanyNameInputProps } from './CmsCompanyNameInput.props';

import CmsCompanyNameInputView from './CmsCompanyNameInput.view';

const CmsCompanyNameInputContainer = (props: CmsCompanyNameInputProps) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const [isEdit, setIsEdit] = useState(false);
  const [newValue, setNewValue] = useState('');

  const status = 'DRAFT';

  const { data, isLoading } = useQuery(
    `fetch-my-cms-DRAFT`,
    () => getMyCMS(status),
    {
      retry: 0,
      onSuccess: (data) => {
        setNewValue(data?.about?.companyName);
      },
      onError: () => {
        console.log('error');
      },
    },
  );

  const [update, { isLoading: isCreating }] = useMutation(updateCMS, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Company Name Updated Succesfully',
        isClosable: true,
        duration: 5000,
      });

      queryCache.invalidateQueries(`fetch-my-cms`, {
        exact: true,
      });

      queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
        exact: true,
      });

      setIsEdit(false);
    },
  });

  const onCancel = () => {
    setIsEdit(false);

    if (data) {
      setNewValue(data?.about?.companyName);
    }
  };

  const onSubmit = () => {
    if (data?.about?.companyName?.trim() === newValue.trim()) {
      return;
    }

    update({
      companyName: data?.about?.companyName || '',
      payload: {
        about: {
          companyName: newValue,
        },
      } as any,
    });
  };

  if (!data) {
    return null;
  }

  return (
    <CmsCompanyNameInputView
      isEdit={isEdit}
      newValue={newValue}
      isLoading={isLoading}
      isCreating={isCreating}
      onSubmit={onSubmit}
      onCancel={onCancel}
      setIsEdit={setIsEdit}
      setNewValue={setNewValue}
      renderLabel={props.renderLabel}
      containerProps={props.containerProps}
    />
  );
};

export default CmsCompanyNameInputContainer;
