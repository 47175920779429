import React, { FC, useState } from 'react';

import {
  Box,
  Text,
  Flex,
  Center,
  Spinner,
  IconButton,
  CircularProgress,
} from '@chakra-ui/react';

import { format } from 'date-fns';

import { useIsMobile, useSearchParamState } from 'utils/hooks';

import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getCustomMissions } from 'api/custom-mission';

import { customStatusColorsMap } from 'constants/assetStatusMap';

import { EmptyMissionsPage } from '../EmptyMissionsPage';
import ResizableTableView from '../ResizableTable';
import { columnsMissionTable } from 'utils/columnsMissionTable';
import Pagination from 'components/primitives/Pagination';

type Props = { isClient: boolean };

const MISSIONS_PER_PAGE = 5;

export const OpenRequests: FC<Props> = ({ isClient }) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const [page, setPage] = useState<number>(1);

  const [search] = useSearchParamState('search');
  const [missionStatus] = useSearchParamState('status');

  const queryKey = [
    `fetch-custom-missions-open`,
    isClient,
    search,
    page,
    missionStatus,
  ];
  const queryFn = () =>
    getCustomMissions({
      isClient,
      page,
      status: isClient ? undefined : 'REQUESTED',
      limit: MISSIONS_PER_PAGE,
      search: !search ? undefined : search,
      statusFilter: !missionStatus ? undefined : missionStatus,
    });

  const { data, isFetching } = useQuery(queryKey, queryFn);
  const type = 'CUSTOM';

  const missions = data?.results || [];
  const isMissions = missions && missions?.length > 0;
  const totalPages = data?.totalPages || 0;
  const tableColumns = columnsMissionTable(missions, type);

  const onMissionClick = (customMissionId: string) => {
    history.push(`/custom-mission/${customMissionId}`);
  };

  if (isMobile) {
    return (
      <Flex
        h="100%"
        minW="100vw"
        direction="column"
        maxW="100vw"
        overflowX="hidden">
        {isFetching ? (
          <Center minH="200px" flex={1}>
            <Spinner />
          </Center>
        ) : missions.length > 0 ? (
          <Flex direction="column">
            <Flex minH="400px" direction="column">
              {missions?.map((mission) => {
                const address = mission.clientDetails?.location?.address;

                const additionalLocations =
                  mission.clientDetails.additionalLocations || [];
                const isMultiLocation = Boolean(additionalLocations.length > 1);

                return (
                  <Flex
                    gridGap="10px"
                    key={mission.id}
                    p="10px 20px"
                    marginRight="30px"
                    borderBottom="1px solid #ccc"
                    direction="column"
                    onClick={() => {
                      history.push(`/custom-mission/${mission.id}`);
                    }}>
                    <Flex justify="space-between" align="flex-end">
                      <Box>
                        <Text fontWeight="600" maxW="100%" noOfLines={1}>
                          {mission?.clientDetails?.name}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize="12px" noOfLines={1}>
                          {format(new Date(mission?.dueDate), 'dd MMM')}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex justify="space-between" align="flex-end">
                      <Box>
                        <Text fontSize="12px" maxW="90%" noOfLines={1}>
                          {isMultiLocation
                            ? `${address.slice(0, 10) + '...'} +${
                                additionalLocations.length
                              } more`
                            : address || ''}
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontSize="12px"
                          fontWeight="bold"
                          noOfLines={1}
                          textTransform="capitalize"
                          color={customStatusColorsMap[mission.status]}>
                          {mission.status?.toLocaleLowerCase()}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        ) : (
          <Text w="100%" m="30px">
            No missions available
          </Text>
        )}
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        maxWidth={isMissions ? '80vw' : undefined}
        overflow="auto">
        {isFetching ? (
          <Center p={20} flex={1}>
            <CircularProgress color="brand.500" isIndeterminate />
          </Center>
        ) : isMissions ? (
          <ResizableTableView
            data={missions}
            columns={tableColumns}
            onRowClick={onMissionClick}
          />
        ) : (
          <EmptyMissionsPage />
        )}
        {isMissions && totalPages !== 1 && page <= totalPages ? (
          <Flex mt={6} justify="flex-end">
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPreviousClick={() => setPage(1)}
              onNextClick={() => setPage(1)}
              onPageNumberClick={(pageNo) => setPage(pageNo)}
            />
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};
