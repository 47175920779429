import React, { FC } from 'react';

import { Box, Flex, Text, Center, CircularProgress } from '@chakra-ui/react';

import calculateDueDate from 'utils/calculateDueDate';
import { missionStatusMap } from 'constants/missionStatusMap';

import Pagination from 'components/primitives/Pagination';

import { MissionListGeneratedProps } from './MissionList.props';
import { EmptyMissionsPage } from '../EmptyMissionsPage';

const MissionListView: FC<MissionListGeneratedProps> = ({
  page,
  setPage,
  missions,
  isLoading,
  isMissions,
  totalPages,
  onMissionClick,
}) => {
  return (
    <Flex direction="column" borderRadius="20px">
      <Flex
        m="15px"
        direction="column"
        maxWidth={isMissions ? '80vw' : undefined}
        overflow="auto">
        {isLoading ? (
          <Center p={20} flex={1}>
            <CircularProgress color="brand.500" isIndeterminate />
          </Center>
        ) : isMissions ? (
          <Flex p="5px" mt="5px" direction="column" minW="1600px">
            <Flex
              py={2}
              px={4}
              gridGap="10px"
              fontSize="14px"
              fontWeight="bold">
              <Text flex={1.5}>ID</Text>
              <Text flex={1.5}>Email</Text>
              <Text flex={2}>Location</Text>
              <Text flex={1} textAlign="center">
                Due date
              </Text>
              <Text flex={1.5} textAlign="center">
                Status
              </Text>
              <Text flex={1} textAlign="center">
                Industry
              </Text>
            </Flex>
            {missions.map((mission) => (
              <Flex
                key={mission.id}
                py={2}
                px={4}
                mb={2}
                gridGap="10px"
                cursor="pointer"
                fontSize="16px"
                border="1px solid #ccc"
                borderRadius="5px"
                userSelect="none"
                position="relative"
                onClick={() => onMissionClick(mission.id)}>
                <Text flex={1.5} noOfLines={1}>
                  {mission?.id || '-'}
                </Text>
                <Text flex={1.5} noOfLines={1}>
                  {mission?.client?.email || '-'}
                </Text>
                <Text flex={2} noOfLines={1}>
                  {mission?.location || '-'}
                </Text>
                <Text flex={1} textAlign="center" noOfLines={1}>
                  {calculateDueDate(new Date(mission?.dueDate))}
                </Text>
                <Text
                  py="2px"
                  flex={1.5}
                  fontSize="14px"
                  fontWeight="bold"
                  textAlign="center"
                  alignSelf="center">
                  {missionStatusMap[mission?.status || 'DEFAULT']}
                </Text>
                <Text flex={1} noOfLines={1} textAlign="center">
                  {mission?.missionIndustry || '-'}
                </Text>
              </Flex>
            ))}
            <Box h="20px" />
          </Flex>
        ) : (
          <EmptyMissionsPage />
        )}
      </Flex>
      {isMissions && totalPages !== 1 && page <= totalPages ? (
        <Flex m={4} justify="flex-end">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onNextClick={() => setPage(page + 1)}
            onPreviousClick={() => setPage(page - 1)}
            onPageNumberClick={(pageNo) => setPage(pageNo)}
          />
        </Flex>
      ) : null}
    </Flex>
  );
};

export default MissionListView;
