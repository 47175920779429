import React, {
  FC,
  useMemo,
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useEffect,
} from 'react';

import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/modal';
import { Button, IconButton } from '@chakra-ui/button';
import {
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Box, Center, Divider, Flex, Stack, Text } from '@chakra-ui/layout';

import {
  MdEdit,
  MdCheck,
  MdClose,
  MdDelete,
  MdAddCircle,
} from 'react-icons/md';

import * as Yup from 'yup';
import { equals } from 'ramda';
import { Form, Formik, useField, useFormikContext } from 'formik';

import Multiselect from 'multiselect-react-dropdown';

import { SessionStore } from 'effector/session/store';

import { useMutation, useQueryCache } from 'react-query';
import { createPackageItem, updatePackageItem } from 'api/cms';

import FormikInputView from 'components/primitives/FormikInput';

import {
  CreatePackageModalType,
  CreatePackageModalProps,
} from './CreatePackageModal.props';
import { CMS, Package } from 'types/cms';
import { industriesMap } from 'constants/industriesMap';

const labelProps = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

const MAX_DELIVERABLES = 7;

const packageValidation = Yup.object().shape({
  additionalServices: Yup.array(),
  description: Yup.string()
    .required('Please, write a description for this package')
    .max(64, 'Description must be 64 characters or less'),
  lineItems: Yup.array().required(),
  name: Yup.string()
    .required('Please provide a package name. e.g. "Gold", "Premium", etc.')
    .max(25, 'Package name should be shorter than 25 characters'),
  price: Yup.number().required('Please provide the price'),
  industry: Yup.array().required(),
});

export const AddItem: FC<{ color: string }> = ({ color }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setFieldValue, values } = useFormikContext<Package>();
  const showToast = useToast();

  const [edit, setEdit] = useState({
    isEdit: false,
    index: 0,
  });
  const [lineItem, setLineItem] = useState({
    name: '',
    count: '',
  });

  const lineItems = values['lineItems'];

  const addItem = () => {
    if (lineItems.length >= MAX_DELIVERABLES) {
      showToast({
        title: 'Limit reached',
        description: `You can only add up to ${MAX_DELIVERABLES} deliverables.`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!!lineItem.count && !!lineItem.name) {
      if (edit.isEdit) {
        setFieldValue('lineItems', [
          ...lineItems.filter((_, p) => p !== edit.index),
          lineItem,
        ]);
      } else {
        setFieldValue('lineItems', [...lineItems, lineItem]);
      }

      setLineItem({
        name: '',
        count: '',
      });
      onClose();
      setEdit({
        isEdit: false,
        index: 0,
      });
    }
  };

  const onChange = (key: keyof typeof lineItem) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const hasNoNumbersChars = (value = '') => {
      const numberRegex = /^\d+$/;
      return !numberRegex.test(value);
    };

    const isNumber = ['count', 'price'].includes(key);

    const {
      target: { value = '' },
    } = e;
    const sanitazedValue = isNumber && hasNoNumbersChars(value) ? '' : value;

    setLineItem((prevState) => ({
      ...prevState,
      [key]: isNumber ? parseFloat(sanitazedValue || '') : sanitazedValue,
    }));
  };

  return (
    <Center flexDir="column">
      {isOpen && (
        <Flex px={6} alignItems="flex-end">
          <Stack>
            <Text {...labelProps}>Item Count</Text>
            <Input
              type="number"
              value={lineItem.count}
              onChange={onChange('count')}
              onKeyPress={(e: any) => {
                if (e?.code === 'Minus') {
                  e?.preventDefault();
                }
              }}
            />
          </Stack>
          <Stack mx={2}>
            <Text {...labelProps}>Item Name</Text>
            <Input value={lineItem.name} onChange={onChange('name')} />
          </Stack>
          <IconButton
            aria-label="add-item"
            size="sm"
            icon={<MdCheck size="20px" />}
            isRound
            mb={1}
            onClick={() => {
              addItem();
            }}
            bg={color}
          />
          <IconButton
            size="sm"
            aria-label="remove-item"
            icon={<MdClose size="20px" />}
            ml="2px"
            mb={1}
            isRound
            variant="ghost"
            color="red.500"
            onClick={() => {
              setLineItem({
                count: '',
                name: '',
              });
              onClose();
              setEdit({
                isEdit: false,
                index: 0,
              });
            }}
          />
        </Flex>
      )}
      <Stack align="center" mt={4}>
        <Stack>
          {lineItems.map((l, i) => {
            return (
              <Flex
                key={`${l.name}-${i}`}
                minW="300px"
                borderRadius="6px"
                bg={`${color}30`}
                align="center"
                border={`1px solid ${color}`}
                overflow="hidden">
                <Text
                  my={1}
                  mx={2}
                  w="100%"
                  color={color}
                  fontWeight="bold"
                  fontSize="14px">
                  {i + 1} - {l.name}
                </Text>
                <IconButton
                  aria-label="edit-1"
                  icon={<MdEdit />}
                  size="sm"
                  variant="ghost"
                  borderLeft={`1px solid ${color}`}
                  borderRadius="0"
                  _focus={{
                    border: 'none',
                    borderLeft: `1px solid ${color}`,
                  }}
                  color={color}
                  onClick={() => {
                    onOpen();
                    setEdit({
                      isEdit: true,
                      index: i,
                    });
                    setLineItem({
                      name: l.name,
                      count: l.count.toString(),
                    });
                  }}
                />
                <IconButton
                  aria-label="delete-item"
                  icon={<MdDelete />}
                  size="sm"
                  variant="ghost"
                  borderLeft={`1px solid ${color}`}
                  borderRadius="0"
                  _focus={{
                    border: 'none',
                    borderLeft: `1px solid ${color}`,
                  }}
                  color={color}
                  onClick={() => {
                    setFieldValue(
                      'lineItems',
                      lineItems.filter((_, p) => p !== i),
                    );
                  }}
                />
              </Flex>
            );
          })}
        </Stack>
        {!isOpen && (
          <Button
            w="160px"
            size="sm"
            variant="ghost"
            leftIcon={<MdAddCircle />}
            onClick={onOpen}
            color={color}>
            Add new Item
          </Button>
        )}
      </Stack>
    </Center>
  );
};

const IndustriesSelect: FC<{ industries: string[] }> = (props) => {
  const { industries = [] } = props;
  const [field, meta, helpers] = useField<string[]>('industry');

  const formattedIndustries = industries.map((i) =>
    industriesMap[i] ? industriesMap[i] : i,
  );

  const selectedValues = field.value?.map((i) => ({ value: i }));
  const options = formattedIndustries.map((i) => ({ value: i }));

  return (
    <FormControl mt="12px">
      <FormLabel htmlFor="industry" {...labelProps}>
        Industry/Service
      </FormLabel>
      <Multiselect
        options={options}
        selectedValues={selectedValues}
        displayValue="value"
        placeholder="Select Industry/Service"
        showCheckbox
        showArrow
        onSelect={(_, selected) => {
          helpers.setValue([...field.value, selected.value]);
        }}
        onRemove={(_, removed) => {
          helpers.setValue(field.value.filter((i) => i !== removed.value));
        }}
        style={{
          searchBox: {
            overflow: 'auto',
          },
          chips: {
            background: '#6728BB',
          },
        }}
      />
      {meta.touched && meta.error && (
        <Text fontSize="xs" color="red.500">
          {meta.error || ''}
        </Text>
      )}
    </FormControl>
  );
};

const INITIAL_VALUES = {
  additionalServices: [],
  description: '',
  lineItems: [],
  name: '',
  price: 0,
  industry: [],
};

const CreatePackageModalView: CreatePackageModalType = forwardRef(
  ({ isEdit, onClosedCallback }: CreatePackageModalProps, ref) => {
    const toast = useToast();
    const queryCache = useQueryCache();
    const {
      values: { packages, industries, stylings },
      setFieldValue,
    } = useFormikContext<CMS>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

    const color = stylings?.colorScheme;
    const pilotID = SessionStore.getState()?.session?.user.id ?? '';

    useEffect(() => {
      if (!isOpen) {
        !!onClosedCallback && onClosedCallback();
      }
    }, [isOpen]);

    const [create, { isLoading }] = useMutation(createPackageItem, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Package Created',
          isClosable: true,
          duration: 5000,
        });

        onClose();
        queryCache.invalidateQueries('fetch-my-cms', { exact: true });
        queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
          exact: true,
        });
      },
    });

    const [update, { isLoading: isUpdating }] = useMutation(updatePackageItem, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Package Updated',
          isClosable: true,
          duration: 5000,
        });

        onClose();
        queryCache.invalidateQueries('fetch-my-cms', { exact: true });
        queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
          exact: true,
        });
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        onOpen: (values) => {
          onOpen();
          if (values) {
            setInitialValues(values);
          } else {
            setInitialValues(INITIAL_VALUES);
          }
        },
      }),
      [setInitialValues],
    );

    const onSubmit = useCallback(
      (values: any) => {
        if (isEdit) {
          create({
            ...values,
            pilot: pilotID,
          });
        } else {
          setFieldValue('packages', [
            ...packages,
            {
              ...values,
              pilot: pilotID,
            },
          ]);

          onClose();
        }
      },
      [packages, pilotID, isEdit, create],
    );

    const onUpdate = useCallback(
      (values: any) => {
        if (isEdit) {
          update({
            payload: {
              ...values,
              pilot: pilotID,
            },
            id: values.id,
          });
        } else {
          setFieldValue('packages', [
            ...packages.filter((i) => i.name !== values.name),
            {
              ...values,
              pilot: pilotID,
            },
          ]);

          onClose();
        }
      },
      [packages, pilotID, isEdit, update],
    );

    const isUpdate = useMemo(() => 'id' in initialValues, [initialValues]);

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="2xl"
        scrollBehavior="inside"
        motionPreset="slideInBottom"
        closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader
            pb={0}
            textAlign="center"
            fontSize="md"
            fontFamily="Monument Extended"
            fontWeight="400">
            {isUpdate ? 'Update' : 'Create a'} Package
          </ModalHeader>
          <Divider borderColor="#f1f1f1" my={4} />
          <ModalBody p={0}>
            <Flex flex={1} direction="column">
              <Formik<Omit<Package, 'id' | 'pilot' | 'disabled' | 'createdAt'>>
                onSubmit={isUpdate ? onUpdate : onSubmit}
                validationSchema={packageValidation}
                initialValues={initialValues}>
                {({ values, isValid }) => (
                  <Form>
                    <Flex direction="column" px={6}>
                      <Text textAlign="center">Package Details</Text>
                      <FormikInputView
                        name="name"
                        label="Name"
                        labelProps={labelProps}
                      />
                      <Flex align="flex-start">
                        <IndustriesSelect industries={industries} />
                        <Box w="20px" h="20px" />
                        <FormikInputView
                          type="number"
                          name="price"
                          label="Price"
                          labelProps={labelProps}
                          containerProps={{ maxW: '120px' }}
                          onKeyPress={(e: any) => {
                            if (e?.code === 'Minus') {
                              e?.preventDefault();
                            }
                          }}
                        />
                      </Flex>
                      <FormikInputView
                        name="description"
                        label="Description"
                        labelProps={labelProps}
                      />
                    </Flex>
                    <Divider borderColor="#f1f1f1" my={4} />
                    <Text textAlign="center">Deliverables</Text>
                    <AddItem color={color} />
                    <Center flexDirection="column">
                      <Divider borderColor="#f1f1f1" mt={4} />
                      <Button
                        my={4}
                        w="160px"
                        size="sm"
                        alignSelf="center"
                        type="submit"
                        bg={color}
                        isDisabled={!isValid || equals(initialValues, values)}
                        isLoading={isLoading || isUpdating}>
                        {isUpdate ? 'UPDATE' : 'CREATE'}
                      </Button>
                    </Center>
                  </Form>
                )}
              </Formik>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  },
);

export default CreatePackageModalView;
