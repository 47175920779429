import React, { FC } from 'react';

import { AiFillFire } from 'react-icons/ai';

import {
  Box,
  Text,
  Flex,
  Input,
  Stack,
  Center,
  Button,
  Textarea,
} from '@chakra-ui/react';

import calculateDueDate from 'utils/calculateDueDate';

import { TitleWithIcon } from 'components/primitives/TitleWithIcon';

import { CustomMission, CustomStatus } from 'types/mission';

const color = '#6728BB';
const fontSize = '14px';

type Props = {
  isClient?: boolean;
  mission: CustomMission;
};

const Deliverables: FC<Props> = ({ mission }) => {
  return (
    <>
      {mission?.package?.lineItems?.map((l, i) => (
        <Flex
          key={`${l.name}-${i}`}
          minW="200px"
          maxW="580px"
          borderRadius="6px"
          bg={`${color}30`}
          align="center"
          border={`1px solid ${color}`}
          overflow="hidden">
          <Text
            my={1}
            mx={2}
            w="100%"
            color={color}
            fontWeight="bold"
            fontSize="16px">
            ({l.count}) - {l.name}
          </Text>
          <Text color={color} mx={3}>
            ${l.count * l.price}
          </Text>
        </Flex>
      ))}
    </>
  );
};

const Services: FC<Props> = ({ mission }) => {
  return (
    <>
      {mission?.package?.additionalServices?.map((l, i) => (
        <Flex
          key={`${l.name}-${i}`}
          minW="200px"
          maxW="580px"
          borderRadius="6px"
          bg={`${color}30`}
          align="center"
          border={`1px solid ${color}`}
          overflow="hidden">
          <Text
            my={1}
            mx={2}
            w="100%"
            color={color}
            fontWeight="bold"
            fontSize="16px">
            {l.name}
          </Text>
          <Text color={color} mx={3}>
            ${l.price}
          </Text>
        </Flex>
      ))}
    </>
  );
};

const TotalCost: FC<{ mission: CustomMission }> = ({ mission }) => {
  const additionalServicesCost: number =
    mission?.package?.additionalServices
      ?.map((i) => Number(i.price || '0'))
      ?.reduce((a, b) => a + b, 0) || 0;

  const lineItemsCost: number = mission?.package?.lineItems
    ?.map((i) => Number(i.count || '0') * Number(i.price || '0'))
    ?.reduce((a, b) => a + b, 0);

  const total = additionalServicesCost + lineItemsCost;

  return (
    <Flex
      pr={8}
      alignSelf="flex-end"
      direction="column"
      align="flex-end"
      justify="flex-end">
      {mission.rush && (
        <Flex
          w="100%"
          pb="10px"
          alignItems="center"
          justifyContent="space-between">
          <TitleWithIcon
            m={0}
            p={0}
            text="Rush Mission"
            TextIcon={
              <AiFillFire
                size="20px"
                color="#E9243F"
                style={{ marginBottom: '2px' }}
              />
            }
          />
          <Box w="50px" />
        </Flex>
      )}
      <Text>Mission Total Price: ${(total || 0).toFixed(2)}</Text>
    </Flex>
  );
};

export const MissionPilotInfo: FC<
  Props & {
    onEditClick: () => void;
  }
> = ({ mission, isClient, onEditClick }) => {
  if (isClient && mission.status === CustomStatus.REQUESTED) {
    return (
      <Center flex={1} py={10}>
        The Pilot has not accepted the mission yet
      </Center>
    );
  }

  const isPilotRejected = !!mission.logs?.find(
    (l) => l.type === CustomStatus.PILOT_REJECTED,
  );

  if (isPilotRejected) {
    return null;
  }

  return (
    <Stack py={6}>
      <Flex justify="space-between">
        <Text color="#424242" mx={6} fontSize="18px" fontWeight="bold">
          Mission Details
        </Text>
        {mission.status === CustomStatus.PILOT_ACCEPTED && !isClient && (
          <Button w="120px" variant="outline" mr={2} onClick={onEditClick}>
            EDIT
          </Button>
        )}
      </Flex>
      {mission?.isPilotCreated ? null : (
        <>
          <Flex
            px={6}
            maxW="1000px"
            flexWrap="wrap"
            fontSize={fontSize}
            fontWeight="bold"
            color="#92835C">
            <Stack minW="100px" flex={1}>
              <Text>Name</Text>
              <Input
                name="name"
                size="lg"
                isReadOnly
                fontSize={fontSize}
                value={mission?.package?.name || ''}
              />
            </Stack>
            <Box h="20px" w="30px" />
            <Stack minW="100px" flex={1}>
              <Text>Industry</Text>
              <Input
                size="lg"
                isReadOnly
                fontSize={fontSize}
                value={mission?.missionIndustry || ''}
              />
            </Stack>
            <Box h="20px" w="30px" />
            <Stack minW="100px" flex={1}>
              <Text>URL Work Samples</Text>
              <Input
                size="lg"
                isReadOnly
                fontSize={fontSize}
                value={mission?.workSampleURL || ''}
              />
            </Stack>
            {!!mission.pilotChosenDate && (
              <>
                <Box h="20px" w="30px" />
                <Stack flex={1}>
                  <Text>Pilot Chosen Date</Text>
                  <Input
                    size="lg"
                    isReadOnly
                    fontSize={fontSize}
                    value={
                      calculateDueDate(new Date(mission?.pilotChosenDate)) || ''
                    }
                  />
                </Stack>
              </>
            )}
          </Flex>
          <Flex
            px={6}
            pt={4}
            pb={5}
            maxW="1000px"
            fontSize={fontSize}
            fontWeight="bold"
            color="#92835C">
            <Stack minW="100px" flex={1}>
              <Text>Description</Text>
              <Textarea
                minH="100px"
                isReadOnly
                fontSize={fontSize}
                value={mission?.package?.description || ''}
              />
            </Stack>
          </Flex>
        </>
      )}
      <Flex
        flexWrap="wrap"
        fontSize={fontSize}
        fontWeight="bold"
        borderTop="1px solid #ccc"
        borderBottom="1px solid #ccc"
        color="#92835C">
        <Stack minW="300px" py={6} flex={1.5} borderRight="1px solid #ccc">
          {mission?.package?.lineItems?.length === 0 ? null : (
            <>
              <Stack mb={10} px={6} spacing={4} flex={1}>
                <Stack align="center" isInline>
                  <Text fontSize="14px" fontWeight="bold">
                    Deliverables
                  </Text>
                </Stack>
                <Deliverables mission={mission} />
              </Stack>
              <Flex pt={6} w="100%" h="1px" borderTop="1px solid #ccc" />
            </>
          )}
          <TotalCost mission={mission} />
        </Stack>
        <Stack minW="300px" px={6} spacing={4} py={6} flex={1}>
          {mission?.package?.additionalServices?.length === 0 ? null : (
            <>
              <Stack align="center" isInline>
                <Text fontSize="14px" fontWeight="bold">
                  Additional Services
                </Text>
              </Stack>
              <Services mission={mission} />
            </>
          )}
        </Stack>
      </Flex>
    </Stack>
  );
};
