import React from 'react';

import { extendTheme, Switch } from '@chakra-ui/react';
import { useField } from 'formik';

import { FormikSwitchProps } from './FormikSwitch.props';

const FormikSwitchView = ({
  name,
  ...props
}: FormikSwitchProps): JSX.Element => {
  const [{ value }, , field] = useField(name);

  return (
    <Switch
      {...props}
      isChecked={value}
      onChange={() => field.setValue(!value)}
    />
  );
};

export default FormikSwitchView;
