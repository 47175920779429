import React, { FC, memo } from 'react';

import {
  Box,
  Text,
  Flex,
  Stack,
  Badge,
  Button,
  Center,
  Heading,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  CircularProgress,
  useDisclosure,
  ModalHeader,
  chakra,
  Skeleton,
} from '@chakra-ui/react';

import { differenceInDays, format } from 'date-fns';

import { useCouponData, useIsMobile } from 'utils/hooks';

import { useHistory } from 'react-router';

import { routesSettings } from 'constants/routes';

import subscriptionPlansUtils from 'utils/chargebeeSubscriptionsUtils';

import { SettingsGeneratedProps } from './Settings.props';
import { ManageAccountButton } from './ManageAccountButton';

const subscriptionsMap: { [key: string]: string } = {
  'Free-USD-Yearly': 'Free Plan',
  'Free-Subscription-USD-Monthly': 'Free Plan',
  'Pro-Subscription-Annually-USD-Monthly': 'Pro Subscription Monthly',
  'Pro-Subscription-Annually-USD-Yearly': 'Pro Subscription Yearly',
};

const billingMap: { [key: string]: string } = {
  month: 'Monthly',
  year: 'Yearly',
};

const STATUSES = ['cancelled', 'non_renewing', 'paused', 'in_trial', 'active'];

const Span = chakra.span;

export const SubscriptionInfo: FC<SettingsGeneratedProps> = ({
  isCancelling,
  isDeleting,
  isLoading,
  isReactivating,
  onCancelPress,
  onDeleteSubscription,
  onReactivatePress,
  data,
  user,
}) => {
  const isMobile = useIsMobile();
  const history = useHistory();

  const subscription = data?.subscription;
  const status = subscription?.status;
  console.log(subscription);

  const { isFreePlan } = subscriptionPlansUtils();

  const coupon = subscription?.coupon || '';

  const isSubscription = STATUSES.includes(status || '') || user?.pro;

  const isTrialPeriod = status === 'in_trial';

  const trialStart = subscription?.trial_start
    ? subscription?.trial_start
    : null;
  const trialEnd = subscription?.trial_end ? subscription?.trial_end : null;

  const today = new Date();
  const endDate = trialEnd ? new Date(trialEnd * 1000) : new Date();

  const daysRemaining = differenceInDays(endDate, today);

  const isCardNotAdded = isFreePlan
    ? false
    : !trialEnd
    ? false
    : daysRemaining <= 7 && data?.customer?.card_status !== 'valid';

  return (
    <>
      {isLoading ? (
        <Center flex={1} minH="500px">
          <Spinner color="brand.500" size="lg" thickness="4px" />
        </Center>
      ) : subscription && isSubscription ? (
        <>
          {isMobile ? (
            <Flex direction="column" p="20px">
              <Badge
                mt="20px"
                p="5px 10px"
                w="90vw"
                borderRadius="20px"
                fontWeight="600"
                fontSize="xl"
                colorScheme={status === 'active' ? 'green' : 'yellow'}
                textTransform="capitalize"
                textAlign="center">
                {status === 'in_trial' ? 'In Trial' : status}
              </Badge>

              {isCardNotAdded && (
                <Flex
                  mt={6}
                  p={5}
                  bg="#fff"
                  direction="column"
                  gridGap="20px"
                  align="center"
                  boxShadow="0px 0px 20px 0px rgba(103, 40, 187, .1)">
                  <Text
                    textAlign="center"
                    fontFamily="Open Sans"
                    color="red.500">
                    Your trial period is about to expire. please add a payment
                    method.
                  </Text>
                </Flex>
              )}

              <Text mt="20px" whiteSpace="pre-line">
                Current Plan :{' '}
                <Span fontWeight="600">
                  {
                    subscriptionsMap[
                      (subscription as any).subscription_items?.[0]
                        ?.item_price_id
                    ]
                  }
                </Span>
              </Text>
              {!isFreePlan && (
                <Text
                  mt="10px"
                  fontSize="xl"
                  color="primaryColor"
                  fontWeight="600">
                  {`$${
                    (subscription as any).subscription_items?.[0]?.amount / 100
                  }/${billingMap[subscription.billing_period_unit]}`}
                </Text>
              )}

              <Text mt="20px">
                Billing :{' '}
                <Span fontWeight="600">
                  {billingMap[subscription.billing_period_unit]}
                </Span>
              </Text>
              {isTrialPeriod ? (
                <>
                  <Text mt="20px">
                    Trial Started on :{' '}
                    <Span fontWeight="600">
                      {trialStart
                        ? format(
                            new Date(trialStart * 1000),
                            'MM-dd-yyyy hh:mm aa',
                          )
                        : '-'}
                    </Span>
                  </Text>

                  <Text mt="20px">
                    Trial Ends on :{' '}
                    <Span fontWeight="600">
                      {trialEnd
                        ? format(
                            new Date(trialEnd * 1000),
                            'MM-dd-yyyy hh:mm aa',
                          )
                        : '-'}
                    </Span>
                  </Text>
                </>
              ) : (
                <Text mt="20px">
                  Subscribed on :{' '}
                  <Span fontWeight="600">
                    {subscription.activated_at
                      ? format(
                          new Date(subscription.activated_at * 1000),
                          'MM-dd-yyyy hh:mm aa',
                        )
                      : ''}
                  </Span>
                </Text>
              )}

              {subscription?.coupons && (
                <Flex my="50px" direction="column" borderRadius="5px">
                  <Text mb="10px" fontWeight="bold">
                    Coupons Applied
                  </Text>
                  <Flex direction="column" gridGap="20px">
                    {subscription?.coupons?.map((coup) => (
                      <Coupon key={coup.coupon_id} id={coup.coupon_id} />
                    ))}
                  </Flex>
                </Flex>
              )}

              <Flex direction="column" mt="20px" gridGap="15px">
                {['cancelled', 'non_renewing', 'paused'].includes(
                  status || '',
                ) ? (
                  <>
                    <Button
                      onClick={onReactivatePress}
                      isLoading={isReactivating}>
                      REACTIVATE PLAN
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() =>
                        history.push(routesSettings.PILOT_WELCOME_PAGE.path)
                      }>
                      {isFreePlan ? 'SUBSCRIBE TO PRO' : 'CHANGE PLAN'}
                    </Button>
                  </>
                ) : status === 'in_trial' && subscription?.cancelled_at ? (
                  <Button
                    variant="outline"
                    onClick={() =>
                      history.push(routesSettings.PILOT_WELCOME_PAGE.path)
                    }>
                    {isFreePlan ? 'SUBSCRIBE TO PRO' : 'CHANGE PLAN'}
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outline"
                      onClick={() =>
                        history.push(routesSettings.PILOT_WELCOME_PAGE.path)
                      }>
                      {isFreePlan ? 'SUBSCRIBE TO PRO' : 'CHANGE PLAN'}
                    </Button>
                    {isFreePlan ? null : (
                      <CancelSubscriptionButton
                        status={status || ''}
                        trialEnd={subscription?.trial_end || 0}
                        isCancelling={isCancelling}
                        onCancelPress={onCancelPress}
                      />
                    )}
                  </>
                )}
              </Flex>

              {Boolean(subscription) && !isFreePlan && <ManageAccountButton />}

              <Button
                mt="15px"
                onClick={() => {
                  const newSearchParams = new URLSearchParams(
                    history.location.search,
                  );
                  newSearchParams.set('showCouponPopup', 'true');

                  history.push({
                    pathname: history.location.pathname,
                    search: newSearchParams.toString(),
                  });
                }}>
                ADD NEW COUPON
              </Button>

              {isFreePlan ? null : (
                <Flex mt="20px" direction="column">
                  {subscription?.next_billing_at ? (
                    <Flex flex={1} direction="column">
                      <Text>
                        Next Billing on :{' '}
                        <Span fontWeight="600">
                          {format(
                            new Date(subscription.next_billing_at * 1000),
                            'MM-dd-yyyy hh:mm aa',
                          )}
                        </Span>
                      </Text>
                    </Flex>
                  ) : (
                    <>
                      {status === 'in_trial' &&
                        subscription?.cancel_schedule_created_at && (
                          <Flex direction="column">
                            <Text>
                              Subscription cancel initiated on :{' '}
                              <Span fontWeight="600">
                                {format(
                                  new Date(
                                    subscription.cancel_schedule_created_at *
                                      1000,
                                  ),
                                  'MM-dd-yyyy hh:mm aa',
                                )}
                              </Span>
                            </Text>
                          </Flex>
                        )}
                      {status === 'in_trial' && subscription?.cancelled_at && (
                        <Flex mt={2} direction="column">
                          <Text>
                            Subscription will be cancelled on :{' '}
                            <Span fontWeight="600">
                              {format(
                                new Date(subscription.cancelled_at * 1000),
                                'MM-dd-yyyy hh:mm aa',
                              )}
                            </Span>
                          </Text>
                        </Flex>
                      )}
                    </>
                  )}
                  {status === 'in_trial' && subscription?.cancelled_at && (
                    <>
                      <Text mt={5} fontSize="18px">
                        You can reactivate your subscription after the trial
                        period ends
                      </Text>
                      <Button isDisabled mt={4}>
                        REACTIVATE PLAN
                      </Button>
                    </>
                  )}
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex my={14} px={[4, 6]} direction="column" flex={1}>
              <Heading
                fontFamily="Monument Extended"
                color="primaryColor"
                fontWeight="200"
                fontSize="xl">
                Manage your subscriptions
              </Heading>

              {isCardNotAdded && (
                <Flex
                  mt={6}
                  p={5}
                  bg="#fff"
                  justify="space-between"
                  boxShadow="0px 0px 20px 0px rgba(103, 40, 187, .1)">
                  <Text fontFamily="Open Sans" color="red.500">
                    Your trial period is about to expire. please add a payment
                    method.
                  </Text>
                </Flex>
              )}

              <Flex
                mt={6}
                p={10}
                bg="#fff"
                direction="column"
                fontFamily="Open Sans"
                boxShadow="0px 0px 20px 0px rgba(103, 40, 187, .1)">
                <Flex alignItems="flex-start" justify="space-between" mb={4}>
                  {status === 'in_trial' && subscription?.cancelled_at ? (
                    <Text fontSize="20px" fontFamily="Open Sans">
                      You are currently on the Trial Period.
                    </Text>
                  ) : (
                    <Stack spacing={1}>
                      <Text
                        fontFamily="Monument Extended"
                        fontWeight="200"
                        whiteSpace="pre-line">
                        Current Plan :{' '}
                        {
                          subscriptionsMap[
                            (subscription as any).subscription_items?.[0]
                              ?.item_price_id
                          ]
                        }
                      </Text>
                      {!isFreePlan && (
                        <Text
                          fontSize="xl"
                          color="primaryColor"
                          pl={2}
                          fontFamily="Monument Extended"
                          fontWeight="200">
                          {`$${
                            (subscription as any).subscription_items?.[0]
                              ?.amount / 100
                          }/${billingMap[subscription.billing_period_unit]}`}
                        </Text>
                      )}
                    </Stack>
                  )}
                  <Badge
                    px={6}
                    py={1}
                    borderRadius="5px"
                    fontFamily="Monument Extended"
                    fontWeight="200"
                    fontSize="xl"
                    colorScheme={status === 'active' ? 'green' : 'yellow'}
                    textTransform="capitalize">
                    {status === 'in_trial' ? 'In Trial' : status}
                  </Badge>
                </Flex>
                <Flex
                  direction={['column', 'column', 'row']}
                  flexWrap="wrap"
                  justify="space-between"
                  flex={1}
                  alignItems="center">
                  <Flex
                    flex={1}
                    direction="column"
                    p={4}
                    border="1px solid #E2E2E2"
                    maxW="500px"
                    w="100%"
                    minW="300px"
                    borderRadius="5px">
                    <Text fontFamily="Open Sans">
                      Billing :{' '}
                      {subscription.billing_period_unit
                        ? billingMap[subscription.billing_period_unit]
                        : '-'}
                    </Text>
                    {isTrialPeriod ? (
                      <>
                        <Text mt={2} fontFamily="Open Sans">
                          Trial Started on :
                        </Text>
                        <Text
                          mt={1}
                          ml={2}
                          fontFamily="Open Sans"
                          fontWeight="600">
                          {trialStart
                            ? format(
                                new Date(trialStart * 1000),
                                'MM-dd-yyyy hh:mm aa',
                              )
                            : '-'}
                        </Text>
                        <Text mt={2} fontFamily="Open Sans">
                          Trial Ends on :
                        </Text>
                        <Text
                          mt={1}
                          ml={2}
                          fontFamily="Open Sans"
                          fontWeight="600">
                          {trialEnd
                            ? format(
                                new Date(trialEnd * 1000),
                                'MM-dd-yyyy hh:mm aa',
                              )
                            : '-'}
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text mt={2} fontFamily="Open Sans">
                          Subscribed on :
                        </Text>
                        <Text
                          mt={1}
                          ml={2}
                          fontFamily="Open Sans"
                          fontWeight="600">
                          {subscription.activated_at
                            ? format(
                                new Date(subscription.activated_at * 1000),
                                'MM-dd-yyyy hh:mm aa',
                              )
                            : ''}
                        </Text>
                      </>
                    )}
                    <Flex mt={6} alignItems="center" flexWrap="wrap">
                      {['cancelled', 'non_renewing', 'paused'].includes(
                        status || '',
                      ) ? (
                        <>
                          <Button
                            w="200px"
                            onClick={onReactivatePress}
                            isLoading={isReactivating}>
                            Reactivate Plan
                          </Button>
                          <Button
                            w="200px"
                            ml={4}
                            variant="outline"
                            onClick={() =>
                              history.push(
                                routesSettings.PILOT_WELCOME_PAGE.path,
                              )
                            }>
                            {isFreePlan ? 'Subscribe to Pro' : 'Change Plan'}
                          </Button>
                        </>
                      ) : status === 'in_trial' &&
                        subscription?.cancelled_at ? (
                        <Button
                          w="200px"
                          variant="outline"
                          onClick={() =>
                            history.push(routesSettings.PILOT_WELCOME_PAGE.path)
                          }>
                          {isFreePlan ? 'Subscribe to Pro' : 'Change Plan'}
                        </Button>
                      ) : (
                        <>
                          <Button
                            mr={4}
                            w="200px"
                            variant="outline"
                            onClick={() =>
                              history.push(
                                routesSettings.PILOT_WELCOME_PAGE.path,
                              )
                            }>
                            {isFreePlan ? 'Subscribe to Pro' : 'Change Plan'}
                          </Button>
                          {isFreePlan ? null : (
                            <CancelSubscriptionButton
                              status={status || ''}
                              trialEnd={subscription?.trial_end || 0}
                              isCancelling={isCancelling}
                              onCancelPress={onCancelPress}
                            />
                          )}
                        </>
                      )}
                    </Flex>

                    {Boolean(subscription) && !isFreePlan && (
                      <ManageAccountButton />
                    )}
                    {/* <DeleteSubscriptionButton
                  isDeleting={isDeleting}
                  onDelete={onDeleteSubscription}
                /> */}
                  </Flex>
                  <Box h="20px" w="20px" />
                  {isFreePlan ? null : (
                    <Flex
                      flex={1}
                      direction="column"
                      p={4}
                      border="1px solid #E2E2E2"
                      maxW="500px"
                      h="100%"
                      w="100%"
                      minW="300px"
                      borderRadius="5px">
                      {subscription?.next_billing_at ? (
                        <Flex flex={1} direction="column">
                          <Text fontFamily="Open Sans">Next Billing on :</Text>
                          <Text
                            mt={1}
                            ml={2}
                            fontFamily="Open Sans"
                            fontWeight="600">
                            {format(
                              new Date(subscription.next_billing_at * 1000),
                              'MM-dd-yyyy hh:mm aa',
                            )}
                          </Text>
                        </Flex>
                      ) : (
                        <>
                          {status === 'in_trial' &&
                            subscription?.cancel_schedule_created_at && (
                              <Flex direction="column">
                                <Text fontFamily="Open Sans">
                                  Subscription cancel initiated on :
                                </Text>
                                <Text
                                  mt={1}
                                  ml={2}
                                  fontFamily="Open Sans"
                                  fontWeight="600">
                                  {format(
                                    new Date(
                                      subscription.cancel_schedule_created_at *
                                        1000,
                                    ),
                                    'MM-dd-yyyy hh:mm aa',
                                  )}
                                </Text>
                              </Flex>
                            )}
                          {status === 'in_trial' && subscription?.cancelled_at && (
                            <Flex mt={2} direction="column">
                              <Text fontFamily="Open Sans">
                                Subscription will be cancelled on :
                              </Text>
                              <Text
                                mt={1}
                                ml={2}
                                fontFamily="Open Sans"
                                fontWeight="600">
                                {format(
                                  new Date(subscription.cancelled_at * 1000),
                                  'MM-dd-yyyy hh:mm aa',
                                )}
                              </Text>
                            </Flex>
                          )}
                        </>
                      )}
                      {status === 'in_trial' && subscription?.cancelled_at && (
                        <>
                          <Text mt={5} fontSize="18px" fontFamily="Open Sans">
                            You can reactivate your subscription after the trial
                            period ends
                          </Text>
                          <Button isDisabled mt={4} w="200px">
                            Reactivate Plan
                          </Button>
                        </>
                      )}
                    </Flex>
                  )}
                </Flex>
                <Flex
                  mt="30px"
                  flex={1}
                  direction="column"
                  p={4}
                  border="1px solid #E2E2E2"
                  maxW="500px"
                  h="100%"
                  w="100%"
                  minW="300px"
                  borderRadius="5px">
                  <Text mb="10px" fontFamily="Open Sans" fontWeight="bold">
                    Add New Coupon
                  </Text>

                  <Button
                    w="200px"
                    onClick={() => {
                      const newSearchParams = new URLSearchParams(
                        history.location.search,
                      );
                      newSearchParams.set('showCouponPopup', 'true');

                      history.push({
                        pathname: history.location.pathname,
                        search: newSearchParams.toString(),
                      });
                    }}>
                    Add
                  </Button>
                </Flex>
                {subscription?.coupons && (
                  <Flex
                    mt="30px"
                    flex={1}
                    direction="column"
                    p={4}
                    border="1px solid #E2E2E2"
                    maxW="500px"
                    h="100%"
                    w="100%"
                    minW="300px"
                    borderRadius="5px">
                    <Text mb="10px" fontFamily="Open Sans" fontWeight="bold">
                      Coupons Applied
                    </Text>

                    <Flex direction="column" gridGap="20px">
                      {subscription?.coupons?.map((coup) => (
                        <Coupon key={coup.coupon_id} id={coup.coupon_id} />
                      ))}
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
        </>
      ) : (
        <Flex my={14} direction="column" alignItems="center" flex={1}>
          <Center
            flexDirection="column"
            p={[5, 20]}
            bg="#fff"
            boxShadow={
              isMobile ? undefined : '0px 0px 50px 0px rgba(103, 40, 187, .23)'
            }
            maxW="800px"
            mx={2}>
            <Heading
              fontSize={['lg', '4xl']}
              color="primaryColor"
              fontFamily="Monument Extended"
              fontWeight="400"
              textAlign="center">
              Upgrade to Pro
            </Heading>
            <Text
              fontSize={['md', 'lg']}
              mt={6}
              fontWeight="400"
              fontFamily="Monument Extended"
              textAlign="center"
              maxW="75%"
              flexWrap="wrap">
              With DroneAdair PRO subscription your one step closer to
              automating your operations. Get back to flying and start booking
              for jobs!"
            </Text>
            <Button
              as="a"
              href={routesSettings.PILOT_WELCOME_PAGE.path}
              w="250px"
              mt={10}>
              GET PRO
            </Button>
          </Center>
        </Flex>
      )}
      <Modal size="xl" isCentered isOpen={isDeleting} onClose={() => null}>
        <ModalOverlay zIndex={0} />
        <ModalContent>
          <ModalBody display="flex" flexDirection="column" alignItems="center">
            <Text
              mt={6}
              textAlign="center"
              fontFamily="Monument Extended"
              fontWeight="200"
              fontSize="20px"
              color="#4f4f4f"
              whiteSpace="pre-line">
              Deleting your subscription...{'\n'}This might take a while.{'\n'}
              Please Wait.
            </Text>
            <CircularProgress isIndeterminate my={6} color="brand.500" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

// const DeleteSubscriptionButton: FC<{
//   onDelete: () => void;
//   isDeleting: boolean;
// }> = (props) => {
//   const { onDelete, isDeleting } = props;
//   const { isOpen, onOpen, onClose } = useDisclosure();

//   return (
//     <>
//       <Button
//         mt={6}
//         bg="red.500"
//         onClick={onOpen}
//         isLoading={isDeleting}
//         w="200px"
//         variant="solid"
//         colorScheme="danger">
//         Delete Subscription
//       </Button>
//       <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
//         <ModalOverlay zIndex={0} />
//         <ModalContent>
//           <ModalHeader
//             textAlign="center"
//             fontWeight="500"
//             fontFamily="Monument Extended">
//             Delete Subscription
//           </ModalHeader>
//           <ModalBody display="flex" flexDirection="column" alignItems="center">
//             <Text mb={4} mx={4} fontSize="lg" textAlign="center">
//               You cannot undo this action. all your data related to your
//               subscriptions will be deleted
//             </Text>

//             <Stack pb={6} isInline>
//               <Button variant="outline" onClick={onClose}>
//                 Cancel
//               </Button>
//               <Button
//                 onClick={() => {
//                   onClose();

//                   onDelete();
//                 }}>
//                 Delete
//               </Button>
//             </Stack>
//           </ModalBody>
//         </ModalContent>
//       </Modal>
//     </>
//   );
// };

const CancelSubscriptionButton: FC<{
  onCancelPress: () => void;
  isCancelling: boolean;
  status: string;
  trialEnd: number;
}> = ({ status, trialEnd, isCancelling, onCancelPress }) => {
  const isMobile = useIsMobile();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        borderColor="red.500"
        color="red.500"
        onClick={onOpen}
        isLoading={isCancelling}
        w={isMobile ? 'auto' : '200px'}
        variant="outline"
        isDisabled={status === 'cancelled'}>
        {isMobile ? 'CANCEL SUBSCRIPTION' : 'Cancel Subscription'}
      </Button>
      <Modal
        size={isMobile ? 'sm' : 'xl'}
        isCentered
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay zIndex={0} />
        <ModalContent>
          <ModalHeader
            textAlign="center"
            fontWeight="500"
            fontFamily="Monument Extended">
            Cancel Subscription
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column" alignItems="center">
            {status === 'in_trial' ? (
              <Text mb={4} mx={4} fontSize="lg" textAlign="center">
                You are currently in trial period which ends on{' '}
                <Text as="span" fontWeight="bold">
                  {format(new Date(trialEnd * 1000), 'MM-dd-yyyy hh:mm aa')}
                </Text>{' '}
                and hence your subscription shall be cancelled on{' '}
                <Text as="span" fontWeight="bold">
                  {format(new Date(trialEnd * 1000), 'MM-dd-yyyy hh:mm aa')}
                </Text>
                . Proceed with cancellation?
              </Text>
            ) : (
              <Text mb={4} mx={4} fontSize="lg" textAlign="center">
                Are you sure you want to cancel your subscription?
              </Text>
            )}

            <Stack pb={6} isInline={isMobile ? false : true}>
              <Button variant="outline" onClick={onClose}>
                Close
              </Button>
              <Button
                onClick={() => {
                  onClose();

                  onCancelPress();
                }}>
                Cancel Subscription
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const Coupon = memo<{ id: string }>(({ id }) => {
  const { data, isLoading } = useCouponData(id);

  return (
    <>
      {isLoading ? (
        <Skeleton
          mb="10px"
          h="15px"
          w="200px"
          startColor="pink.500"
          endColor="orange.500"
        />
      ) : (
        <Flex direction="column">
          <Text color="brand.500" fontWeight="bold">
            {data?.name || ''}{' '}
            <Span>
              {data?.discount_type === 'percentage'
                ? `(${data?.discount_percentage}%)`
                : ''}
            </Span>
          </Text>
          <Text fontSize="14px">{data?.id || ''} applied</Text>
        </Flex>
      )}
    </>
  );
});
