import React, { forwardRef, memo, useEffect, useImperativeHandle } from 'react';

import { useDisclosure, useToast } from '@chakra-ui/react';

import { differenceInDays, format } from 'date-fns';

import { getSubscriptions, updatePaymentMethod } from 'api/chargebee';
import { useMutation } from 'react-query';

import { useChargebee } from 'utils/hooks/useChargebee';

import * as chargebee from 'effector/chargebee';

import {
  RefType,
  PlanExpirationHandlerProps,
} from './PlanExpirationHandler.props';

import PlanExpirationHandlerView from './PlanExpirationHandler.view';

const Container = forwardRef<RefType, PlanExpirationHandlerProps>(
  (props, ref) => {
    const toast = useToast();
    const cbInstance = useChargebee();

    const cbSubId = chargebee.selectors.useCbSubId();
    const subscriptionData = chargebee.selectors.useCbSub();

    const { isOpen, onOpen, onClose } = useDisclosure();

    useImperativeHandle(
      ref,
      () => ({
        onOpen,
      }),
      [],
    );

    const [getMySubs, { isLoading: isFetching }] = useMutation(
      getSubscriptions,
      {
        onSuccess: (data) => {
          const subscriptionItem = data?.[0];
          const customer = subscriptionItem?.customer;
          const isValidCard = customer?.card_status === 'valid';

          if (isValidCard) {
            onClose();

            toast({
              status: 'success',
              title: 'Success',
              description: 'Your Payment Details have been updated',
              isClosable: true,
              duration: 5000,
            });
          } else {
            toast({
              status: 'warning',
              title: 'Warning',
              description: 'Please add a Payment Method!',
              isClosable: true,
              duration: 5000,
            });
          }
        },
      },
    );

    const [submit, { isLoading: isSubmitting }] = useMutation(
      updatePaymentMethod,
      {
        onSuccess: (options) => {
          cbInstance.openCheckout({
            hostedPage: async () => options,
            close: () => {
              cbInstance.closeAll();
              chargebee.fetchCbSubsEv();
              getMySubs();
            },
          });
        },
      },
    );

    const trialEndDate = subscriptionData?.subscription?.trial_end
      ? format(
          new Date(subscriptionData?.subscription?.trial_end * 1000),
          'MM-dd-yyyy hh:mm aa',
        )
      : '';

    const onModalClose = () => {
      if (isSubmitting) {
        return;
      }

      sessionStorage.setItem('isMessageSeen', 'true');

      onClose();
    };

    const onProceedClick = () => {
      sessionStorage.setItem('isMessageSeen', 'true');

      submit();
    };

    useEffect(() => {
      if (!subscriptionData) {
        return;
      }

      const subscription = subscriptionData?.subscription;

      if (!subscription) {
        return;
      }

      const isTrialPeriod = subscription.status === 'in_trial';

      if (!isTrialPeriod) {
        return;
      }

      const trialEnd = subscription?.trial_end ? subscription?.trial_end : null;

      if (!trialEnd) {
        return;
      }

      if (subscriptionData?.customer?.card_status !== 'no_card') {
        return;
      }

      const today = new Date();
      const endDate = new Date(trialEnd * 1000);

      const daysRemaining = differenceInDays(endDate, today);

      if (daysRemaining > 7) {
        return;
      }

      const isMessageSeen = sessionStorage.getItem('isMessageSeen');

      if (isMessageSeen === 'true') {
        return;
      }

      onOpen();
    }, [cbSubId]);

    return (
      <PlanExpirationHandlerView
        isOpen={isOpen}
        onClose={onModalClose}
        trialEndDate={trialEndDate}
        onProceedClick={onProceedClick}
        isSubmitting={isSubmitting || isFetching}
      />
    );
  },
);

const PlanExpirationHandler = memo(Container);

export default PlanExpirationHandler;
