import React, { FC } from 'react';

import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Flex, Stack } from '@chakra-ui/layout';

import { MdAddCircle, MdEdit } from 'react-icons/md';

import { FormikValues, useFormikContext } from 'formik';

import { socialIconsMap } from 'utils/socialItemsMap';

import AddSocialModal from '../AddSocialModal';

import { CMS, SocialType } from 'types/cms';

export const SocialNetworks: FC<{ onSave: () => void }> = ({ onSave }) => {
  const formikRef = useFormikContext<FormikValues>();
  const values = formikRef.values as CMS;
  const { isOpen, onClose, onOpen } = useDisclosure();

  const color = values?.stylings?.colorScheme || '#6728BB';

  const isLinksPresent = values?.socials?.length > 0;

  return (
    <>
      <Flex mt="10px" mb={1} justify="space-between" align="center">
        {isLinksPresent && (
          <Button
            w="80px"
            size="sm"
            variant="ghost"
            alignSelf="flex-end"
            leftIcon={<MdEdit />}
            onClick={onOpen}
            color={color}
            _focus={{ outline: 'none' }}>
            Edit
          </Button>
        )}
      </Flex>
      {isLinksPresent ? (
        <Flex mb={4} bg="#F1F1F1" py={4} px={6} borderRadius="5px">
          <Stack isInline alignItems="center" flexWrap="wrap" spacing={4}>
            {values.socials.map((social, index) => (
              <Box
                maxH="35px"
                borderRadius="5px"
                key={`social-${index}`}
                boxShadow="0 0 10px 0 rgba(0, 0, 0, 0.15)">
                {socialIconsMap(social.type as keyof typeof SocialType)}
              </Box>
            ))}
          </Stack>
        </Flex>
      ) : (
        <Button
          w="260px"
          variant="ghost"
          leftIcon={<MdAddCircle size="20px" />}
          onClick={onOpen}
          color={color}>
          Add Social Media Links
        </Button>
      )}
      <AddSocialModal isOpen={isOpen} onClose={onClose} onSave={onSave} />
    </>
  );
};
