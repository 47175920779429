import React, { FC, useCallback, useState } from 'react';

import { Center, Flex } from '@chakra-ui/react';
import { Text } from '@chakra-ui/layout';

import { CLOUD_FRONT_URL } from 'constants/cloudFronUrl';

import { useCMS } from './Context';

import { MyWorkTabs } from './MyWorkTabs';

import './premium-website-font.css';
import Carousel from 'components/modules/Carousel';

export const SkyRiseView: FC = () => {
  const { cms } = useCMS();

  const previousWork = cms?.previousWork || [];

  const [index, setIndex] = useState<0 | 1>(0);

  function fetchMyWork() {
    return (
      previousWork.map((p) => ({
        ...p,
        imageUrl: `${CLOUD_FRONT_URL}/users/portfolio/${p?.user}/${p?.keyName}?format=jpeg&width=1200`,
      })) || []
    );
  }

  const [myWork] = useState(fetchMyWork());

  const videos = myWork.filter((item) => 'url' in item);
  const photos = myWork.filter((item) => !('url' in item));

  const items = index === 0 ? photos : videos;

  const renderCarousel = useCallback((items: any) => {
    return <Carousel items={items} />;
  }, []);

  return (
    <Flex flexDir="column" justifyContent="center" h="full">
      <Text
        color="#222222"
        fontSize={{ base: '25px', md: '32px' }}
        userSelect="none"
        fontWeight="bold"
        fontFamily="Poppins, sans"
        textAlign="center">
        My Work
      </Text>
      <Center mt={3} flexDirection="column">
        {renderCarousel(items)}
        <MyWorkTabs index={index} setIndex={setIndex} />
      </Center>
    </Flex>
  );
};
