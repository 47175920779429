import React, { useState } from 'react';

import {
  Box,
  Flex,
  Text,
  Button,
  Center,
  useToast,
  CircularProgress,
} from '@chakra-ui/react';

import { useHistory, useLocation } from 'react-router';

import { useIsMobile } from 'utils/hooks';

import { routesSettings } from 'constants/routes';

import * as stripe from 'effector/stripe';
import * as session from 'effector/session';

import { MissionQuoteTabs } from 'components/modules/MissionQuoteTabs';
import { NotificationsIcon } from 'components/modules/NotificationsIcon';
import { PremiumMissionTabs } from 'components/modules/PremiumMissionTabs';
import { RefetchCmsQueryHandler } from 'components/modules/WebsiteForm/WebsiteForm.view';

import UpdateToProCardView from '../UpdateToProCard';
import Searchbar from 'components/modules/Searchbar';
import PilotHeader from 'components/modules/PilotHeader';
import MissionTabs from 'components/modules/MissionTabs';
import StripeBanner from 'components/modules/StripeBanner';
import SubscribeToPro from 'components/modules/SubscribeToPro';
import MobileViewModal from 'components/modules/MobileViewModal';
import MobileDashboard from 'components/modules/MobileDashboard';
import UberMissionRequests from 'components/modules/UberMissionRequests';

import { DashboardGeneratedProps } from './Dashboard.props';

const useIsCustomOpen = () => {
  const history = useHistory();
  const location = history.location;
  const search = new URLSearchParams(location.search);

  const custom = search.get('type');

  return custom == '1';
};

const DashboardView = ({
  isLoading,
  hasPremiumWebsite,
  isSitePublished,
  subscription,
}: DashboardGeneratedProps) => {
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();
  const isCustomOpen = useIsCustomOpen();
  const isAccConnected = stripe.selectors.useIsConnected();

  const isMobile = useIsMobile();

  const isPro = session.selectors.useIsPro();
  const isTrial = subscription?.subscription.status === 'in_trial';

  const firstname = session.selectors.useUser()?.firstName || '';

  const [index, setIndex] = useState<0 | 1>(isCustomOpen ? 1 : 0);

  const handleChangeIndex = (index: 0 | 1) => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set('type', String(index));

    if (searchParams.get('page')) {
      searchParams.delete('page');
    }

    if (searchParams.get('status')) {
      searchParams.delete('status');
    }

    setIndex(index);

    history.replace({
      state: location.state,
      search: searchParams.toString(),
      pathname: location.pathname,
    });
  };

  const renderCustomButton = () => {
    return (
      <Button
        size="sm"
        onClick={() => {
          if (!isAccConnected) {
            toast({
              status: 'warning',
              isClosable: true,
              duration: 5000,
              description:
                'Please Connect your Stripe account in order to create a Custom Mission',
            });
          } else {
            history.push(routesSettings.PILOT_CREATE_CUSTOM_MISSION.path);
          }
          // if (isSitePublished) {
          //   history.push(routesSettings.PILOT_CREATE_CUSTOM_MISSION.path);
          // } else {
          //   toast({
          //     status: 'warning',
          //     description:
          //       'Before sending Custom Missions, you must publish your Company website as it is currently unpublished.',
          //     isClosable: true,
          //     duration: 5000,
          //   });
          // }
        }}>
        CREATE CUSTOM MISSION
      </Button>
    );
  };

  if (isLoading) {
    return (
      <Center minW={isMobile ? '100vw' : undefined} flex={1}>
        <CircularProgress isIndeterminate color="brand.500" />
      </Center>
    );
  }

  if (!hasPremiumWebsite) {
    return (
      <Center flex={1}>
        <RefetchCmsQueryHandler />
      </Center>
    );
  }

  if (isMobile) {
    return <MobileDashboard />;
  }

  return (
    <Flex direction="column" flex={1} h="100%" w="100%">
      <StripeBanner />
      <Flex
        w="100%"
        h="100%"
        alignSelf="center"
        px={[5]}
        pb="100px"
        flex={1}
        direction="column">
        {isMobile ? null : (
          <>
            <Flex mt="20px" justify="space-between" align="center">
              <Text
                color="#92835C"
                fontFamily="Monument Extended"
                fontSize="26px"
                fontWeight="600">
                Welcome {firstname}!
              </Text>

              <Searchbar />

              <PilotHeader name={firstname} />
            </Flex>
            {isTrial && !Boolean(subscription?.card) ? (
              <UpdateToProCardView />
            ) : null}
          </>
        )}
        {isPro ? (
          <>
            <Flex py={4} gridGap="10px" flexWrap="wrap">
              <Button
                variant="outline"
                isActive={index === 0}
                onClick={() => handleChangeIndex(0)}>
                Package Missions
                <Box position="absolute" right="0" top="-2">
                  <NotificationsIcon type={0} />
                </Box>
              </Button>
              <Button
                variant="outline"
                isActive={index === 1}
                onClick={() => handleChangeIndex(1)}>
                Custom Missions
                <Box position="absolute" right="0" top="-2">
                  <NotificationsIcon type={1} />
                </Box>
              </Button>
            </Flex>
            {index === 0 && <PremiumMissionTabs />}
            {index === 1 && (
              <MissionQuoteTabs renderCustomButton={renderCustomButton} />
            )}
          </>
        ) : (
          <>
            <SubscribeToPro />
            <PremiumMissionTabs />
            <MissionTabs />
            <UberMissionRequests />
          </>
        )}

        <MobileViewModal />
      </Flex>
    </Flex>
  );
};

export default DashboardView;
