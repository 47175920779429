import { FormValuesProps } from 'routes/client/Dashboard/Dashboard.props';
import { Industries } from 'types/cms';
import * as Yup from 'yup';

export const MissionLocationSchema = Yup.object().shape({
  mapSelection: Yup.string(),
  location: Yup.string().when('mapSelection', {
    is: 'ADDRESS',
    then: Yup.string().required('Address is required!'),
  }),
  latitude: Yup.number().when('mapSelection', {
    is: 'GPS',
    then: Yup.number().required('Latitude is required!'),
  }),
  longitude: Yup.number().when('mapSelection', {
    is: 'GPS',
    then: Yup.number().required('Longitude is required!'),
  }),
  gps: Yup.object(),
  restricted: Yup.boolean(),
  missionIndustry: Yup.string(),
  package: Yup.string().required('Package is required!'),
  city: Yup.string().required('city is required'),
  state: Yup.string().required('state is required'),
  locationDetail: Yup.string().required('Location Details are required'),
});

export const getDefaultFormLocation = (values: FormValuesProps): any => ({
  client: '',
  location: values?.location ?? '',
  latitude: String(values.gps?.coordinates?.[1] ?? 40.712675),
  longitude: String(values.gps?.coordinates?.[0] ?? -74.006652),
  gps: values?.gps ?? {
    coordinates: [
      values.gps?.coordinates?.[0] ?? -74.006652,
      values.gps?.coordinates?.[1] ?? 40.712675,
    ],
  },
  mapSelection: 'ADDRESS',
  restricted: values?.restricted ?? false,
  missionIndustry: Industries.OTHERS,
  package: values?.package ?? '',
  city: values?.city ?? '',
  state: values?.state ?? '',
  locationDetail: values?.locationDetail ?? '',
  additionalServices: values?.additionalServices ?? [],
});
