import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { AddSocialModalProps } from './AddSocialModal.props';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useTheme,
  Button,
  Stack,
  Checkbox,
  Text,
  Input,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { SocialsItem, SocialType } from 'types/cms';
import { ErrorMessage, useField } from 'formik';
import { socialIconsMap } from 'utils/socialItemsMap';
import { indexOf } from 'lodash';
import { object } from 'yup';

const socialUrls: { [key: string]: string } = {
  [SocialType.FACEBOOK]: 'www.facebook.com/',
  [SocialType.INSTAGRAM]: 'www.instagram.com/',
  [SocialType.LINKEDIN]: 'www.linkedin.com/',
  [SocialType.TWITTER]: 'www.twitter.com/',
  [SocialType.YOUTUBE]: 'www.youtube.com/',
  [SocialType.VIMEO]: 'www.vimeo.com/',
  [SocialType.TIKTOK]: 'www.tiktok.com/',
};

const getBaseUrl = (social: string) => socialUrls[social] || '';

const getSocialUrl = (val: string, social: string) =>
  `${socialUrls[social]}${val}` || '';

type SocialItemProps = {
  name: string;
  items: Array<SocialsItem>;
  onUpdate: (val: Array<SocialsItem>) => void;
  onError: (error: boolean) => void;
};

const SocialItem = memo(
  ({ name, items, onUpdate, onError }: SocialItemProps) => {
    const theme = useTheme();
    const currentItem = items.find((i) => i.type === name);
    const itemIndex = items.findIndex((i) => i.type === name);
    const [url, setUrl] = useState(currentItem?.url?.split('/')[1] || '');
    const [isChecked, setIsChecked] = useState(currentItem ? true : false);
    const [isTouched, setIsTouched] = useState(false);

    const error = () => {
      const isError = isChecked && url === '' ? true : false;
      return isError;
    };

    const reqField = () => {
      const isReqField = isTouched && isChecked && url === '' ? true : false;
      return isReqField;
    };

    useEffect(() => {
      const urlError = error();
      onError(urlError);
    }, [isChecked, isTouched, currentItem]);

    return (
      <FormControl isInvalid={reqField()}>
        <Stack mb={4} isInline align="center" spacing={4}>
          <Checkbox
            w="20px"
            colorScheme="purple"
            isChecked={isChecked}
            onChange={() => {
              if (isChecked) {
                setUrl('');
                setIsChecked(false);
                const filteredArr = items.filter((i) => i.type !== name);
                onUpdate(filteredArr);
              } else {
                setIsChecked(true);
              }
            }}
          />
          {socialIconsMap(name as keyof typeof SocialType, undefined, '30px')}
          <Stack isInline alignItems="center" w="100%" height="50px">
            <Text
              flex={0.8}
              fontSize="sm"
              fontWeight="600"
              color={theme.colors.textColor}>
              {getBaseUrl(name)}
            </Text>
            <Stack>
              <Input
                flex={1.5}
                size="xs"
                borderRadius="5px"
                borderColor={theme.colors.textColor}
                px={2}
                py={1}
                fontSize="sm"
                isDisabled={!isChecked}
                value={url}
                onBlur={() => setIsTouched(true)}
                onChange={(e) => {
                  setUrl(e.target.value);
                  if (items && itemIndex !== -1) {
                    items[itemIndex].url = getSocialUrl(e.target.value, name);
                  } else {
                    const newObj = {
                      type: name,
                      url: getSocialUrl(e.target.value, name),
                    };
                    items.push(newObj);
                  }
                }}
              />
              {reqField() && (
                <FormErrorMessage flexDirection={'column'} alignItems="start">
                  Required field
                </FormErrorMessage>
              )}
            </Stack>
          </Stack>
        </Stack>
      </FormControl>
    );
  },
);

const AddSocialModalView = (props: AddSocialModalProps): JSX.Element => {
  const theme = useTheme();
  const [inputProps, , field] = useField('socials');
  const value = inputProps.value as Array<SocialsItem>;
  const [error, setError] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Array<SocialsItem>>(value);

  const onError = (error: boolean) => {
    if (error === true) {
      setError(true);
    } else {
      setError(false);
    }
  };

  useEffect(() => {
    setSelectedItems((prevValues) => [
      ...prevValues,
      ...value.filter((i) => !prevValues.map((p) => p.url).includes(i.url)),
    ]);
    setError((prev) => !prev);
  }, [value]);

  return (
    <Modal
      size="xl"
      isOpen={props.isOpen}
      onClose={props.onClose}
      scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="sm" color={theme.colors.primaryColor}>
          Add Social Networks
        </ModalHeader>
        <ModalCloseButton size="sm" mt={1} color={theme.colors.buttonBgColor} />
        <ModalBody>
          {Object.keys(SocialType).map((social, index) => (
            <SocialItem
              key={`${social}-${index}`}
              name={social}
              items={selectedItems}
              onUpdate={(val) => setSelectedItems(val)}
              onError={onError}
            />
          ))}
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            size="sm"
            w="136px"
            onClick={() => {
              field.setValue([...selectedItems]);
              props.onClose();

              setTimeout(() => {
                props.onSave();
              }, 500);
            }}
            isDisabled={error}>
            SAVE
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSocialModalView;
