import React, { FC, useState } from 'react';

import {
  Tab,
  Box,
  Tabs,
  Flex,
  Text,
  Stack,
  Input,
  Button,
  Divider,
  TabList,
  TabPanel,
  TabPanels,
  Checkbox,
  IconButton,
  CheckboxGroup,
  useDisclosure,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  MdEdit,
  MdCheck,
  MdDelete,
  MdArrowBack,
  MdAddCircleOutline,
  MdInfo,
} from 'react-icons/md';

import { getMyCMS } from 'api/cms';
import { pilotAcceptMission } from 'api/custom-mission';
import { useMutation, useQuery, useQueryCache } from 'react-query';

import { Form, Formik, useField, useFormikContext } from 'formik';

import FormikInput from 'components/primitives/FormikInput';
import FormikTextArea from 'components/primitives/FormikTextArea';
import FormikDatePicker from 'components/primitives/FormikDatePicker';

import { ChangesRequest } from './ChangesRequest';
import { MissionClientInfo } from './MissionClientInfo';

import { CustomMission } from 'types/mission';

import {
  getInitialValues,
  validationSchema,
} from './MobileCustomMissionDetails.utils';
import { CMS } from 'types/cms';

type Props = {
  mission: CustomMission;
  missionId: string;
  setIsEdit: (isEdit: boolean) => void;
};

const color = '#6728BB';
const labelProps = {
  fontSize: '14px',
  color: 'primaryColor',
};

const fontSize = '14px';

const css = {
  '::placeholder': {
    fontStyle: 'italic',
    fontSize: '14px',
  },
};

const sx = {
  '.chakra-checkbox__control[data-checked]': {
    background: '#6728BB',
    borderColor: '#6728BB',
    color: '#fff',
  },
  '.chakra-checkbox__control[data-focus]': {
    boxShadow: 'none',
  },
};

const tabProps = {
  fontWeight: 'bold',
  color: 'primaryColor',
  _focus: { outline: 'none' },
  _selected: { borderBottom: '4px', borderColor: 'primaryColor' },
};

export const EditDetailsForm: FC<Props> = (props) => {
  const { mission, missionId, setIsEdit } = props;

  const [index, setIndex] = useState(1);

  const toast = useToast();
  const queryCache = useQueryCache();

  const [submit, { isLoading }] = useMutation(
    (_payload: any) => pilotAcceptMission(_payload, missionId),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(`fetch-custom-mission-${missionId}`);

        toast({
          status: 'success',
          title: 'Custom Mission sent',
          isClosable: true,
          duration: 3000,
        });

        setIsEdit(false);
      },
    },
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={getInitialValues(mission)}
      onSubmit={(values) => {
        const additionalServicesCost: number =
          values.additionalServices
            ?.map((i) => Number(i.price || '0'))
            ?.reduce((a, b) => a + b, 0) || 0;

        const lineItemsCost: number = values.lineItems
          .map((i) => Number(i.count || '0') * Number(i.price || '0'))
          ?.reduce((a, b) => a + b, 0);

        const totalCharge = additionalServicesCost + lineItemsCost;

        const payload = {
          package: {
            name: values?.name,
            description: values?.description,
            price: lineItemsCost,
            lineItems: values?.lineItems,
            additionalServices: values?.additionalServices,
          },
          missionIndustry: values.missionIndustry,
          workSampleURL: values?.workSampleURL,
          totalCharge,
          pilotChosenDate: new Date(values?.pilotChosenDate).toISOString(),
        };

        submit(payload);
      }}>
      {({ errors, values, isValid, validateForm }) => {
        const additionalServicesCost: number =
          values.additionalServices
            ?.map((i) => Number(i.price || '0'))
            ?.reduce((a, b) => a + b, 0) || 0;

        const lineItemsCost: number = values.lineItems
          .map((i) => Number(i.count || '0') * Number(i.price || '0'))
          ?.reduce((a, b) => a + b, 0);

        const total = additionalServicesCost + lineItemsCost;
        return (
          <Form>
            <Tabs
              m={0}
              p={0}
              isFitted
              minW="100vw"
              maxW="100vw"
              index={index}
              onChange={setIndex}>
              <TabList m={0}>
                <Tab {...tabProps}>Location</Tab>
                {mission.isPilotCreated ? null : (
                  <Tab {...tabProps}>Details</Tab>
                )}
                <Tab {...tabProps}>Services</Tab>
              </TabList>

              <TabPanels p={0}>
                <TabPanel p={0} m={0}>
                  <MissionClientInfo mission={mission}>
                    <Flex mt="20px" gridGap="10px" mb="20px" align="center">
                      <Button
                        isDisabled={isLoading}
                        leftIcon={<MdArrowBack size="22px" />}
                        fontSize="12px"
                        variant="outline"
                        onClick={() => setIsEdit(false)}></Button>
                      <Button
                        isDisabled={isLoading}
                        fontSize="12px"
                        w="80vw"
                        onClick={() => setIndex((prev) => prev + 1)}>
                        NEXT
                      </Button>
                    </Flex>
                  </MissionClientInfo>
                </TabPanel>
                {mission.isPilotCreated ? null : (
                  <TabPanel p={0}>
                    <ChangesRequest mission={mission} />
                    <Flex direction="column" gridGap="15px" p="20px">
                      <>
                        <FormikInput
                          name="name"
                          label="Mission Name"
                          size="lg"
                          fontSize={fontSize}
                          labelProps={labelProps}
                        />
                        <FormikInput
                          size="lg"
                          name="missionIndustry"
                          label="Industry"
                          labelProps={labelProps}
                          fontSize={fontSize}
                        />
                        <FormikInput
                          size="lg"
                          name="workSampleURL"
                          label="URL Work Samples"
                          labelProps={labelProps}
                          fontSize={fontSize}
                        />
                        <FormikDatePicker
                          name="pilotChosenDate"
                          label="Date"
                          labelProps={labelProps}
                          disablePastDates={true}
                          textFieldProps={{
                            css,
                            fontSize: '14px',
                            size: 'lg',
                            bg: 'inputColor',
                          }}
                          minDate={
                            mission?.eventStart
                              ? new Date(mission.eventStart)
                              : new Date()
                          }
                          maxDate={
                            mission?.dueDate
                              ? new Date(mission.dueDate)
                              : undefined
                          }
                        />
                        <FormikTextArea
                          minH="100px"
                          name="description"
                          label="Description"
                          labelProps={labelProps}
                          fontSize={fontSize}
                        />

                        <Flex mt="20px" gridGap="10px" mb="20px" align="center">
                          <Button
                            isDisabled={isLoading}
                            leftIcon={<MdArrowBack size="22px" />}
                            fontSize="12px"
                            variant="outline"
                            onClick={() => setIndex(0)}></Button>
                          <Button
                            fontSize="12px"
                            w="80vw"
                            isDisabled={
                              isLoading ||
                              Boolean(
                                errors.name ||
                                  errors.missionIndustry ||
                                  errors.description ||
                                  errors.workSampleURL ||
                                  errors.pilotChosenDate,
                              )
                            }
                            onClick={async () => {
                              const _errors = await validateForm();

                              if (
                                _errors.name ||
                                _errors.missionIndustry ||
                                _errors.description ||
                                _errors.workSampleURL ||
                                _errors.pilotChosenDate
                              ) {
                                return;
                              }

                              setIndex((prev) => prev + 1);
                            }}>
                            NEXT
                          </Button>
                        </Flex>
                      </>
                    </Flex>
                  </TabPanel>
                )}
                <TabPanel p={0}>
                  <ChangesRequest mission={mission} />
                  <Flex direction="column" p="20px">
                    <Deliverables />
                    <Divider my="20px" borderColor="brand.500" />
                    <Services />
                    <Text mt="20px" fontWeight="bold" fontSize="22px">
                      Mission Total Price: ${total.toFixed(2)}
                    </Text>
                    <Flex mt="20px" gridGap="10px" mb="20px" align="center">
                      <Button
                        isDisabled={isLoading}
                        leftIcon={<MdArrowBack size="22px" />}
                        fontSize="12px"
                        variant="outline"
                        onClick={() => setIndex(1)}></Button>
                      <Button
                        isLoading={isLoading}
                        type="submit"
                        fontSize="12px"
                        w="80vw"
                        isDisabled={!isValid}>
                        SEND CUSTOM MISSION
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Form>
        );
      }}
    </Formik>
  );
};

type Values = ReturnType<typeof getInitialValues>;

const Deliverables: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { values, errors, touched, setFieldValue } = useFormikContext<Values>();

  const initialValue = {
    count: '',
    name: '',
    price: '',
  };

  const [localValue, setLocalValue] = useState(initialValue);

  const isInvalid = Boolean(errors?.lineItems) && Boolean(touched.lineItems);

  return (
    <>
      {isOpen === true && (
        <>
          <Flex direction="column" w="100%" gridGap="20px">
            <Stack>
              <Text {...labelProps} fontWeight="bold">
                Item Name
              </Text>
              <Input
                isInvalid={isInvalid}
                value={localValue.name}
                onChange={(e) =>
                  setLocalValue((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
              />
            </Stack>

            <Flex gridGap="20px">
              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Item Quantity
                </Text>
                <Input
                  isInvalid={isInvalid}
                  type="number"
                  value={localValue.count}
                  onChange={(e) =>
                    setLocalValue((prev) => ({
                      ...prev,
                      count: e.target.value,
                    }))
                  }
                />
              </Stack>

              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Item Price
                </Text>
                <Input
                  isInvalid={isInvalid}
                  type="number"
                  value={localValue.price}
                  onChange={(e) =>
                    setLocalValue((prev) => ({
                      ...prev,
                      price: e.target.value,
                    }))
                  }
                />
              </Stack>
            </Flex>

            <Flex align="flex-end" gridGap="20px">
              <Stack flex={1}>
                <Text {...labelProps} fontWeight="bold">
                  Total
                </Text>
                <Input
                  isReadOnly
                  value={
                    Number(localValue.price || '0') *
                    Number(localValue.count || '0')
                  }
                  bg="#CFCFCF80"
                />
              </Stack>
              <Stack flex={1}>
                <IconButton
                  w="30px"
                  aria-label="add-item"
                  size="sm"
                  icon={<MdCheck size="20px" />}
                  isRound
                  isDisabled={
                    !localValue.name || !localValue.price || !localValue.count
                  }
                  onClick={() => {
                    setFieldValue('lineItems', [
                      localValue,
                      ...values.lineItems,
                    ]);
                    setLocalValue(initialValue);
                    onClose();
                  }}
                />
              </Stack>
            </Flex>
          </Flex>
          {isInvalid && (
            <Text fontSize="sm" color="red.500">
              {errors?.lineItems || ''}
            </Text>
          )}
        </>
      )}
      {values?.lineItems?.map((l, i) => (
        <Flex
          my="20px"
          p="10px"
          key={`${l.name}-${i}`}
          borderRadius="6px"
          bg={`${color}30`}
          direction="column"
          border={`1px solid ${color}`}
          overflow="hidden">
          <Text color={color} fontWeight="bold" fontSize="16px">
            {l.name}
          </Text>
          <Divider />
          <Flex align="center" gridGap="20px" pt="10px">
            <Flex justify="center" flex={1}>
              <Text>{l.count}</Text>
            </Flex>
            <Divider
              borderColor="brand.500"
              orientation="vertical"
              minH="50px"
            />
            <Flex justify="center" flex={1}>
              <Text>${l.price}</Text>
            </Flex>
            <Divider
              orientation="vertical"
              minH="50px"
              borderColor="brand.500"
            />
            <Flex justify="center" gridGap="20px">
              <IconButton
                size="xs"
                aria-label="edit-1"
                icon={<MdEdit size="20px" />}
                variant="ghost"
                color={color}
                onClick={() => {
                  onOpen();
                  setLocalValue({
                    name: l.name,
                    price: l.price,
                    count: l.count,
                  });
                }}
              />
              <IconButton
                size="xs"
                aria-label="delete-item"
                icon={<MdDelete size="20px" />}
                variant="ghost"
                color={color}
                onClick={() => {
                  setFieldValue(
                    'lineItems',
                    values.lineItems.filter((_, p) => p !== i),
                  );
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      ))}
      {isOpen === false && (
        <>
          <Button
            variant="ghost"
            maxW="580px"
            w="100%"
            borderRadius="5px"
            bg="#F7F1FF"
            leftIcon={<MdAddCircleOutline size="20px" />}
            onClick={() => {
              onOpen();
              setLocalValue(initialValue);
            }}>
            Add new item
          </Button>
          <Text fontSize="sm" color="red.500">
            {errors?.lineItems || ''}
          </Text>
        </>
      )}
    </>
  );
};

type ServicesType = CMS['additionalServices'];

const Services: FC = () => {
  const initialValue = {
    name: '',
    price: '',
  };

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  const [services, setServices] = useState<ServicesType>([]);
  const [localValue, setLocalValue] = useState(initialValue);

  const [, _, helpers] = useField<any[]>('additionalServices');

  useQuery(`fetch-my-cms-published`, () => getMyCMS('PUBLISH'), {
    onSuccess: (data) => {
      if (data.additionalServices) {
        setServices((prev) => prev.concat(data.additionalServices));
      }
    },
    retry: 0,
    onError: () => {
      console.log('');
    },
  });

  return (
    <Flex direction="column" flex={1} mb="50px">
      <Stack my="20px" align="center" isInline>
        <Text fontSize="16px" fontWeight="bold">
          Additional Services
        </Text>
        <Tooltip
          p={2}
          px={4}
          borderRadius="5px"
          hasArrow
          bg="#E1E1E1"
          color="#000"
          placement="top"
          label="Create Add-ons for your client to choose from"
          fontSize="md">
          <Box>
            <MdInfo size="20px" color="#6728BB" />
          </Box>
        </Tooltip>
      </Stack>
      <CheckboxGroup
        onChange={(values) => {
          const formatted = values.map((v) => {
            const _item = String(v).split('pri$e');

            return { name: _item[0], price: Number(_item[1]) };
          });

          helpers.setValue(formatted);
        }}>
        <Stack>
          {services.map((a, i) => (
            <Checkbox
              sx={sx}
              fontWeight="bold"
              key={`${a.name}-${a.price}-${i}`}
              value={`${a.name}pri$e${a.price}`}>
              {a.name} - ${a.price.toFixed(2)}
            </Checkbox>
          ))}
          {isOpen === true && (
            <Flex pt={4} direction="column" gridGap="20px">
              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Item Name
                </Text>
                <Input
                  value={localValue.name}
                  onChange={(e) =>
                    setLocalValue((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
              </Stack>

              <Flex gridGap="20px" align="flex-end">
                <Stack flex={1}>
                  <Text {...labelProps} fontWeight="bold">
                    Item Price
                  </Text>
                  <Input
                    type="number"
                    value={localValue.price}
                    onChange={(e) =>
                      setLocalValue((prev) => ({
                        ...prev,
                        price: e.target.value,
                      }))
                    }
                  />
                </Stack>
                <Stack flex={1}>
                  <IconButton
                    aria-label="add-item"
                    icon={<MdCheck size="20px" />}
                    isRound
                    w="30px"
                    isDisabled={!localValue.name || !localValue.price}
                    onClick={() => {
                      setServices((prev) => [
                        ...prev,
                        {
                          name: localValue.name,
                          price: Number(localValue.price),
                        },
                      ]);

                      setLocalValue(initialValue);
                      onClose();
                    }}
                  />
                </Stack>
              </Flex>
            </Flex>
          )}
          {isOpen === false && (
            <Box pt={4}>
              <Button
                variant="ghost"
                maxW="580px"
                w="100%"
                borderRadius="5px"
                bg="#F7F1FF"
                leftIcon={<MdAddCircleOutline size="20px" />}
                onClick={() => {
                  onOpen();
                  setLocalValue(initialValue);
                }}>
                Add service
              </Button>
            </Box>
          )}
        </Stack>
      </CheckboxGroup>
    </Flex>
  );
};
