import { getMissionAssets } from 'api/mission';
import { QueryResult, useQuery } from 'react-query';
import { ApiResponse, MissionAsset } from 'types/mission';

export const useMissionAssets = (
  missionId: string,
): QueryResult<ApiResponse<MissionAsset[]>, unknown> => {
  const useQueryResult: QueryResult<
    ApiResponse<MissionAsset[]>,
    unknown
  > = useQuery(
    `fetch-missions-assets-${missionId}`,
    async () => getMissionAssets(missionId),
    {
      enabled: !!missionId,
      onError: (err) => {
        console.log(err);
      },
    },
  );

  return useQueryResult;
};
