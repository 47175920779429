import React, {
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { selectors } from 'effector/session/store';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { getUser, updateProfilePicture } from 'api/auth';
import { useToast } from '@chakra-ui/toast';
import { useDisclosure } from '@chakra-ui/hooks';

import { UserAvatarProps, FileType } from './UserAvatar.props';
import UserAvatarView from './UserAvatar.view';

const UserAvatarContainer = forwardRef(
  (props: UserAvatarProps, ref: any): JSX.Element => {
    const toast = useToast();
    const disclosure = useDisclosure();
    const queryCache = useQueryCache();

    const [progress, setProgress] = useState<number>(0);
    const [localFile, setLocalFile] = useState<FileType>({
      preview: '',
    } as FileType);

    const uid = selectors.useUserId() ?? '';

    const queryKey = `fetch-user-${uid}`;

    useImperativeHandle(
      ref,
      () => ({
        onOpen: disclosure.onOpen,
      }),
      [],
    );

    const { data: userInfo } = useQuery(
      queryKey,
      async () => await getUser(uid),
      {
        onSuccess: ({ signedUrl }) => {
          setLocalFile((prevValue) =>
            !prevValue.preview
              ? ({
                  preview: signedUrl,
                } as FileType)
              : prevValue,
          );
        },
      },
    );

    const [upload, { isLoading, reset }] = useMutation(
      async () =>
        await updateProfilePicture(localFile, uid, (e) => {
          setProgress(Math.round((e.loaded / e.total) * 100));
        }),
      {
        onSuccess: () => {
          toast({
            status: 'success',
            title: 'Upload Success',
            description: 'Profile image updated',
            isClosable: true,
            duration: 5000,
          });

          disclosure.onClose();
          queryCache.invalidateQueries(queryKey, { exact: true });

          setProgress(0);
          setLocalFile({ preview: '' } as FileType);
        },
      },
    );

    const profileImage = useMemo(() => userInfo && userInfo?.signedUrl, [
      userInfo,
    ]);

    return (
      <UserAvatarView
        reset={reset}
        progress={progress}
        localFile={localFile}
        isLoading={isLoading}
        disclosure={disclosure}
        upload={() => upload()}
        profileImage={profileImage}
        setLocalFile={setLocalFile}
        givenHeight={props?.givenHeight}
        givenWidht={props.givenWidht}
        borderRadius={props.borderRadius}
      />
    );
  },
);

export default UserAvatarContainer;
