import { selectors } from 'effector/session';
import { queryCache, useQuery } from 'react-query';
import { getNotifications, markAsRead } from 'api/notification';
import { getUserType } from 'effector/session/store';

export const useNotifications = (type: 0 | 1 | undefined) => {
  const userId = selectors.useUserId();

  const queryKey = [`fetch-notifications`, userId, type];
  const queryFn = () => getNotifications(type);
  const options = {
    // 5 minutes
    refetchInterval: 30 * 1000,
    onError: () => console.log(''),
  };

  const query = useQuery(queryKey, queryFn, options);

  return query;
};

export const markNotificationAsRead = async (
  missionId: string,
  type: 0 | 1,
) => {
  const userType = getUserType();

  if (userType !== 'PILOT') {
    return;
  }

  try {
    const query: any = queryCache
      .getQueries()
      .find(
        (q) =>
          q.queryKey.includes(`fetch-notifications`) &&
          q.queryKey.includes(type),
      );

    const notifications = query?.state?.data;

    const notification = notifications?.results?.find(
      (n: any) => n.missionId === missionId || n.customMissionId === missionId,
    );

    if (notification && notification.isRead === false) {
      await markAsRead(notification.id);

      queryCache.invalidateQueries({
        queryKey: [`fetch-notifications`],
      });
    }
  } catch (e) {
    console.log('');
  }
};
