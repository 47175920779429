import React, { useEffect, useMemo, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';

import { Form, Formik } from 'formik';

import { IoIosFilm } from 'react-icons/io';
import { SiGooglemaps } from 'react-icons/si';
import { MdNotes } from 'react-icons/md';

import { useStore } from 'effector-react';
import { SessionStore } from 'effector/session/store';

import { ResponsivePackageBoxContainer } from '../QuickQuote/QuickQuote.style';

import { MobilePacksCarousel } from '../MobilePacksCarousel';
import { PackageBox } from 'components/primitives/PackageBox';
import { TitleWithIcon } from 'components/primitives/TitleWithIcon';

import GoogleMaps from 'components/primitives/GoogleMaps';
import FormikInput from 'components/primitives/FormikInput';
import PlacesAutocompleteView from 'components/primitives/PlacesAutocomplete';

import {
  FormValues,
  setFormValues,
  setTabSelected,
} from 'routes/client/Dashboard/Dashboard.utils';

import {
  MissionLocationSchema,
  getDefaultFormLocation,
} from './MissionLocationPanel.utils';

import { MissionLocationPanelProps } from './MissionLocationPanel.props';
import { MapMarkerType } from 'components/primitives/GoogleMaps/GoogleMaps.props';
import FormikTextAreaView from 'components/primitives/FormikTextArea';
import { SelectLocationButton } from './SelectLocationButton';
import { Industries } from 'types/cms';
import { AdditionalServicesSelect } from 'routes/premiumWebsite/PremiumMissionCreate/PremiumMissionCreate.view';
import { ADDITIONAL_SERVICES } from 'constants/allServices';

const direction: any = ['column', 'column', 'column', 'column', 'row'];
const width: any = ['100%', '100%', '100%', '100%', '48%'];
const labelProps: any = {
  fontWeight: '700',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  mb: '5px',
};

const MissionLocationPanelView = (props: MissionLocationPanelProps) => {
  const formValues = useStore(FormValues);
  const [isSmall] = useMediaQuery('(max-width: 600px)');
  const userId = SessionStore.getState()?.session?.user?.id ?? '';

  const initialCoordinates = useMemo(
    () => ({
      lat: formValues?.gps?.coordinates?.[1] ?? 40.712675,
      lng: formValues?.gps?.coordinates?.[0] ?? -74.006652,
    }),
    [],
  );

  const [mapMarker, setMapMarker] = useState<MapMarkerType>(initialCoordinates);

  const coordinates = useMemo(
    () => ({
      lat: mapMarker.lat,
      lng: mapMarker.lng,
    }),
    [mapMarker],
  );

  useEffect(() => {
    window.scrollTo({
      top: 10,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Flex
      p={{ base: '10px 20px', md: '30px 50px' }}
      background="white"
      borderRadius="10px"
      border="1px"
      borderColor="#ccc"
      flexDirection="column"
      boxShadow="md">
      <Formik
        initialValues={getDefaultFormLocation(formValues)}
        enableReinitialize
        validateOnMount
        validationSchema={MissionLocationSchema}
        onSubmit={(values) => {
          setFormValues({
            ...formValues,
            location: values.location,
            gps: {
              coordinates: [+values.longitude, +values.latitude],
            },
            restricted: values.restricted,
            client: userId,
            missionIndustry: Industries.OTHERS,
            package: values.package,
            city: values.city,
            state: values.state,
            locationDetail: values.locationDetail,
            additionalServices: values?.additionalServices,
          });
          setTabSelected(1);
        }}>
        {(formik) => {
          const { values, setFieldValue, submitForm, errors } = formik;
          return (
            <Form>
              <Flex id="address-tab" flexDirection="column" mb="20px">
                <TitleWithIcon
                  text="Select Location"
                  TextIcon={<SiGooglemaps size="16px" />}
                />
                <Text fontSize="sm">
                  Select the location on the map, write the address or the GPS
                  coordinates below where the footage is going to be taken. Some
                  areas could have flying restrictions.
                </Text>
              </Flex>
              <Flex
                mb="20px"
                justifyContent="space-between"
                direction={direction}>
                <Box
                  w={width}
                  height="250px"
                  mb="20px"
                  background="#fff"
                  border="1px"
                  borderColor="#ccc"
                  bg="inputColor">
                  <GoogleMaps
                    mapContainerStyle={{
                      width: '100%',
                      height: '250px',
                    }}
                    initialCenter={coordinates}
                    marker={coordinates}
                    zoom={13}
                    setFieldValue={setFieldValue}
                  />
                </Box>
                <Flex w={width} flexDirection="column">
                  <Tabs
                    isFitted
                    variant="enclosed"
                    mb="15px"
                    defaultIndex={values?.mapSelection == 'ADDRESS' ? 0 : 1}
                    onChange={(e) => {
                      setFieldValue(
                        'mapSelection',
                        e === 0 ? 'ADDRESS' : 'GPS',
                      );
                    }}>
                    <TabList>
                      <Tab>
                        <Text fontSize="sm" fontWeight="700">
                          Address
                        </Text>
                      </Tab>
                      <Tab>
                        <Text fontSize="sm" fontWeight="700">
                          GPS
                        </Text>
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel p="0">
                        <PlacesAutocompleteView
                          value={values.location}
                          name="location"
                          labelText="Address"
                          setFieldValue={setFieldValue}
                          inputStyle={{
                            background: '#fafafa',
                          }}
                          labelProps={{
                            marginBottom: '5px',
                            fontSize: '12px',
                            fontWeight: 700,
                          }}
                          searchOptions={{
                            componentRestrictions: {
                              country: 'us',
                            },
                            types: ['address'],
                          }}
                        />
                      </TabPanel>
                      <TabPanel p="0">
                        <Flex justifyContent="space-between">
                          <FormikInput
                            w="98%"
                            mr="2%"
                            label="Latitude"
                            labelProps={labelProps}
                            size="md"
                            mb="10px"
                            name="latitude"
                            defaultValue={values.latitude}
                            placeholder=""
                          />
                          <FormikInput
                            w="98%"
                            ml="2%"
                            label="Longitude"
                            labelProps={labelProps}
                            name="longitude"
                            defaultValue={values.longitude}
                            placeholder=""
                            size="md"
                            mb="10px"
                          />
                        </Flex>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                  <Flex justifyContent="flex-end" mb="20px">
                    <SelectLocationButton setMapMarker={setMapMarker} />
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction="column" mb={3}>
                <TitleWithIcon
                  text="Location Details (*)"
                  TextIcon={<MdNotes size="16px" height="10px" width="10px" />}
                />
                <FormikTextAreaView
                  minH="120px"
                  name="locationDetail"
                  placeholder="(ie. Specific site directions, parking information, other instructions)"
                />
              </Flex>
              {/* <Flex flexDirection="column" mb="20px" id="missionIndustry">
                <TitleWithIcon
                  text="Mission Type"
                  TextIcon={<MdPhotoCamera size="16px" />}
                />
                <MissionTypes formik={formik} />
                <ErrorMessage
                  name="missionIndustry"
                  render={(errorMessage) => (
                    <Text color="red.500" fontSize="sm">
                      {errorMessage}
                    </Text>
                  )}
                />
              </Flex> */}
              <AdditionalServicesSelect
                brandColor="#6728BB"
                data={ADDITIONAL_SERVICES}
              />
              <Flex flexDirection="column" mb="20px">
                <TitleWithIcon
                  text="Available Packs"
                  TextIcon={<IoIosFilm size="18px" />}
                />
                {isSmall ? (
                  <Flex direction="column" maxW="320px" mb="20px">
                    <MobilePacksCarousel
                      packs={props.packages || []}
                      onPackSelect={(pack) => {
                        setFieldValue('package', pack.id);
                        setTimeout(() => {
                          submitForm();
                        }, 500);

                        setTimeout(() => {
                          const errorsArr = Object.keys(errors);

                          const isMissionTypeErr = errorsArr.includes(
                            'missionIndustry',
                          );
                          const isAddressErr =
                            errorsArr.includes('city') ||
                            errorsArr?.includes('state') ||
                            errorsArr?.includes('location');
                          const isPackageErr = errorsArr.includes('package');

                          if (isPackageErr) {
                            const el = document.getElementById('package');

                            el?.scrollIntoView({ behavior: 'smooth' });
                          } else if (isMissionTypeErr) {
                            const el = document.getElementById(
                              'missionIndustry',
                            );

                            el?.scrollIntoView({ behavior: 'smooth' });
                          } else if (isAddressErr) {
                            const el = document.getElementById('address-tab');

                            el?.scrollIntoView({ behavior: 'smooth' });
                          }
                        }, 800);
                      }}
                    />
                  </Flex>
                ) : (
                  <ResponsivePackageBoxContainer>
                    {props.packages?.map((pack) => {
                      return (
                        <PackageBox
                          key={pack.id}
                          pack={pack}
                          submit={submitForm}
                          isValid={true}
                          setFieldValue={setFieldValue}
                          packageID={pack.id}
                        />
                      );
                    })}
                  </ResponsivePackageBoxContainer>
                )}
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default MissionLocationPanelView;
