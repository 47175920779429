import React, {
  FC,
  memo,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useImperativeHandle,
} from 'react';

import {
  Box,
  Tab,
  Tabs,
  Text,
  Flex,
  Icon,
  Radio,
  Stack,
  Heading,
  Button,
  Checkbox,
  Divider,
  TabList,
  TabPanel,
  TabPanels,
  RadioGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/media-query';

import { addDays, format, set } from 'date-fns';
import { motion } from 'framer-motion';
import { Form, Formik, useField, useFormikContext } from 'formik';

import authApi, { checkIfEmailExist } from 'api/auth';

import { createMission, createMissionWithRegistration } from 'api/mission';
import { useMutation } from 'react-query';

import { getRememberMe, selectors, SessionStore } from 'effector/session/store';

import { SiGooglemaps } from 'react-icons/si';
import { BsFillPeopleFill } from 'react-icons/bs';
import { MdClose, MdNotes, MdAddCircle, MdCalendarToday } from 'react-icons/md';

import { IoIosFilm, IoIosCheckmarkCircleOutline } from 'react-icons/io';

import {
  AiFillFire,
  AiTwotoneCalendar,
  AiOutlinePlaySquare,
} from 'react-icons/ai';

import { Package, Services } from 'types/cms';
import { MissionStatuses } from 'types/mission';

import {
  Spacer,
  SpacerFill,
  ResponsiveTab,
  ResponsiveTabList,
  ResposiveContainerWithSidebar,
} from '../../client/Dashboard/Dashboard.style';
import { TitleWithIcon } from 'components/primitives/TitleWithIcon';
import { MissionCheckoutPanel } from 'components/modules/MissionCheckoutPanel';
import { MobileMissionCreationTabs } from 'components/modules/MobileMissionCreationTabs';

import hexTorgb from 'hex-rgb';
import Multiselect from 'multiselect-react-dropdown';
import GoogleMaps from 'components/primitives/GoogleMaps';
import TimePicker from 'components/primitives/TimePicker';
import FormikInputView from 'components/primitives/FormikInput';
import FormikCalendar from 'components/primitives/FormikCalendar';
import FormikDatePicker from 'components/primitives/FormikDatePicker';
import FormikTextAreaView from 'components/primitives/FormikTextArea';
import PlacesAutocompleteView from 'components/primitives/PlacesAutocomplete';

import {
  getInitialValues,
  loginSchema,
  ValidationSchema,
} from './PremiumMissionCreate.utils';

import {
  State,
  FormType,
  PremiumMissionCreateGeneratedProps,
} from './PremiumMissionCreate.props';
import { MobileMissionCreationFooter } from 'components/modules/MobileMissionCreationFooter';
import { SelectLocationButton } from './SelectLocationButton';
import { METERS } from 'constants/common';
import { loginEvent } from 'effector/session/events';
import { cms } from './cmsStore';
import { useHistory } from 'react-router-dom';

const MotionFlex = motion.custom(Flex);

const animation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

const css = {
  '::placeholder': {
    fontStyle: 'italic',
    fontSize: '12px',
  },
};

export const getTabs = (): string[] => {
  let tabs = [
    'Mission Location',
    'Mission Details',
    'Checkout',
    'Confirmation',
  ];

  const isAuth = !!SessionStore.getState()?.session?.user?.id;

  if (!isAuth) {
    tabs = [
      'Mission Location',
      'Mission Details',
      'Information Details',
      'Checkout',
      'Confirmation',
    ];
  }

  return tabs;
};

const labelProps: any = {
  fontWeight: '700',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  mb: '5px',
};

export const AdditionalServicesSelect = ({
  brandColor,
  data,
}: {
  brandColor: string;
  data: Services[];
}) => {
  const [field, _, helpers] = useField<Services[]>('additionalServices');

  const options = data?.map((i) => ({
    value: `${i.name} (+$${i.price})`,
    id: `${i.name}-${i.price}`,
  }));

  if (data?.length < 1) {
    return null;
  }

  return (
    <FormControl mt={4}>
      <FormLabel fontWeight="700" fontSize="xs" color={brandColor}>
        Add additional deliverables
      </FormLabel>
      <Box maxW="400px" mb={6}>
        <Multiselect
          options={options}
          selectedValues={(field.value || [])?.map((i) => ({
            value: `${i.name} (+$${i.price})`,
            id: `${i.name}-${i.price}`,
          }))}
          displayValue="value"
          placeholder="Select deliverables"
          showCheckbox
          showArrow
          style={{
            searchBox: {
              overflow: 'auto',
              fontSize: '14px',
              width: '100%',
            },
            chips: {
              background: '#6728BB',
              fontWeight: 'bold',
            },
            option: {
              color: '#6728BB',
              fontSize: '14px',
              fontWeight: 'bold',
              backgroundColor: '#fff',
              borderBottom: '1px solid #afafaf',
            },
          }}
          onSelect={(_, selected) => {
            const selectedValue: any = data?.find(
              (i) => `${i.name}-${i.price}` == selected.id,
            );

            helpers.setValue([...field.value, selectedValue]);
          }}
          onRemove={(_, removed) => {
            helpers.setValue(
              field.value.filter((i) => `${i.name}-${i.price}` != removed.id),
            );
          }}
        />
      </Box>
    </FormControl>
  );
};

const CustomTab = ({
  tabSelected,
  isSelected,
  selected,
  tabsFilled,
  brandColor,
  ...props
}: any) => {
  const tabsLength = getTabs().length;

  return (
    <>
      <ResponsiveTab
        background={
          isSelected ? 'white' : tabsFilled[selected] ? '#fff' : '#f1f1f1'
        }
        color={
          isSelected ? '#000' : tabsFilled[selected] ? brandColor : '#707070'
        }
        fontSize="xs"
        _selected={{ color: '#000', bg: 'white' }}
        border={tabsFilled[selected] ? `1px solid ${brandColor}` : undefined}
        borderColor={tabsFilled[selected] ? brandColor : '#afafa'}
        {...props}>
        {props.children}
      </ResponsiveTab>
      {tabsLength - 1 === selected ? (
        ''
      ) : selected < tabSelected ? (
        <SpacerFill
          style={{
            backgroundColor: brandColor,
          }}
        />
      ) : (
        <Spacer />
      )}
    </>
  );
};

const MissionSummary = ({
  values,
  pilotData,
}: {
  values: FormType;
  pilotData: State;
}) => {
  const packName = pilotData.pack.name;
  const packItems = pilotData.pack.lineItems;
  const lat = values.gps.coordinates?.[1];
  const lng = values.gps.coordinates?.[0];
  const additionalServicesCost =
    values?.additionalServices
      ?.map((i) => i.price)
      ?.reduce((a, b) => a + b, 0) || 0;

  const computedTotal = pilotData.pack.price + additionalServicesCost;

  const { dueDate, eventStart, location } = values;

  const brandColor = pilotData.brandColor;

  return (
    <Flex
      w="280px"
      maxH="750px"
      px="30px"
      py="10px"
      mb="20px"
      background="#fff"
      borderRadius="10px"
      border="1px"
      borderColor="#92835C"
      flexDirection="column">
      <Flex flexDirection="column" borderBottom="1px solid #ccc" pb="10px">
        <Text
          fontSize="lg"
          mb="14px"
          textAlign="center"
          fontFamily="Monument Extended">
          MISSION DETAILS
        </Text>
        <TitleWithIcon
          text="Location"
          TextIcon={<Icon as={SiGooglemaps} h="15px" w="15px" />}
          color={brandColor}
        />
        <Text ml="4px" fontSize="xs">
          {location}
        </Text>
        <Box
          w="110%"
          h="130px"
          my="10px"
          mx="-5%"
          borderWidth="1px"
          borderColor="#707070"
          bg="inputColor">
          <GoogleMaps
            mapContainerStyle={{
              width: '100%',
              height: '100%',
            }}
            initialCenter={{ lat, lng }}
            marker={{ lat, lng }}
            zoom={15}
            disableClickMarkerChange
          />
        </Box>
        {/* <TitleWithIcon
          text="Mission Type"
          TextIcon={<Icon as={MdCameraAlt} h="15px" w="15px" />}
          color={brandColor}
        />
        <Text fontSize="sm" mb={2} ml="4px">
          {getIndustryName(missionIndustry || '')}
        </Text> */}
        <TitleWithIcon
          text={packName}
          TextIcon={<Icon as={IoIosFilm} h="15px" w="15px" />}
          color={brandColor}
        />
        {packItems?.length &&
          packItems.map((item: any, index: number) => (
            <Text fontSize="xs" key={index} my="3px">
              - {item.count ? `(${item.count})` : ''} {item.name}
            </Text>
          ))}
      </Flex>
      {dueDate && eventStart && (
        <Flex
          flexDirection="column"
          mt="10px"
          pb="10px"
          borderBottomWidth="1px"
          borderColor="#ccc">
          <TitleWithIcon
            text="Due Date"
            TextIcon={<Icon as={AiTwotoneCalendar} h="15px" w="15px" />}
            color={brandColor}
          />
          <Text fontSize="xs">
            {format(new Date(eventStart), 'MM/dd/yyyy')} -{' '}
            {format(new Date(dueDate), 'MM/dd/yyyy')}
          </Text>
        </Flex>
      )}
      {/* {rush && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          pb="10px">
          <TitleWithIcon
            text="Rush Mission"
            TextIcon={
              <Icon as={AiFillFire} h="15px" w="15px" color="#E9243F" />
            }
            color={brandColor}
          />
          <Text fontSize="xs" fontWeight="bold">
            $99.00
          </Text>
        </Flex>
      )} */}
      {Boolean(additionalServicesCost) && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          pb="10px">
          <TitleWithIcon
            text="Additional services cost"
            TextIcon={
              <Icon as={AiFillFire} h="15px" w="15px" color="#E9243F" />
            }
            color={brandColor}
          />
          <Text fontSize="xs" fontWeight="bold">
            ${additionalServicesCost.toFixed(2)}
          </Text>
        </Flex>
      )}
      <Flex
        mt="auto"
        py="15px"
        borderTop="1px solid #ccc"
        fontWeight="bold"
        justifyContent="space-between"
        alignItems="center">
        <Text color={brandColor} fontSize="sm">
          Total Amount
        </Text>
        <Text fontWeight="700" fontSize="24px">
          ${computedTotal}
        </Text>
      </Flex>
    </Flex>
  );
};

const getInitialState = () => {
  let initialState: Record<number, boolean> = {
    0: true,
    1: false,
    2: false,
    3: false,
  };

  const isAuth = !!SessionStore.getState()?.session?.user?.id;

  if (!isAuth) {
    initialState = {
      0: true,
      1: !!window.location.search,
      2: false,
      3: false,
      4: false,
    };
  }

  return initialState;
};

const HourInput: FC<{
  type: 'Start' | 'End';
  timeName: string;
  brandColor: string;
}> = ({ type, timeName, brandColor }) => {
  const [, meta, helpers] = useField(timeName);

  return (
    <Stack>
      <Text fontSize="12px" fontWeight="bold" color={brandColor}>
        {type} Hour
      </Text>
      <Flex>
        <TimePicker
          brandColor={brandColor}
          isInvalid={Boolean(meta.touched && meta.error)}
          onClick={() => helpers.setTouched(true)}
          onChange={(time) => helpers.setValue(time)}
        />
      </Flex>
    </Stack>
  );
};

const LiveOrOnsiteSection: FC<{ brandColor: string }> = ({ brandColor }) => {
  const [days, setDays] = useState<number>(1);

  const { values, setFieldValue } = useFormikContext<FormType>();

  const isLive = values?.liveEvent;
  const isOnSite = values?.onsite;

  return (
    <>
      {!isLive && !isOnSite && (
        <FormikCalendar
          css={css}
          brandColor={brandColor}
          fontSize="14px"
          labelProps={{
            color: brandColor,
            fontSize: '12px',
          }}
          checkBoxProps={{
            size: 'md',
            alignItems: 'center',
            fontSize: '12px',
          }}
          renderLabel={() => (
            <TitleWithIcon
              text="Date Range"
              TextIcon={<MdCalendarToday />}
              color={brandColor}
            />
          )}
        />
      )}
      {(isLive || (!isLive && values.onsite)) && (
        <Flex flexWrap="wrap">
          <FormikDatePicker
            name="liveDay"
            label="Day"
            labelProps={{
              fontSize: '12px',
              color: brandColor,
            }}
            disablePastDates={true}
            textFieldProps={{
              css,
              w: '200px',
              fontSize: '14px',
            }}
            containerProps={{ maxW: '200px' }}
            minDate={addDays(new Date(), 1)}
          />
          <Box w="20px" h="10px" />
          <HourInput
            type="Start"
            timeName="liveStartTime"
            brandColor={brandColor}
          />
          <Box w="20px" h="10px" />
          <HourInput
            type="End"
            timeName="liveEndTime"
            brandColor={brandColor}
          />
        </Flex>
      )}
      {(isLive || (!isLive && values.onsite)) && days > 1 && (
        <Flex mt={2} flexWrap="wrap" align="flex-end">
          <FormikDatePicker
            name="liveDay2"
            label="Day"
            labelProps={{
              fontSize: '12px',
              color: brandColor,
            }}
            disablePastDates={true}
            textFieldProps={{
              css,
              w: '200px',
              fontSize: '14px',
            }}
            containerProps={{ maxW: '200px' }}
          />
          <Box w="20px" h="10px" />
          <HourInput
            type="Start"
            timeName="liveStartTime2"
            brandColor={brandColor}
          />
          <Box w="20px" h="10px" />
          <HourInput
            type="End"
            timeName="liveEndTime2"
            brandColor={brandColor}
          />
          {days == 2 && (
            <IconButton
              ml={4}
              mb={1}
              isRound
              size="sm"
              aria-label="close-2-btn"
              icon={<MdClose />}
              borderColor={brandColor}
              bg={brandColor}
              _hover={{
                bg: `${brandColor}10`,
              }}
              _active={{
                bg: `${brandColor}30`,
              }}
              onClick={() => {
                setDays(1);
                setFieldValue('liveDay2', undefined);
              }}
            />
          )}
        </Flex>
      )}
      {(isLive || (!isLive && values.onsite)) && days > 2 && (
        <Flex mt={2} flexWrap="wrap" align="flex-end">
          <FormikDatePicker
            name="liveDay3"
            label="Day"
            labelProps={{
              fontSize: '12px',
              color: brandColor,
            }}
            disablePastDates={true}
            textFieldProps={{
              css,
              w: '200px',
              fontSize: '14px',
            }}
            containerProps={{ maxW: '200px' }}
          />
          <Box w="20px" h="10px" />
          <HourInput
            type="Start"
            timeName="liveStartTime3"
            brandColor={brandColor}
          />
          <Box w="20px" h="10px" />
          <HourInput
            type="End"
            timeName="liveEndTime3"
            brandColor={brandColor}
          />
          <IconButton
            ml={4}
            mb={1}
            isRound
            size="sm"
            aria-label="close-2-btn"
            icon={<MdClose />}
            borderColor={brandColor}
            bg={brandColor}
            _hover={{
              bg: `${brandColor}10`,
            }}
            _active={{
              bg: `${brandColor}30`,
            }}
            onClick={() => {
              setDays(2);
              setFieldValue('liveDay3', undefined);
            }}
          />
        </Flex>
      )}
      {days < 3 && !isLive && values.onsite ? (
        <Button
          mt={4}
          size="sm"
          w="200px"
          onClick={() => {
            setDays((prev) => (prev == 3 ? prev : prev + 1));
          }}
          leftIcon={<MdAddCircle size="20px" />}
          variant="outline"
          borderColor={brandColor}
          color={brandColor}
          _hover={{
            bg: `${brandColor}10`,
          }}
          _active={{
            bg: `${brandColor}30`,
          }}>
          ADD ANOTHER DAY
        </Button>
      ) : null}
      {values.onsite && (
        <Flex mt={6} flexDirection="column">
          <TitleWithIcon
            text="On Site Contact Information"
            TextIcon={<BsFillPeopleFill />}
            color={brandColor}
          />
          <Flex
            maxW="250px"
            direction="column"
            align="center"
            flexWrap={{
              base: 'wrap',
              sm: 'wrap',
              md: 'wrap',
              lg: 'nowrap',
            }}>
            <FormikInputView
              name="contactName"
              label="Name"
              placeholder="Your name here"
              minW="250px"
              containerProps={{ mt: 0 }}
            />
            <Box w="30px" h="10px" />
            <FormikInputView
              name="contactEmail"
              label="Email"
              placeholder="Your email here"
              minW="250px"
              containerProps={{ mt: 0 }}
            />
            <Box w="30px" h="10px" />
            <FormikInputView
              type="number"
              name="contactPhoneNumber"
              label="Phone number"
              placeholder="Your phone number here"
              minW="250px"
              containerProps={{ mt: 0 }}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};

type TabsFilled = { [key: number]: boolean };
type MapSelection = 'ADDRESS' | 'GPS';

const FormTabs = ({
  data,
  onModalClose = undefined,
  brandColor = '#6728BB',
  additionalServices,
}: {
  onModalClose?: () => void;
  brandColor: string;
  data: PremiumMissionCreateGeneratedProps['data'];
  additionalServices?: Services[];
}) => {
  const history = useHistory();
  const emailRef = useRef<string>('');
  const missionIdRef = useRef<string>('');
  const clientSecretRef = useRef<string>('');
  const passwordTokenRef = useRef<string>('');
  const formik = useFormikContext<FormType>();
  const { values, setFieldValue, errors } = formik;
  const [isSmall] = useMediaQuery('(max-width: 600px)');
  const [selectedTab, setSelectedTab] = useState<number>(() =>
    !!window.location.search ? Number(1) : Number(0),
  );

  const [mapSelection, setMapSelection] = useState<MapSelection>('ADDRESS');
  const [tabsFilled, setTabFilled] = useState<TabsFilled>(getInitialState);

  const uid = selectors.useUserId();
  const userEmail = selectors.useUser()?.email;

  const tabs = getTabs();

  const [create, { isLoading: isCreating }] = useMutation(createMission, {
    onSuccess: (res) => {
      clientSecretRef.current = res.data.clientSecret;
    },
  });

  const [
    createWithRegistration,
    { isLoading: isCreatingWithRegistration },
  ] = useMutation(createMissionWithRegistration, {
    onSuccess: (res) => {
      missionIdRef.current = (res.mission as any)?.id;
      clientSecretRef.current =
        typeof res?.clientSecret === 'string'
          ? res?.clientSecret
          : 'client_secret' in (res.clientSecret as any)
          ? (res?.clientSecret as any)?.client_secret
          : '';
      passwordTokenRef.current = res.resetPasswordToken;
    },
  });

  const isLoading = isCreating || isCreatingWithRegistration;

  const coordinates = useMemo(
    () => ({
      lat: values.gps.coordinates[1],
      lng: values.gps.coordinates[0],
    }),
    [values.gps.coordinates],
  );

  const goToCheckout = (index?: number) => {
    if (index) {
      setSelectedTab(index);
      setTabFilled((state) => ({ ...state, [index]: true }));

      return;
    }

    if (!uid) {
      setSelectedTab(3);
      setTabFilled((state) => ({ ...state, [3]: true }));
    } else {
      setSelectedTab(2);
      setTabFilled((state) => ({ ...state, [2]: true }));
    }
  };

  const onMissionCreate = useCallback(async () => {
    let contact = null;
    let meetupTime: any[] = [];
    let eventStart = values.eventStart;
    let dueDate = values.dueDate;

    if (values.liveEvent) {
      eventStart = values.liveDay || '';
      dueDate = values.liveDay || '';

      const startTime = values?.liveStartTime?.split(':');
      const startHours = parseInt(startTime[0] || '0');
      const startMinutes = parseInt(startTime[1] || '0');

      const endTime = values?.liveEndTime?.split(':');
      const endHours = parseInt(endTime[0] || '0');
      const endMinutes = parseInt(endTime[1] || '0');

      eventStart = set(new Date(eventStart), {
        hours: startHours,
        minutes: startMinutes,
      }).toISOString();

      dueDate = set(new Date(dueDate), {
        hours: endHours,
        minutes: endMinutes,
      }).toISOString();

      if (values.onsite) {
        contact = {
          name: values.contactName,
          phone: values.contactPhoneNumber,
          email: values.contactEmail,
        };
      }
    }

    if (!values.liveEvent && values.onsite) {
      const startTime = values?.liveStartTime?.split(':');
      const startHours = parseInt(startTime[0] || '0');
      const startMinutes = parseInt(startTime[1] || '0');

      meetupTime = [
        set(new Date(values.liveDay || ''), {
          hours: startHours,
          minutes: startMinutes,
        }).toISOString(),
      ];

      if (!!values?.liveDay2) {
        const startTime2 = values?.liveStartTime2?.split(':');
        const startHours2 = parseInt(startTime2[0] || '0');
        const startMinutes2 = parseInt(startTime2[1] || '0');

        meetupTime = [
          ...meetupTime,
          set(new Date(values.liveDay2 || ''), {
            hours: startHours2,
            minutes: startMinutes2,
          }).toISOString(),
        ];
      }

      if (!!values.liveDay3) {
        const startTime3 = values?.liveStartTime3?.split(':');
        const startHours3 = parseInt(startTime3[0] || '0');
        const startMinutes3 = parseInt(startTime3[1] || '0');

        meetupTime = [
          ...meetupTime,
          set(new Date(values.liveDay3), {
            hours: startHours3,
            minutes: startMinutes3,
          }).toISOString(),
        ];
      }

      contact = {
        name: values.contactName,
        phone: values.contactPhoneNumber,
        email: values.contactEmail,
      };
    }

    const payload = {
      city: values.city,
      state: values.state,
      gps: values.gps,
      location: values.location,
      package: data.pack.id ?? values?.package,
      missionIndustry: values.missionIndustry,
      status: MissionStatuses.FOR_PAYMENT,
      eventStart,
      dueDate,
      client: uid,
      rush: values.rush,
      restricted: values.restricted,
      liveEvent: values.liveEvent,
      onsite: values.onsite,
      notes: values.notes,
      meetupTime,
      contact,
      additionalServices: values?.additionalServices || [],
      saveCard: true,
      pilot: data?.pilot || null,
      cms: 'id' in data ? data?.id : undefined,
      locationDetail: values?.locationDetail,
      model: data?.pilot ? 'premium' : 'uber',
      stripeId: data?.pilot ? data?.stripeId : undefined,
    };

    if (uid) {
      await create(payload);
    } else {
      delete payload.client;

      emailRef.current = values.email || '';

      await createWithRegistration({
        ...payload,
        email: values.email,
        firstName: (values as any)?.firstName,
        lastName: (values as any)?.lastName,
      });
    }

    setFieldValue('eventStart', eventStart);
    setFieldValue('dueDate', dueDate);
  }, [create, createWithRegistration, values, data, uid]);

  useLayoutEffect(() => {
    if (onModalClose) {
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [selectedTab, onModalClose]);

  const startDate = format(new Date(values?.eventStart), 'MM/dd/yyyy');
  const endDate = format(new Date(values?.dueDate), 'MM/dd/yyyy');

  const goToDashboard = () => history.push('/missions');

  return (
    <Tabs
      flex={1}
      isLazy
      variant="soft-rounded"
      isFitted
      index={selectedTab}
      onChange={(index) => index !== 3 && setSelectedTab(index)}>
      <>
        {isSmall ? (
          <MobileMissionCreationTabs
            isExtraTab={tabs.length > 4}
            tabSelected={selectedTab}
            onClose={onModalClose}>
            <Box h="150px" />
          </MobileMissionCreationTabs>
        ) : (
          <ResponsiveTabList justify="center" flex={1} mt={4}>
            {tabs.map((tab, index) => (
              <CustomTab
                key={index}
                selected={index}
                tabSelected={selectedTab}
                tabsFilled={tabsFilled}
                onClick={() => {
                  if (selectedTab === 3) return;
                  setSelectedTab(index);
                }}
                isDisabled={!tabsFilled[index]}
                cursor={
                  !tabsFilled[index] || selectedTab === 3
                    ? 'not-allowed'
                    : 'pointer'
                }
                _focus={{
                  outline: 'none',
                }}
                _selected={{
                  bg: brandColor,
                  color: 'white',
                }}
                brandColor={brandColor}>
                {tab}
              </CustomTab>
            ))}
          </ResponsiveTabList>
        )}
      </>
      <TabPanels>
        <TabPanel>
          <Flex direction="column">
            <Flex flexDirection="column" mb="10px">
              <TitleWithIcon
                text="Select Location"
                TextIcon={<SiGooglemaps size="16px" />}
                color={brandColor}
              />
              <Text fontSize="sm">
                Select the location on the map, write the address or the GPS
                coordinates below where the footage is going to be taken. Some
                areas could have flying restrictions.
              </Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              direction={['column', 'column', 'row']}
              my={4}>
              <Box
                w="100%"
                maxW={['300px', '400px']}
                height="250px"
                mb="10px"
                background="#fff"
                border="1px"
                borderColor="#ccc"
                bg="inputColor"
                borderRadius="20px"
                overflow="hidden">
                <GoogleMaps
                  mapContainerStyle={{
                    width: '100%',
                    height: '250px',
                  }}
                  initialCenter={coordinates}
                  marker={coordinates}
                  zoom={13}
                  setFieldValue={setFieldValue}
                />
              </Box>
              <Flex pl={4} w="100%" direction="column">
                <Tabs
                  isFitted
                  variant="enclosed"
                  mb="15px"
                  defaultIndex={mapSelection === 'ADDRESS' ? 0 : 1}
                  onChange={(e) => {
                    setMapSelection(e === 0 ? 'ADDRESS' : 'GPS');
                  }}>
                  <TabList>
                    <Tab>
                      <Text fontSize="sm" fontWeight="700">
                        Address
                      </Text>
                    </Tab>
                    <Tab>
                      <Text fontSize="sm" fontWeight="700">
                        GPS
                      </Text>
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel p="0">
                      <PlacesAutocompleteView
                        value={values.location}
                        name="location"
                        labelText="Address"
                        setFieldValue={setFieldValue}
                        inputStyle={{
                          background: '#fafafa',
                        }}
                        labelProps={{
                          marginBottom: '5px',
                          fontSize: '12px',
                          fontWeight: 700,
                        }}
                        searchOptions={{
                          componentRestrictions: {
                            country: 'us',
                          },
                          types: ['address'],
                        }}
                      />
                    </TabPanel>
                    <TabPanel p="0">
                      <Flex justifyContent="space-between">
                        <FormikInputView
                          w="98%"
                          mr="2%"
                          label="Latitude"
                          labelProps={labelProps}
                          size="md"
                          mb="10px"
                          name="latitude"
                          defaultValue={values.latitude.toString()}
                          placeholder=""
                        />
                        <FormikInputView
                          w="98%"
                          ml="2%"
                          label="Longitude"
                          labelProps={labelProps}
                          name="longitude"
                          defaultValue={values.longitude.toString()}
                          placeholder=""
                          size="md"
                          mb="10px"
                        />
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <Flex justifyContent="flex-end" mb="20px">
                  <SelectLocationButton
                    mapSelection={mapSelection}
                    coordinates={
                      (data as any)?.stripeId
                        ? (data as any)?.coordinates
                        : null
                    }
                    radius={
                      (data as any)?.stripeId
                        ? (data as any)?.radius / METERS
                        : null
                    }
                    company={(data as any)?.company || ''}
                    onCloseMissionModal={() => {
                      onModalClose?.();
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column" mb={3}>
              <AdditionalServicesSelect
                brandColor={brandColor}
                data={additionalServices || []}
              />
              <TitleWithIcon
                text="Location Details (*)"
                color={brandColor}
                TextIcon={<MdNotes size="18px" height="10px" width="10px" />}
              />
              <FormikTextAreaView
                minH="120px"
                name="locationDetail"
                placeholder="(ie. Specific site directions, parking information, other instructions)"
              />
            </Flex>
            <Flex
              justify="space-between"
              my="10px"
              direction={{ base: 'column', md: 'row' }}>
              <Flex flex={1} direction="column">
                <TitleWithIcon
                  text="Selected Pack"
                  color={brandColor}
                  TextIcon={<IoIosFilm size="18px" />}
                />
                <Box
                  m={2}
                  p={4}
                  pb={1}
                  ml={0}
                  minW="150px"
                  maxW="150px"
                  h="250px"
                  cursor="pointer"
                  borderRadius="5px"
                  borderWidth="1px"
                  borderColor={brandColor}
                  display="flex"
                  flexDirection="column">
                  <Text textAlign="left" fontSize="14px" fontWeight="bold">
                    {data.pack.name}
                  </Text>
                  <Stack flex={1} mt={2} spacing={1}>
                    {data.pack.lineItems.map((p, itemIndex) => (
                      <Text fontSize="12px" key={`${p.name}-${itemIndex}`}>
                        - {p.count ? `(${p.count})` : ''} {p.name}
                      </Text>
                    ))}
                  </Stack>
                  <Divider color={brandColor} />
                  <Text
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                    color={brandColor}>
                    {data.pack.price ? `$${data.pack.price}` : ''}
                  </Text>
                </Box>
              </Flex>
              <Flex flex={1.06} flexDirection="column">
                {/* <TitleWithIcon
                  text="Mission Type"
                  color={brandColor}
                  TextIcon={<MdPhotoCamera size="16px" />}
                /> */}
                {/* <Flex direction="column" flex={1}>
                  <MissionTypes formik={formik} />
                  <ErrorMessage
                    name="missionIndustry"
                    render={(errorMessage) => (
                      <Text color="red.500" fontSize="sm">
                        {errorMessage}
                      </Text>
                    )}
                  />
                </Flex> */}
                <Button
                  mt={2}
                  w="100%"
                  isDisabled={
                    Boolean(errors.location) || Boolean(!values?.locationDetail)
                  }
                  onClick={() => {
                    setSelectedTab(1);
                    setTabFilled((state) => ({ ...state, [1]: true }));
                  }}
                  alignSelf="center">
                  NEXT
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </TabPanel>
        <TabPanel>
          <Flex>
            <Flex h="100%" direction="column" pr={2}>
              <Text w="100%" fontSize="sm" my="20px" ml="-15px">
                Select the date, details for your request, or add additional
                details for the job.
              </Text>
              <Flex w="100%" direction="column" mb="20px">
                <TitleWithIcon
                  text="Is this a live event?"
                  TextIcon={<AiOutlinePlaySquare />}
                  color={brandColor}
                />
                <RadioGroup
                  display="flex"
                  colorScheme="brand"
                  defaultValue={values.liveEvent ? '1' : '0'}
                  onChange={(value) => setFieldValue('liveEvent', value == 1)}>
                  <Radio value="1" mr="20px">
                    <Text fontSize="xs">Yes</Text>
                  </Radio>
                  <Radio value="0">
                    <Text fontSize="xs">No</Text>
                  </Radio>
                </RadioGroup>
              </Flex>
              <Flex w="100%" direction="column" mb="20px">
                <TitleWithIcon
                  text="Do you need to be on the site?"
                  TextIcon={<BsFillPeopleFill />}
                  color={brandColor}
                />
                <RadioGroup
                  display="flex"
                  colorScheme="brand"
                  defaultValue={values.onsite ? '1' : '0'}
                  onChange={(value) => setFieldValue('onsite', value == 1)}>
                  <Radio value="1" mr="20px">
                    <Text fontSize="xs">Yes</Text>
                  </Radio>
                  <Radio value="0">
                    <Text fontSize="xs">No</Text>
                  </Radio>
                </RadioGroup>
              </Flex>

              <LiveOrOnsiteSection brandColor={brandColor} />
              <Box h="20px" />
              <TitleWithIcon
                text="Mission Notes"
                TextIcon={
                  <SiGooglemaps size="10px" height="10px" width="10px" />
                }
                color={brandColor}
              />
              <FormikTextAreaView
                name="notes"
                w="100%"
                h="150px"
                placeholder="(ie. Specific site directions, parking information, other instructions)"
                fontSize="xs"
                mb="20px"
                defaultValue={values.notes}
              />
              <Checkbox
                w="100%"
                mb="20px"
                size="md"
                display="flex"
                alignItems="flex-start"
                colorScheme="brand"
                onChange={(e) =>
                  setFieldValue('acknowledgeWeather', !!e.target.checked)
                }
                sx={{
                  '.chakra-checkbox__control[data-checked]': {
                    background: brandColor,
                    borderColor: brandColor,
                  },
                  '.chakra-checkbox__control[data-focus]': {
                    boxShadow: 'none',
                  },
                }}
                defaultIsChecked={values.acknowledgeWeather}>
                <Text fontSize="xs">
                  I acknowledge it is possible that Airspace Restrictions or
                  unfavorable weather conditions may arise, which could result
                  in the need to reschedule or postpone.
                </Text>
              </Checkbox>
              <Flex justifyContent="flex-end" w="100%">
                {isSmall ? (
                  <MobileMissionCreationFooter>
                    <Flex>
                      <Button
                        w="100px"
                        mr="10px"
                        size="md"
                        variant="outline"
                        onClick={() => setSelectedTab(0)}>
                        GO BACK
                      </Button>
                      {!!uid ? (
                        <Button
                          onClick={() => goToCheckout()}
                          size="md"
                          w="100px"
                          isLoading={isLoading}
                          isDisabled={!values.acknowledgeWeather}>
                          CHECKOUT
                        </Button>
                      ) : (
                        <Button
                          size="md"
                          w="100px"
                          onClick={() => {
                            setSelectedTab(2);
                            setTabFilled((prev) => ({
                              ...prev,
                              [1]: true,
                              [2]: true,
                            }));
                          }}
                          isDisabled={!values.acknowledgeWeather}>
                          CONTINUE
                        </Button>
                      )}
                    </Flex>
                  </MobileMissionCreationFooter>
                ) : (
                  <Flex>
                    <Button
                      w="150px"
                      mx="5px"
                      size="md"
                      variant="outline"
                      onClick={() => setSelectedTab(0)}>
                      GO BACK
                    </Button>
                    {!!uid ? (
                      <Button
                        onClick={() => goToCheckout()}
                        mx="5px"
                        size="md"
                        w="150px"
                        isLoading={isLoading}
                        isDisabled={!values.acknowledgeWeather}>
                        CHECKOUT
                      </Button>
                    ) : (
                      <Button
                        mx="5px"
                        size="md"
                        w="150px"
                        onClick={() => {
                          setSelectedTab(2);
                          setTabFilled((prev) => ({
                            ...prev,
                            [1]: true,
                            [2]: true,
                          }));
                        }}
                        isDisabled={!values.acknowledgeWeather}>
                        CONTINUE
                      </Button>
                    )}
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Box display={{ base: 'none', md: 'block' }}>
              <MissionSummary values={values} pilotData={data} />
            </Box>
          </Flex>
        </TabPanel>
        {!uid && (
          <TabPanel>
            <Flex flex={1}>
              <EmailForm
                setSelectedTab={setSelectedTab}
                isLoading={isLoading}
                goToCheckout={goToCheckout}
                isCms={Boolean(data?.pilot)}
              />
              <Box display={{ base: 'none', md: 'block' }}>
                <MissionSummary values={values} pilotData={data} />
              </Box>
            </Flex>
          </TabPanel>
        )}
        <TabPanel>
          <MissionCheckoutPanel
            pilotStripeId={data?.pilot ? data?.stripeId : undefined}
            onMissionCreate={onMissionCreate}
            setSelectedTab={setSelectedTab}
            clientSecretRef={clientSecretRef}
            renderSideBar={<MissionSummary pilotData={data} values={values} />}
          />
        </TabPanel>
        <TabPanel>
          <ResposiveContainerWithSidebar
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start">
            <Flex
              w={2 / 3}
              mb="20px"
              mr={[0, '20px', '20px', '20px', 0]}
              p={['30px', '30px 50px', '30px 50px', '30px 50px']}
              background="#fff"
              borderRadius="10px"
              border="1px"
              borderColor="#ccc"
              flexDirection="column">
              <Icon
                as={IoIosCheckmarkCircleOutline}
                w="80px"
                h="80px"
                color="paymentSuccessIconColor"
                alignSelf="center"
              />
              <Text fontSize="md" my="15px" textAlign="center">
                Payment Confirmed
              </Text>
              <Text my="15px" fontSize="xs" textAlign="center">
                Your payment was successful, a confirmation email will be sent
                to
                {uid ? ` ${userEmail}` : ` registered email`}
              </Text>
              <Text
                my="10px"
                fontSize="xs"
                fontWeight="bold"
                textAlign="center">
                Your mission Due Date:
              </Text>
              <Text fontSize="xs" textAlign="center">
                {values?.dueDate && values.eventStart
                  ? `${startDate} - ${endDate}`
                  : ''}
              </Text>
              <Text my="15px" fontSize="xs" textAlign="center">
                {uid
                  ? 'If there’s any issue with the order you can contact DroneAdair support.'
                  : 'Mission successfully created, please set a password to access your account'}
              </Text>
              {uid ? (
                <Button
                  w="200px"
                  alignSelf="center"
                  onClick={() => {
                    goToDashboard();
                  }}>
                  VIEW DASHBOARD
                </Button>
              ) : (
                <Button
                  alignSelf="center"
                  w="200px"
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/reset-password/${passwordTokenRef.current}/${emailRef.current}/${missionIdRef.current}`,
                      '_self',
                    );
                  }}>
                  SET PASSWORD
                </Button>
              )}
              {/* <Text my="15px" fontSize="xs" textAlign="center">
                You have not receive your mail?
              </Text> */}
              {/* TODO:  */}
              {/* <Flex w="100%" justifyContent="center" mt="15px">
                <Button
                  w="200px"
                  mr="20px"
                  fontSize="xs"
                  onClick={onResendEmailClick}>
                  RESEND MAIL
                </Button>
              </Flex> */}
            </Flex>
            <MissionSummary pilotData={data} values={values} />
          </ResposiveContainerWithSidebar>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const EmailForm = ({
  setSelectedTab,
  isLoading,
  goToCheckout,
  isCms,
}: {
  setSelectedTab: Function;
  isLoading: boolean;
  isCms: boolean;
  goToCheckout: (index?: number) => void;
}) => {
  const toast = useToast();

  const { push, location } = useHistory();
  const { errors, values } = useFormikContext<FormType>();
  const [isSmall] = useMediaQuery('(max-width: 600px)');

  const [flow, setFlow] = useState<'login' | 'register'>('register');

  const isAuth = !!SessionStore.getState()?.session?.user?.id;

  const [login, { isLoading: isLoggingIn }] = useMutation(authApi.login, {
    onSuccess: (res) => {
      loginEvent({
        session: res,
        rememberMe: getRememberMe(),
      });

      setTimeout(() => {
        goToCheckout(2);
      }, 500);
    },
  });

  const [checkEmail, { isLoading: isChecking }] = useMutation(
    checkIfEmailExist,
    {
      onSuccess: (isEmailExist) => {
        if (isEmailExist) {
          toast({
            status: 'error',
            title: 'Error',
            description: 'Entered email already exist. please login',
            isClosable: true,
            duration: 5000,
          });

          setFlow('login');

          setTimeout(() => {
            const el = document.getElementById('_password-field');

            el?.focus?.();
          }, 200);
        } else {
          goToCheckout();
        }
      },
    },
  );

  return (
    <Stack flex="1" spacing={4} p={4}>
      <Text fontSize="24px" fontWeight="bold">
        Your almost there!
      </Text>
      {flow === 'login' ? (
        <>
          <Formik
            initialValues={{
              _email: values?.email || '',
              _password: '',
            }}
            validationSchema={loginSchema}
            onSubmit={async (v) => {
              login({
                email: v._email,
                password: v._password,
              });
            }}>
            {(formik) => (
              <Form>
                <Stack maxW="400px">
                  <FormikInputView name="_email" type="email" label="Email" />
                  <FormikInputView
                    id="_password-field"
                    name="_password"
                    type="password"
                    label="Password"
                  />
                </Stack>
                {isSmall ? (
                  <MobileMissionCreationFooter>
                    <Flex>
                      <Button
                        mr="10px"
                        w="100px"
                        size="md"
                        variant="outline"
                        isDisabled={isLoggingIn}
                        onClick={() => {
                          setFlow('register');
                        }}>
                        GO BACK
                      </Button>
                      <Button
                        onClick={() => formik.submitForm()}
                        size="md"
                        w="100px"
                        isLoading={isLoggingIn}
                        isDisabled={
                          !formik.isValid ||
                          !values.acknowledgeWeather ||
                          !formik.values._email ||
                          !formik.values._password
                        }>
                        CHECKOUT
                      </Button>
                    </Flex>
                  </MobileMissionCreationFooter>
                ) : (
                  <Flex pt={5} flexWrap="wrap" align="center">
                    <Button
                      w="150px"
                      size="md"
                      variant="outline"
                      isDisabled={isLoggingIn}
                      onClick={() => {
                        setFlow('register');
                      }}>
                      GO BACK
                    </Button>
                    <Box w={{ base: '150px', md: '10px' }} h="10px" />
                    <Button
                      onClick={() => formik.submitForm()}
                      size="md"
                      w="150px"
                      isLoading={isLoggingIn}
                      isDisabled={
                        !formik.isValid ||
                        !values.acknowledgeWeather ||
                        !formik.values._email ||
                        !formik.values._password
                      }>
                      CHECKOUT
                    </Button>
                  </Flex>
                )}
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <Box>
            <Stack>
              <Button w="300px" onClick={() => setFlow('login')}>
                LOGIN
              </Button>
              {isCms && (
                <Button
                  variant="outline"
                  w="300px"
                  onClick={() => {
                    cms.setCmsEvent(location.pathname);

                    push('/forgot-password');
                  }}>
                  FORGOT PASSWORD
                </Button>
              )}
            </Stack>
            <Text mt={10}>
              Or fill the info and we’ll send all the details and instructions
            </Text>
          </Box>
          <FormikInputView name="firstName" label="First Name" maxW="400px" />
          <FormikInputView name="lastName" label="Last Name" maxW="400px" />
          <FormikInputView name="email" label="Email" maxW="400px" />
          <FormikInputView
            name="confirmEmail"
            label="Confirm Email"
            maxW="400px"
            type="email"
            error={errors?.confirmEmail}
            onPaste={(e) => e.preventDefault()}
          />
          {isSmall ? (
            <MobileMissionCreationFooter>
              <Flex>
                <Button
                  mr="10px"
                  w="100px"
                  size="md"
                  variant="outline"
                  isDisabled={isLoading || isChecking}
                  onClick={() => {
                    setSelectedTab(isAuth ? 0 : 1);
                  }}>
                  GO BACK
                </Button>
                <Button
                  onClick={() =>
                    isAuth ? goToCheckout() : checkEmail(values?.email)
                  }
                  size="md"
                  w="100px"
                  isLoading={isLoading || isChecking}
                  isDisabled={
                    !values.acknowledgeWeather ||
                    !values.email ||
                    !values.confirmEmail ||
                    !!errors?.email ||
                    !!errors.confirmEmail ||
                    !(values as any)?.firstName?.trim?.() ||
                    !(values as any)?.lastName?.trim?.()
                  }>
                  CHECKOUT
                </Button>
              </Flex>
            </MobileMissionCreationFooter>
          ) : (
            <Flex pt={5} flexWrap="wrap" align="center">
              <Button
                w="150px"
                size="md"
                variant="outline"
                isDisabled={isLoading || isChecking}
                onClick={() => {
                  setSelectedTab(isAuth ? 0 : 1);
                }}>
                GO BACK
              </Button>
              <Box w={{ base: '150px', md: '10px' }} h="10px" />
              <Button
                onClick={() =>
                  isAuth ? goToCheckout() : checkEmail(values?.email)
                }
                size="md"
                w="150px"
                isLoading={isLoading || isChecking}
                isDisabled={
                  !values.acknowledgeWeather ||
                  !values.email ||
                  !values.confirmEmail ||
                  !!errors?.email ||
                  !!errors.confirmEmail ||
                  !(values as any)?.firstName?.trim?.() ||
                  !(values as any)?.lastName?.trim?.()
                }>
                CHECKOUT
              </Button>
            </Flex>
          )}
        </>
      )}
    </Stack>
  );
};

const PremiumMissionCreateView = ({
  data,
}: PremiumMissionCreateGeneratedProps): JSX.Element => {
  const [isSmall] = useMediaQuery('(max-width: 600px)');

  const rgbaColor = useMemo(
    () =>
      data?.brandColor
        ? hexTorgb(data?.brandColor, { format: 'css', alpha: 0.14 })
        : 'rgba(103, 40, 187, .23)',
    [data?.brandColor],
  );

  if (!data) {
    return <Box />;
  }

  return (
    <Flex
      flex={1}
      direction="column"
      h="100%"
      w="100%"
      p={[0, 10]}
      alignItems="center"
      zIndex={0}>
      <MotionFlex
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={-1}
        {...animation}
        bg="#fff">
        <Box h="250px" w="100%" bg={data?.brandColor || '#6728BB'} zIndex={1} />
      </MotionFlex>
      <Heading
        fontSize={{ base: '16px', md: '30px' }}
        color="#fff"
        fontWeight="200"
        fontFamily="Monument Extended">
        Create your Mission
      </Heading>
      <MotionFlex
        mt={5}
        flex={1}
        direction="column"
        h="100%"
        w="100%"
        maxW="1200px"
        bg="#fff"
        boxShadow={`0px 0px 50px 0px ${rgbaColor}`}
        zIndex={1}
        pb={10}
        px={[4, 10]}
        {...animation}>
        <Formik<FormType>
          initialValues={getInitialValues(data)}
          onSubmit={(values) => {
            console.log(values);
          }}
          validationSchema={ValidationSchema}>
          {() => (
            <FormTabs data={data} brandColor={data?.brandColor || '#6728BB'} />
          )}
        </Formik>
      </MotionFlex>
    </Flex>
  );
};

export default PremiumMissionCreateView;

type Props = {
  pilot: string;
  pack: Package;
  stripeId: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  radius: number;
  brandColor: string;
  industries: Array<string>;
  id?: string;
  additionalServices?: Services[];
} | null;

type RefType = {
  onOpen: (data: Props) => void;
};

export const MissionCreateModal = memo(
  forwardRef<RefType>((_, ref) => {
    const [data, setData] = useState<Props>(null);
    const [isSmall] = useMediaQuery('(max-width: 600px)');

    const { isOpen, onClose, onOpen } = useDisclosure();

    useImperativeHandle(
      ref,
      () => ({
        onOpen: (pilotData) => {
          onOpen();
          setData(pilotData);
        },
      }),
      [],
    );

    if (!data) {
      return null;
    }

    return (
      <Modal
        size="5xl"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        onOverlayClick={onClose}>
        <ModalOverlay />
        <ModalContent minH={isSmall ? '100vh' : undefined}>
          <ModalBody>
            <Formik<FormType>
              initialValues={getInitialValues(data)}
              onSubmit={(values) => {
                console.log(values);
              }}
              validationSchema={ValidationSchema}>
              {() => (
                <FormTabs
                  data={data}
                  onModalClose={onClose}
                  brandColor={data?.brandColor || '#6728BB'}
                  additionalServices={data?.additionalServices}
                />
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }),
);
