import React, { FC, useState } from 'react';

import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';

import { PilotList } from './PilotList';

import MissionListBanner from '../MissionListBanner';
import TabPanelWithShadow from 'components/primitives/TabPanelWithShadow';

import { PilotsTabsGeneratedProps } from './PilotsTabs.props';

const tabSpacing = [5, 8, 8];

const PilotsTabsView: FC<PilotsTabsGeneratedProps> = (props) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Tabs
      mt={20}
      mb={40}
      variant="enclosed"
      index={tabIndex}
      onChange={setTabIndex}>
      <TabList>
        <Tab
          _focus={{ borderColor: 'none' }}
          _selected={{ backgroundColor: 'primaryColor', color: 'white' }}
          fontSize="md"
          fontWeight="bold"
          px={tabSpacing}
          py="3">
          YOUR PILOTS
        </Tab>
        <Tab
          fontSize="md"
          style={{ visibility: 'hidden' }}
          fontWeight="bold"
          color="white"
          px={tabSpacing}
          py="3">
          Tab Placeholder
        </Tab>
      </TabList>
      <MissionListBanner
        bannerType={tabIndex === 1 ? undefined : 'premium'}
        highlightedTitle={'ASSIGNED PILOTS'}
      />
      <TabPanels bg="white" border="0.5px solid #E2E8F0">
        <TabPanelWithShadow p={0} m={0} bg="white">
          <PilotList type="1" />
        </TabPanelWithShadow>
      </TabPanels>
    </Tabs>
  );
};

export default PilotsTabsView;
