import React from 'react';

import { toggleNewUser } from 'effector/session/events';

import { selectors } from 'effector/session';

import { StripeConnectSuccessProps } from './StripeConnectSuccess.props';

import StripeConnectSuccessView from './StripeConnectSuccess.view';

const StripeConnectSuccessContainer = (props: StripeConnectSuccessProps) => {
  const user = selectors.useUser();

  const fname = user?.firstName || '';

  const goToDashboard = () => {
    props.history.replace('/pilot/dashboard');

    toggleNewUser(false);
  };

  const _props = {
    fname,
    goToDashboard,
  };

  return <StripeConnectSuccessView {..._props} />;
};

export default StripeConnectSuccessContainer;
