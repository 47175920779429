import React, { FC } from 'react';

import { chakra } from '@chakra-ui/react';

const Span = chakra('span');

export const Asterik: FC = () => {
  return (
    <Span fontSize="16px" fontWeight="bold" color="#F52121">
      *
    </Span>
  );
};
