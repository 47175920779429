import React, { FC } from 'react';

import {
  Box,
  Text,
  Flex,
  Input,
  Radio,
  Stack,
  chakra,
  Textarea,
  RadioGroup,
} from '@chakra-ui/react';

import { MdPeople, MdPlace, MdPlayArrow } from 'react-icons/md';

import GoogleMaps from 'components/primitives/GoogleMaps';

import { AloftToast } from 'components/primitives/AloftToast';
import { TitleWithIcon } from 'components/primitives/TitleWithIcon';
import { CustomMission } from 'types/mission';

import calculateDueDate from 'utils/calculateDueDate';

const brandColor = '#6728BB';
const fontSize = '14px';

const ChakraUl = chakra('ul');
const ChakraLi = chakra('li');

export const MissionClientInfo: FC<{ mission: CustomMission }> = ({
  mission,
}) => {
  const coordinates = {
    lat: mission?.clientDetails?.location?.lat || 0,
    lng: mission?.clientDetails?.location?.lng || 0,
  };

  const dateRange =
    mission?.eventStart === mission?.dueDate
      ? calculateDueDate(new Date(mission.dueDate))
      : `${calculateDueDate(
          new Date(mission?.eventStart),
        )} - ${calculateDueDate(new Date(mission?.dueDate))}`;

  const isRush = Boolean(mission.rush);
  const additionalLocations = mission.clientDetails.additionalLocations || [];
  const isMultiLocation = Boolean(additionalLocations.length > 1);

  return (
    <Flex p={6} pb={10} flexWrap="wrap" borderBottom="1px solid #ccc">
      <Stack maxW={{ base: '280px', sm: '360px' }}>
        <TitleWithIcon
          ml={-1}
          TextIcon={<MdPlace size="16px" />}
          text="Location"
          fontSize={fontSize}
        />
        {isMultiLocation ? (
          <ChakraUl>
            {additionalLocations.map((loc) => (
              <ChakraLi
                key={loc.address}
                color="black"
                fontWeight="500"
                fontSize={fontSize}>
                {loc.address}
              </ChakraLi>
            ))}
          </ChakraUl>
        ) : (
          <Text color="black" fontWeight="500" fontSize={fontSize}>
            {mission?.clientDetails?.location?.address}
          </Text>
        )}
        <Box
          mb={6}
          minW={{ base: '250px', md: '340px' }}
          w={['100%']}
          height="240px"
          background="#fff"
          borderColor="#ccc"
          bg="inputColor"
          borderRadius="30px"
          overflow="hidden">
          <GoogleMaps
            mapContainerStyle={{
              width: '100%',
              height: '240px',
            }}
            initialCenter={coordinates}
            marker={coordinates}
            zoom={13}
          />
        </Box>
        {mission.airspace ? (
          <AloftToast size="sm" {...(mission.airspace as any)} />
        ) : null}
      </Stack>
      <Box h="30px" w="40px" />
      <Stack flex={1} spacing={6} mt={{ base: 5, sm: '0' }}>
        <Flex
          flexWrap="wrap"
          fontSize={fontSize}
          fontWeight="bold"
          color="#92835C">
          <Stack minW="300px" flex={1}>
            <Text>Name</Text>
            <Input
              size="lg"
              fontSize={fontSize}
              isReadOnly
              value={mission?.clientDetails?.name}
            />
          </Stack>
          <Box h="20px" w="30px" />
          <Stack minW="300px" flex={1}>
            <Text>Industry</Text>
            <Input
              size="lg"
              fontSize={fontSize}
              isReadOnly
              value={mission?.clientDetails?.industry}
            />
          </Stack>
          <Box h="20px" w="30px" />
          {mission?.isPilotCreated ? null : (
            <Stack minW="300px" flex={1}>
              <Text>Budget</Text>
              <Input
                size="lg"
                fontSize={fontSize}
                isReadOnly
                value={'$' + mission?.clientDetails?.budget}
              />
            </Stack>
          )}
        </Flex>
        <Flex
          flexWrap="wrap"
          fontSize={fontSize}
          fontWeight="bold"
          color="#92835C">
          <Stack minW="300px" flex={1}>
            <Text>Email</Text>
            <Input
              size="lg"
              fontSize={fontSize}
              isReadOnly
              value={mission?.clientDetails?.email}
            />
          </Stack>
          <Box h="20px" w="30px" />
          <Stack minW="300px" flex={2.1}>
            <Text>Date Range</Text>
            <Box>
              <Input
                size="lg"
                fontSize={fontSize}
                isReadOnly
                value={dateRange}
                {...(isRush && {
                  borderColor: 'green.500',
                  borderWidth: '2px',
                })}
              />
              {isRush && (
                <Text fontStyle="italic" color="green.500">
                  *this mission needs to be done in the next 48 hours*
                </Text>
              )}
            </Box>
          </Stack>
        </Flex>
        <Flex
          flexWrap="wrap"
          fontSize={fontSize}
          fontWeight="bold"
          color="#92835C">
          <Stack minW="300px" flex={1}>
            <Text>Phone Number</Text>
            <Input
              size="lg"
              fontSize={fontSize}
              isReadOnly
              value={mission?.clientDetails?.phone}
            />
          </Stack>
          <Box h="20px" w={{ base: '0', sm: '30px' }} />
          <Stack flex={1} mt={1} spacing={4} minW="220px">
            <Stack isInline align="center">
              <Box
                maxH="18px"
                border={`1px solid ${brandColor}`}
                borderRadius="2px">
                <MdPlayArrow color={brandColor} />
              </Box>
              <Text fontWeight="bold">Is this a live event?</Text>
            </Stack>
            <RadioGroup
              defaultValue={mission?.liveEvent ? '1' : '0'}
              sx={{
                '.chakra-radio__control[data-checked]': {
                  background: brandColor,
                  borderColor: brandColor,
                },
                '.chakra-radio__control[data-focus]': {
                  boxShadow: 'none',
                },
              }}>
              <Stack direction="row">
                <Radio isReadOnly value="1">
                  Yes
                </Radio>
                <Radio isReadOnly value="0">
                  No
                </Radio>
              </Stack>
            </RadioGroup>
          </Stack>
          <Box h="10px" w="30px" />
          <Stack flex={1} mt={1} spacing={4} minW="200px">
            <Stack isInline align="center">
              <Box>
                <MdPeople color={brandColor} />
              </Box>
              <Text fontWeight="bold">Do you need to be on site?</Text>
            </Stack>
            <RadioGroup
              defaultValue={mission?.onsite ? '1' : '0'}
              sx={{
                '[data-focus]': { boxShadow: 'none' },
                '.chakra-radio__control[data-checked]': {
                  background: brandColor,
                  borderColor: brandColor,
                },
              }}>
              <Stack direction="row">
                <Radio isReadOnly value="1">
                  Yes
                </Radio>
                <Radio isReadOnly value="0">
                  No
                </Radio>
              </Stack>
            </RadioGroup>
          </Stack>
        </Flex>
        <Stack>
          <Text fontSize={fontSize} fontWeight="bold" color="#92835C">
            Mission Details
          </Text>
          <Textarea
            minH="100px"
            fontSize={fontSize}
            isReadOnly
            value={mission?.missionDetails}
            size="lg"
            color="#92835C"
          />
        </Stack>
      </Stack>
    </Flex>
  );
};
