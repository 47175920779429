import React, { FC, useRef, useState } from 'react';

import { Input } from '@chakra-ui/input';
import { Button } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { useDisclosure } from '@chakra-ui/hooks';
import { Center, Flex, SimpleGrid, Stack } from '@chakra-ui/layout';

import { useField } from 'formik';

import { NormalText } from 'components/primitives/NormalText';

import { getIndustryName } from 'constants/industriesMap';

import { Industries } from 'types/cms';
import { MdAddCircle } from 'react-icons/md';

const colorScheme = '#6728BB';

export const IndustriesSection: FC<{ onSave: () => void }> = ({ onSave }) => {
  const [input, , helpers] = useField<any>('industries');
  const { value: stylings } = useField<any>('stylings')[0];

  const color = stylings?.colorScheme || colorScheme;

  const [newOption, setNewOption] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);

  const { value } = input;
  const { setValue } = helpers;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [options, setOptions] = useState(() => {
    const defaultOptions = Object.values(Industries);
    const finalOptions = [
      ...defaultOptions,
      ...value?.filter((i: any) => !defaultOptions.includes(i)),
    ];

    return finalOptions;
  });

  return (
    <Stack spacing={6}>
      <Flex justifyContent="space-between" ml="30px">
        <NormalText fontSize="14px" color="#707070">
          Select your Industries/Services
        </NormalText>
      </Flex>
      <SimpleGrid
        maxW="600px"
        spacingY={6}
        columns={{ base: 1, md: 2 }}
        pl={10}>
        {options.map((item, index) => (
          <Checkbox
            sx={{
              '.chakra-checkbox__control[data-checked]': {
                background: color,
                borderColor: color,
                color: '#fff',
              },
              '.chakra-checkbox__control[data-focus]': {
                boxShadow: 'none',
              },
            }}
            key={`${item}-${index}`}
            colorScheme="brand"
            value={item}
            isChecked={value.includes(item)}
            onChange={() => {
              if (value.includes(item)) {
                setValue(value.filter((i: any) => i !== item));
              } else {
                setValue([...value, item]);
              }

              setTimeout(() => {
                onSave();
              }, 500);
            }}>
            <NormalText fontSize="14px" color="#707070">
              {getIndustryName(item || '')}
            </NormalText>
          </Checkbox>
        ))}
      </SimpleGrid>
      {isOpen ? (
        <Center flexDirection="column" pt={6}>
          <Stack w="100%" maxW="450px" mb={4}>
            <NormalText userSelect="none">Industries/Services names</NormalText>
            <Input
              ref={inputRef}
              onChange={(e) => {
                setNewOption(e.target.value);
              }}
              placeholder="Solar, Construction"
            />
          </Stack>
          <Button
            w="300px"
            bg={color}
            isDisabled={!newOption}
            onClick={() => {
              setOptions((prev) => [...prev, newOption]);
              setValue([...value, newOption]);
              setNewOption('');
              if (inputRef.current?.value) {
                inputRef.current.value = '';
              }

              setTimeout(() => {
                onSave();
              }, 500);
            }}>
            SAVE INDUSTRY/SERVICE
          </Button>
          <Button
            mt={2}
            w="200px"
            variant="ghost"
            color={color}
            borderColor={color}
            onClick={() => {
              setNewOption('');
              onClose();
            }}>
            CANCEL
          </Button>
        </Center>
      ) : (
        <Flex pl={10}>
          <Button
            variant="ghost"
            leftIcon={<MdAddCircle size="20px" />}
            onClick={onOpen}
            color={color}>
            Add Industries/Services
          </Button>
        </Flex>
      )}
    </Stack>
  );
};
