import React, { FC } from 'react';

import { Box, Center, Flex, Spinner, Text } from '@chakra-ui/react';

import {
  MdInfo,
  MdCheckCircle,
  MdNewReleases,
  MdChevronRight,
} from 'react-icons/md';

import calculateDueDate from 'utils/calculateDueDate';

import { getIndustryName } from 'constants/industriesMap';
import { missionStatusMap } from 'constants/missionStatusMap';

import Pagination from 'components/primitives/Pagination';

import { Mission, MissionStatuses } from 'types/mission';
import { ClientMissionsGeneratedProps } from './ClientMissions.props';
import { EmptyMissionsPage } from '../EmptyMissionsPage';

const tabPaddingX = [1, 1, 5];
const tabPaddingY = [1, 1, 2];
const responsiveContainer = ['auto', 'auto', '500px', '600px', '900px'];

const LoadingWrapper = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: any;
}) => {
  if (isLoading) {
    return (
      <Center p="20px">
        <Spinner />
      </Center>
    );
  }

  return children;
};

const Missions = ({
  missions,
  onMissionClick,
}: {
  missions: Mission[];
  onMissionClick: (missionId: string | number) => void;
}) => {
  if (!missions?.length) {
    return <EmptyMissionsPage />;
  }

  if (missions?.length) {
    return (
      <>
        {missions.map((mission: Mission) => {
          return (
            <Box
              fontSize={[10, 10, 12]}
              shadow="sm"
              borderWidth="1px"
              borderRadius="5px"
              mb={2}
              cursor="pointer"
              key={mission.id}
              display={'flex'}
              flexDirection={'row'}>
              <Box
                px="10px"
                w="100%"
                overflowX="auto"
                minWidth={responsiveContainer}
                onClick={() => onMissionClick(mission.id)}>
                <Flex pt={2} flex={1} color="grey">
                  <Box px={tabPaddingX} py={tabPaddingY} w="100px">
                    <Text isTruncated>{mission.id}</Text>
                  </Box>
                  <Box minW="250px" flex={2} px={tabPaddingX} py={tabPaddingY}>
                    <Text fontSize="md" noOfLines={1}>
                      {mission.location}
                    </Text>
                  </Box>
                  <Box flex={2.5} px={tabPaddingX} py={tabPaddingY}>
                    {mission.eventStart === mission.dueDate ? (
                      <Text fontSize="md">{`${calculateDueDate(
                        mission.dueDate,
                      )}`}</Text>
                    ) : (
                      <Text fontSize="md">{`${calculateDueDate(
                        mission.eventStart,
                      )} - ${calculateDueDate(mission.dueDate)}`}</Text>
                    )}
                  </Box>
                  <Box flex={1.5} px={tabPaddingX} py={tabPaddingY}>
                    <Text fontSize="md" isTruncated>
                      {getIndustryName(mission?.missionIndustry || '')}
                    </Text>
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    px={tabPaddingX}
                    py={tabPaddingY}>
                    <Text fontSize="md" isTruncated mr={3}>
                      {missionStatusMap[mission?.status || 'DEFAULT']}
                    </Text>
                    <Flex>
                      {mission.status === MissionStatuses.COMPLETED ? (
                        <MdCheckCircle size="18px" color="#34D17D" />
                      ) : [
                          MissionStatuses.SCHEDULED,
                          MissionStatuses.ASSETS_UPLOADED,
                          MissionStatuses.FLIGHT_COMPLETED,
                          MissionStatuses.ORDERED,
                        ].includes(mission.status as MissionStatuses) ? (
                        <MdNewReleases size="18px" color="#018DE1" />
                      ) : (
                        <MdInfo size="18px" color="#E9243Fed" />
                      )}
                      <MdChevronRight size="18px" color="#6728BB" />
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Box>
          );
        })}
      </>
    );
  }

  return <Flex />;
};

const ClientMissionsView: FC<ClientMissionsGeneratedProps> = ({
  page,
  setPage,
  missions,
  isLoading,
  totalPages,
  onMissionClick,
}) => {
  const isMissions = missions && missions?.length > 0;

  return (
    <Box px="15px">
      <Box
        fontSize={[10, 10, 12]}
        borderRadius="2px"
        minWidth={responsiveContainer}
        overflowX="auto"
        shadow="sm"
        px="10px"
        borderWidth="1px"
        borderColor="transparent"
        w="100%">
        <Flex pt={5} flex={1} fontWeight="600" color="black">
          <Box px={tabPaddingX} py={tabPaddingY} w="100px">
            <Text fontSize="md">Mission ID</Text>
          </Box>
          <Box flex={2} minW="250px" px={tabPaddingX} py={tabPaddingY}>
            <Text fontSize="md">Location</Text>
          </Box>
          <Box flex={2.5} px={tabPaddingX} py={tabPaddingY}>
            <Text fontSize="md">Due Date</Text>
          </Box>
          <Box flex={1.5} px={tabPaddingX} py={tabPaddingY}>
            <Text fontSize="md">Type</Text>
          </Box>
          <Box flex={1} px={tabPaddingX} py={tabPaddingY}>
            <Text fontSize="md">Status</Text>
          </Box>
        </Flex>
      </Box>
      <Box maxH="76vh" overflowY="auto">
        <LoadingWrapper isLoading={isLoading}>
          <Missions missions={missions} onMissionClick={onMissionClick} />
        </LoadingWrapper>
      </Box>
      <Box py={3}>
        {isMissions && totalPages !== 1 && page <= totalPages ? (
          <Flex mt={6} justify="flex-end">
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPreviousClick={() => setPage(page - 1)}
              onNextClick={() => setPage(page + 1)}
              onPageNumberClick={(pageNo) => setPage(pageNo)}
            />
          </Flex>
        ) : null}
      </Box>
    </Box>
  );
};

export default ClientMissionsView;
