import React, { FC, useState } from 'react';

import { Box, Center, CircularProgress, Flex, Text } from '@chakra-ui/react';

import { useIsMobile } from 'utils/hooks';

import { selectors } from 'effector/session';

import { Header } from './Header';
import { DetailsForm } from './DetailsForm';
import { MissionActions } from './MissionActions';
import { ChangesRequest } from './ChangesRequest';
import { RejectedReasons } from './RejectedReasons';
import { MissionPilotInfo } from './MissionPilotInfo';
import { MissionClientInfo } from './MissionClientInfo';
import { MissionAssetsUpload } from 'components/modules/MissionAssetsUpload';

import { CustomStatus } from 'types/mission';
import { CustomMissionGeneratedProps } from './CustomMission.props';

import MissionAssets from 'components/modules/MissionAssets';
import MobileCustomMissionDetails from 'components/modules/MobileCustomMissionDetails';
import BackButtonWithTitleView from 'components/primitives/BackButtonWithTitle/BackButtonWithTitle.view';

const CustomMissionData: FC<Required<CustomMissionGeneratedProps>> = (
  props,
) => {
  const { mission, missionId, isDeleting, onMissionDelete } = props;

  const [isEdit, setIsEdit] = useState(false);

  const userType = selectors.useUser();

  const isClient = userType?.accountType === 'CLIENT';

  const showUploadedAssets = [
    CustomStatus.ORDERED,
    CustomStatus.ASSETS_UPLOADED,
    CustomStatus.COMPLETED,
  ].includes(mission.status);

  return (
    <>
      <Header
        isEdit={isEdit}
        isClient={isClient}
        status={mission.status}
        customMissionId={mission.id || ''}
        customMission={mission}
        isDeleting={isDeleting}
        onMissionDelete={onMissionDelete}
      />
      <MissionClientInfo mission={mission} />
      {isEdit === true && (
        <Flex mt={6}>
          <ChangesRequest mission={mission} />
        </Flex>
      )}
      {isEdit ? (
        <DetailsForm
          missionId={missionId}
          mission={mission}
          onSuccess={() => setIsEdit(false)}
        />
      ) : mission.status !== CustomStatus.REQUESTED ? (
        <MissionPilotInfo
          mission={mission}
          isClient={isClient}
          onEditClick={() => setIsEdit(true)}
        />
      ) : null}
      {isEdit === false && <ChangesRequest mission={mission} />}
      {[CustomStatus.ORDERED, CustomStatus.COMPLETED].includes(
        mission.status,
      ) &&
        !isClient && (
          <MissionAssetsUpload
            type={1}
            mission={
              {
                id: missionId,
              } as any
            }
          />
        )}
      {showUploadedAssets && (
        <MissionAssets
          type={1}
          missionId={missionId}
          package={props.mission.package?.name ?? ''}
          containerProps={{ bg: '#fff' }}
        />
      )}
      {isEdit === false && (
        <>
          <MissionActions
            mission={mission}
            customMissionId={mission.id || ''}
            onClose={() => setIsEdit(false)}
            onSubmitClick={() => setIsEdit(true)}
          />
          <RejectedReasons mission={mission} />
        </>
      )}
    </>
  );
};

const View = (props: CustomMissionGeneratedProps) => {
  const isMobile = useIsMobile();

  const { mission, missionId, isLoading } = props;

  if (isMobile) {
    return (
      <MobileCustomMissionDetails
        mission={mission}
        missionId={missionId}
        isLoading={isLoading}
      />
    );
  }

  return (
    <Flex direction="column" flex={1}>
      <Box ml={3}>
        <BackButtonWithTitleView title="" />
      </Box>
      <Flex
        mx={4}
        mt={2}
        mb={20}
        bg="#fff"
        flex={1}
        minH="90vh"
        direction="column"
        borderRadius="20px"
        overflow="hidden"
        boxShadow="0 0 10px 0 rgba(0,0,0,0.1)">
        {isLoading ? (
          <Center flex={1}>
            <CircularProgress isIndeterminate size="50px" color="brand.500" />
          </Center>
        ) : mission === undefined ? (
          <Center flex={1}>
            <Text fontSize="22px" fontWeight="bold">
              Mission Not Found
            </Text>
          </Center>
        ) : (
          <CustomMissionData
            mission={mission}
            missionId={missionId}
            isLoading={isLoading}
            isDeleting={props.isDeleting}
            onMissionDelete={props.onMissionDelete}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default View;
