import React, { useEffect, useRef, useState } from 'react';

import { Input } from '@chakra-ui/input';
import { Button } from '@chakra-ui/button';
import { useToast } from '@chakra-ui/toast';
import { FormLabel } from '@chakra-ui/form-control';
import { Box, Stack, Text, Wrap, WrapItem } from '@chakra-ui/layout';

import { MdCreditCard } from 'react-icons/md';

import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { Form, Formik, useFormikContext } from 'formik';

import { createStripeIntent } from 'api/stripe';

import { useMutation, useQueryCache } from 'react-query';

import { SessionStore } from 'effector/session/store';

import FormikInputView from '../FormikInput';

import { TitleWithIcon } from '../TitleWithIcon';

import { defaultFormCheckout, MissionCheckoutSchema } from './SaveACard.utils';

import { SaveACardProps } from './SaveACard.props';
import { FormikRefType } from 'components/modules/MissionCheckoutPanel/MissionCheckoutPanel.props';

const labelProps = {
  fontWeight: '600',
  color: 'textColor',
};

const OPTIONS = {
  style: {
    base: {
      color: '#32325D',
      fontWeight: 500,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#CFD7DF',
      },
      ':-webkit-autofill': {
        color: '#e39f48',
      },
    },
    invalid: {
      color: 'red',
    },
  },
};

const FormErrors = () => {
  const { errors } = useFormikContext<any>();

  const errorsArr = [errors.ccNumber, errors.ccCVC, errors.ccExp];

  if (errorsArr.every((e) => e === undefined)) {
    return null;
  }

  return (
    <Box>
      {errorsArr.map((e, i) => (
        <Text key={`${e}-${i}`} fontWeight="bold" color="red.500">
          {e}
        </Text>
      ))}
    </Box>
  );
};

const SaveACardView = ({
  userEmail,
  isPilotStripeAcc = false,
  titleProps = {},
}: SaveACardProps): JSX.Element => {
  const toast = useToast();
  const stripe = useStripe();
  const elements = useElements();
  const queryCache = useQueryCache();
  const formikRef = useRef<FormikRefType>(null);

  const uid = SessionStore.getState()?.session?.user.id ?? '';

  const card = elements?.getElement(CardNumberElement);
  const cvcInput = elements?.getElement(CardCvcElement);
  const expInput = elements?.getElement(CardExpiryElement);

  const [isUpdated, setIsUpdated] = useState(false);
  const [isExpDisabled, setIsExpDisabled] = useState(true);
  const [isCvcDisabled, setIsCvcDisabled] = useState(true);
  const [isNumberDisabled, setIsNumberDisabled] = useState(true);

  const [submit] = useMutation(createStripeIntent, {
    onSuccess: async ({ client_secret }) => {
      await saveCard(client_secret);
    },
  });

  const [saveCard, { isSuccess }] = useMutation(
    async (clientSecret: string) => {
      if (card && stripe) {
        const response = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card,
            billing_details: {
              name: formikRef.current?.values?.ccName,
            },
          },
        });

        return response;
      }
    },
    {
      onError: (err: any) => {
        console.log(err);
        toast({
          status: 'error',
          title: 'Save Card Error',
          isClosable: true,
          duration: 5000,
        });
      },
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Card Saved successfully',
          isClosable: true,
          duration: 5000,
        });

        if (!userEmail) {
          card?.clear();
          cvcInput?.clear();
          expInput?.clear();
          formikRef?.current?.resetForm({});
          queryCache.invalidateQueries(`fetch-payment-methods-${uid}`);
        }
      },
    },
  );

  useEffect(() => {
    const unsubscribe = cvcInput?.on('change', (e) => {
      if (e.empty) {
        setIsCvcDisabled(true);
      } else if (e.error) {
        setIsCvcDisabled(true);
      } else if (!e.complete) {
        setIsCvcDisabled(true);
      } else if (e.complete) {
        setIsCvcDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [cvcInput, isUpdated]);

  useEffect(() => {
    const unsubscribe = expInput?.on('change', (e) => {
      if (e.empty) {
        setIsExpDisabled(true);
      } else if (e.error) {
        setIsExpDisabled(true);
      } else if (!e.complete) {
        setIsExpDisabled(true);
      } else if (e.complete) {
        setIsExpDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [expInput, isUpdated]);

  useEffect(() => {
    const unsubscribe = card?.on('change', (e) => {
      if (e.empty) {
        setIsNumberDisabled(true);
      } else if (e.error) {
        setIsNumberDisabled(true);
      } else if (!e.complete) {
        setIsNumberDisabled(true);
      } else if (e.complete) {
        setIsNumberDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [card, isUpdated]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={defaultFormCheckout}
      onSubmit={async () => {
        await submit();
      }}
      validationSchema={MissionCheckoutSchema}>
      {({ values, isValid, isSubmitting }) => (
        <Form>
          <TitleWithIcon
            fontSize="14px"
            text="Card Details"
            TextIcon={<MdCreditCard size="15px" />}
            {...titleProps}
          />
          <Text mb="20px" fontSize="14px">
            <i>We accept both Credit and Debit cards for your convenience.</i>
          </Text>
          <Stack spacing={6} mb="10px">
            <FormikInputView
              name="ccName"
              label="Name (as it appears on the Card)"
              labelProps={labelProps}
              maxW="400px"
              fontSize="16px"
              value={values.ccName}
            />
            <Wrap spacing={[2, 6]}>
              <WrapItem w="100%" maxW="220px">
                <Box w="100%">
                  <FormLabel htmlFor="card-number" {...labelProps}>
                    Card Number
                  </FormLabel>
                  <Input
                    as={CardNumberElement}
                    id="card-number"
                    borderRadius="5px"
                    borderWidth="1px"
                    p="10px"
                    options={{ showIcon: true, ...OPTIONS }}
                    bg="inputColor"
                    onReady={() => {
                      setIsUpdated(true);
                    }}
                  />
                </Box>
              </WrapItem>
              <WrapItem>
                <Box>
                  <FormLabel htmlFor="exp-date" {...labelProps}>
                    Expiration Date
                  </FormLabel>
                  <Input
                    as={CardExpiryElement}
                    id="exp-date"
                    borderRadius="5px"
                    borderWidth="1px"
                    p="10px"
                    options={OPTIONS}
                    bg="inputColor"
                  />
                </Box>
              </WrapItem>
              <WrapItem>
                <Box w="60px">
                  <FormLabel htmlFor="cvc" {...labelProps}>
                    CVC
                  </FormLabel>
                  <Input
                    as={CardCvcElement}
                    id="cvc"
                    borderRadius="5px"
                    borderWidth="1px"
                    p="10px"
                    options={OPTIONS}
                    bg="inputColor"
                  />
                </Box>
              </WrapItem>
            </Wrap>
            <FormErrors />
            {!userEmail && !isPilotStripeAcc && (
              <Button
                type="submit"
                fontSize="xs"
                w="200px"
                isLoading={isSubmitting}
                isDisabled={
                  !isValid ||
                  !values?.ccName ||
                  isNumberDisabled ||
                  isCvcDisabled ||
                  isExpDisabled
                }>
                SAVE CARD
              </Button>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default SaveACardView;
