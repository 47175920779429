export function formatElapsedTime(seconds: number): string {
  if (seconds < 60) {
    return `${seconds.toFixed(2)}s`;
  } else {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${isNaN(minutes) ? 0 : minutes}m ${
      isNaN(remainingSeconds) ? 0 : remainingSeconds.toFixed(2)
    }s`;
  }
}
