import styled from '@emotion/styled';
import Calendar from 'react-calendar';
import { CustomCalendarProps } from './Calendar.props';

export const CustomCalendar = styled<
  (props: CustomCalendarProps) => JSX.Element
>(Calendar)`
  ${({
    brandColor = '#6728bb',
    boxShadow = '0 14px 30px rgba(0, 0, 0, 0.15)',
  }) => `width: 360px !important;
  min-height: 250px !important;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 10px;
  box-shadow: ${boxShadow};
  box-sizing: border-box;

  @media (max-width: 400px) {
    width: 100%;
    min-width: 200px;
    max-width: 280px;
    font-size: 12px;
  }

  .react-calendar__navigation button {
    font-weight: bold;
    letter-spacing: 0.5px;

    &:hover,
    &:enabled {
      background: none;
    }
  }

  .react-calendar__navigation__label__labelText{
    color: ${brandColor};
  }

  .react-calendar__month-view__weekdays {
    border-bottom: 0.5px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 3px;
  }

  .react-calendar__navigation {
    height: 35px;
    margin-bottom: 0.3em;
  }

  .react-calendar__tile {
    height: 42px;
    box-sizing: border-box;
    border: 1px solid transparent;

    &:enabled:focus {
      background: ${brandColor};
      color: #fff;
      border-radius: 50px;
    }

    &:enabled:hover {
      background: #fff;
      border-radius: 55px;
      border: 1px solid ${brandColor};
      color: navy;
    }

    @media (max-width: 400px) {
      height: 35px;
    }
  }

  .react-calendar__tile--now {
    background: #fff;
    font-weight: bold;
    color: ${brandColor} !important;

    abbr {
      border-bottom: 2px solid ${brandColor};
    }

    &:not(:enabled) {
      &:hover {
        background: #fff !important;
      }
    }
  }

  .react-calendar__month-view__days__day[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
    border-radius: 50px;
  }

  .react-calendar__tile--active {
    background: ${brandColor} !important;
    color: #fff !important;
    border-radius: 50px;

    &:hover {
      background: ${brandColor};
      color: #fff;
      border-radius: 2px;
    }  
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: currentColor;
}
  `}
`;
