import React, { FC, useEffect, useState } from 'react';

import { Text, Flex, IconButton, useToast } from '@chakra-ui/react';
import { MdChevronLeft, MdClose } from 'react-icons/md';

import { EventRegister } from 'utils/customEvent';

import { MissionQuoteTabs } from '../MissionQuoteTabs';
import { PremiumMissionTabs } from '../PremiumMissionTabs';

import { MobileDashboardGeneratedProps } from './MobileDashboard.props';
import MobileCreateCustomMission from '../MobileCreateCustomMission';
import MobileDashboardContentView from './MobileDashboardContent.view';
import CreateCustomMissionButton from './CreateCustomMissionButton';
import usePilot from 'routes/pilot/context/usePilot';
import MobileViewModalView from '../MobileViewModal';
import { isMobile } from 'react-device-detect';

type PageType = 'custom' | 'company' | 'mobile-create-custom' | null;
const CUSTOM_MISSION_TYPES = ['custom', 'mobile-create-custom'];

const MobileDashboardView: FC<MobileDashboardGeneratedProps> = ({
  missions,
  customMissions,
  isLoadingMissions,
  isLoadingCustomMissions,
  isSitePublished,
}) => {
  const toast = useToast();
  const { hideNavBar, showNavBar } = usePilot();

  const [type, setType] = useState<PageType>();
  const IS_CUSTOM_MISSION = CUSTOM_MISSION_TYPES.includes(type || '');

  useEffect(() => {
    console.log(type);

    if (type === 'mobile-create-custom') {
      hideNavBar();
      return;
    }

    showNavBar();
  }, [type]);

  const renderHeaderContent = (type: PageType) => {
    if (!type) {
      EventRegister.emit('render_header_content', {
        renderContent: null,
      });
      return;
    }

    const renderContent = () => {
      return (
        <Flex align="center">
          <IconButton
            size="xs"
            color="#fff"
            aria-label="dash-back"
            icon={
              type === 'mobile-create-custom' ? (
                <MdClose size="30px" />
              ) : (
                <MdChevronLeft size="30px" />
              )
            }
            variant="ghost"
            onClick={() => {
              renderHeaderContent(null);
              setType(null);
            }}
          />
          <Text fontSize="20px" fontWeight="bold" maxW="90%" noOfLines={1}>
            {IS_CUSTOM_MISSION
              ? 'Custom Mission Request'
              : 'Company Mission Request'}
          </Text>
        </Flex>
      );
    };

    EventRegister.emit('render_header_content', {
      renderContent,
    });
  };

  const onCreateCustomMission = () => {
    if (isSitePublished) {
      renderHeaderContent('mobile-create-custom');
      setType('mobile-create-custom');
      return;
    }

    toast({
      status: 'warning',
      description:
        'Before sending Custom Missions, you must publish your Company website as it is currently unpublished.',
      isClosable: true,
      duration: 5000,
    });
  };

  const renderMobilePage = () => {
    const pageRenderStrategy = {
      company: () => <PremiumMissionTabs />,
      custom: () => (
        <>
          <MissionQuoteTabs />
          <CreateCustomMissionButton onClick={onCreateCustomMission} />
        </>
      ),
      ['mobile-create-custom']: () => <MobileCreateCustomMission />,
    };

    if (!type || !pageRenderStrategy[type]) {
      return (
        <MobileDashboardContentView
          missions={missions}
          customMissions={customMissions}
          isLoadingMissions={isLoadingMissions}
          isLoadingCustomMissions={isLoadingCustomMissions}
          renderHeaderContent={renderHeaderContent}
          setType={setType}
        />
      );
    }
    return pageRenderStrategy[type]();
  };

  return (
    <Flex
      h="100%"
      minW="100vw"
      direction="column"
      maxW="100vw"
      overflowX="hidden"
      backgroundColor="#F5F5F5">
      {renderMobilePage()}
      {isMobile ? <MobileViewModalView /> : <></>}
    </Flex>
  );
};

export default MobileDashboardView;
