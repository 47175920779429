import api from 'api/instance';
import { da } from 'date-fns/locale';
import { Tutorial } from 'types/tutorials';

const URL = process.env.REACT_APP_API_URL;

export const createTutorial = async (payload: any) => {
  const data = await api.post(`${URL}/tutorials`, payload);
  return data;
};

export const updateTutorial = async (payload: any, tutorialId: any) => {
  const data = await api.patch(`${URL}/tutorials/${tutorialId}`, payload);
  return data;
};

export const deleteTutorial = async (tutorialId: string) => {
  const { data } = await api.delete(`${URL}/tutorials/${tutorialId}`);
  return data;
};

export const getTutorials = async (): Promise<Tutorial[]> => {
  const { data } = await api.get(`${URL}/tutorials`);
  console.log('data -> ', data.results);
  return data.results;
};

export const getTutorial = async (tutorialId: string): Promise<Tutorial> => {
  const { data } = await api.get(`${URL}/tutorials/${tutorialId}`);
  return data;
};

// Additional functions for tutorial-related operations can be added here.
// For example, functions for uploading tutorial assets, retrieving tutorial assets, etc.

export default {
  createTutorial,
  updateTutorial,
  deleteTutorial,
  getTutorials,
  getTutorial,
};
