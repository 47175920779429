import React, { FC } from 'react';

import logo from 'res/payment-success.png';

import {
  Text,
  Flex,
  Image,
  Button,
  Center,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { animationConfig, MotionFlex } from './Motion';

import { updateUserData } from 'api/auth';
import { useMutation, useQueryCache } from 'react-query';

import FormikInput from 'components/primitives/FormikInput';
import FormikDatePicker from 'components/primitives/FormikDatePicker';
import PlacesAutocompleteView from 'components/primitives/PlacesAutocomplete';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { Session } from 'types/session';
import { CommonProps } from '../OnboardingModal.props';

const InformationDetails: FC<CommonProps> = ({ cms, setIndex }) => {
  const pilot = cms?.pilot as Session['user'];
  const queryKey = `fetch-cms-company-${cms?.username}`;

  const initialValues = {
    firstName: pilot?.firstName,
    lastName: pilot?.lastName,
    email: pilot?.email,
    phone: pilot?.phone,
    pilotCertNumber: pilot?.pilotCertNumber,
    pilotCertExpire: pilot?.pilotCertExpire
      ? new Date(pilot?.pilotCertExpire)
      : new Date(),
    address: pilot?.address,
    state: pilot?.state,
    zipcode: pilot?.zipcode,
  };

  const toast = useToast();
  const queryCache = useQueryCache();

  const [update, { isLoading }] = useMutation(updateUserData, {
    onSuccess: () => {
      toast({
        status: 'success',
        isClosable: true,
        title: 'Information Details Updated',
      });
      queryCache.invalidateQueries(queryKey);
      setIndex(2);
    },
  });

  return (
    <MotionFlex {...animationConfig} flex={1}>
      <Center gridGap="40px">
        <Image src={logo} maxH="150px" transform="rotateY(180deg)" />
        <Flex direction="column" gridGap="20px" w="30%">
          <Heading fontSize="22px">Information details</Heading>
          <Text>
            We need this information to be able to help you set up your account
            the easiest way possible.
          </Text>
        </Flex>
      </Center>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          delete (values as any).email;

          update({
            id: pilot?.id,
            ...values,
          });
        }}>
        {({ values, isValid, setFieldValue }) => (
          <Form>
            <Flex px="50px" gridGap="20px" flex={1} direction="column">
              <Flex gridGap="20px" align="flex-start">
                <FormikInput name="firstName" label="First Name" isRequired />
                <FormikInput name="lastName" label="Last Name" isRequired />
              </Flex>

              <Flex gridGap="20px" align="flex-end">
                <FormikInput
                  isDisabled
                  name="email"
                  label="Email Address"
                  isRequired
                />
                <FormikInput
                  name="phone"
                  label="Phone"
                  autoComplete="new-password"
                />
              </Flex>

              <Flex gridGap="20px" align="flex-start">
                <FormikInput
                  type="number"
                  isRequired
                  name="pilotCertNumber"
                  label="Remote Pilot Certificate Number"
                />
                <FormikDatePicker
                  isRequired
                  name="pilotCertExpire"
                  label="Pilot Certificate Expiration"
                  labelProps={{ mt: 3, fontSize: 14 }}
                />
              </Flex>

              <Flex gridGap="20px" align="flex-start">
                <Flex gridGap="20px" align="flex-start" flex={1}>
                  <FormikInput
                    name="state"
                    label="State"
                    autoComplete="new-password"
                  />

                  <FormikInput
                    type="number"
                    name="zipcode"
                    label="Zip Code"
                    autoComplete="new-password"
                  />
                </Flex>

                <Flex flex={1}>
                  <PlacesAutocompleteView
                    useTextArea
                    isRequired
                    value={values?.address || ''}
                    name="address"
                    labelText="Address"
                    labelProps={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                    setFieldValue={setFieldValue}
                    noPlaceholder
                    searchOptions={{
                      componentRestrictions: {
                        country: 'us',
                      },
                    }}
                    inputStyle={{
                      autoComplete: 'off',
                      onBlur: () => '',
                    }}
                    onSelect={async (address: string) => {
                      const result = await geocodeByAddress(address);
                      const { lat, lng } = await getLatLng(result?.[0]);

                      const state =
                        result?.[0]?.address_components?.find((i) =>
                          i.types.includes('administrative_area_level_1'),
                        )?.long_name ?? '';

                      const zipcode =
                        result?.[0]?.address_components?.find((i) =>
                          i.types.includes('postal_code'),
                        )?.long_name ?? '';

                      setFieldValue('state', state);
                      setFieldValue('zipcode', zipcode);
                      setFieldValue('address', address);
                      setFieldValue('latitude', lat);
                      setFieldValue('longitude', lng);
                    }}
                  />
                </Flex>
              </Flex>

              <Center gridGap="20px">
                <Button
                  w="300px"
                  variant="outline"
                  isDisabled={isLoading}
                  onClick={() => setIndex(2)}>
                  SKIP
                </Button>
                <Button
                  w="300px"
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={!isValid}>
                  CONTINUE
                </Button>
              </Center>
            </Flex>
          </Form>
        )}
      </Formik>
    </MotionFlex>
  );
};

export default InformationDetails;
