import React, { useState } from 'react';

import {
  Box,
  Flex,
  Link,
  Text,
  Button,
  Heading,
  Checkbox,
  Image,
} from '@chakra-ui/react';

import { Form, Formik } from 'formik';

import { GoogleLogin } from '@react-oauth/google';

import FormikInput from 'components/primitives/FormikInput';
import CenterFormContainer from 'components/layouts/ConterFormContainer';

import { LoginGeneratedProps } from './Login.props';
import MobileViewModalView from 'components/modules/MobileViewModal';
import { isMobile } from 'react-device-detect';
import MobileLandingScreenView from 'components/modules/MobileLandingScreen';
import { useIsMobile, useResponsive } from 'utils/hooks';
import DroneAdairLogo2Icon from 'components/icons/DroneAdairLogo2Icon';
import { useHistory } from 'react-router-dom';
import image from 'res/logout-img.png';
import MobileLoginView from 'components/modules/MobileLogin';

const sx = {
  '.chakra-checkbox__control[data-checked]': {
    background: '#6728BB',
    borderColor: '#6728BB',
    color: '#fff',
  },
  '.chakra-checkbox__control[hover]': {
    background: '#6728BB',
    borderColor: '#6728BB',
    color: '#fff',
  },
  '.chakra-checkbox__control[data-focus]': {
    boxShadow: 'none',
  },
};

const LoginView = (props: LoginGeneratedProps) => {
  const { isMobile } = useResponsive();
  const [step, setStep] = useState(0);

  const onLoginPress = () => {
    setStep(1);
  };
  const onSignupPress = () => {
    props.onSignupPress();
  };

  const renderMobileLogin = () => {
    return (
      <>
        {step === 0 ? (
          <MobileLandingScreenView
            onSuccess={props.onGoogleLogin}
            onLoginPress={onLoginPress}
            onSingupPress={props.onSignupPress}
          />
        ) : (
          <MobileLoginView
            onSignupPress={onSignupPress}
            onSubmit={props.onSubmit}
            isLoading={props.isLoading}
          />
        )}
      </>
    );
  };

  return (
    <>
      {isMobile ? (
        renderMobileLogin()
      ) : (
        <CenterFormContainer title="Welcome Back!">
          <Flex
            bg="white"
            maxW="500px"
            width="100%"
            py={10}
            mb={20}
            borderRadius="5px"
            border="1px"
            borderColor="gray.200"
            flexDir="column"
            align="center">
            <Flex flexDir="column" maxW="510px" w="100%" px="50px" py="30px">
              <Heading
                fontFamily="Monument Extended"
                textAlign="left"
                size="md"
                color="primaryColor">
                Login to your account
              </Heading>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                onSubmit={props.onSubmit}>
                <Form>
                  <FormikInput name="email" label="Email Address" />
                  <FormikInput
                    type="password"
                    name="password"
                    label="Password"
                  />
                  <Text mt="15px" fontSize="14px">
                    Forgot password?{' '}
                    <Link href="/forgot-password" textDecoration="underline">
                      Click here
                    </Link>
                  </Text>

                  <Checkbox
                    my="15px"
                    sx={sx}
                    onChange={(e) => {
                      props.onCheckboxChange(e.target.checked);
                    }}>
                    Remember Me
                  </Checkbox>

                  <Button
                    type="submit"
                    mb={5}
                    isFullWidth
                    isDisabled={props.isLoading}
                    isLoading={props.isLoading}>
                    LOGIN
                  </Button>
                  <GoogleLogin
                    containerProps={{
                      style: {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                    }}
                    text="signin_with"
                    auto_select={false}
                    shape="circle"
                    size="large"
                    width="100%"
                    onSuccess={(res) => {
                      if (res?.credential) {
                        props.onGoogleLogin(res.credential);
                      }
                    }}
                  />
                  {props.denySingUp ? (
                    <></>
                  ) : (
                    <Box mt="5">
                      <Text mt={2} fontSize="12px" fontWeight="bold">
                        Don't have an account?
                      </Text>
                      <Text mt={2} fontSize="12px">
                        Create a free account, request and manage your missions
                      </Text>
                      <Button
                        mb={5}
                        type="submit"
                        mt="10"
                        variant="outline"
                        isFullWidth
                        onClick={props.onSignupPress}>
                        SIGN UP
                      </Button>
                      <GoogleLogin
                        containerProps={{
                          style: {
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          },
                        }}
                        text="signup_with"
                        auto_select={false}
                        shape="circle"
                        size="large"
                        width="100%"
                        onSuccess={(res) => {
                          if (res?.credential) {
                            props.onGoogleSignup(res.credential);
                          }
                        }}
                      />
                    </Box>
                  )}
                </Form>
              </Formik>
            </Flex>
          </Flex>
        </CenterFormContainer>
      )}
    </>
  );
};

export default LoginView;
