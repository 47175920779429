import React, { FC, useEffect, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import {
  selectors,
  getUserId,
  shouldSessionPersist,
} from 'effector/session/store';
import { fetchStripeKeyFx } from 'effector/stripe';
import { getUser } from 'api/auth';
import { updateUserEvent } from 'effector/session/events';

import AuthRoutes from './auth';
import PilotRoutes from './pilot';
import ClientRoutes from './client';
import PilotWebsiteRoutes from './premiumWebsite';

import { AccountTypes } from 'types/accountTypes';

const RootRoutes: FC = () => {
  const { pathname, search } = useLocation();

  const isSession = selectors.useIsSession();
  const isNewUser = selectors.useIsNewUser();
  const accountType = selectors.useAccountType();

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = getUserId();

      if (!userId) {
        return;
      }

      const data = await getUser(userId).catch(() => null);

      if (data) {
        updateUserEvent(data);
      }
    };

    fetchUserData();
    fetchStripeKeyFx();
  }, []);

  const isProWebsite = useMemo(
    () => pathname.includes('/cms/') || pathname.includes('/create-mission'),
    [pathname, search],
  );

  const isPilot = useMemo(() => accountType === AccountTypes.pilot, [
    accountType,
  ]);

  const isClient = useMemo(() => accountType === AccountTypes.client, [
    accountType,
  ]);

  const isStripeConnect = useMemo(
    () => isNewUser && pathname.includes('/stripe-connect'),
    [pathname, isNewUser],
  );

  const Routes = useMemo(() => {
    if (isProWebsite) {
      return PilotWebsiteRoutes;
    }

    if (isSession) {
      if (isPilot) {
        if (isStripeConnect) {
          return AuthRoutes;
        }

        return PilotRoutes;
      } else if (isClient) {
        return ClientRoutes;
      }
    }

    return AuthRoutes;
  }, [isSession, isClient, isPilot, isProWebsite, isStripeConnect]);

  return <Routes />;
};

const RoutesWrapper: FC = () => {
  useEffect(() => {
    shouldSessionPersist();
  }, []);

  return <RootRoutes />;
};

export default RoutesWrapper;
