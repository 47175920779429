import React, { FC } from 'react';

import { Flex, Text, FlexProps } from '@chakra-ui/react';

import { motion } from 'framer-motion';

const bgVariants = {
  active: {
    backgroundColor: '#5f5f5f',
    transition: {
      duration: 0.3,
    },
  },
  inactive: {
    backgroundColor: '#2f2f2f',
    transition: {
      duration: 0.3,
    },
  },
};

const MotionText = motion.custom(Text);

type Props = {
  index: 0 | 1;
  setIndex: React.Dispatch<React.SetStateAction<0 | 1>>;
  containerProps?: FlexProps;
};

export const MyWorkTabs: FC<Props> = ({
  index,
  setIndex,
  containerProps = {},
}) => {
  return (
    <Flex
      gridGap="5px"
      mt="2vh"
      mb="20px"
      p="5px"
      borderRadius="10px"
      bg="#2f2f2f"
      color="#fff"
      fontSize="18px"
      {...containerProps}>
      <MotionText
        fontWeight="bold"
        as="button"
        p="10px 40px"
        bg={index === 0 ? '#5f5f5f' : '#2f2f2f'}
        borderRadius="10px"
        onClick={() => setIndex(0)}
        initial={index === 0 ? 'active' : 'inactive'}
        animate={index === 0 ? 'active' : 'inactive'}
        variants={bgVariants}>
        Photos
      </MotionText>
      <MotionText
        fontWeight="bold"
        as="button"
        p="10px 40px"
        bg={index === 1 ? '#5f5f5f' : '#2f2f2f'}
        borderRadius="10px"
        onClick={() => setIndex(1)}
        initial={index === 1 ? 'active' : 'inactive'}
        animate={index === 1 ? 'active' : 'inactive'}
        variants={bgVariants}>
        Videos
      </MotionText>
    </Flex>
  );
};
