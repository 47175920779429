import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import {
  Box,
  Flex,
  Text,
  Input,
  Stack,
  Button,
  Center,
  useToast,
  Tooltip,
  Checkbox,
  IconButton,
  CheckboxGroup,
  useDisclosure,
  FormLabel,
} from '@chakra-ui/react';
import {
  MdInfo,
  MdEdit,
  MdCheck,
  MdDelete,
  MdAddCircleOutline,
} from 'react-icons/md';
import { AiFillFire } from 'react-icons/ai';

import { getMyCMS } from 'api/cms';
import { pilotAcceptMission } from 'api/custom-mission';
import { useMutation, useQuery, useQueryCache } from 'react-query';

import { Form, Formik, useField, useFormikContext } from 'formik';

import { TitleWithIcon } from 'components/primitives/TitleWithIcon';

import FormikDatePicker from 'components/primitives/FormikDatePicker';
import FormikInput from 'components/primitives/FormikInput/FormikInput.view';
import FormikTextArea from 'components/primitives/FormikTextArea/FormikTextArea.view';

import {
  getInitialValues,
  DetailsFormValidationSchema,
} from './CustomMission.utils';

import { CMS } from 'types/cms';
import { CustomMission, CustomStatus } from 'types/mission';
import { CustomCalendar } from 'components/primitives/Calendar/Calendar.style';
import { getStripeId } from 'effector/session/store';
type Values = ReturnType<typeof getInitialValues>;

const labelProps = {
  fontSize: '14px',
};

const color = '#6728BB';
const fontSize = '14px';

const css = {
  '::placeholder': {
    fontStyle: 'italic',
    fontSize: '14px',
  },
};

const sx = {
  '.chakra-checkbox__control[data-checked]': {
    background: '#6728BB',
    borderColor: '#6728BB',
    color: '#fff',
  },
  '.chakra-checkbox__control[data-focus]': {
    boxShadow: 'none',
  },
};

const Deliverables: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { values, errors, touched, setFieldValue } = useFormikContext<Values>();

  const initialValue = {
    count: '',
    name: '',
    price: '',
  };

  const [localValue, setLocalValue] = useState(initialValue);

  const isInvalid = Boolean(errors?.lineItems) && Boolean(touched.lineItems);

  return (
    <>
      {isOpen === true && (
        <>
          <Flex align="flex-end" flexWrap="wrap">
            <Stack>
              <Text {...labelProps} fontWeight="bold">
                Item Quantity
              </Text>
              <Input
                isInvalid={isInvalid}
                type="number"
                maxW="100px"
                value={localValue.count}
                onChange={(e) =>
                  setLocalValue((prev) => ({
                    ...prev,
                    count: e.target.value,
                  }))
                }
              />
            </Stack>
            <Box h="20px" w="30px" />
            <Stack>
              <Text {...labelProps} fontWeight="bold">
                Item Name
              </Text>
              <Input
                isInvalid={isInvalid}
                maxW="200px"
                value={localValue.name}
                onChange={(e) =>
                  setLocalValue((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
              />
            </Stack>
            <Box h="20px" w="30px" />
            <Stack>
              <Text {...labelProps} fontWeight="bold">
                Item Price
              </Text>
              <Input
                isInvalid={isInvalid}
                type="number"
                maxW="100px"
                value={localValue.price}
                onChange={(e) =>
                  setLocalValue((prev) => ({
                    ...prev,
                    price: e.target.value,
                  }))
                }
              />
            </Stack>
            <Box h="20px" w="30px" />
            <Stack>
              <Text {...labelProps} fontWeight="bold">
                Total
              </Text>
              <Input
                isReadOnly
                value={
                  Number(localValue.price || '0') *
                  Number(localValue.count || '0')
                }
                maxW="100px"
                bg="#CFCFCF80"
              />
            </Stack>
            <IconButton
              ml={3}
              aria-label="add-item"
              size="sm"
              icon={<MdCheck size="20px" />}
              isRound
              mb={1}
              isDisabled={
                !localValue.name || !localValue.price || !localValue.count
              }
              onClick={() => {
                setFieldValue('lineItems', [localValue, ...values.lineItems]);
                setLocalValue(initialValue);
                onClose();
              }}
            />
          </Flex>
          {isInvalid && (
            <Text fontSize="sm" color="red.500">
              {errors?.lineItems || ''}
            </Text>
          )}
        </>
      )}
      {values?.lineItems?.map((l, i) => (
        <Flex
          key={`${l.name}-${i}`}
          minW="200px"
          maxW="580px"
          borderRadius="6px"
          bg={`${color}30`}
          align="center"
          border={`1px solid ${color}`}
          overflow="hidden">
          <Text
            my={1}
            mx={2}
            w="100%"
            color={color}
            fontWeight="bold"
            fontSize="14px">
            ({l.count}) - {l.name} (+${l.price})
          </Text>
          <IconButton
            aria-label="edit-1"
            icon={<MdEdit />}
            size="sm"
            variant="ghost"
            borderLeft={`1px solid ${color}`}
            borderRadius="0"
            _focus={{
              border: 'none',
              borderLeft: `1px solid ${color}`,
            }}
            color={color}
            onClick={() => {
              onOpen();
              setLocalValue({
                name: l.name,
                price: l.price,
                count: l.count,
              });
            }}
          />
          <IconButton
            aria-label="delete-item"
            icon={<MdDelete />}
            size="sm"
            variant="ghost"
            borderLeft={`1px solid ${color}`}
            borderRadius="0"
            _focus={{
              border: 'none',
              borderLeft: `1px solid ${color}`,
            }}
            color={color}
            onClick={() => {
              setFieldValue(
                'lineItems',
                values.lineItems.filter((_, p) => p !== i),
              );
            }}
          />
        </Flex>
      ))}
      {isOpen === false && (
        <>
          <Button
            variant="ghost"
            maxW="580px"
            w="100%"
            borderRadius="5px"
            bg="#F7F1FF"
            leftIcon={<MdAddCircleOutline size="20px" />}
            onClick={() => {
              onOpen();
              setLocalValue(initialValue);
            }}>
            Add new item
          </Button>
          <Text fontSize="sm" color="red.500">
            {errors?.lineItems || ''}
          </Text>
        </>
      )}
    </>
  );
};

type ServicesType = CMS['additionalServices'];

const Services: FC = () => {
  const initialValue = {
    name: '',
    price: '',
  };

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  const [services, setServices] = useState<ServicesType>([]);
  const [localValue, setLocalValue] = useState(initialValue);

  const [, _, helpers] = useField<any[]>('additionalServices');

  useQuery(`fetch-my-cms-published`, () => getMyCMS('PUBLISH'), {
    onSuccess: (data) => {
      if (data.additionalServices) {
        setServices((prev) => prev.concat(data.additionalServices));
      }
    },
    retry: 0,
    onError: () => {
      console.log('');
    },
  });

  return (
    <Flex direction="column" flex={1}>
      <CheckboxGroup
        onChange={(values) => {
          const formatted = values.map((v) => {
            const _item = String(v).split('pri$e');

            return { name: _item[0], price: Number(_item[1]) };
          });

          helpers.setValue(formatted);
        }}>
        <Stack>
          {services.map((a, i) => (
            <Checkbox
              sx={sx}
              fontWeight="bold"
              key={`${a.name}-${a.price}-${i}`}
              value={`${a.name}pri$e${a.price}`}>
              {a.name} - ${a.price.toFixed(2)}
            </Checkbox>
          ))}
          {isOpen === true && (
            <Flex pt={4} align="flex-end" flexWrap="wrap">
              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Item Name
                </Text>
                <Input
                  maxW="200px"
                  value={localValue.name}
                  onChange={(e) =>
                    setLocalValue((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
              </Stack>
              <Box h="20px" w="30px" />
              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Item Price
                </Text>
                <Input
                  type="number"
                  maxW="100px"
                  value={localValue.price}
                  onChange={(e) =>
                    setLocalValue((prev) => ({
                      ...prev,
                      price: e.target.value,
                    }))
                  }
                />
              </Stack>
              <Box h="20px" w="30px" />
              <IconButton
                ml={3}
                aria-label="add-item"
                size="sm"
                icon={<MdCheck size="20px" />}
                isRound
                mb={1}
                isDisabled={!localValue.name || !localValue.price}
                onClick={() => {
                  setServices((prev) => [
                    ...prev,
                    {
                      name: localValue.name,
                      price: Number(localValue.price),
                    },
                  ]);

                  setLocalValue(initialValue);
                  onClose();
                }}
              />
            </Flex>
          )}
          {isOpen === false && (
            <Box pt={4}>
              <Button
                variant="ghost"
                maxW="580px"
                w="100%"
                borderRadius="5px"
                bg="#F7F1FF"
                leftIcon={<MdAddCircleOutline size="20px" />}
                onClick={() => {
                  onOpen();
                  setLocalValue(initialValue);
                }}>
                Add service
              </Button>
            </Box>
          )}
        </Stack>
      </CheckboxGroup>
    </Flex>
  );
};

const TotalCost: FC<{ rush: boolean }> = ({ rush }) => {
  const { values } = useFormikContext<Values>();

  const additionalServicesCost: number =
    values.additionalServices
      ?.map((i) => Number(i.price || '0'))
      ?.reduce((a, b) => a + b, 0) || 0;

  const lineItemsCost: number = values.lineItems
    .map((i) => Number(i.count || '0') * Number(i.price || '0'))
    ?.reduce((a, b) => a + b, 0);

  const total = additionalServicesCost + lineItemsCost;

  return (
    <Flex
      pt={6}
      pr={8}
      align="flex-end"
      justify="flex-end"
      borderTop="1px solid #ccc">
      <Stack spacing={1}>
        {rush && (
          <Flex
            w="100%"
            pb="10px"
            alignItems="center"
            justifyContent="space-between">
            <TitleWithIcon
              m={0}
              p={0}
              text="Rush Mission"
              TextIcon={
                <AiFillFire
                  size="20px"
                  color="#E9243F"
                  style={{ marginBottom: '2px' }}
                />
              }
            />
            <Box w="50px" />
          </Flex>
        )}
        <Text>Mission Total Price: ${total.toFixed(2)}</Text>
      </Stack>
    </Flex>
  );
};

const SubmitButton: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { isValid } = useFormikContext<Values>();

  return (
    <Button isDisabled={!isValid} type="submit" isLoading={isLoading} w="240px">
      SEND CUSTOM MISSION
    </Button>
  );
};

export const DetailsForm: FC<{
  missionId: string;
  mission: CustomMission;
  onSuccess: () => void;
}> = ({ missionId, mission, onSuccess }) => {
  const toast = useToast();
  const queryCache = useQueryCache();
  const [isRescheduled, setIsRescheduled] = useState(false);

  const datePickerRef = useRef(null);

  const isPilotRejected = !!mission.logs?.find(
    (l) => l.type === CustomStatus.PILOT_REJECTED,
  );

  const [submit, { isLoading }] = useMutation(
    (_payload: any) => pilotAcceptMission(_payload, missionId),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(`fetch-custom-mission-${missionId}`);

        toast({
          status: 'success',
          title: 'Custom Mission sent',
          isClosable: true,
          duration: 3000,
        });

        onSuccess();
      },
    },
  );

  if (isPilotRejected) {
    return null;
  }

  return (
    <Stack py={6}>
      <Text mx={6} fontSize="18px" fontWeight="bold">
        Mission Details
      </Text>
      <Formik
        initialValues={getInitialValues(mission)}
        validationSchema={DetailsFormValidationSchema}
        onSubmit={(values) => {
          const additionalServicesCost: number =
            values.additionalServices
              ?.map((i) => Number(i.price || '0'))
              ?.reduce((a, b) => a + b, 0) || 0;

          const lineItemsCost: number = values.lineItems
            .map((i) => Number(i.count || '0') * Number(i.price || '0'))
            ?.reduce((a, b) => a + b, 0);

          const totalCharge = additionalServicesCost + lineItemsCost;

          const payload: any = {
            package: {
              name: values?.name,
              description: values?.description,
              price: lineItemsCost,
              lineItems: values?.lineItems,
              additionalServices: values?.additionalServices,
            },
            missionIndustry: values.missionIndustry,
            workSampleURL: values?.workSampleURL,
            isRescheduled: isRescheduled,
            totalCharge,
            pilotChosenDate: new Date(values?.pilotChosenDate).toISOString(),
          };

          if (!mission.stripeId) {
            payload.stripeId = getStripeId();
          }

          submit(payload);
        }}>
        {({ errors }) => (
          <Form>
            {mission?.isPilotCreated ? (
              <Flex
                px={6}
                flexWrap="wrap"
                gridGap="2rem"
                maxW="500px"
                mb="20px">
                <FormikInput
                  name="name"
                  label="Mission Name"
                  size="lg"
                  fontSize={fontSize}
                  labelProps={labelProps}
                  error={errors?.name}
                />
              </Flex>
            ) : (
              <>
                <Flex px={6} flexWrap="wrap" gridGap="2rem">
                  <Stack minW="300px" flex={1}>
                    <FormikInput
                      name="name"
                      label="Mission Name"
                      size="lg"
                      fontSize={fontSize}
                      labelProps={labelProps}
                    />
                  </Stack>
                  <Stack minW="300px" flex={1}>
                    <FormikInput
                      size="lg"
                      name="missionIndustry"
                      label="Industry"
                      labelProps={labelProps}
                      fontSize={fontSize}
                    />
                  </Stack>
                  <Stack minW="300px" flex={1} justifyContent="baseline">
                    <FormikInput
                      size="lg"
                      name="workSampleURL"
                      label="URL Work Samples"
                      labelProps={labelProps}
                      fontSize={fontSize}
                    />
                  </Stack>
                  <Stack flex={1} justifyContent="flex-end">
                    <FormLabel fontSize={14} fontWeight={600}>
                      Date
                      <Stack>
                        <Checkbox
                          color="#4D4D4D"
                          mt={2}
                          alignItems="flex-start"
                          colorScheme="brand"
                          checked={isRescheduled}
                          onChange={(e) => setIsRescheduled(e.target.checked)}
                          sx={{
                            '.chakra-checkbox__control[data-checked]': {
                              background: '#6728BB',
                              borderColor: '#6728BB',
                            },
                            '.chakra-checkbox__control[data-focus]': {
                              boxShadow: 'none',
                            },
                          }}>
                          <Text fontSize="12px">
                            Can't do it on those dates
                          </Text>
                        </Checkbox>
                        <FormikDatePicker
                          name="pilotChosenDate"
                          disablePastDates={false}
                          textFieldProps={{
                            css,
                            fontSize: '14px',
                            size: 'lg',
                            bg: 'inputColor',
                          }}
                          containerProps={{
                            mt: 3,
                          }}
                          maxDate={
                            isRescheduled
                              ? undefined
                              : new Date(mission.dueDate)
                          }
                        />
                      </Stack>
                    </FormLabel>
                  </Stack>
                </Flex>
                <Flex px={6} pt={2} pb={5} borderBottom="1px solid #ccc">
                  <FormikTextArea
                    minH="100px"
                    name="description"
                    label="Description"
                    labelProps={labelProps}
                    fontSize={fontSize}
                  />
                </Flex>
              </>
            )}
            <Flex flexWrap="wrap" borderBottom="1px solid #ccc">
              <Stack
                minW="300px"
                py={6}
                flex={1.5}
                borderRight="1px solid #ccc">
                <Stack mb={4} px={6} spacing={4} flex={1}>
                  <Stack align="center" isInline>
                    <Text fontSize="18px" fontWeight="bold">
                      Deliverables
                    </Text>
                    <Tooltip
                      p={2}
                      px={4}
                      borderRadius="5px"
                      hasArrow
                      bg="#E1E1E1"
                      color="#000"
                      placement="top"
                      label="Photos, videos, travel expenses, etc."
                      fontSize="md">
                      <Box>
                        <MdInfo size="24px" color="#6728BB" />
                      </Box>
                    </Tooltip>
                  </Stack>
                  <Deliverables />
                </Stack>
                <TotalCost rush={!!mission.rush} />
              </Stack>
              <Stack minW="300px" px={6} spacing={4} py={6} flex={1}>
                <Stack align="center" isInline>
                  <Text fontSize="18px" fontWeight="bold">
                    Additional Services
                  </Text>
                  <Tooltip
                    p={2}
                    px={4}
                    borderRadius="5px"
                    hasArrow
                    bg="#E1E1E1"
                    color="#000"
                    placement="top"
                    label="Create Add-ons for your client to choose from"
                    fontSize="md">
                    <Box>
                      <MdInfo size="24px" color="#6728BB" />
                    </Box>
                  </Tooltip>
                </Stack>
                <Services />
              </Stack>
            </Flex>
            <Center pt={10} pb={4} flexWrap="wrap">
              <Button
                isDisabled={isLoading}
                w="240px"
                variant="outline"
                mr={2}
                onClick={onSuccess}>
                CANCEL
              </Button>
              <SubmitButton isLoading={isLoading} />
            </Center>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
