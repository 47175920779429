import React, { useMemo } from 'react';

import { Flex, Center, CircularProgress } from '@chakra-ui/react';

import { useIsMobile } from 'utils/hooks';

import TabWithHeaderView from '../TabWithHeader';
import Pagination from 'components/primitives/Pagination';

import { PremiumMissionListGeneratedProps } from './PremiumMissionList.props';
import { EmptyMissionsPage } from '../EmptyMissionsPage';
import ResizableTableView from '../ResizableTable';
import MobilePremiumMissionListView from '../MobilePremiumMissionList';
import { columnsMissionTable } from 'utils/columnsMissionTable';

const PremiumMissionListView = ({
  page,
  setPage,
  missions,
  isLoading,
  totalPages,
  companyName,
  onMissionClick,
}: PremiumMissionListGeneratedProps) => {
  const isMobile = useIsMobile();

  const isMissions = missions?.length > 0;
  const columns = columnsMissionTable(missions);

  if (isMobile) {
    return (
      <MobilePremiumMissionListView
        missions={missions}
        isLoading={isLoading}
        isMissions={isMissions}
        totalPages={totalPages}
        onMissionClick={onMissionClick}
        setPage={setPage}
        page={page}
      />
    );
  }

  return (
    <TabWithHeaderView
      bannerType="premium"
      highlightedTitle={companyName}
      unhighlightedTitle="MISSIONS">
      <Flex direction="column" flex={1} overflow="hidden">
        <Flex direction="column" maxWidth="80vw" overflow="auto">
          {isLoading ? (
            <Center p={20} flex={1}>
              <CircularProgress color="brand.500" isIndeterminate />
            </Center>
          ) : isMissions ? (
            <ResizableTableView
              data={missions}
              columns={columns}
              onRowClick={onMissionClick}
            />
          ) : (
            <EmptyMissionsPage />
          )}
          {isMissions && totalPages !== 1 && page <= totalPages ? (
            <Flex mt={6} justify="flex-end">
              <Pagination
                totalPages={totalPages}
                currentPage={page}
                onPreviousClick={() => setPage(1)}
                onNextClick={() => setPage(1)}
                onPageNumberClick={(pageNo) => setPage(pageNo)}
              />
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </TabWithHeaderView>
  );
};

export default PremiumMissionListView;
