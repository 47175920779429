import React, { useEffect } from 'react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { ResposiveContainerWithSidebar } from 'routes/client/Dashboard/Dashboard.style';
import { MissionSideBar } from '../MissionSideBar';
import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { Flex, Text } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';

import { MissionConfirmationPanelProps } from './MissionConfirmationPanel.props';
import { SessionStore } from 'effector/session/store';
import {
  FormValues,
  clearStore,
} from 'routes/client/Dashboard/Dashboard.utils';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';
import { routesSettings } from 'constants/routes';
import { useQueryCache } from 'react-query';
import { MissionStatuses } from 'types/mission';
import { format } from 'date-fns';
import { MobileMissionCreationFooter } from '../MobileMissionCreationFooter';

const MissionConfirmationPanelView = ({
  packages,
}: MissionConfirmationPanelProps): JSX.Element => {
  const history = useHistory();
  const queryCache = useQueryCache();
  const formValues = useStore(FormValues);
  const [isSmall] = useMediaQuery('(max-width: 600px)');
  const userEmail = SessionStore.getState()?.session?.user?.email ?? '';

  const onDoneClick = () => {
    history.replace(routesSettings.CLIENT_MISSIONS.path);
    queryCache.invalidateQueries(
      [
        `fetch-missions-undefined-CLIENT`,
        `fetch-missions-${MissionStatuses.COMPLETED}-CLIENT`,
      ],
      {
        exact: true,
      },
    );

    queryCache.invalidateQueries(`fetch-client-missions`);

    // resetting the form stores
    clearStore();
  };

  useEffect(() => {
    window.scrollTo({
      top: 10,
    });
  }, []);

  const startDate = format(new Date(formValues?.eventStart), 'MM/dd/yyyy');
  const endDate = format(new Date(formValues?.dueDate), 'MM/dd/yyyy');

  const EventDateTextRend = (): JSX.Element => {
    return formValues.eventStart !== formValues.dueDate ? (
      <Text fontSize="xs">
        {formValues?.dueDate && formValues.eventStart
          ? `${startDate} - ${endDate}`
          : ''}
      </Text>
    ) : (
      <Text fontSize="xs">
        {formValues?.dueDate && formValues.eventStart ? `${startDate}` : ''}
      </Text>
    );
  };
  return (
    <ResposiveContainerWithSidebar
      w="100%"
      justifyContent="space-evenly"
      alignItems="flex-start">
      <Flex
        w={2 / 3}
        mb="20px"
        mr={[0, '20px', '20px', '20px', 0]}
        p={['30px', '30px 50px', '30px 50px', '30px 50px']}
        background="#fff"
        borderRadius="10px"
        border="1px"
        borderColor="#ccc"
        flexDirection="column">
        <Icon
          as={IoIosCheckmarkCircleOutline}
          w="80px"
          h="80px"
          color="paymentSuccessIconColor"
          alignSelf="center"
        />
        <Text fontSize="md" my="15px" textAlign="center">
          Payment Confirmed
        </Text>
        <Text my="15px" fontSize="xs">
          Your payment was successful, a confirmation email will be sent to
          {` ${userEmail}`}
        </Text>
        <Text my="10px" fontSize="xs" fontWeight="bold">
          Your mission Due Date:
        </Text>
        <EventDateTextRend />
        <Text my="15px" fontSize="xs">
          If there’s any issue with the order you can contact DroneAdair
          support.
        </Text>
        {isSmall ? (
          <MobileMissionCreationFooter>
            <Flex>
              <Button fontSize="xs" onClick={onDoneClick}>
                GO TO DASHBOARD
              </Button>
            </Flex>
          </MobileMissionCreationFooter>
        ) : (
          <Flex w="100%" justifyContent="center" mt="15px">
            <Button w="200px" mr="20px" fontSize="xs" onClick={onDoneClick}>
              DONE
            </Button>
          </Flex>
        )}
      </Flex>
      <MissionSideBar packages={packages} />
    </ResposiveContainerWithSidebar>
  );
};

export default MissionConfirmationPanelView;
