import React, { FC, useState } from 'react';

import { Stack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { useToast } from '@chakra-ui/toast';
import { Spinner } from '@chakra-ui/spinner';

import { useFormikContext } from 'formik';

import { aloftCheck } from 'api/mission';

import { reverseGeocoding } from 'utils/reverseGeocoding';

import { AloftToast } from 'components/primitives/AloftToast';

import { AloftResponse } from 'types/mission';

type Props = { setMapMarker: Function };

export const SelectLocationButton: FC<Props> = (props) => {
  const toast = useToast();

  const { setMapMarker } = props;
  const { values, setFieldValue } = useFormikContext<any>();

  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [response, setResponse] = useState<null | AloftResponse>(null);

  const checkAloftData = async (payload: {
    latitude: number;
    longitude: number;
  }) => {
    try {
      setIsChecking(true);

      const _response = await aloftCheck(payload);

      setIsChecking(false);

      setResponse(_response);

      return true;
    } catch (error: any) {
      setIsChecking(false);

      toast({
        status: 'error',
        description: error?.message || 'something went wrong',
        isClosable: true,
        duration: 2000,
      });

      return false;
    }
  };

  return (
    <Stack w="100%">
      <Button
        w="100%"
        leftIcon={isChecking ? <Spinner /> : undefined}
        fontSize="sm"
        disabled={
          isChecking
            ? isChecking
            : values.mapSelection === 'ADDRESS'
            ? !values.location
            : !values.latitude && !values.longitude
        }
        onClick={async () => {
          if (!values?.latitude || !values?.longitude) {
            return;
          }

          const latitude = +values?.latitude;
          const longitude = +values?.longitude;

          const isValid = await checkAloftData({
            latitude,
            longitude,
          });

          if (!isValid) {
            // setFieldValue('location', '');
            // return;
          }

          if (values.mapSelection === 'ADDRESS') {
            if (!values.location) return;
            setMapMarker({
              lat: latitude,
              lng: longitude,
            });
          } else {
            if (!values.latitude || !values.longitude) return;
            setMapMarker({
              lat: latitude,
              lng: longitude,
            });

            const address_components = await reverseGeocoding(
              latitude,
              longitude,
            );

            if (!address_components) {
              return;
            }

            const { city, location, state } = address_components;

            setFieldValue('location', location);
            setFieldValue('city', city);
            setFieldValue('state', state);
          }
        }}>
        {isChecking ? 'We are checking this airpsace' : 'SELECT LOCATION'}
      </Button>
      {response !== null ? <AloftToast w="100%" {...response} /> : null}
    </Stack>
  );
};
