import React, { FC } from 'react';

import {
  Flex,
  Input,
  Stack,
  Button,
  FormLabel,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import { MdClose, MdEdit } from 'react-icons/md';

import { CmsCompanyNameInputGeneratedProps } from './CmsCompanyNameInput.props';

const CmsCompanyNameInputView: FC<CmsCompanyNameInputGeneratedProps> = ({
  isEdit,
  newValue,
  isLoading,
  isCreating,
  onSubmit,
  setIsEdit,
  onCancel,
  setNewValue,
  renderLabel,
  containerProps = {},
}) => {
  return (
    <Stack mt="50px" spacing="0" minW="300px" {...containerProps}>
      <Flex align="flex-end" justify="space-between">
        {renderLabel ? (
          renderLabel()
        ) : (
          <FormLabel fontSize="12px" fontWeight="bold">
            Company Name
          </FormLabel>
        )}
        <IconButton
          aria-label="close-input-icons"
          icon={
            isLoading ? (
              <Spinner />
            ) : isEdit ? (
              <MdClose size="18px" />
            ) : (
              <MdEdit size="18px" />
            )
          }
          variant="ghost"
          size="xs"
          mb="5px"
          isDisabled={isCreating || isLoading}
          onClick={isEdit ? onCancel : () => setIsEdit(true)}
        />
      </Flex>
      <Input
        value={newValue}
        isReadOnly={!isEdit || isCreating || isLoading}
        onChange={(e) => setNewValue(e.target.value.trim())}
      />
      <Flex h="10px" />
      {isEdit && (
        <Button alignSelf="center" isLoading={isCreating} onClick={onSubmit}>
          Update name
        </Button>
      )}
    </Stack>
  );
};

export default CmsCompanyNameInputView;
