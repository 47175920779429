import { useQuery } from 'react-query';
import { getPilotCollaborations } from 'api/mission';

export const usePilotCollaborations = (type: '0' | '1') => {
  const queryKey = `fetch-pilots-${type}`;
  const queryFn = () => getPilotCollaborations(type);

  const query = useQuery(queryKey, queryFn, {
    onError: () => {
      console.log('');
    },
  });

  return query;
};
