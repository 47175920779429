import { phoneRegExp } from 'constants/registration';
import * as yup from 'yup';

const settingsValidationSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is invalid')
    .required('Required field'),
});

export { settingsValidationSchema };
