import React, { useMemo } from 'react';

import { GoogleMap, Marker, Circle } from '@react-google-maps/api';
import { GoogleMapsProps } from './GoogleMaps.props';

const MAP_OPTIONS = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDoubleClickZoom: true,
  scrollwheel: false,
};

const CIRCLE_OPTIONS = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

const GoogleMapsView = (props: GoogleMapsProps): JSX.Element => {
  const position = useMemo(
    () => ({
      lat: props.marker?.lat ?? 40.712675,
      lng: props.marker?.lng ?? -74.006652,
    }),
    [props.marker],
  );

  const options = useMemo(
    () => ({
      ...MAP_OPTIONS,
      ...props.mapOptions,
    }),
    [props.mapOptions],
  );

  return (
    <GoogleMap
      mapContainerStyle={props.mapContainerStyle}
      center={props.initialCenter}
      options={options}
      zoom={props.zoom}>
      <Marker position={position} />
      {props.isRadius && props.radius && (
        <Circle
          center={position}
          options={CIRCLE_OPTIONS}
          radius={props.radius}
        />
      )}
      {props.polygon && props.polygon}
    </GoogleMap>
  );
};

export default React.memo(GoogleMapsView);
