import React from 'react';

import { Box, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';

import { useIsMobile } from 'utils/hooks';

import { useStore } from 'effector-react';
import { SessionStore } from 'effector/session/store';

import Image1 from 'res/Frame.png';
import Image2 from 'res/DA_DL.png';

import PilotHeader from 'components/modules/PilotHeader';

import { EducationGeneratedProps } from './Education.props';

import MobileEducationView from 'components/modules/MobileEducationView/MobileEducationView';
import COURSES from 'utils/educationCourses';
import CourseCard from 'components/primitives/CourseCard/CourseCard';

const EducationView = (props: EducationGeneratedProps) => {
  const isMobile = useIsMobile();

  const name = useStore(SessionStore)?.session?.user.firstName || '';

  return (
    <Flex pb="100px" flex={1} direction="column" bg="#f1f1f1">
      {isMobile ? (
        <MobileEducationView />
      ) : (
        <Flex flexWrap={'wrap'} flexDirection={'column'}>
          <>
            <Stack
              mx={5}
              style={{
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 30,
              }}>
              <Stack
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 20,
                }}>
                <PilotHeader name={name} />
              </Stack>
            </Stack>
            <Flex bg="#fff" mt={10}>
              <Image src={Image1} h="200px" w="200px" />
              <Box w="40px" />
              <Stack mt={4}>
                <Heading>Droneadair Education</Heading>
                <Text fontSize="22px" lineHeight="24px" maxW="400px">
                  We are happy to help you to improve your skills!
                </Text>
              </Stack>
              <Box w="40px" />
              <Image
                src={Image2}
                h="200px"
                w="100%"
                maxW="400px"
                objectFit="contain"
              />
            </Flex>
          </>
          <Flex flexWrap="wrap" align="center">
            {COURSES.map((course, index) => (
              <CourseCard key={`${course.id}-${index}`} course={course} />
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default EducationView;
