import React, { FC } from 'react';

import { Button } from '@chakra-ui/react';

import * as chargebee from 'effector/chargebee';

import { useIsMobile } from 'utils/hooks';

import { useMutation } from 'react-query';
import { getPortalSession } from 'api/chargebee';

import { useChargebee } from 'utils/hooks/useChargebee';

export const ManageAccountButton: FC = () => {
  const isMobile = useIsMobile();
  const chargebeeInstance = useChargebee();

  const [fetch, { isLoading }] = useMutation(getPortalSession, {
    onSuccess: ({ portal_session }) => {
      const cbPortal = chargebeeInstance.createChargebeePortal();

      chargebeeInstance.setPortalSession(async () => portal_session);

      cbPortal.open({
        close: function () {
          chargebee.fetchCbSubsEv();
        },
      });
    },
  });

  return (
    <Button
      textTransform={isMobile ? 'uppercase' : undefined}
      isLoading={isLoading}
      fontSize={isMobile ? '14px' : '12px'}
      mt="15px"
      w={isMobile ? '100%' : '200px'}
      onClick={() => {
        fetch();
      }}>
      Update Payment Method
    </Button>
  );
};
