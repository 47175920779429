import { getMissions, GetMissionsAPIParams } from 'api/mission';
import { useStore } from 'effector-react';
import { SessionStore } from 'effector/session/store';
import { QueryResult, useQuery } from 'react-query';
import { AccountTypes } from 'types/accountTypes';
import { ApiResponse, Mission } from 'types/mission';

const useMissionsList = (
  apiParams: GetMissionsAPIParams,
): QueryResult<ApiResponse<Mission[]>, unknown> => {
  const sessionStore = useStore(SessionStore);

  const user = sessionStore?.session?.user;
  const isPilot = user?.accountType === AccountTypes.pilot;

  const isClientOrPilot = isPilot ? { pilot: user?.id } : { client: user?.id };
  const currentUserType = isPilot ? 'PILOT' : 'CLIENT';

  const useQueryResult = useQuery(
    `fetch-missions-${apiParams.type}-${currentUserType}`,
    async () => getMissions({ ...isClientOrPilot, ...apiParams }),
  );

  return useQueryResult;
};

export default useMissionsList;
