import React from 'react';

import Course1 from 'res/logos/1.png';
import Course2 from 'res/logos/2.png';
import Course3 from 'res/logos/3.png';
import Course4 from 'res/logos/4.png';
import Course5 from 'res/logos/5.png';
import Course6 from 'res/logos/6.jpeg';
import Course7 from 'res/logos/7.jpeg';
import Course8 from 'res/logos/8.jpeg';
import Course9 from 'res/logos/9.jpeg';
import Course10 from 'res/logos/10.jpeg';

export enum CourseTypes {
  basic,
  prep,
  business,
}

export interface Course {
  id: number;
  name: string;
  type: number;
  label: string;
  items: string[];
  discount: string;
  image: string;
  link: string;
}

const COURSES: Course[] = [
  {
    id: 1,
    name: 'Drones 101',
    type: CourseTypes.basic,
    label: "In this course, you'll learn:",
    items: [
      'The Basic Components of Drones',
      'How Drones are currently being used, and may be used in the future',
      'Eight practical flight exercises you can do at home',
    ],
    discount: '20$',
    image: Course1,
    link:
      'https://courses.dronelaunchacademy.com/p/drones-101?coupon_code=DRONEADAIR&affcode=58782_yyyahpto',
  },
  {
    id: 2,
    name: 'Part 107 Exam Prep',
    type: CourseTypes.prep,
    label: "In this course, you'll learn:",
    items: [
      '107 Regulations',
      'Operations limitations',
      'Flight at night',
      'Restrictions and requirements',
    ],
    discount: '50$',
    image: Course2,
    link:
      'https://courses.dronelaunchacademy.com/p/drone-exam-study-course?coupon_code=DRONEADAIR&affcode=58782_yyyahpto',
  },
  {
    id: 3,
    name: 'Aerial Video A to Z',
    type: CourseTypes.basic,
    label: "In this course, you'll learn:",
    items: [
      'What type of equipment (computers, software, drones) you need.',
      'Pre-flight planning and how to avoid amateur mistakes',
      'How to correctly film slow motion footage',
    ],
    discount: '150$',
    image: Course3,
    link:
      'https://courses.dronelaunchacademy.com/p/aerial-video-a-to-z?coupon_code=DRONEADAIR&affcode=58782_yyyahpto',
  },
  {
    id: 4,
    name: 'Aerial Photo Pro',
    type: CourseTypes.basic,
    label: "In this course, you'll learn:",
    items: [
      'From takeoff to capturing the photo to landing to editing',
      'How to research and scope out your location',
      'How to take photos in low-light that have that "glowing" look',
    ],
    discount: '50$',
    image: Course4,
    link:
      'https://courses.dronelaunchacademy.com/p/aerialphotopro?coupon_code=DRONEADAIR&affcode=58782_yyyahpto',
  },
  {
    id: 5,
    name: 'Aerial Roof Inspection Pro',
    type: CourseTypes.prep,
    label: "In this course, you'll learn:",
    items: [
      'Materials, obstruction and anatomy',
      'Roof damages examples, conducting flights',
      'Create orthomosaic map and deliverables',
    ],
    discount: '150$',
    image: Course5,
    link:
      'https://courses.dronelaunchacademy.com/p/aerial-roof-inspection-pro?coupon_code=DRONEADAIR&affcode=58782_yyyahpto',
  },
  {
    id: 6,
    name: 'Drone Business Foundations',
    type: CourseTypes.business,
    label: "In this course, you'll learn:",
    items: [
      `The "Success Equation," will help you understand the factors that will determine your business's success`,
      `How to create a drone business plan`,
      `How to choose a niche industry for your business to focus on`,
      'And more',
    ],
    discount: '0',
    image: Course6,
    link:
      'https://courses.dronelaunchacademy.com/p/drone-business-foundations?coupon_code=DRONEADAIR&affcode=58782_yyyahpto',
  },
  {
    id: 7,
    name: 'Powerful Online Presence',
    type: CourseTypes.business,
    label: 'This course will teach you how to:',
    items: [
      `Choose a name and logo for your business`,
      `Set up your business's social media profiles`,
      `Build and optimize your website`,
      `Create a portfolio that will help you land clients`,
      `And more`,
    ],
    discount: '30%',
    image: Course7,
    link:
      'https://courses.dronelaunchacademy.com/p/powerful-online-presence?coupon_code=DRONEADAIR&affcode=58782_yyyahpto',
  },
  {
    id: 8,
    name: 'Drone Business Back Office',
    type: CourseTypes.business,
    label: "In this course, you'll learn:",
    items: [
      `How to set up and register your business entity`,
      `How to set up your business bank accounts and accounting software`,
      `How to pay business taxes`,
      `How to choose a drone insurance policy that will best fit your business's needs`,
      `And more`,
    ],
    discount: '30%',
    image: Course8,
    link:
      'https://courses.dronelaunchacademy.com/p/drone-business-back-office?coupon_code=DRONEADAIR&affcode=58782_yyyahpto',
  },
  {
    id: 9,
    name: 'Landing Dream Drone Clients',
    type: CourseTypes.business,
    label: 'In this course, 6-figure drone business owners will teach you:',
    items: [
      `How to network and build relationships with key decision-makers, which will help you close important deals`,
      `Tried and tested outreach methods that will help you transform your leads into paying clients`,
      `You'll also get access to their EXACT outreach messages, emails, presentations, pricing guidelines, and more.`,
    ],
    discount: '30%',
    image: Course9,
    link:
      'https://courses.dronelaunchacademy.com/p/landing-dream-drone-clients?coupon_code=DRONEADAIR&affcode=58782_yyyahpto',
  },
  {
    id: 10,
    name: 'Google Ads for Drone Businesses',
    type: CourseTypes.business,
    label: 'This course will teach you:',
    items: [
      `What Google Ads are and why you should use them`,
      `How Google Ads Campaigns and Search Ads work`,
      `The types of Keyword Matches and Negative Keywords`,
      `How to use location targeting to find leads that want to hire drone pilots in your town or city`,
      `How to optimize your Google Ads`,
      `And more`,
    ],
    discount: '30%',
    image: Course10,
    link:
      'https://courses.dronelaunchacademy.com/p/digital-marketing-and-paid-advertising-for-drone-businesses?coupon_code=DRONEADAIR&affcode=58782_yyyahpto',
  },
];

export default COURSES;
