import React from 'react';
import {
  Flex,
  Input,
  Text,
  FormControl,
  FormLabel,
  chakra,
  Tooltip,
  FormErrorMessage,
  Textarea,
} from '@chakra-ui/react';
import { ErrorMessage, useField } from 'formik';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { PlacesAutocompleteProps } from './PlacesAutocomplete.props';
import { MdInfo, MdPlace } from 'react-icons/md';
import { TitleWithIcon } from '../TitleWithIcon/index';

const Span = chakra.span;

const PlacesAutocompleteView = ({
  name = '',
  isInfo = false,
  searchOptions,
  onChange,
  isRequired,
  isInvalid,
  isTouched,
  infoColor = '#6728BB',
  suggestionsContainerProps,
  isMobile,
  ...props
}: PlacesAutocompleteProps): JSX.Element => {
  const [, meta] = useField(name);
  let placesRef: any;

  return (
    <PlacesAutocomplete
      ref={(ref) => {
        placesRef = ref;
      }}
      value={props.value}
      searchOptions={searchOptions}
      onChange={(address: string) => {
        return onChange
          ? onChange(address)
          : props.setFieldValue && props.setFieldValue(name, address);
      }}
      onSelect={
        props.onSelect
          ? props.onSelect
          : async (address: string) => {
              if (props.setFieldValue) {
                props.setFieldValue(name, address);
                const result = await geocodeByAddress(address);
                const geometry = await getLatLng(result[0]);
                props.setFieldValue('latitude', geometry.lat.toString());
                props.setFieldValue('longitude', geometry.lng.toString());
                const city =
                  result?.[0]?.address_components?.find((i) =>
                    i.types.includes('administrative_area_level_2'),
                  )?.long_name || '';
                const state =
                  result?.[0]?.address_components?.find((i) =>
                    i.types.includes('administrative_area_level_1'),
                  )?.long_name || '';

                props.setFieldValue('city', city);
                props.setFieldValue('state', state);

                if (props.setMapMarker) {
                  props.setMapMarker.current = geometry;
                }

                if (props.onSelect) {
                  props.onSelect(result);
                }
              }
            }
      }>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <FormControl mt={3} isRequired={isRequired} isInvalid={isInvalid}>
          {isMobile && (
            <TitleWithIcon
              ml={-1}
              TextIcon={<MdPlace size="16px" />}
              text="Location"
              fontSize={'13px'}
            />
          )}
          <FormLabel
            marginBottom="9px"
            fontWeight="400"
            htmlFor={name}
            textTransform="capitalize"
            position="relative"
            {...props.labelProps}>
            {!isMobile ? props.labelText || name : null}
            {isInfo && (
              <Tooltip
                p={4}
                bg="#fff"
                shadow="lg"
                hasArrow
                color="#4f4f4f"
                placement="top"
                label="We need the specific location in order to give you a more accurate price">
                <Span position="absolute" ml={3} top="3px">
                  <MdInfo size="18px" color={infoColor} />
                </Span>
              </Tooltip>
            )}
          </FormLabel>

          <Flex flexDirection="column">
            {props.useTextArea ? (
              <Textarea
                {...getInputProps({
                  placeholder: !props.noPlaceholder ? 'Search Places ...' : '',
                })}
                resize="none"
                rows={1}
                h="40px"
                fontSize="15px"
                whiteSpace="nowrap"
                overflowX="auto"
                overflowY="hidden"
                value={props.value}
                mb="10px"
                id={name}
                bg="inputColor"
                aria-describedby={`${name}-helper-text`}
                isInvalid={meta.touched && meta.error ? true : false}
                {...props.inputStyle}
                onBlur={
                  props.inputStyle?.onBlur
                    ? () => {
                        const suggestion = suggestions?.[0];

                        if (!suggestion) {
                          return;
                        }

                        const suggProps = getSuggestionItemProps(suggestion);

                        const event = new MouseEvent('click', {
                          bubbles: true,
                        });

                        suggProps?.onClick?.(event as any);
                      }
                    : undefined
                }
              />
            ) : (
              <Input
                {...getInputProps({
                  placeholder: !props.noPlaceholder ? 'Search Places ...' : '',
                })}
                value={props.value}
                mb="10px"
                id={name}
                bg="inputColor"
                aria-describedby={`${name}-helper-text`}
                isInvalid={meta.touched && meta.error ? true : false}
                {...props.inputStyle}
                onBlur={
                  props.inputStyle?.onBlur
                    ? () => {
                        const suggestion = suggestions?.[0];

                        if (!suggestion) {
                          return;
                        }

                        const suggProps = getSuggestionItemProps(suggestion);

                        const event = new MouseEvent('click', {
                          bubbles: true,
                        });

                        suggProps?.onClick?.(event as any);
                      }
                    : undefined
                }
              />
            )}
            <Flex
              flexDirection="column"
              position="absolute"
              top="64px"
              width="100%"
              backgroundColor="#fff"
              zIndex="1"
              {...suggestionsContainerProps}>
              {loading && (
                <Flex padding="10px">
                  <Text fontSize="13px">Loading...</Text>
                </Flex>
              )}
              {suggestions.map((suggestion) => {
                const style = suggestion.active
                  ? {
                      backgroundColor: '#fafafa',
                      cursor: 'pointer',
                    }
                  : {
                      backgroundColor: '#ffffff',
                      cursor: 'pointer',
                    };
                return (
                  <Flex
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                    key={suggestion.placeId}
                    paddingY="5px"
                    paddingX="10px"
                    borderColor="#f3f33"
                    borderWidth="1px">
                    <Flex>{suggestion.description}</Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
          {Boolean(meta.error) && meta.touched && (
            <>
              <FormErrorMessage
                flexDirection={'column'}
                alignItems={'flex-start'}>
                Required Field
              </FormErrorMessage>
            </>
          )}
        </FormControl>
      )}
    </PlacesAutocomplete>
  );
};

export default React.memo(PlacesAutocompleteView);
