import { CMS, LineItems } from './cms';

interface Package {
  additionalServices: PackageLineItems[];
  description: string;
  disabled: boolean;
  id: string;
  lineItems: PackageLineItems[];
  length: number;
  name: string;
  pilot: string;
  price: number;
}

export enum MissionStatuses {
  FOR_PAYMENT = 'FOR_PAYMENT',
  ORDERED = 'ORDERED',
  SCHEDULED = 'SCHEDULED',
  FLIGHT_COMPLETED = 'FLIGHT_COMPLETED',
  ASSETS_UPLOADED = 'ASSETS_UPLOADED',
  PROPOSAL = 'PROPOSAL',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
}

export interface Mission {
  id: string;
  client?: CreateUserEvent;
  liveEvent?: boolean;
  onsite?: boolean;
  dueDate: Date;
  startEvent: Date;
  restrictedArea?: boolean;
  missionIndustry?: string;
  package?: Package | any;
  additionalServices?: AdditionalService[];
  totalCharge?: number;
  notes?: string;
  gps?: GPSType;
  status?: string;
  pilot?: Pilot;
  location: string;
  type?: string;
  eventStart: Date;
  meetupTime?: string[] | null;
  cms?: CMS;
  contact: null | {
    name: string;
    email: string;
    phone: string;
  };
  city: string;
  state: string;
  pilotNotes?: string;
  locationDetail?: string;
  airspace?: Partial<AloftResponse>;
  cancellation?: {
    reason: string;
    requestedBy: 'CLIENT' | 'PILOT';
    status: 'ACCEPTED' | 'REJECTED';
  };
}

export interface CreateMission {
  firstName: string;
  lastName: string;
  dateOfService: Date;
  email: string;
  phone: string;
  restrictedArea: boolean;
  location: string;
  missionIndustry: string;
  package: number;
  gps: GPSType;
}

export interface AdditionalService {
  name: string;
  description: string;
  price: number;
}

export interface PackageLineItems {
  name: string;
  description: string;
  count: number;
  price?: number;
}

export interface ListMission {
  radius: number;
  // missionIndustry: string;
  lat: string;
  lng: string;
}

export interface OnsiteContact {
  name: string;
  phoneNumber: string;
  email: string;
}

export interface GPSType {
  coordinates: Array<number>;
  lng: number;
  lat: number;
}

export interface CreateUserEvent {
  cognitoId: string;
  email: string;
  userStatus: string;
  givenName: string;
  middleName: string;
  familyName: string;
  fullname?: string;
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  state: string;
  zipCode: string;
  companyName: string;
  accountType: string;
  pilotCertNumber: string;
  profileImage: string;
  isActivated?: boolean;
}

export interface Pilot extends CreateUserEvent {
  id?: string;
}

export interface Assets {
  fileName: string;
  fileType: string;
  fileSize: number;
  dateCreated: Date;
  status: string;
  mission: string;
  url: string;
}

export interface MissionAssetStatus {
  missionId: string;
  status: AssetStatus;
}

export enum AssetStatus {
  IN_PROCESS = 'IN_PROCESS',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  FOR_REVIEW = 'FOR_REVIEW',
  COMPLETED = 'COMPLETED',
}

export interface MissionAsset {
  fileName: string;
  fileType: string;
  fileSize: number;
  keyName: string;
  status: string;
  id: string;
  signedUrl: string;
  expiredAt: string;
  createdAt?: string;
}

export interface ApiResponse<T> {
  results: T;
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

type Advisories = {
  color: {
    name: string;
    hex: string;
    rgb: number[];
  };
  description: string;
  details: Array<{
    key: string;
    type: string;
    value: string;
  }>;
  distance: {
    lat: number;
    long: number;
    unit: string;
    value: number;
  };
  geometry: any;
  name: string;
  value?: string;
  properties: object;
  type: string;
};

export type AloftResponse = {
  advisories: Advisories[];
  classes: any[];
  airport: any[];
  color: {
    name: string;
    hex: string;
    rgb: number[];
  };
  geometry: {
    data: string;
    format: string;
  };
  overview: {
    full: string;
    icon: string;
    short: string;
  };
  text_color: {
    hex: string;
    name: string;
    rgb: number[];
  };
};

type Location = {
  address: string;
  lat: number;
  lng: number;
  state: string;
  zipcode: string;
};

export type CustomMission = {
  id?: string;
  client?: string | object;
  pilot?: string | object;
  cms?: string | object;
  clientDetails: {
    name: string;
    email: string;
    phone: string;
    location: Location;
    additionalLocations?: Array<Location>;
    industry: string;
    budget: number;
  };
  rush?: boolean;
  liveEvent?: boolean;
  onsite?: boolean;
  contact?: {
    name: string;
    phone: string;
    email: string;
  };
  eventStart: string;
  dueDate: string;
  missionDetails: string;
  stripeId: string;
  meetupTime: string[];
  status: CustomStatus;
  missionIndustry: string;
  package: {
    name: string;
    lineItems: LineItems[];
    additionalServices: AdditionalService[];
    price: number;
    description: string;
  };
  workSampleURL?: string;
  isRescheduled?: boolean;
  totalCharge?: number;
  logs?: Array<{
    author?: string;
    createdAt: string;
    reason: string;
    type: CustomStatus;
    _id: string;
  }>;
  airspace?: Partial<AloftResponse>;
  pilotChosenDate?: string;
  isPilotCreated?: boolean;
};

export enum CustomStatus {
  REQUESTED = 'REQUESTED',
  PILOT_ACCEPTED = 'PILOT_ACCEPTED',
  PILOT_REJECTED = 'PILOT_REJECTED',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  CLIENT_REJECTED = 'CLIENT_REJECTED',
  CLIENT_CHANGE_REQUESTED = 'CLIENT_CHANGE_REQUESTED',
  FOR_PAYMENT = 'FOR_PAYMENT',
  ORDERED = 'ORDERED',
  FLIGHT_COMPLETED = 'FLIGHT_COMPLETED',
  ASSETS_UPLOADED = 'ASSETS_UPLOADED',
  COMPLETED = 'COMPLETED',
}
