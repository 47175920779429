import React, { useState } from 'react';

import { getNearMissions } from 'api/mission';
import { useQuery } from 'react-query';

import { selectors } from 'effector/session';

import { UberMissionRequestsProps } from './UberMissionRequests.props';

import UberMissionRequestsView from './UberMissionRequests.view';

const UberMissionRequestsContainer = (props: UberMissionRequestsProps) => {
  const user = selectors.useUser();

  const [page, setPage] = useState(1);

  const queryKey = [`fetch-uber-mission-requests`, 'pilot', page];
  const queryFn = () =>
    getNearMissions({
      page,
      limit: 5,
      radius: 500,
      lat: user?.latitude || 0,
      lng: user?.longitude || 0,
    });
  const options = {
    enabled: !!user,
  };

  const { data, isLoading } = useQuery(queryKey, queryFn, options);

  const missions = data?.results || [];
  const totalPages = data?.totalPages || 0;
  const isMissions = missions && missions?.length > 0;

  return (
    <UberMissionRequestsView
      page={page}
      setPage={setPage}
      missions={missions}
      isLoading={isLoading}
      totalPages={totalPages}
      isMissions={isMissions}
    />
  );
};

export default UberMissionRequestsContainer;
