import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';
import { logoutEvent } from 'effector/session/events';

export const closeEv = createEvent();
export const showEv = createEvent<typeof initial>();

const initial = {
  img: undefined as undefined | string,
  title: '',
  message: '',
  btnText: '',
  isOpen: false,
  goToDashboard: false as boolean | undefined,
};

const $alert = createStore(initial)
  .on(showEv, (_, payload) => payload)
  .reset(closeEv)
  .reset(logoutEvent);

export const useAlert = () => useStore($alert);
