import React, { useEffect } from 'react';

import {
  Tab,
  Tabs,
  Text,
  Flex,
  Center,
  Spinner,
  TabList,
  TabPanel,
  TabPanels,
  IconButton,
} from '@chakra-ui/react';
import { MdChevronLeft } from 'react-icons/md';

import { useHistory } from 'react-router-dom';

import * as session from 'effector/session';

import { EventRegister } from 'utils/customEvent';

import Aloft from './Aloft';
import Pilot from './Pilot';
import Assets from './Assets';
import Details from './Details';

import { AccountTypes } from 'types/accountTypes';
import { MobileMissionDetailsGeneratedProps } from './MobileMissionDetails.props';

const MobileMissionDetailsView = (
  props: MobileMissionDetailsGeneratedProps,
) => {
  const history = useHistory();
  const userType = session.selectors.useAccountType();
  const isPilot = userType === AccountTypes.pilot;

  useEffect(() => {
    const renderContent = () => {
      return (
        <Flex align="center">
          <IconButton
            size="xs"
            color="#fff"
            aria-label="dash-back"
            icon={<MdChevronLeft size="30px" />}
            variant="ghost"
            onClick={() => {
              EventRegister.emit('render_header_content', {
                renderContent: null,
              });

              history.goBack();
            }}
          />
          <Text fontSize="20px" fontWeight="bold" maxW="90%" noOfLines={1}>
            Mission Details
          </Text>
        </Flex>
      );
    };

    EventRegister.emit('render_header_content', {
      renderContent,
    });
  }, []);

  if (props.isLoading) {
    return (
      <Center flex={1}>
        <Spinner size="lg" />
      </Center>
    );
  }

  if (!props.mission) {
    return null;
  }

  return (
    <Tabs w="100%" mt="20px" isFitted variant="enclosed">
      <TabList>
        <Tab fontSize="12px">Mission</Tab>
        <Tab fontSize="12px">Airspace</Tab>
        <Tab fontSize="12px">{isPilot ? 'Client' : 'Pilot'}</Tab>
        <Tab fontSize="12px">Assets</Tab>
      </TabList>
      <TabPanels>
        <TabPanel p={0}>
          <Details mission={props.mission} />
        </TabPanel>
        <TabPanel p={0}>
          <Aloft
            airspace={props.mission?.airspace}
            coordinates={props.mission?.gps?.coordinates || []}
          />
        </TabPanel>
        <TabPanel p={0}>
          <Pilot mission={props.mission} />
        </TabPanel>
        <TabPanel p={0}>
          <Assets mission={props.mission} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default MobileMissionDetailsView;
