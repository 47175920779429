import { Box, Center, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import { MdInfo } from 'react-icons/md';
import {
  Deliverables,
  Services,
} from 'routes/pilot/CreateCustomMission/Deliverables';
import { labelProps } from '../MobileCreateCustomMission.view';

export const ServiceTab = () => {
  const { values } = useFormikContext<any>();

  const total = () => {
    let linesSum = 0;
    let additionalServ = 0;
    let totalSum = 0;

    values.lineItems.forEach((item: any) => {
      linesSum += item.price * item.count;
    });
    if (values.additionalServices) {
      values.additionalServices.forEach((item: any) => {
        additionalServ += item.price;
      });
    }
    totalSum = linesSum + additionalServ;

    return totalSum;
  };

  return (
    <Flex w="100%" flexDirection="column" bg="#F5F5F5">
      <Deliverables />
      <Stack minW="300px" px={6} spacing={4} py={6} flex={1}>
        <Stack align="center" isInline>
          <Text fontSize="18px" fontWeight="bold">
            Additional Services
          </Text>
          <Tooltip
            p={2}
            px={4}
            borderRadius="5px"
            hasArrow
            bg="#E1E1E1"
            color="#000"
            placement="top"
            label="Create Add-ons for your client to choose from"
            fontSize="md">
            <Box>
              <MdInfo size="24px" color="#6728BB" />
            </Box>
          </Tooltip>
        </Stack>
        <Services />
        <Stack>
          <Center flexDirection="column">
            <Text {...labelProps} fontSize="16px" fontWeight={600}>
              Mission Total Price: ${`${total()}`}
            </Text>
          </Center>
        </Stack>
      </Stack>
    </Flex>
  );
};
