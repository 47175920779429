import React from 'react';

import { EducationProps } from './Education.props';
import EducationView from './Education.view';

const EducationContainer = (props: EducationProps): JSX.Element => {
  return <EducationView />;
};

export default EducationContainer;
