import { Box, IconButton } from '@chakra-ui/react';
import React from 'react';

import { ArrowInCircleProps } from './ArrowInCircle.props';

const ArrowForwardInCircleView = (props: ArrowInCircleProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="65"
      fill="none"
      viewBox="0 0 84 85">
      <g filter="url(#filter0_d_1961_14704)">
        <path
          fill="#fff"
          d="M72 38.5c0 16.568-13.432 30-30 30-16.568 0-30-13.432-30-30 0-16.568 13.432-30 30-30 16.568 0 30 13.432 30 30zM41.33 25.323a2.307 2.307 0 00-.014 3.264l7.546 7.605H29.596a2.307 2.307 0 100 4.616h19.266l-7.546 7.605a2.309 2.309 0 103.28 3.25l11.45-11.537a2.308 2.308 0 000-3.252l-11.45-11.538a2.308 2.308 0 00-3.267-.013z"></path>
      </g>
      <defs>
        <filter
          id="filter0_d_1961_14704"
          width="84"
          height="84"
          x="0"
          y="0.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="6"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1961_14704"></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1961_14704"
            result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default ArrowForwardInCircleView;
