import React from 'react';

import { ResourcesProps } from './Resources.props';
import ResourcesView from './Resources.view';

const ResourcesContainer = (props: ResourcesProps): JSX.Element => {
  return <ResourcesView />;
};

export default ResourcesContainer;
