import { BiCheckCircle, BiErrorCircle, BiRefresh } from 'react-icons/bi';
import { AssetStatus, CustomStatus, MissionStatuses } from 'types/mission';

export const assetStatusMap: { [key in keyof typeof AssetStatus]: string } = {
  [AssetStatus.ACCEPTED]: 'Accepted',
  [AssetStatus.FOR_REVIEW]: 'For Review',
  [AssetStatus.IN_PROCESS]: 'In Process',
  [AssetStatus.REJECTED]: 'Rejected',
  [AssetStatus.COMPLETED]: 'Completed',
};

export const assetStatusIconsMap: {
  [key in keyof typeof AssetStatus]: JSX.Element;
} = {
  [AssetStatus.ACCEPTED]: BiCheckCircle({ color: '#34D17D', size: '20px' }),
  [AssetStatus.IN_PROCESS]: BiRefresh({ color: '#018DE1', size: '20px' }),
  [AssetStatus.REJECTED]: BiErrorCircle({ color: '#E9243F', size: '20px' }),
  [AssetStatus.FOR_REVIEW]: BiRefresh({ color: 'blue', size: '20px' }),
  [AssetStatus.COMPLETED]: BiCheckCircle({ color: '#34D17D', size: '20px' }),
};

export const customStatusMap: Record<string, string> = {
  ASSETS_UPLOADED: 'Assets Uploaded',
  PENDING_PAYMENT: 'Pending Payment',
  CLIENT_CHANGE_REQUESTED: 'Client Change Requested',
  CLIENT_REJECTED: 'Client Rejected',
  COMPLETED: 'Completed',
  FLIGHT_COMPLETED: 'Flight Completed',
  FOR_PAYMENT: 'For Payment',
  ORDERED: 'Ordered',
  PILOT_ACCEPTED: 'Open',
  PILOT_REJECTED: 'Pilot Rejected',
  REQUESTED: 'Requested',
};

export const customStatusColorsMap: any = {
  ASSETS_UPLOADED: '#34D17D',
  PENDING_PAYMENT: '#A020F0',
  CLIENT_CHANGE_REQUESTED: '#E9243F',
  CLIENT_REJECTED: '#E9243F',
  COMPLETED: '#34D17D',
  FLIGHT_COMPLETED: '#34D17D',
  FOR_PAYMENT: '#FFA500',
  ORDERED: '#018DE1',
  PILOT_ACCEPTED: '#018DE1',
  PILOT_REJECTED: '#E9243F',
  REQUESTED: '#018DE1',
};

export const missionStatusColorsMap: Record<MissionStatuses, string> = {
  ASSETS_UPLOADED: '#34D17D',
  FLIGHT_COMPLETED: '#A020F0',
  CANCELLED: '#E9243F',
  DECLINED: '#E9243F',
  COMPLETED: '#34D17D',
  FOR_PAYMENT: '#FFA500',
  ORDERED: '#018DE1',
  SCHEDULED: '#018DE1',
  PROPOSAL: '#018DE1',
};
