import React, { useRef, useState } from 'react';

import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';

import { MdArrowBack, MdEdit, MdNotifications } from 'react-icons/md';

import { Form, Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';

import { UserAvatar } from 'components/modules/UserAvatar';
import { DeleteAccountButton } from 'components/modules/DeleteAccountButton';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import PilotHeaderView from 'components/modules/PilotHeader';
import FormikInputView from 'components/primitives/FormikInput';
import PlacesAutocompleteView from 'components/primitives/PlacesAutocomplete';

import { SettingsGeneratedProps } from './Settings.props';
import { settingsValidationSchema } from './Settings.utils';
import ClientHeaderView from 'components/modules/ClientHeader/ClientHeader.view';
import ClientHeader from 'components/modules/ClientHeader';
import { useHistory } from 'react-router-dom';

const MotionButton = motion.custom(Button);

const animation = {
  initial: {
    opacity: 0,
    transition: { duration: 0.05 },
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.05 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.05 },
  },
};

const SettingsView = (props: SettingsGeneratedProps) => {
  const { user, isLoading, onSubmit } = props;
  const history = useHistory();

  const userAvatarRef = useRef<any>(null);

  const [isEditing, setIsEditing] = useState(false);

  return (
    <Flex direction="column" h="100%" flex={1}>
      <Stack
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 20,
          marginRight: 20,
          marginLeft: 25,
        }}>
        <Heading
          fontFamily="Monument Extended"
          color="primaryColor"
          fontWeight="200"
          fontSize="xl">
          <IconButton
            mb="2px"
            mr={3}
            size="xs"
            color="primaryColor"
            variant="ghost"
            aria-label="close-tabs"
            icon={<MdArrowBack size="30px" />}
            onClick={() => history.goBack()}
          />
          Profile
        </Heading>
        <Stack
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 20,
          }}>
          {/* <IconButton
            mb="2px"
            mr={3}
            size="xs"
            color="primaryColor"
            variant="ghost"
            aria-label="close-tabs"
            icon={<MdNotifications size="4vh" />}
            onClick={() => console.log('notifications')}
          /> */}
          <ClientHeader name={user?.firstName || ''} />
        </Stack>
      </Stack>
      <Formik
        initialValues={{
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: user?.email || '',
          address: user?.address || '',
          phone: user?.phone || '',
          state: user?.state || '',
          zipcode: user?.zipcode || '',
        }}
        validationSchema={settingsValidationSchema}
        onSubmit={(values) => onSubmit(values, () => setIsEditing(false))}>
        {({ values, setFieldValue, resetForm, handleSubmit }) => (
          <Form>
            <Flex px={[4, 6]} direction="column" flex={1}>
              <Flex
                mt={6}
                p={10}
                bg="#fff"
                flexWrap="wrap"
                fontFamily="Open Sans"
                boxShadow="0px 0px 20px 0px rgba(103, 40, 187, .1)">
                <Flex
                  flex={0.5}
                  mr={4}
                  align="center"
                  minW="200px"
                  direction="column">
                  <UserAvatar
                    ref={userAvatarRef}
                    givenHeight={160}
                    givenWidht={160}
                  />
                  <Text
                    mt={4}
                    fontSize="16px"
                    fontWeight="bold"
                    color="brand.500"
                    cursor="pointer"
                    userSelect="none"
                    onClick={() => {
                      userAvatarRef?.current?.onOpen?.();
                    }}>
                    Change Profile
                  </Text>
                </Flex>
                <Flex direction="column" flex={1}>
                  <AnimatePresence>
                    {!isEditing ? (
                      <MotionButton
                        {...animation}
                        variant="ghost"
                        borderRadius="10px"
                        alignSelf="flex-end"
                        type="button"
                        w="150px"
                        bg="#ECE3F8"
                        leftIcon={<MdEdit />}
                        onClick={() => {
                          setIsEditing(true);
                        }}>
                        EDIT
                      </MotionButton>
                    ) : (
                      <Box h="40px" />
                    )}
                  </AnimatePresence>
                  <Flex>
                    <Flex flex={1} direction="column" minW="200px">
                      <FormikInputView
                        name="firstName"
                        label="First Name"
                        isDisabled={!isEditing}
                      />
                      <FormikInputView name="email" label="Email" isDisabled />
                      <PlacesAutocompleteView
                        inputStyle={{
                          bg: 'inputColor',
                          isDisabled: !isEditing,
                          mb: '0',
                        }}
                        labelProps={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                        noPlaceholder
                        value={values?.address || ''}
                        name="address"
                        labelText="Address"
                        searchOptions={{
                          componentRestrictions: {
                            country: 'us',
                          },
                        }}
                        setFieldValue={setFieldValue}
                        onSelect={async (address: string) => {
                          const result = await geocodeByAddress(address);
                          const { lat, lng } = await getLatLng(result?.[0]);

                          const state =
                            result?.[0]?.address_components?.find((i) =>
                              i.types.includes('administrative_area_level_1'),
                            )?.long_name ?? '';

                          const zipcode =
                            result?.[0]?.address_components?.find((i) =>
                              i.types.includes('postal_code'),
                            )?.long_name ?? '';

                          setFieldValue('state', state);
                          setFieldValue('zipcode', zipcode);
                          setFieldValue('address', address);
                          setFieldValue('latitude', lat);
                          setFieldValue('longitude', lng);
                        }}
                      />
                    </Flex>
                    <Flex w="20px" h="20px" />
                    <Flex flex={1} direction="column" minW="200px" maxW="350px">
                      <FormikInputView
                        name="lastName"
                        label="Last Name"
                        isDisabled={!isEditing}
                      />
                      <FormikInputView
                        name="phone"
                        label="Phone"
                        isDisabled={!isEditing}
                      />
                      <Flex w="100%">
                        <Flex flexDir="column">
                          <FormikInputView
                            name="state"
                            label="State"
                            isDisabled={!isEditing}
                          />
                        </Flex>
                        <Flex w="20px" h="20px" />
                        <Flex flexDir="column">
                          <FormikInputView
                            type="number"
                            name="zipcode"
                            label="Zip Code"
                            isDisabled={!isEditing}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Center mt={8} minH="50px">
                    <AnimatePresence>
                      {isEditing ? (
                        <>
                          <MotionButton
                            {...animation}
                            w="200px"
                            variant="outline"
                            isDisabled={isLoading}
                            onClick={() => {
                              resetForm();
                              setIsEditing(false);
                            }}>
                            CANCEL
                          </MotionButton>
                          <MotionButton
                            {...animation}
                            ml={2}
                            w="200px"
                            isLoading={isLoading}
                            onClick={() => {
                              handleSubmit();
                            }}>
                            SAVE
                          </MotionButton>
                        </>
                      ) : (
                        <DeleteAccountButton />
                      )}
                    </AnimatePresence>
                  </Center>
                </Flex>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

export default SettingsView;
