import React, { useEffect, useState } from 'react';

import {
  Text,
  Input,
  chakra,
  FormLabel,
  IconButton,
  FormControl,
  FormHelperText,
  Textarea,
  InputProps,
  TextareaProps,
} from '@chakra-ui/react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

import { useField } from 'formik';

import { FormikInputProps } from './FormikInput.props';

const Span = chakra.span;

const FormikInputView = ({
  name,
  label,
  helperText,
  labelProps,
  error,
  isRequired,
  defaultValue,
  type: initialType,
  containerProps = {},
  isOptional = false,
  isInvalid,
  renderLabel,
  useTextArea = false,
  ...props
}: FormikInputProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const [type, setType] = useState(initialType);

  //Set touched as false to prevent side errors  in a partial dirty form
  useEffect(() => {
    helpers.setTouched(false);
  }, []);
  return (
    <FormControl
      mt={3}
      width="100%"
      height="auto"
      {...containerProps}
      isRequired={isRequired}
      isInvalid={isInvalid}>
      {renderLabel
        ? renderLabel()
        : label && (
            <FormLabel
              fontSize="14px"
              fontWeight="700"
              htmlFor={name}
              {...labelProps}>
              {label}
              {isOptional && (
                <Span ml={2} color="#707070" fontSize="12px" fontWeight="400">
                  (optional)
                </Span>
              )}
            </FormLabel>
          )}
      {useTextArea ? (
        <Textarea
          resize="none"
          rows={1}
          h="40px"
          fontSize="15px"
          whiteSpace="nowrap"
          overflowX="auto"
          overflowY="hidden"
          pr="15px"
          sx={{
            '::-webkit-scrollbar': {
              width: '0px',
              height: '0px',
            },
            '::-webkit-scrollbar-track-piece ': {
              width: '0px',
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
              borderRadius: '0px',
            },
            '::-webkit-scrollbar-thumb': {
              borderRadius: '0px',
              border: '0px solid transparent',
              backgroundClip: 'content-box',
              backgroundColor: 'transparent',
              width: '0px',
            },
          }}
          id={name}
          aria-describedby={`${name}-helper-text`}
          bg="inputColor"
          borderColor="inputBorderColor"
          value={field.value ?? defaultValue}
          isInvalid={Boolean(error || (meta.touched && meta.error))}
          onBlur={() => helpers.setTouched(true)}
          onChange={(e) => helpers.setValue(e.target.value)}
          {...(props as TextareaProps)}
        />
      ) : (
        <Input
          type={type || name}
          id={name}
          isInvalid={Boolean(error || (meta.touched && meta.error))}
          aria-describedby={`${name}-helper-text`}
          value={field.value ?? defaultValue}
          bg="inputColor"
          borderColor="inputBorderColor"
          onBlur={() => helpers.setTouched(true)}
          onChange={(e) => helpers.setValue(e.target.value)}
          onWheel={(e: any) => {
            e.target?.blur();
          }}
          onKeyDown={(e) => {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
              e.preventDefault();
            }
          }}
          {...(props as InputProps)}
        />
      )}
      {initialType === 'password' && (
        <IconButton
          aria-label="password-toggle"
          icon={
            type == 'text' ? (
              <IoMdEye size="20px" />
            ) : (
              <IoMdEyeOff size="20px" />
            )
          }
          position="absolute"
          right={0}
          variant="ghost"
          _focus={{ border: 'none' }}
          zIndex={3}
          onClick={() =>
            setType((prev) => (prev === 'text' ? 'password' : 'text'))
          }
        />
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {meta.touched && (error || meta.error) && (
        <Text mt={useTextArea ? '0' : '5px'} color="red.500" fontSize="12px">
          {(meta.touched && meta.error) || error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikInputView;
