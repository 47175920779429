export const reverseGeocoding = async (
  lat: number,
  lng: number,
): Promise<{
  city: string;
  state: string;
  location: string;
} | null> => {
  try {
    const result = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    )
      .then((res) => res.json())
      .then((data) => data?.results[0]);

    const city =
      result?.address_components?.find((i: any) =>
        i.types.includes('administrative_area_level_2'),
      )?.long_name || '';
    const state =
      result?.address_components?.find((i: any) =>
        i.types.includes('administrative_area_level_1'),
      )?.long_name || '';

    const location = result?.formatted_address || '';

    return {
      city,
      state,
      location,
    };
  } catch (error) {
    return null;
  }
};
