import api from 'api/instance';
import { SessionStore } from 'effector/session/store';
import { CMS, Package } from 'types/cms';

const URL = process.env.REACT_APP_API_URL;

export const createCMS = async (payload: CMS): Promise<CMS> => {
  const { data } = await api.post<CMS>(`${URL}/cms`, payload);

  return data;
};

type FileType = File & { signedUrl: string; id: string };

export const uploadImage = async (file: FileType): Promise<any> => {
  return new Promise((resolve: (val: FileType) => void, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', file.signedUrl, true);
    xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

    xhr.onload = () => {
      resolve(file);
    };
    xhr.onerror = () => {
      if (xhr.status !== 200) {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`,
          ),
        );
      }
    };
    xhr.send(file);
  });
};

export const getMyCMS = async (
  status: 'DRAFT' | 'PUBLISH' | undefined = undefined,
): Promise<CMS> => {
  const pilotId = SessionStore.getState()?.session?.user?.id || '';

  const { data } = await api.get<CMS>(`${URL}/cms/pilot/${pilotId}`, {
    params: {
      status,
    },
  });

  return data;
};

export const checkCMS = async (): Promise<any> => {
  const pilotId = SessionStore.getState()?.session?.user?.id || '';

  const { data } = await api.get<CMS>(`${URL}/cms/pilot/check-cms/${pilotId}`);

  return data;
};

export const getCMSByCompany = async (
  username: string,
  params?: any,
): Promise<CMS> => {
  const { data } = await api.get<CMS>(`${URL}/cms/company/${username}`, {
    params,
  });

  const dayAvailability = data?.about?.dayAvailability.map((d) => ({
    ...d,
    start: {
      hour:
        typeof d.start === 'string'
          ? Number(((d.start as unknown) as string)?.split(' ')[0] || 0)
          : typeof d.start.hour === 'string'
          ? Number(d.start.hour)
          : d.start.hour,
      minutes:
        typeof d.start.minutes === 'string'
          ? Number(d.start.minutes)
          : d.start.minutes,
      meridiem:
        typeof d.start === 'string'
          ? ((d.start as unknown) as string)?.split(' ')[1]
          : d.start.meridiem,
    } as any,
    end: {
      hour:
        typeof d.end === 'string'
          ? Number(((d.end as unknown) as string)?.split(' ')[0] || 0)
          : typeof d.end.hour === 'string'
          ? Number(d.end.hour)
          : d.end.hour,
      minutes:
        typeof d.end.minutes === 'string'
          ? Number(d.end.minutes)
          : d.end.minutes,
      meridiem:
        typeof d.end === 'string'
          ? ((d.end as unknown) as string)?.split(' ')[1]
          : d.end.meridiem,
    } as any,
  }));

  return {
    ...data,
    about: {
      ...data?.about,
      dayAvailability,
    },
  };
};

export const updateCMS = async ({
  payload,
  companyName,
}: {
  payload: Partial<CMS>;
  companyName: string;
}): Promise<CMS> => {
  const testimonialsFromPayload = payload.testimonials;
  const filteredTestimonials = (payload.testimonials || [])?.map((t) => ({
    name: t.name,
    position: t.position,
    testimony: t.testimony,
    imageUrl: t.imageUrl,
    image: t?.image,
  }));

  const { data } = await api.patch<CMS>(
    `${URL}/cms/${encodeURIComponent(companyName)}`,
    {
      ...payload,
      testimonials: filteredTestimonials,
    },
  );

  const allTestimonialPromises = data.testimonials
    .filter((testimony) => testimony.type === 'update')
    .map(async (testimony) => {
      const testimonyFromPayload = testimonialsFromPayload?.find(
        (t) =>
          t.name === testimony.name &&
          t.position === testimony.position &&
          t.testimony === testimony.testimony,
      );

      return await new Promise((resolve: (id: string) => void, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', testimony.signedUrl, true);
        xhr.setRequestHeader('Content-Type', 'multipart/form-data');
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

        xhr.onload = () => {
          resolve('');
        };
        xhr.onerror = () => {
          if (xhr.status !== 200) {
            reject(
              new Error(
                `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`,
              ),
            );
          }
        };

        if (testimonyFromPayload?.testimonyFile) {
          xhr.send(testimonyFromPayload.testimonyFile as any);
        }
      });
    });

  await Promise.all(allTestimonialPromises);

  return data;
};

export const updatePreviousWorkItem = async (
  file?: File,
  url?: string,
  title?: string,
  itemId?: string,
  onProgress?: (a: ProgressEvent) => void,
): Promise<any> => {
  const pilotId = SessionStore.getState()?.session?.user?.id || '';

  let payload: any = {
    user: pilotId,
    url,
    title,
    status: 'DRAFT',
    fileType: file?.type ?? 'image/jpg',
  };

  const isNewFile = file && 'name' in file && 'type' in file;

  if (file && isNewFile) {
    payload = {
      ...payload,
      noAsset: false,
      fileName: file.name,
      fileType: file?.type || 'image/jpg',
      fileSize: file.size > 10000 ? 1000 : file.size,
    };
  }

  const { data } = await api.patch(`${URL}/portfolio/${itemId}`, payload);

  if (data?.noAsset || !isNewFile) {
    return await api.patch(
      `${URL}/portfolio/${data.id}/confirmed?status=DRAFT`,
    );
  }

  const id = await new Promise((resolve: (id: string) => void, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', data.signedUrl, true);
    xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }

    xhr.onload = () => {
      resolve(data.id);
    };
    xhr.onerror = () => {
      if (xhr.status !== 200) {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`,
          ),
        );
      }
    };
    xhr.send(file);
  });

  return await api.patch(`${URL}/portfolio/${id}/confirmed?status=DRAFT`);
};

export const editTitle = async ({
  id,
  title,
}: {
  id: string;
  title: string;
}): Promise<any> => {
  const pilotId = SessionStore.getState()?.session?.user?.id || '';

  const { data } = await api.patch(`${URL}/portfolio/${id}`, {
    title,
    user: pilotId,
    status: 'DRAFT',
  });

  return data;
};

export const deletePreviousWorkItem = async (id: string): Promise<any> =>
  await api.delete(`${URL}/portfolio/${id}?status=DRAFT`);

export const createEquipementItem = async ({
  fileName,
  type,
}: {
  fileName: string;
  type: string;
}): Promise<any> => {
  const pilotId = SessionStore.getState()?.session?.user?.id || '';

  const { data } = await api.post(`${URL}/equipment`, {
    fileName,
    user: pilotId,
    type,
    status: 'DRAFT',
  });

  return await api.patch(`${URL}/equipment/${data.id}/confirmed?status=DRAFT`);
};

export const deleteEquipmentItem = async (id: string): Promise<any> =>
  await api.delete(`${URL}/equipment/${id}?status=DRAFT`);

export const updateEquipmentItem = async (
  id: string,
  type: string,
  value: string,
) => {
  const pilotId = SessionStore.getState()?.session?.user?.id || '';

  const { data } = await api.patch(`${URL}/equipment/${id}`, {
    fileName: value,
    user: pilotId,
    type,
    status: 'DRAFT',
  });

  return data;
};

export const createPreviousWorkItem = async (
  file?: File,
  url?: string,
  title?: string,
  onProgress?: (a: ProgressEvent) => void,
): Promise<any> => {
  const pilotId = SessionStore.getState()?.session?.user?.id || '';

  let payload: any = {
    user: pilotId,
    url,
    title,
    status: 'DRAFT',
    fileType: 'image/jpg',
  };

  if (file) {
    payload = {
      ...payload,
      fileName: file.name,
      fileType: file?.type || 'image/jpg',
      fileSize: file.size > 1000 ? 1000 : file.size,
    };
  }

  const { data } = await api.post(`${URL}/portfolio`, payload);

  if (data?.noAsset) {
    return await api.patch(
      `${URL}/portfolio/${data.id}/confirmed?status=DRAFT`,
    );
  }

  const id = await new Promise((resolve: (id: string) => void, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', data.signedUrl, true);
    xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }

    xhr.onload = () => {
      resolve(data.id);
    };
    xhr.onerror = () => {
      if (xhr.status !== 200) {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`,
          ),
        );
      }
    };
    xhr.send(file);
  });

  return await api.patch(`${URL}/portfolio/${id}/confirmed?status=DRAFT`);
};

export const createPackageItem = async (payload: Package): Promise<any> => {
  const { data } = await api.post(`${URL}/packages`, {
    ...payload,
    status: 'DRAFT',
  });

  return data;
};

export const updatePackageItem = async ({
  id,
  payload,
}: {
  payload: Package;
  id: string;
}): Promise<any> => {
  const { data } = await api.patch(`${URL}/packages/${id}`, {
    additionalServices: payload.additionalServices,
    description: payload.description,
    lineItems: payload.lineItems,
    name: payload.name,
    pilot: payload.pilot,
    price: payload.price,
    industry: payload?.industry,
    status: 'DRAFT',
  });

  return data;
};

export const deletePackageItem = async (id: string): Promise<any> =>
  await api.delete(`${URL}/packages/${id}?status=DRAFT`);

export const uploadProfilePic = async (
  file: File,
  type: 0 | 1,
  onProgress?: (e: ProgressEvent) => void,
): Promise<any> => {
  const pilotId = SessionStore.getState()?.session?.user?.id || '';

  const {
    data: { signedUrl },
  } = await api.post<{ signedUrl: string }>(`${URL}/cms/upload/${pilotId}`, {
    type,
  });

  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', signedUrl, true);
    xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }

    xhr.onload = () => {
      resolve(`${type === 0 ? 'profile' : 'banner'} image uploaded`);
    };

    xhr.onerror = () => {
      if (xhr.status !== 200) {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`,
          ),
        );
      }
    };
    xhr.send(file);
  });
};
