import React from 'react';
import { Flex } from '@chakra-ui/react';
import FormikInput from 'components/primitives/FormikInput';
import { fontSize, labelProps } from '../MobileCreateCustomMission.view';

const ClientDetailsTab = () => {
  return (
    <Flex flexDirection="column" align="center" minHeight={'71vh'}>
      <Flex flexDir="column" fontSize={fontSize} width="100%" fontWeight="bold">
        <Flex
          flexDirection="column"
          fontSize={fontSize}
          fontWeight="bold"
          color={'primary'}
          gridGap="10px"
          align="flex-start">
          <FormikInput
            name="clientDetails.name"
            type="text"
            label="Name"
            labelProps={labelProps}
            containerProps={{ flex: 1 }}
          />
          <FormikInput
            name="clientDetails.email"
            type="email"
            label="Email"
            labelProps={labelProps}
            containerProps={{ flex: 1, mt: 5 }}
          />
          <FormikInput
            name="clientDetails.phone"
            label="Phone"
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            labelProps={labelProps}
            containerProps={{ flex: 1, mt: 0 }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ClientDetailsTab;
