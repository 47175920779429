import React from 'react';

import { Center, CircularProgress, Flex, Button, Grid } from '@chakra-ui/react';

import { TutorialsGeneratedProps } from './Tutorials.props';
import { isMobile } from 'react-device-detect';
import YourSvg from './../../../components/icons/droneTutorial.svg';
const TutorialsView = (props: TutorialsGeneratedProps) => {
  if (props.isLoading) {
    return (
      <Center minW={isMobile ? '100vw' : undefined} flex={1}>
        <CircularProgress isIndeterminate color="brand.500" />
      </Center>
    );
  }

  return (
    <Flex h="100%" direction="column" flex={1}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '25%',
          background: '#FBFBFB',
          overflow: 'hidden',
        }}>
        <div
          style={{
            flex: 0.4,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <img
            src={YourSvg}
            alt="Your SVG Image"
            style={{
              height: '100%', // Height 100% to fill the container
              objectFit: 'cover', // Ensure the entire image is covered
            }}></img>
        </div>
        <div
          style={{
            flex: 1,
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <div
            style={{
              color: 'black',
              fontSize: 34,
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              wordWrap: 'break-word',
            }}>
            DroneAdair Tutorial & Tips
          </div>
          <div
            style={{
              color: '#6B6B6B',
              fontSize: 20,
              fontFamily: 'Roboto',
              wordWrap: 'break-word',
            }}>
            We want you to take the most of our platform, in here you can start
            the tutorial anytime or look at some tips!
          </div>
        </div>
      </div>

      <Grid
        h="auto"
        margin={20}
        templateColumns="repeat(auto-fill, minmax(284px, 1fr))"
        gap={10}>
        {props.tutorials && props.tutorials.length > 0 ? (
          props.tutorials.map((tutorial, index) => (
            <div
              style={{
                flex: 0.6,
                maxHeight: 'auto',
                background: '#F8F8F8',
                boxShadow:
                  '0px 17.273971557617188px 26.774656295776367px rgba(0, 0, 0, 0.06)',
                borderRadius: '6.91px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
                overflow: 'hidden',
              }}
              key={index}>
              <div
                style={{
                  flex: 1,
                  width: 'auto',
                  height: 'auto',
                  paddingTop: 10,
                }}>
                {getYouTubeVideoId(tutorial.videoLink) ? (
                  <div style={{ height: '80%', width: '100%' }}>
                    <img
                      src={`${getYouTubeVideoId(tutorial.videoLink)}`}
                      alt="Video Image"
                      style={{
                        width: '325px',
                        height: '100%',
                        objectFit: 'cover',
                        borderTopLeftRadius: '6.91px',
                        borderTopRightRadius: '6.91px',
                        borderBottomLeftRadius: '0',
                        borderBottomRightRadius: '0',
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#CEC4B5',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <span>No Image Available</span>
                  </div>
                )}
              </div>
              <div
                style={{
                  height: '80%',
                  width: 'auto',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    color: 'black',
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    wordWrap: 'break-word',
                    marginBottom: '20px',
                    marginLeft: '10px',
                    marginRight: '10px',
                    justifyContent: 'center',
                  }}>
                  {tutorial.name}
                </div>
                <button
                  onClick={() => window.open(tutorial.videoLink, '_blank')}
                  style={{
                    maxWidth: '192px',
                    background: '#6418C3',
                    borderRadius: '27.64px',
                    padding: '8px',
                    paddingRight: '16px',
                    paddingLeft: '16px',
                    boxSizing: 'border-box',
                    marginBottom: '20px',
                    alignItems: 'center',
                    color: '#F3F3F3',
                    textAlign: 'center',
                    fontSize: '12.36px',
                    fontFamily: 'montserrat',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    wordWrap: 'break-word',
                    margin: '10',
                  }}>
                  Launch tutorial
                </button>
              </div>
            </div>
          ))
        ) : (
          <div>No tutorials available.</div>
        )}
      </Grid>
    </Flex>
  );
};

const getYouTubeVideoId = (videoLink: string) => {
  try {
    console.log('videoLink·->·', videoLink);
    // https://img.youtube.com/vi/hqdefault.jpg
    const url = new URL(videoLink);
    const host = url.host;
    console.log(host);
    if (host === 'youtu.be') {
      const searchParams = new URLSearchParams(url.pathname);
      return `https://img.youtube.com/vi${url.pathname}/hqdefault.jpg`;
    } else if (host === 'www.youtube.com') {
      const searchParams = new URLSearchParams(url.search);
      const videoId = searchParams.get('v');
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    } else if (host === 'www.loom.com') {
      // `https://cdn.loom.com/sessions/${videoId}/images/thumbnail.jpg`
      const searchParams = new URLSearchParams(url.pathname);
      const pathArray = url.pathname.split('/');
      const videoId = pathArray[pathArray.length - 1];
      console.log('Loom Video ID:', videoId);
      console.log('loom link->', url);
      const match = searchParams.get('v');
      return null;
    }
  } catch (error) {
    console.error('Error parsing YouTube video link:', error);
    return null;
  }
};
export default TutorialsView;
