import React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';

import { MissionAccordionGeneratedProps } from './MissionAccordion.props';
import MissionOverview from '../MissionOverview';
import calculateDueDate from 'utils/calculateDueDate';

const tabPaddingX = [1, 1, 5];
const tabPaddingY = [1, 1, 2];

const MissionAccordionView = (
  props: MissionAccordionGeneratedProps,
): JSX.Element => {
  const EventDateTextRend = () => {
    return calculateDueDate(props.mission.eventStart) ===
      calculateDueDate(props.mission.dueDate) ? (
      <Text
        fontSize="12px"
        px={tabPaddingX}
        py={tabPaddingY}
        flex={2.5}
        textAlign="left">
        {calculateDueDate(props.mission.eventStart)}
      </Text>
    ) : (
      <Text
        fontSize="12px"
        px={tabPaddingX}
        py={tabPaddingY}
        flex={2.5}
        textAlign="left">
        {calculateDueDate(props.mission.eventStart)} -{' '}
        {calculateDueDate(props.mission.dueDate)}
      </Text>
    );
  };
  return (
    <Accordion allowToggle allowMultiple flex={1} my={1}>
      <AccordionItem
        mx="2"
        borderRadius="5px"
        borderWidth="1px"
        _focus={{ borderWidth: 0 }}>
        <AccordionButton p={0} px="2" flex={1}>
          <Flex flex={1} fontWeight="600" color="grey">
            <Text
              fontSize="12px"
              px={tabPaddingX}
              py={tabPaddingY}
              flex={0.8}
              textAlign="left"
              isTruncated>
              {props.mission.id}
            </Text>
            <Text
              fontSize="12px"
              px={tabPaddingX}
              py={tabPaddingY}
              flex={2.5}
              textAlign="left"
              isTruncated>
              {props.mission.location}
            </Text>
            <EventDateTextRend />
            <Text
              fontSize="12px"
              px={tabPaddingX}
              py={tabPaddingY}
              flex={1.5}
              textAlign="left">
              {props.mission.package?.name}
            </Text>
            <Text
              fontSize="12px"
              px={tabPaddingX}
              py={tabPaddingY}
              flex={1}
              textAlign="center">
              $ {props.mission.totalCharge}
            </Text>
          </Flex>
          <AccordionIcon mr={2} />
        </AccordionButton>
        <AccordionPanel py={4} borderTopWidth="1px" flex={1}>
          <MissionOverview
            mission={props.mission}
            buttonSection={
              <>
                <Button
                  size="sm"
                  w="136px"
                  isLoading={props.isAccepting}
                  onClick={props.acceptSubmit}>
                  ACCEPT
                </Button>
                <Button
                  size="sm"
                  w="136px"
                  variant="outline"
                  isLoading={props.isDeclining}
                  onClick={props.declineSubmit}>
                  DECLINE
                </Button>
              </>
            }
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default MissionAccordionView;
