import { routesSettings } from 'constants/routes';
import {
  MdCameraAlt,
  MdDashboard,
  MdFeedback,
  MdHeadsetMic,
  MdImportContacts,
  MdLogout,
  MdPerson,
  MdSearch,
  MdSettings,
  MdStars,
  MdWeb,
  MdWorkspacesFilled,
  MdLightbulb,
} from 'react-icons/md';

const navItemsClient = [
  {
    name: 'NEW MISSION',
    //link: routesSettings.CLIENT_DASHBOARD.path,
    icon: MdSearch,
    redirect: true,
  },
  {
    name: 'MISSIONS',
    link: routesSettings.CLIENT_MISSIONS.path,
    icon: MdCameraAlt,
  },
];

const navItemsPilot = [
  {
    name: 'DASHBOARD',
    link: routesSettings.PILOT_DASHBOARD.path,
    icon: MdDashboard,
  },
  // {
  //   name: 'PRO MISSIONS',
  //   link: routesSettings.PILOT_PRO_MISSIONS.path,
  //   icon: MdStars,
  // },
  {
    name: 'MY WEBSITE',
    link: routesSettings.PILOT_MY_WEBSITE.path,
    icon: MdWeb,
  },
  {
    name: 'EDUCATION',
    link: routesSettings.PILOT_EDUCATION.path,
    icon: MdImportContacts,
  },
  {
    name: 'RESOURCES',
    link: routesSettings.PILOT_RESOURCES.path,
    icon: MdWorkspacesFilled,
  },
  {
    name: 'TUTORIALS',
    link: routesSettings.PILOT_TUTORIALS.path,
    icon: MdLightbulb,
  },
  {
    name: 'FEEDBACK',
    link: 'https://forms.gle/JkqpwDLtbwNTKqoa8',
    icon: MdFeedback,
  },
];

const lateralHeaderMenu = [
  {
    name: 'Profile',
    link: routesSettings.PILOT_SETTINGS.path,
    icon: MdPerson,
    color: '#6418C3',
  },
  {
    name: 'Support',
    link: 'https://help.droneadair.com/',
    icon: MdHeadsetMic,
    color: '#6418C3',
  },
  {
    name: 'Log out',
    link: routesSettings.AUTH_LOGIN,
    icon: MdLogout,
    color: '#92835C',
  },
];

export { navItemsClient, navItemsPilot, lateralHeaderMenu };
