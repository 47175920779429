import React, { FC, useRef } from 'react';

import {
  Box,
  Text,
  Flex,
  Input,
  Radio,
  Stack,
  Textarea,
  RadioGroup,
  Icon,
  Button,
} from '@chakra-ui/react';
import { MdPeople, MdPlace, MdPlayArrow, MdCall } from 'react-icons/md';

import calculateDueDate from 'utils/calculateDueDate';

import GoogleMaps from 'components/primitives/GoogleMaps';

import { AloftToast } from 'components/primitives/AloftToast';
import { TitleWithIcon } from 'components/primitives/TitleWithIcon';

import { CustomMission } from 'types/mission';

type Props = {
  children: React.ReactNode;
  mission: CustomMission;
};

const fontSize = '14px';
const brandColor = '#6728BB';

export const MissionClientInfo: FC<Props> = (props) => {
  const { mission, children } = props;

  const coordinates = {
    lat: mission?.clientDetails?.location?.lat || 0,
    lng: mission?.clientDetails?.location?.lng || 0,
  };

  const dateRange = mission
    ? mission?.eventStart === mission?.dueDate
      ? calculateDueDate(new Date(mission.dueDate))
      : `${calculateDueDate(
          new Date(mission?.eventStart),
        )} - ${calculateDueDate(new Date(mission?.dueDate))}`
    : '';

  const status = mission.status;
  const isRush = Boolean(mission?.rush);

  return (
    <Flex minW="100vw" direction="column">
      <Stack p="20px">
        <TitleWithIcon
          ml={-1}
          TextIcon={<MdPlace size="16px" />}
          text="Location"
          fontSize={fontSize}
        />
        <Text color="black" fontWeight="500" fontSize={fontSize}>
          {mission?.clientDetails?.location?.address}
        </Text>
        <Box
          mb={6}
          w={['100%']}
          height="150px"
          background="#fff"
          borderColor="#ccc"
          bg="inputColor"
          borderRadius="30px"
          overflow="hidden">
          <GoogleMaps
            mapContainerStyle={{
              width: '100%',
              height: '240px',
            }}
            initialCenter={coordinates}
            marker={coordinates}
            zoom={13}
          />
        </Box>
        {mission.airspace ? (
          <AloftToast size="sm" {...(mission.airspace as any)} />
        ) : null}
      </Stack>

      <Flex
        direction="column"
        p="20px"
        gridGap="20px"
        fontSize="14px"
        fontWeight="bold"
        color="primaryColor">
        <Stack minW="300px" flex={1}>
          <Text>Name</Text>
          <Input
            size="lg"
            fontSize={fontSize}
            isReadOnly
            value={mission?.clientDetails?.name}
          />
        </Stack>
        <Stack flex={1}>
          <Text>Email</Text>
          <Input
            size="lg"
            fontSize={fontSize}
            isReadOnly
            value={mission?.clientDetails?.email}
          />
        </Stack>
        <Stack flex={1}>
          <Text>Phone Number</Text>
          <Input
            size="lg"
            fontSize={fontSize}
            isReadOnly
            value={mission?.clientDetails?.phone}
          />
          <Button
            aria-label="Call Client"
            size="md"
            variant="primary"
            backgroundColor="#33b864"
            color="white"
            fontWeight={'500'}
            leftIcon={<MdCall />}
            letterSpacing={0.1}
            onClick={() => {
              window.location.href = `tel:${mission?.clientDetails?.phone}`;
            }}>
            Call Client
          </Button>
        </Stack>

        <Stack>
          <Text>Mission Details</Text>
          <Textarea
            minH="100px"
            fontSize={fontSize}
            isReadOnly
            value={mission?.missionDetails}
            size="lg"
            color="#92835C"
          />
        </Stack>

        <Stack flex={1}>
          <Text>Industry</Text>
          <Input
            size="lg"
            fontSize={fontSize}
            isReadOnly
            value={mission?.clientDetails?.industry}
          />
        </Stack>
        {mission?.isPilotCreated ? null : (
          <Stack flex={1}>
            <Text>Budget</Text>
            <Input
              size="lg"
              fontSize={fontSize}
              isReadOnly
              value={'$' + mission?.clientDetails?.budget}
            />
          </Stack>
        )}
        <Stack>
          <Text>Date Range</Text>
          <Box>
            <Input
              size="lg"
              fontSize={fontSize}
              isReadOnly
              value={dateRange}
              {...(isRush && {
                borderColor: 'green.500',
                borderWidth: '2px',
              })}
            />
            {isRush && (
              <Text fontStyle="italic" color="green.500">
                *this mission needs to be done in the next 48 hours*
              </Text>
            )}
          </Box>
        </Stack>

        <Stack isInline align="center">
          <Box
            maxH="18px"
            border={`1px solid ${brandColor}`}
            borderRadius="2px">
            <MdPlayArrow color={brandColor} />
          </Box>
          <Text fontWeight="bold">Is this a live event?</Text>
        </Stack>
        <RadioGroup
          defaultValue={mission?.liveEvent ? '1' : '0'}
          sx={{
            '.chakra-radio__control[data-checked]': {
              background: brandColor,
              borderColor: brandColor,
            },
            '.chakra-radio__control[data-focus]': {
              boxShadow: 'none',
            },
          }}>
          <Stack direction="row">
            <Radio isReadOnly value="1">
              Yes
            </Radio>
            <Radio isReadOnly value="0">
              No
            </Radio>
          </Stack>
        </RadioGroup>

        <Stack isInline align="center">
          <Box>
            <MdPeople color={brandColor} />
          </Box>
          <Text fontWeight="bold">Do you need to be on site?</Text>
        </Stack>
        <RadioGroup
          defaultValue={mission?.onsite ? '1' : '0'}
          sx={{
            '[data-focus]': { boxShadow: 'none' },
            '.chakra-radio__control[data-checked]': {
              background: brandColor,
              borderColor: brandColor,
            },
          }}>
          <Stack direction="row">
            <Radio isReadOnly value="1">
              Yes
            </Radio>
            <Radio isReadOnly value="0">
              No
            </Radio>
          </Stack>
        </RadioGroup>

        {children}
      </Flex>
    </Flex>
  );
};
