import React, { FC } from 'react';

import { Flex, Stack, Text } from '@chakra-ui/react';

import { Mission } from 'types/mission';

type Props = {
  airspace: Mission['airspace'];
  coordinates: number[];
};

const labelProps = {
  fontSize: '13px',
  fontWeight: 'bold',
  color: 'primaryColor',
};

const Aloft: FC<Props> = ({ airspace, coordinates }) => {
  return (
    <Flex p="20px" pb="100px" gridGap="15px" flex={1} direction="column">
      <Stack spacing={1}>
        <Text {...labelProps}>Coordinates</Text>
        <Flex gridGap="100px" flexWrap="wrap">
          <Stack spacing={0}>
            <Text {...labelProps} color="black">
              Latitude:
            </Text>
            <Text>{coordinates?.[1] || '0'}</Text>
          </Stack>
          <Stack spacing={0}>
            <Text {...labelProps} color="black">
              Longitude:
            </Text>
            <Text>{coordinates?.[0] || '0'}</Text>
          </Stack>
        </Flex>
      </Stack>

      <Stack spacing={1}>
        <Text {...labelProps}>Overview</Text>
        <Text>{airspace?.overview?.full || '-'}</Text>
      </Stack>

      <Stack spacing={1}>
        <Text {...labelProps}>Classes</Text>
        {airspace?.classes?.map((_class) => (
          <Text key={_class}>{typeof _class === 'string' ? _class : '-'}</Text>
        ))}
      </Stack>

      <Stack spacing={1}>
        <Text {...labelProps}>Advisories</Text>
        {airspace?.advisories?.map((adv) => (
          <Text key={adv?.name}>{adv?.name || '-'}</Text>
        ))}
      </Stack>
    </Flex>
  );
};

export default Aloft;
