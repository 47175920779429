import React, { useMemo, useState } from 'react';

import { useToast } from '@chakra-ui/react';

import { pilotCreateMission } from 'api/custom-mission';
import { getMyCMS } from 'api/cms';

import { useMutation, useQuery } from 'react-query';

import { set } from 'date-fns';

import { selectors } from 'effector/session';

import {
  FormValues,
  CreateCustomMissionProps,
} from './CreateCustomMission.props';
import { CMS } from 'types/cms';

import CreateCustomMissionView from './CreateCustomMission.view';

const CreateCustomMissionContainer = (props: CreateCustomMissionProps) => {
  const toast = useToast();
  const user = selectors.useUser();

  const { data: cms, isLoading: loadingCms } = useQuery(
    `fetch-my-cms-published`,
    () => getMyCMS('PUBLISH'),
    {
      retry: 0,
      onError: () => console.log(''),
    },
  );

  const cmsId = cms?.id ? cms.id : '';

  const [create, { isLoading }] = useMutation(pilotCreateMission, {
    onSuccess: ({ id }) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Your custom mission has been created successfully',
        duration: 5000,
        isClosable: true,
      });

      props.history.goBack();

      setTimeout(() => {
        props.history.push(`/custom-mission/${id}`);
      }, 100);
    },
  });

  const onCreateMission = (values: FormValues) => {
    const additionalServicesCost: number =
      values.additionalServices
        ?.map((i) => Number(i.price || '0'))
        ?.reduce((a, b) => a + b, 0) || 0;

    const lineItemsCost: number = values.lineItems
      ?.map((i) => Number(i.count || '0') * Number(i.price || '0'))
      ?.reduce((a, b) => a + b, 0);

    const totalCharge = additionalServicesCost + lineItemsCost;

    let contact = null;
    let meetupTime: any[] = [];
    let eventStart = new Date(values?.eventStart).toISOString();
    let dueDate = new Date(values?.dueDate).toISOString();

    if (values.liveEvent) {
      eventStart = values.liveDay || '';
      dueDate = values.liveDay || '';

      const startTime = values?.liveStartTime?.split(':');
      const startHours = parseInt(startTime[0] || '0');
      const startMinutes = parseInt(startTime[1] || '0');

      const endTime = values?.liveEndTime?.split(':');
      const endHours = parseInt(endTime[0] || '0');
      const endMinutes = parseInt(endTime[1] || '0');

      eventStart = set(new Date(eventStart), {
        hours: startHours,
        minutes: startMinutes,
      }).toISOString();

      dueDate = set(new Date(dueDate), {
        hours: endHours,
        minutes: endMinutes,
      }).toISOString();

      if (values.onsite) {
        contact = {
          name: values.contactName,
          phone: values.contactPhoneNumber,
          email: values.contactEmail,
        };
      }
    }

    if (!values.liveEvent && values.onsite) {
      const startTime = values?.liveStartTime?.split(':');
      const startHours = parseInt(startTime[0] || '0');
      const startMinutes = parseInt(startTime[1] || '0');

      meetupTime = [
        set(new Date(values.liveDay || ''), {
          hours: startHours,
          minutes: startMinutes,
        }).toISOString(),
      ];

      if (!!values?.liveDay2) {
        const startTime2 = values?.liveStartTime2?.split(':');
        const startHours2 = parseInt(startTime2[0] || '0');
        const startMinutes2 = parseInt(startTime2[1] || '0');

        meetupTime = [
          ...meetupTime,
          set(new Date(values.liveDay2 || ''), {
            hours: startHours2,
            minutes: startMinutes2,
          }).toISOString(),
        ];
      }

      if (!!values.liveDay3) {
        const startTime3 = values?.liveStartTime3?.split(':');
        const startHours3 = parseInt(startTime3[0] || '0');
        const startMinutes3 = parseInt(startTime3[1] || '0');

        meetupTime = [
          ...meetupTime,
          set(new Date(values.liveDay3), {
            hours: startHours3,
            minutes: startMinutes3,
          }).toISOString(),
        ];
      }

      contact = {
        name: values.contactName,
        phone: values.contactPhoneNumber,
        email: values.contactEmail,
      };
    }

    const payload = {
      cms: cmsId || '',
      client: null,
      pilot: user?.id,
      clientDetails: {
        name: values?.clientDetails?.name,
        email: values?.clientDetails?.email,
        phone: Boolean(values?.clientDetails?.phone)
          ? values?.clientDetails?.phone
          : 'no phone number',
        location: values?.clientDetails?.location,
        industry: values?.clientDetails?.industry,
      },
      rush: values?.rush,
      onsite: values?.onsite,
      liveEvent: values?.liveEvent,
      eventStart,
      dueDate,
      missionDetails: values?.missionDetails,
      stripeId: user?.stripeId || '',
      meetupTime,
      contact,
      totalCharge,
      package: {
        lineItems: values?.lineItems,
        additionalServices: values?.additionalServices,
        price: lineItemsCost,
      },
    };

    create(payload);
  };

  return (
    <CreateCustomMissionView
      isLoading={isLoading || loadingCms}
      onCreateMission={onCreateMission}
      onCancelPress={props.history.goBack}
    />
  );
};

export default CreateCustomMissionContainer;
