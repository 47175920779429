import React, { FC, useRef, useState } from 'react';

import {
  MdStop,
  MdCheck,
  MdClose,
  MdAdjust,
  MdCancel,
  MdTurnedIn,
  MdHowToReg,
  MdFilterList,
  MdCheckCircle,
  MdEventAvailable,
  MdRequestQuote,
  MdChangeCircle,
  MdOpenWith,
} from 'react-icons/md';
import {
  Box,
  Text,
  Flex,
  Button,
  chakra,
  useOutsideClick,
} from '@chakra-ui/react';

import { useSearchParamState } from 'utils/hooks';

import { missionStatusMap } from 'constants/missionStatusMap';

import { IconType } from 'react-icons';
import { FiltersProps } from './Filters.props';
import { CustomStatus, MissionStatuses } from 'types/mission';
import { customStatusMap } from 'constants/assetStatusMap';

const Span = chakra('span');

const DATES = ['1 day', '1 week', '2 weeks', '1 month', '6 months', '1 year'];

const STATUS_ICONS_MAP: Record<string, IconType> = {
  [MissionStatuses.SCHEDULED]: MdEventAvailable,
  [MissionStatuses.PROPOSAL]: MdEventAvailable,
  [MissionStatuses.ORDERED]: MdHowToReg,
  [MissionStatuses.FOR_PAYMENT]: MdAdjust,
  [MissionStatuses.FLIGHT_COMPLETED]: MdCheck,
  [MissionStatuses.COMPLETED]: MdCheckCircle,
  [MissionStatuses.ASSETS_UPLOADED]: MdTurnedIn,
  [MissionStatuses.CANCELLED]: MdCancel,
  [MissionStatuses.DECLINED]: MdStop,
  DEFAULT: MdEventAvailable,
};

const CUSTOM_STATUS_ICONS_MAP: Record<string, IconType> = {
  ASSETS_UPLOADED: MdTurnedIn,
  PENDING_PAYMENT: MdAdjust,
  CLIENT_CHANGE_REQUESTED: MdChangeCircle,
  CLIENT_REJECTED: MdClose,
  COMPLETED: MdCheckCircle,
  FLIGHT_COMPLETED: MdCheck,
  FOR_PAYMENT: MdAdjust,
  ORDERED: MdHowToReg,
  PILOT_ACCEPTED: MdOpenWith,
  PILOT_REJECTED: MdStop,
  REQUESTED: MdRequestQuote,
  DEFAULT: MdEventAvailable,
};

const FiltersView: FC<FiltersProps> = ({ type = 'mission' }) => {
  const flexRef = useRef<HTMLDivElement>(null);

  const [isShow, setIsShow] = useState(false);

  const [status, setStatus] = useSearchParamState('status');

  useOutsideClick({
    ref: flexRef,
    handler: () => {
      setIsShow(false);
    },
  });

  return (
    <Box position="relative">
      <Button
        mb="10px"
        borderRadius="8px"
        w="150px"
        variant="outline"
        leftIcon={<MdFilterList size="22px" />}
        rightIcon={isShow ? <MdClose size="22px" color="red" /> : undefined}
        onClick={() => {
          setIsShow((prev) => !prev);
        }}>
        Filters
      </Button>

      <Flex
        ref={flexRef}
        data-show="false"
        zIndex={1}
        p="20px"
        px="35px"
        position="absolute"
        direction="column"
        bg="#fff"
        minW="500px"
        right={0}
        boxShadow="0 0 10px 0 rgba(0,0,0,0.2)"
        borderRadius="8px"
        gridGap="20px"
        css={
          isShow
            ? {
                transition: 'opacity 0.5s ease',
                opacity: 1,
                visibility: 'visible',
              }
            : {
                transition: 'opacity 0.5s ease',
                opacity: 0,
                visibility: 'hidden',
              }
        }>
        <Text fontWeight="500">Filter by mission status</Text>
        {type === 'mission' ? (
          <Flex flex={1} gridGap="10px" flexWrap="wrap">
            {Object.entries(MissionStatuses).map(([_status]) => {
              const name = missionStatusMap[_status];
              const Icon = STATUS_ICONS_MAP[_status];
              return (
                <Text
                  display="flex"
                  align="center"
                  as="button"
                  gridGap="5px"
                  key={_status.toString()}
                  fontSize="14px"
                  px="10px"
                  py="5px"
                  bg={status === _status ? '#6728BB' : undefined}
                  color={status === _status ? '#fff' : '#6728BB'}
                  fontWeight="bold"
                  borderRadius="5px"
                  border="2px solid #6728BB"
                  onClick={() => {
                    if (status === _status) {
                      setStatus('');
                    } else {
                      setStatus(_status);
                    }
                  }}>
                  <Span mt="1.5px">
                    <Icon size="18px" />
                  </Span>
                  {name}
                </Text>
              );
            })}
          </Flex>
        ) : (
          <Flex flex={1} gridGap="10px" flexWrap="wrap">
            {Object.entries(CustomStatus).map(([_status]) => {
              const name = customStatusMap[_status];
              const Icon = CUSTOM_STATUS_ICONS_MAP[_status];

              return (
                <Text
                  display="flex"
                  align="center"
                  as="button"
                  gridGap="5px"
                  key={_status.toString()}
                  fontSize="14px"
                  px="10px"
                  py="5px"
                  bg={status === _status ? '#6728BB' : undefined}
                  color={status === _status ? '#fff' : '#6728BB'}
                  fontWeight="bold"
                  borderRadius="5px"
                  border="2px solid #6728BB"
                  onClick={() => {
                    if (status === _status) {
                      setStatus('');
                    } else {
                      setStatus(_status);
                    }
                  }}>
                  <Span mt="1.5px">
                    <Icon size="18px" />
                  </Span>
                  {name}
                </Text>
              );
            })}
          </Flex>
        )}

        {/* <Text mt="20px" fontWeight="500">
          Filter by date
        </Text>
        <Flex flex={1} gridGap="10px" flexWrap="wrap">
          {DATES.map((date) => (
            <Text
              as="button"
              key={date.toString()}
              fontSize="14px"
              px="10px"
              py="5px"
              color="#6728BB"
              fontWeight="bold"
              borderRadius="5px"
              border="2px solid #6728BB">
              {date}
            </Text>
          ))}
        </Flex> */}
      </Flex>
    </Box>
  );
};

export default FiltersView;
