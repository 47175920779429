import React, { FC, useRef, useState } from 'react';

import {
  Text,
  Modal,
  Button,
  Center,
  Textarea,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';

import { selectors } from 'effector/session';

import { AccountTypes } from 'types/accountTypes';
import { HandleCancelMissionStatusModalGeneratedProps } from './HandleCancelMissionStatusModal.props';
import { MdCheckCircleOutline } from 'react-icons/md';

const HandleCancelMissionStatusModalView: FC<HandleCancelMissionStatusModalGeneratedProps> = ({
  isOpen,
  onClose,
  mission,
  isSuccess,
  isLoading,
  handleSubmit,
  cancellation,
}) => {
  const accountType = selectors.useAccountType();
  const isClient = accountType === AccountTypes.client;

  const isSubmitted = useState(!!cancellation?.status)[0];

  const statusRef = useRef<0 | 1>();

  if (
    String(accountType).toLowerCase() ===
    String(cancellation?.requestedBy).toLowerCase()
  ) {
    return null;
  }

  if (isSubmitted) {
    return null;
  }

  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={() => null}>
      <ModalOverlay />
      <ModalContent py="20px">
        {isSuccess ? (
          <>
            {statusRef.current === 0 ? (
              <ModalHeader fontWeight="500" textAlign="center" mx="20px">
                Your cancellation request was successfully declined
              </ModalHeader>
            ) : (
              <ModalHeader fontWeight="500" textAlign="center" mx="20px">
                Your cancellation request was successfully accepted
              </ModalHeader>
            )}
          </>
        ) : (
          <>
            <ModalHeader fontWeight="500" textAlign="center" mx="20px">
              You received a cancellation request for this mission
            </ModalHeader>
            <ModalCloseButton />
          </>
        )}

        <ModalBody fontSize="18px" textAlign="center">
          {isSuccess && (
            <Center>
              <MdCheckCircleOutline size="100px" color="#65CD5C" />
            </Center>
          )}

          {isSuccess ? (
            <>
              {statusRef.current === 0 ? (
                <>
                  <Text fontSize="14px" mx="20px">
                    We suggest you to contact the{' '}
                    {isClient ? 'pilot' : 'client'} and let them know the
                    reasons behind
                  </Text>
                  <Box fontSize="14px">
                    <ul>
                      <li>
                        <strong>Name:</strong>{' '}
                        {isClient
                          ? mission?.pilot?.firstName || ''
                          : mission?.client?.firstName || ''}
                      </li>

                      <li>
                        <strong>Email:</strong>{' '}
                        {isClient
                          ? mission?.pilot?.email || ''
                          : mission?.client?.email || ''}
                      </li>
                    </ul>
                  </Box>
                </>
              ) : (
                <Text fontSize="14px" mx="20px">
                  Your payment refund has been initiated. please check your
                  email inbox
                </Text>
              )}
            </>
          ) : (
            <>
              <Text fontSize="14px" mx="20px">
                The {isClient ? 'Pilot' : 'Client'} leaved the details of they
                request in the box below
              </Text>

              <Textarea
                mt="20px"
                placeholder="Put your cancellation request reason here"
                isReadOnly
                value={cancellation?.reason}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter justifyContent="center">
          {isSuccess ? (
            <>
              <Button onClick={onClose}>CLOSE</Button>
            </>
          ) : (
            <>
              <Button
                variant="outline"
                mr={2}
                isLoading={isLoading}
                onClick={() => {
                  statusRef.current = 0;
                  handleSubmit('REJECTED');
                }}>
                DECLINE REQUEST
              </Button>
              <Button
                isLoading={isLoading}
                onClick={() => {
                  statusRef.current = 1;
                  handleSubmit('ACCEPTED');
                }}>
                ACCEPT REQUEST
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default HandleCancelMissionStatusModalView;
