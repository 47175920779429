import React, { FC, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { MdMoreVert } from 'react-icons/md';

type TreeDotsMenuProps = {
  options?: any[];
};

const TreeDotsMenu = (options: TreeDotsMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Menu autoSelect={false}>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon as={MdMoreVert} />}
        variant="outline"
        style={{
          color: '#FFFF',
          background: 'transparent',
          borderColor: '#FFF',
        }}
        onClick={handleMenuToggle}
      />

      <MenuList>
        <MenuGroup>
          {options?.options?.map((option: any, index: number) => {
            return <MenuItem key={index}>{option}</MenuItem>;
          })}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default TreeDotsMenu;
