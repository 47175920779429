import { Button } from '@chakra-ui/react';
import React from 'react';

type Props = {
  onClick: () => void;
};

const CreateCustomMissionButton = ({ onClick }: Props) => {
  return (
    <Button
      position="fixed"
      bottom="5rem"
      left="50%"
      transform="translateX(-50%)"
      borderRadius="26px"
      zIndex="10"
      color="white"
      p="1.5rem"
      fontSize="0.8rem"
      onClick={onClick}>
      CREATE CUSTOM MISSION
    </Button>
  );
};

export default CreateCustomMissionButton;
