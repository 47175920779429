import React, { useEffect, useRef, useState } from 'react';

import { Box, Flex, VStack, Text, Button, Center } from '@chakra-ui/react';

import { CollapsibleMenuGeneratedProps } from './CollapsibleMenu.props';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { lateralHeaderMenu } from 'utils/navItems';
import { UserAvatar } from 'components/modules/UserAvatar';
import { AnimatePresence, motion } from 'framer-motion';

const _contprops = {
  mr: 1,
  w: '100%',
  minW: '200px',
  maxW: '200px',
  position: 'relative' as any,
};

const _defaultprops = {
  px: 2,
  py: 2,
  w: '100%',
  zIndex: 2,
  maxW: '200px',
  color: '#fff',
  align: 'center',
  borderRadius: '10px',
  alignSelf: 'flex-end',
  border: '2px solid #fff',
};

const _buttonprops = {
  maxW: '240px',
  mt: 1,
  color: '#fff',
  bg: '#fff',
  w: '100%',
  size: 'sm',
};

const _dropdnprops = {
  left: 0,
  right: 0,
  h: '120px',
  pt: '30px',
  px: '10px',
  pb: '10px',
  top: '70%',
  shadow: 'md',
  borderBottomRadius: '10px',
  border: '2px solid #fff',
  borderTop: 'none',
  position: 'absolute' as any,
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 },
  transition: { duration: 0.3 },
};

const MotionBox = motion.custom(Box);

const CollapsibleMenuView = (
  props: CollapsibleMenuGeneratedProps,
): JSX.Element => {
  const { menuActions, userName } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuItems = lateralHeaderMenu;

  const handleOutsideClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <Flex {..._contprops}>
      <Flex
        justifyContent={'space-around'}
        width="100%"
        alignItems={'center'}
        backgroundColor={'#ffff'}
        {..._defaultprops}>
        <Box borderRadius="10px" bg={'#C4C4C4'} borderWidth="none">
          <UserAvatar givenHeight="50px" givenWidht="50px" borderRadius={10} />
        </Box>
        <Flex
          justifyItems={'space-around'}
          cursor="pointer"
          onClick={() => toggleMenu()}
          ref={ref}>
          <Text
            maxWidth={'8vh'}
            noOfLines={1}
            fontSize="1em"
            fontWeight="700"
            color={'#353835'}>
            {userName}
          </Text>
          <Box
            ref={ref}
            size="25px"
            color={'#6418C3'}
            alignContent={'baseline'}
            as={isOpen ? MdArrowDropUp : MdArrowDropDown}
            css={{
              transition: 'transform 1s ease',
            }}
          />
        </Flex>
      </Flex>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <MotionBox bg={'#fff'} zIndex={1} {..._dropdnprops} height={'230%'}>
            {menuItems.map((menuItem, index) => {
              const { name, color, icon: Icon } = menuItem;

              return (
                // eslint-disable-next-line react/jsx-key
                <div key={`${index} - ${name}`}>
                  <Box borderTop={'1px solid #EDEDED'}></Box>
                  <Center>
                    <Button
                      {..._buttonprops}
                      variant="ghost"
                      onClick={() => menuActions(menuItem)}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'flex-start'}
                      key={`${name}-${index}`}
                      paddingLeft={8}>
                      <Icon size="25px" fill={color} />
                      <Text
                        fontSize="15px"
                        fontWeight="700"
                        textAlign={'start'}
                        color={color}
                        paddingLeft={7}>
                        {name}
                      </Text>
                    </Button>
                  </Center>
                </div>
              );
            })}
          </MotionBox>
        )}
      </AnimatePresence>
    </Flex>
  );
};

export default CollapsibleMenuView;
