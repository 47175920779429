import React from 'react';

import { Flex } from '@chakra-ui/react';
import PilotHeader from 'components/modules/PilotHeader';

import { CreateWebsiteGeneratedProps } from './CreateWebsite.props';
import { SessionStore } from 'effector/session/store';
//import { WebsiteForm } from 'components/modules/WebsiteForm';

const CreateWebsiteView = ({
  onSubmit,
}: CreateWebsiteGeneratedProps): JSX.Element => {
  const name = SessionStore.getState()?.session?.user?.firstName ?? '';

  return (
    <Flex flex={1} direction="column" pt={[10, 20]} alignSelf="flex-start">
      <PilotHeader name={name} />
      {/* <WebsiteForm onSubmit={onSubmit} name={name} /> */}
    </Flex>
  );
};

export default CreateWebsiteView;
