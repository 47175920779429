import React, { useState } from 'react';

import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import PilotHeader from 'components/modules/PilotHeader';
import { BiMedal } from 'react-icons/bi';

import { PremiumPaymentGeneratedProps } from './PremiumPayment.props';
import ContentContainer from 'components/layouts/ContentContainer';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SaveACard } from 'components/primitives/SaveACard';
import { SavedCards } from 'components/primitives/SavedCards';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { PaymentMethod } from 'types/stripe';
import { useHistory } from 'react-router';
import { routesSettings } from 'constants/routes';
import { useStore } from 'effector-react';
import { StripeKeyStore } from 'effector/stripe';
import { SessionStore } from 'effector/session/store';

const PaymentForm = ({
  isSuccess,
  isLoading,
  setSelectedCard,
  selectedCard,
  payNow,
}: {
  isSuccess: boolean;
  isLoading: boolean;
  setSelectedCard: Function;
  selectedCard?: PaymentMethod;
  payNow: () => Promise<any>;
}) => {
  const history = useHistory();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  if (isSuccess) {
    return (
      <Flex
        bg="white"
        boxShadow="0px 0px 35px 0px rgba(50, 50, 50, 0.05)"
        borderWidth="1px"
        borderColor="#d8d8d8"
        flex={1}
        maxW="600px"
        maxH="400px"
        direction="column"
        borderRadius="10px"
        fontSize="12px"
        py={5}
        px={8}
        justify="center">
        <Icon
          as={IoIosCheckmarkCircleOutline}
          w="80px"
          h="80px"
          color="paymentSuccessIconColor"
          alignSelf="center"
        />
        <Text fontSize="md" mt={6} textAlign="center">
          Payment Confirmed
        </Text>
        <Text mt={6} textAlign="left">
          Your payment was successful, you can start using the premium features
          right now. You will get the receipt in a confirmation email.
        </Text>
        <Text mt={4} textAlign="left">
          If there’s any issue with the order you can contact DroneAdair
          support.
        </Text>
        <Button
          size="sm"
          w="150px"
          alignSelf="center"
          fontWeight="bold"
          mt={10}
          onClick={() => {
            history.replace(routesSettings.PILOT_DASHBOARD.path);

            setTimeout(() => {
              history.go(0);
            }, 200);
          }}>
          DONE
        </Button>
      </Flex>
    );
  }

  return (
    <Flex
      w={2 / 3}
      flex={1}
      mb="20px"
      mr={[0, '20px', '20px', '20px']}
      p={['30px', '30px 50px', '30px 50px', '30px 50px']}
      background="#fff"
      borderRadius="10px"
      border="1px"
      borderColor="#ccc"
      flexDirection="column">
      <Text w="100%" mb="10px" ml="-15px">
        Select a Payment Method
      </Text>
      <SaveACard />
      <SavedCards setSelectedCard={setSelectedCard} />
      <Checkbox
        w="100%"
        my="20px"
        size="md"
        display="flex"
        alignItems="flex-start"
        colorScheme="brand"
        isChecked={isChecked}
        onChange={(e) => setIsChecked(e.target.checked)}>
        <Text fontSize="xs">
          I acknowledge that I have read and agree the{' '}
          <Link textDecoration="underline" color="buttonBgColor">
            terms and confitions
          </Link>
        </Text>
      </Checkbox>
      <Flex direction={['column', 'column', 'row']}>
        <Button
          w="200px"
          mr="10px"
          fontSize="xs"
          variant="outline"
          onClick={() => history.push(routesSettings.PILOT_DASHBOARD.path)}>
          CANCEL
        </Button>
        <Button
          mt={[4, 4, 0]}
          fontSize="xs"
          w="200px"
          isLoading={isLoading}
          onClick={payNow}
          isDisabled={!selectedCard || !isChecked}>
          PAY NOW
        </Button>
      </Flex>
    </Flex>
  );
};

const SubscriptionInfo = (): JSX.Element => (
  <Flex
    flex={1}
    maxW={['100%', '280px']}
    h="600px"
    bg="#707070"
    ml={[0, 4]}
    mt={[5, 0]}
    direction="column"
    boxShadow="0px 0px 35px 0px rgba(50, 50, 50, 0.05)"
    borderRadius="10px"
    color="white"
    fontSize="12px"
    py={5}
    px={2}>
    <Stack isInline alignItems="center">
      <BiMedal size="20px" />
      <Text fontWeight="bold">Droneadair Premium Subscription</Text>
    </Stack>
    <Stack ml={1} mt={2} h="100%">
      <Text>
        Subscription to the monthly benefits for pilots registered in Droneadair
        network
      </Text>
      <Text fontWeight="bold" mt={4}>
        Features:
      </Text>
      <Text whiteSpace="pre-line">
        - Your own public website to share with potential clients{'\n\n'}-
        Create customized footage packs{'\n\n'}- Discounts in educational
        courses with flying schools{'\n\n'}- Direct communication with the
        client
        {'\n\n'}- Data transfer made easy, integrate your Droneadair platform
        into your own website
      </Text>
      <Divider borderColor="white" mx={2} />
      <Text mt={4} fontWeight="bold">
        Benefits:
      </Text>
      <Text>- You can cancel at anytime</Text>
      <Flex flex={1} direction="column" justifyContent="flex-end" h="100%">
        <Divider borderColor="white" mt="150px" mb="1" />
        <Text textAlign="right" mr={2} fontSize="22px" fontWeight="bold">
          $199/mo
        </Text>
      </Flex>
    </Stack>
  </Flex>
);

const PremiumPaymentView = ({
  setSelectedCard,
  selectedCard,
  isSuccess,
  isLoading,
  payNow,
}: PremiumPaymentGeneratedProps): JSX.Element => {
  const firstName = SessionStore.getState()?.session?.user?.firstName ?? '';

  return (
    <ContentContainer>
      <>
        <PilotHeader name={firstName} />
        <Flex w="100%" maxW="900px" direction="column" alignSelf="center">
          <Text
            fontSize="22px"
            fontWeight="bold"
            color="black"
            fontFamily="Monument Extended">
            You are minutes away
          </Text>
          <Text
            fontSize="22px"
            fontWeight="200"
            color="#4f4f4f"
            fontFamily="Monument Extended">
            to enjoy the premium benefits
          </Text>
          <Flex flex={1} mt={10} direction={['column', 'row']}>
            <PaymentForm
              isLoading={isLoading}
              isSuccess={isSuccess}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              payNow={payNow}
            />
            <SubscriptionInfo />
          </Flex>
        </Flex>
      </>
    </ContentContainer>
  );
};

export default (props: PremiumPaymentGeneratedProps): JSX.Element | null => {
  const { public_key } = useStore(StripeKeyStore);

  if (!public_key) {
    return null;
  }

  return (
    <Elements stripe={loadStripe(public_key)}>
      <PremiumPaymentView {...props} />
    </Elements>
  );
};
