import { useQuery } from 'react-query';

import { getCouponDetails } from 'api/chargebee';

const useCouponData = (coupon: string) => {
  const queryKey = [`cb-coupon`, coupon];
  const queryFn = () => getCouponDetails(coupon);
  const options = {
    enabled: !!coupon,
    keepPreviousData: true,
  };

  const query = useQuery(queryKey, queryFn, options);

  return query;
};

export default useCouponData;
