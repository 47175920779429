import React, { FC } from 'react';

import {
  Text,
  Flex,
  Modal,
  Image,
  Button,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

import alertCertifification from 'res/logout-img.png';

import { PlanExpirationHandlerGeneratedProps } from './PlanExpirationHandler.props';

const View: FC<PlanExpirationHandlerGeneratedProps> = ({
  isOpen,
  onClose,
  trialEndDate,
  isSubmitting,
  onProceedClick,
}) => {
  return (
    <Modal isCentered size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex align="center" direction="column" p="30px">
            <Image
              maxH="200px"
              objectFit="contain"
              src={alertCertifification}
            />
            <Text
              mt="20px"
              textAlign="center"
              fontSize="22px"
              fontWeight="bold">
              Your Trial period is about to end!
            </Text>

            <Text
              mt="20px"
              textAlign="center"
              fontSize="14px"
              fontWeight="bold">
              Trial period ends on : {trialEndDate}
            </Text>

            <Text
              mt="20px"
              textAlign="center"
              fontSize="14px"
              fontWeight="bold">
              Submit your Credit Card info to continue enjoying benefits of Pro
              plan
            </Text>

            <Button
              isLoading={isSubmitting}
              w="200px"
              mt="20px"
              onClick={onProceedClick}>
              PROCEED
            </Button>
            <Button
              isDisabled={isSubmitting}
              w="200px"
              mt="10px"
              variant="outline"
              onClick={onClose}>
              CLOSE
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default View;
