import React, { useRef } from 'react';

import { Button } from '@chakra-ui/react';

import CancelMissionModal, { RefType } from '../CancelMissionModal';

import { MissionStatuses } from 'types/mission';
import { PilotMissionActionsGeneratedProps } from './PilotMissionActions.props';

const PilotMissionActionsView = ({
  complete,
  isLoading,
  mission,
  assetsUploaded,
}: PilotMissionActionsGeneratedProps) => {
  const cancelMissionModalRef = useRef<RefType>(null);

  const isComplete = mission?.status === MissionStatuses.COMPLETED;

  if (isComplete) {
    return null;
  }

  return (
    <>
      <Button
        size="sm"
        w="150px"
        isLoading={isLoading}
        onClick={complete}
        isDisabled={!Boolean(assetsUploaded)}>
        COMPLETE
      </Button>
      <Button
        size="sm"
        w="150px"
        variant="outline"
        onClick={cancelMissionModalRef.current?.onOpen}>
        CANCEL
      </Button>

      <CancelMissionModal ref={cancelMissionModalRef} missionId={mission?.id} />
    </>
  );
};

export default PilotMissionActionsView;
