import React, { FC } from 'react';

import {
  MdList,
  MdPlace,
  MdPerson,
  MdZoomIn,
  MdPeople,
  MdListAlt,
  MdReceipt,
  MdPlayArrow,
  MdDateRange,
  MdNavigation,
  MdViewCarousel,
} from 'react-icons/md';
import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';

import { missionStatusMap } from 'constants/missionStatusMap';
import { missionStatusColorsMap } from 'constants/assetStatusMap';

import * as session from 'effector/session';

import { format } from 'date-fns';
import { goToMaps } from 'utils/goToMaps';

import GoogleMaps from 'components/primitives/GoogleMaps';

import { AccountTypes } from 'types/accountTypes';
import { Mission, MissionStatuses, PackageLineItems } from 'types/mission';

const labelProps = {
  fontSize: '13px',
  fontWeight: 'bold',
  color: 'primaryColor',
};

const IconWithText = ({ text, Icon }: { text: string; Icon?: any }) => {
  return (
    <Stack isInline alignItems="center" spacing={1}>
      {Icon && <Icon size={15} color="#908361" />}
      <Text {...labelProps}>{text}</Text>
    </Stack>
  );
};

const Details: FC<{ mission: Mission }> = ({ mission }) => {
  const userType = session.selectors.useAccountType();

  const isPilot = userType === AccountTypes.pilot;

  const formatDate = (date?: Date) => {
    if (!date) {
      return '';
    }

    return format(new Date(date), 'dd MMMM, yyyy');
  };

  return (
    <Flex p="20px" pb="100px" gridGap="15px" flex={1} direction="column">
      <Stack spacing={0}>
        <Text {...labelProps}>Mission ID</Text>
        <Text>{mission?.id || ''}</Text>
      </Stack>

      <Stack spacing={0}>
        <Text {...labelProps}>Mission Status</Text>
        <Text
          fontWeight="bold"
          color={missionStatusColorsMap[mission?.status as MissionStatuses]}>
          {missionStatusMap[mission?.status as any]}
        </Text>
      </Stack>
      {mission?.package && (
        <Box h="100%">
          <IconWithText Icon={MdViewCarousel} text="Pack Requested" />
          <Text>{mission?.package?.name}</Text>
          <Text fontWeight="500">
            {mission?.package?.lineItems?.map(
              (item: PackageLineItems, index: number) => (
                <React.Fragment key={`${item.name}-${index}`}>
                  - {item.count ? `(${item.count})` : ''} {item.name}
                  <br />
                </React.Fragment>
              ),
            )}
            {mission?.package?.additionalServices?.map(
              (item: PackageLineItems, index: number) => (
                <React.Fragment key={`${item.name}-${index}`}>
                  - {item.name}
                  <br />
                </React.Fragment>
              ),
            )}
          </Text>
        </Box>
      )}

      <Box>
        <IconWithText Icon={MdPlace} text="Location" />
        <Text mt="5px" mb="10px">
          {mission?.location}
        </Text>
        <Box
          w={['100%']}
          height="200px"
          mb="10px"
          background="#fff"
          bg="inputColor"
          borderRadius="10px"
          overflow="hidden">
          <GoogleMaps
            mapContainerStyle={{
              width: '100%',
              height: '200px',
            }}
            initialCenter={{
              lat: mission?.gps?.coordinates[1] || 0,
              lng: mission?.gps?.coordinates[0] || 0,
            }}
            marker={{
              lat: mission?.gps?.coordinates[1] || 0,
              lng: mission?.gps?.coordinates[0] || 0,
            }}
            zoom={13}
          />
        </Box>
        <Flex pt="10px" justify="space-between">
          <Button
            p={2}
            size="xs"
            variant="ghost"
            alignSelf="flex-end"
            leftIcon={<MdZoomIn size={15} />}>
            ENLARGE
          </Button>
          <Button
            as="a"
            p={2}
            size="xs"
            variant="ghost"
            alignSelf="flex-end"
            leftIcon={<MdNavigation size={15} />}
            onClick={() =>
              goToMaps({
                lat: mission?.gps?.coordinates[1] || 0,
                lng: mission?.gps?.coordinates[0] || 0,
              })
            }>
            START NAVIGATION
          </Button>
        </Flex>
      </Box>
      <Box>
        <IconWithText Icon={MdDateRange} text="Due Date" />
        {formatDate(mission?.eventStart) === formatDate(mission?.dueDate) ? (
          <Text>{formatDate(mission?.eventStart)}</Text>
        ) : (
          <Text>
            {formatDate(mission?.eventStart)} - {formatDate(mission?.dueDate)}{' '}
          </Text>
        )}
      </Box>
      <Box>
        <IconWithText Icon={MdPlayArrow} text="Live Event" />
        <Text>{mission?.liveEvent ? 'Yes' : 'No'}</Text>
      </Box>
      {mission?.liveEvent && (
        <Box>
          <IconWithText text="Event Starts At" />

          <Text>{formatDate(mission?.eventStart)}</Text>
        </Box>
      )}
      {mission?.onsite && (
        <Box>
          <IconWithText Icon={MdPeople} text="Contact on Site" />
          <Text>{mission?.onsite ? 'Yes' : 'No'}</Text>
        </Box>
      )}
      {mission?.meetupTime && (
        <Box>
          <IconWithText text="Time for Meeting" />
          <Text>
            {mission?.meetupTime?.[0]
              ? formatDate(new Date(mission?.meetupTime?.[0]))
              : ''}
          </Text>
        </Box>
      )}
      <Box>
        <Box>
          <IconWithText Icon={MdList} text="Mission Notes" />
          <Text
            mt="5px"
            p="5px 10px"
            border="1px solid #ccc"
            borderRadius="5px"
            bg="#fff">
            {mission?.notes || 'No Notes written for this mission'}
          </Text>
        </Box>
        <Box mt={4}>
          <IconWithText Icon={MdList} text="Location Details" />
          <Text
            mt="5px"
            p="5px 10px"
            border="1px solid #ccc"
            borderRadius="5px"
            bg="#fff">
            {mission?.locationDetail ||
              'No extra location details written for this mission'}
          </Text>
        </Box>
        {mission?.contact && (
          <Box mt={6}>
            <IconWithText Icon={MdPerson} text="Contact" />
            <Text>Name: {mission?.contact?.name || ''}</Text>
            <Text>Email: {mission?.contact?.email || ''}</Text>
            <Text>Phone: {mission?.contact?.phone || ''}</Text>
          </Box>
        )}
      </Box>
      <Box borderBottom="1px solid #ccc">
        {mission?.additionalServices &&
          mission?.additionalServices?.length > 0 && (
            <Box my={2}>
              <IconWithText Icon={MdListAlt} text="Additional Services" />
              {mission.additionalServices?.map((a, i) => (
                <Stack
                  fontSize="12px"
                  fontWeight="500"
                  isInline
                  key={`${a.name}-${i}`}>
                  <Text>- {a.name}</Text>
                  <Text>${a.price}</Text>
                </Stack>
              ))}
            </Box>
          )}
      </Box>
      <Flex justify="space-between">
        <IconWithText
          Icon={MdReceipt}
          text={isPilot ? 'Total Pay' : 'Total Charged'}
        />
        <Text fontWeight="bold" fontSize="24px" color="#343835">
          ${mission?.totalCharge}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Details;
