import React from 'react';

import { useQuery } from 'react-query';

import { checkCMS, getMyCMS } from 'api/cms';
import { getMissions } from 'api/mission';

import { selectors } from 'effector/session';

import { useSearchParamState } from 'utils/hooks';

import { useHistory } from 'react-router-dom';

import { PremiumMissionListProps } from './PremiumMissionList.props';

import PremiumMissionListView from './PremiumMissionList.view';

const MISSIONS_PER_PAGE = 10;

const PremiumMissionListContainer = ({ type }: PremiumMissionListProps) => {
  const history = useHistory();
  const user = selectors.useUser();

  const [search] = useSearchParamState('search');
  const [page, setPage] = useSearchParamState('page');
  const [missionStatus] = useSearchParamState('status');

  const { data: publishedCMS, isLoading: isCheckLoading } = useQuery(
    `fetch-check-cms`,
    () => checkCMS(),
    {
      retry: 0,
      onError: () => {
        console.log('error');
      },
    },
  );

  const isPublished = publishedCMS?.isExist;
  const status = isPublished ? 'PUBLISH' : 'DRAFT';

  const { data: cms, isLoading } = useQuery(
    `fetch-my-cms`,
    () => getMyCMS(status),
    {
      retry: 0,
      onError: () => {
        console.log('error');
      },
    },
  );

  const companyName =
    cms?.about?.companyName?.toUpperCase() || '(COMPANY NAME)';
  const cmsId = cms?.id ? cms.id : '';

  const queryKey = [
    `fetch missions accepted`,
    type,
    cmsId,
    page,
    search,
    missionStatus,
  ];
  const queryFn = () =>
    getMissions({
      type,
      cms: cmsId,
      pilot: user?.id,
      limit: MISSIONS_PER_PAGE,
      dueDate: new Date().toISOString(),
      search: !search ? undefined : search,
      page: !page ? 1 : Number(page),
      status: !missionStatus ? undefined : missionStatus,
    });

  const options = {
    enabled: !!cmsId,
    keepPreviousData: true,
    // refetch after 5 minutes
    refetchInterval: 1000 * 60 * 3,
  };

  const { data, isFetching } = useQuery(queryKey, queryFn, options);

  const missions = data?.results || [];
  const totalPages = data?.totalPages || 0;

  const onMissionClick = (missionId: string) => {
    history.push(`mission/details/${missionId}`);
  };

  return (
    <PremiumMissionListView
      page={Number(page) || 1}
      setPage={(_page) => setPage(String(_page))}
      missions={missions}
      totalPages={totalPages}
      companyName={companyName}
      onMissionClick={onMissionClick}
      isLoading={isLoading || isFetching || isCheckLoading}
      isNotPublished={!isPublished}
    />
  );
};

export default PremiumMissionListContainer;
