import React from 'react';
import UserRegistration from '../../../components/modules/UserRegistration';
import PilotRegistration from '../../../components/modules/PilotRegistration';
import CompanyRegistration from '../../../components/modules/CompanyRegistration';

import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
} from '@chakra-ui/react';

import { useIsMobile } from 'utils/hooks';

import MobileRegistration from 'components/modules/MobileRegistration';

import { RegisterGeneratedProps } from './Register.props';

const RegistrationContainer = ({ children }: any) => (
  <Flex
    bg="white"
    width={['100%', '100%', '100%', '960px']}
    py={10}
    px={10}
    borderRadius="5px"
    border="1px"
    borderColor="gray.200"
    flexDir="column">
    {children}
  </Flex>
);

const tabSpacing = [0, 0, 10];

const RegisterView = (props: RegisterGeneratedProps) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <MobileRegistration
        isLoading={props.isLoading}
        onSubmit={props.onSubmit}
      />
    );
  }

  return (
    <Flex justify="center" align="center" padding={5} width="100%" bg="gray.50">
      <Flex align="center" flexDir="column">
        <Heading
          textAlign="center"
          fontSize="xl"
          fontWeight="100"
          my={10}
          maxW="800px"
          fontFamily="Monument Extended">
          {props.headerMessage}
        </Heading>
        <Tabs
          variant="enclosed"
          index={props.tabIndex}
          onChange={props.handleTabsChange}>
          <TabList>
            <Tab
              fontWeight="bold"
              fontSize={['12px', '16px']}
              px={tabSpacing}
              py="5"
              textTransform="uppercase"
              letterSpacing={1}
              bg="white"
              borderColor={theme.colors.primaryColor}
              _selected={{
                bg: theme.colors.primaryColor,
                color: 'white',
              }}>
              Pilot Registration
            </Tab>
            <Tab
              style={{ visibility: 'hidden' }}
              fontWeight="bold"
              px={tabSpacing}
              py="5">
              Company Registration
            </Tab>
            <Tab
              style={{ visibility: 'hidden' }}
              fontWeight="bold"
              px={tabSpacing}
              py="5">
              Company Registration
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0} m={0}>
              <RegistrationContainer>
                <PilotRegistration
                  onCancel={props.onCancel}
                  onSubmit={props.onSubmit}
                  isLoading={props.isLoading}
                />
              </RegistrationContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  );
};

export default RegisterView;
