import React, { useState } from 'react';

import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import DatePicker from 'components/primitives/DatePicker';
import { useField, useFormikContext } from 'formik';
import { isSameDay } from 'date-fns';

import { FormikDatePickerProps } from './FormikDatePicker.props';

const FormikDatePickerView = ({
  name,
  error,
  label,
  disablePastDates,
  labelProps,
  containerProps = {},
  isRequired,
  ...props
}: FormikDatePickerProps): JSX.Element => {
  const [{ value = new Date() }, meta] = useField(name);
  const [initialDate] = useState({
    date: new Date(value),
    string: value,
  });

  const formik = useFormikContext();

  return (
    <FormControl {...containerProps} isRequired>
      {label && (
        <FormLabel
          htmlFor={name}
          fontSize="12px"
          fontWeight="700"
          {...labelProps}>
          {label}
        </FormLabel>
      )}
      <DatePicker
        {...props}
        value={value}
        onChange={([date]) => {
          if (!date) {
            return;
          }

          if (isSameDay(date, initialDate.date)) {
            formik.setFieldValue(name, initialDate.string);
          } else {
            formik.setFieldValue(name, date.toISOString());
          }
        }}
        textFieldProps={{
          isInvalid: Boolean(error || meta.error),
          name,
          isReadOnly: true,
          ...props.textFieldProps,
        }}
        disablePastDates={disablePastDates}
      />
      {(error || meta.error) && (
        <Text color="red.500" fontSize="sm">
          {error || meta.error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikDatePickerView;
