import React from 'react';

import { selectors } from 'effector/session';

import IntroTutorial from './IntroTutorial';
import ResendEmailModal from './ResendEmailModal';

import { InactiveAccountBlockerGeneratedProps } from './InactiveAccountBlocker.props';

const InactiveAccountBlockerView = (
  props: InactiveAccountBlockerGeneratedProps,
) => {
  const isTutorialShown = selectors.useIsTutorialShown();

  const { children, isActivated } = props;

  if (isActivated) {
    return children;
  }

  return (
    <>
      {children}
      {isTutorialShown ? <ResendEmailModal /> : <IntroTutorial />}
    </>
  );
};
export default InactiveAccountBlockerView;
