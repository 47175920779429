import React, { FC, useRef, useState } from 'react';

import {
  Flex,
  Modal,
  Input,
  Button,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { CouponCodeModalGeneratedProps } from './CouponCodeModal.props';

const CouponCodeModalView: FC<CouponCodeModalGeneratedProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const couponCodeRef = useRef('');

  const [shouldProceed, setShouldProceed] = useState(false);

  return (
    <Modal
      size="2xl"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent py="20px" borderRadius="30px">
        <ModalHeader
          textAlign="center"
          fontFamily="Monument Extended"
          fontWeight="200">
          Got a coupon code?
        </ModalHeader>
        <Text align="center" px={12} pb={4}>
          If you have a unique discount code, feel free to enter it here.
          Otherwise, you can simply click "Skip."
        </Text>

        <ModalBody px="50px">
          <Flex direction="column" gridGap="20px" align="center" pb={4}>
            <Input
              maxW="300px"
              bg="inputColor"
              placeholder="Enter Coupon Code"
              onChange={(e) => {
                couponCodeRef.current = e.target.value;
              }}
            />
            <Flex gridGap="24px" pt={2}>
              <Button
                px={7}
                onClick={() => onSubmit(couponCodeRef.current)}
                isLoading={isLoading}>
                Submit
              </Button>
              <Button
                variant="outline"
                onClick={onClose}
                isDisabled={isLoading}
                px={8}>
                Skip »
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CouponCodeModalView;
