import React, { FC } from 'react';

import { useToast } from '@chakra-ui/toast';
import { IconButton } from '@chakra-ui/button';

import { MdDelete } from 'react-icons/md';

import { useField } from 'formik';

import { deletePreviousWorkItem } from 'api/cms';
import { useMutation, useQueryCache } from 'react-query';

export const DeleteButton: FC<{ id: string }> = ({ id }) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const { value: stylings } = useField<any>('stylings')[0];

  const color = stylings?.colorScheme;

  const [onDelete, { isLoading }] = useMutation(deletePreviousWorkItem, {
    onSuccess: () => {
      toast({
        duration: 2000,
        isClosable: true,
        status: 'success',
        title: 'File Deleted',
      });

      queryCache.invalidateQueries('fetch-my-cms', {
        exact: true,
      });

      queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
        exact: true,
      });
    },
  });

  return (
    <IconButton
      size="sm"
      top={0}
      right={0}
      zIndex={2}
      borderRadius="0 0 0 10px"
      position="absolute"
      aria-label="delete-button"
      icon={<MdDelete size="16px" />}
      isLoading={isLoading}
      onClick={() => onDelete(id)}
      bg={color}
    />
  );
};
