import React, { FC, useRef, useState } from 'react';

import {
  Text,
  Flex,
  Radio,
  Stack,
  Image,
  Center,
  Button,
  Textarea,
  RadioGroup,
} from '@chakra-ui/react';

import success from 'res/req_change_success.png';

import { useMutation, useQueryCache } from 'react-query';
import { getUserId } from 'effector/session/store';
import { clientRejectMission } from 'api/custom-mission';

type Status = 'accept_mission' | 'request_changes' | 'reject';

type RejectMissionProps = {
  onSelect: (_status: Status) => void;
  customMissionId: string;
};

export const RejectMission: FC<RejectMissionProps> = ({
  onSelect,
  customMissionId,
}) => {
  const queryCache = useQueryCache();

  const [index, setIndex] = useState<number>(0);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [submit, { isLoading, isSuccess }] = useMutation(
    (_message: string) => {
      const client = getUserId();

      return clientRejectMission(_message, customMissionId, client);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(`fetch-custom-mission-${customMissionId}`);
      },
    },
  );

  const onSend = () => {
    let message = options[index];

    if (index === 4) {
      const inputValue = inputRef.current?.value || '';

      if (inputValue.trim()?.length <= 0) {
        inputRef.current?.focus?.();

        return;
      } else {
        message = inputValue;
      }
    }

    submit(message);
  };

  const options = [
    'Budget Concern',
    "Timeframe Doens't Work",
    'Safety Concern',
    'Others',
  ];

  if (isSuccess) {
    return (
      <Flex py={10} px={6} direction="column" flex={1}>
        <Flex flexWrap="wrap">
          <Image w="200px" h="200px" mr={4} src={success} objectFit="cover" />
          <Stack mt={4} flex={1} minW="200px">
            <Text fontSize="18px" fontWeight="bold">
              Rejection reasons sent!
            </Text>
            <Text fontSize="14px">
              Thank you again for choosing us, and we hope that we can work
              together in the future to meet your drone mission needs. If you
              have any further questions or concerns, please do not hesitate to
              reach out to us.
            </Text>
          </Stack>
        </Flex>
        <Button
          mt={6}
          w="150px"
          alignSelf="center"
          onClick={() => onSelect(undefined as any)}>
          CLOSE
        </Button>
      </Flex>
    );
  }

  return (
    <Flex p={6} flex={1} direction="column">
      <Text mb={4} fontSize="22px" fontWeight="bold" textAlign="center">
        Reject Mission
      </Text>
      <Text fontSize="14px" textAlign="center">
        Please select one of the reasons below to send to pilot. If none are
        relevant put your rejection or change request in the "Other" field. Your
        pilot will get a notification of your response.
      </Text>
      <RadioGroup
        my={4}
        sx={{
          '.chakra-radio__control[data-checked]': {
            background: '#6728BB',
            borderColor: '#6728BB',
          },
          '.chakra-radio__control[data-focus]': {
            boxShadow: 'none',
          },
        }}
        value={index.toString()}
        onChange={(e) => {
          setIndex(Number(e));
        }}>
        <Stack>
          {options.map((option, index) => (
            <Radio key={option} value={index.toString()}>
              <Text fontWeight="bold">{option}</Text>
            </Radio>
          ))}
          {index === 4 && (
            <Textarea
              ref={inputRef}
              autoFocus
              placeholder="Put your request change here"
            />
          )}
        </Stack>
      </RadioGroup>
      <Center my={4} flexWrap="wrap">
        <Button
          isDisabled={isLoading}
          variant="outline"
          w="200px"
          mr={3}
          onClick={() => onSelect(undefined as any)}>
          CANCEL
        </Button>
        <Button isLoading={isLoading} w="200px" onClick={onSend}>
          SEND
        </Button>
      </Center>
    </Flex>
  );
};
