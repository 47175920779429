import React, { FC, useEffect } from 'react';

import { Box, Flex, IconButton, Text } from '@chakra-ui/react';

import { MdChevronLeft } from 'react-icons/md';
import { BiDotsVertical } from 'react-icons/bi';

import { useHistory } from 'react-router-dom';

import { EventRegister } from 'utils/customEvent';

import { MobileCustomMissionDetailsProps } from './MobileCustomMissionDetails.props';

import MobileCustomMissionDetailsView from './MobileCustomMissionDetails.view';

const Container: FC<MobileCustomMissionDetailsProps> = (props) => {
  const history = useHistory();

  useEffect(() => {
    const renderContent = () => {
      return (
        <Flex align="center">
          <IconButton
            size="xs"
            color="#fff"
            aria-label="dash-back"
            icon={<MdChevronLeft size="30px" />}
            variant="ghost"
            onClick={() => {
              EventRegister.emit('render_header_content', {
                renderContent: null,
              });

              history.goBack();
            }}
          />
          <Text fontSize="20px" fontWeight="bold" maxW="90%" noOfLines={1}>
            Custom Mission Request
          </Text>
        </Flex>
      );
    };

    const renderRightIcon = () => {
      return <Box as={BiDotsVertical} size="22px" color="#fff" />;
    };

    EventRegister.emit('render_header_content', {
      renderContent,
      renderRightIcon,
    });
  }, []);

  return <MobileCustomMissionDetailsView {...props} />;
};

export default Container;
