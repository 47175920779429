import { getUser } from 'api/auth';
import { createEffect } from 'effector';
import { showEv } from 'effector/alert';
import { reverseGeocoding } from 'utils/reverseGeocoding';
import { fetchUser } from './events';
import { getUserId } from './store';

export const fetchActionRangeAddress = createEffect(
  `fetch-action-range-address`,
  {
    handler: async ({ lat, lng }: { lat: number; lng: number }) => {
      try {
        const address = await reverseGeocoding(lat, lng);

        return address?.location;
      } catch (error) {
        console.log(error);
      }
    },
  },
);

export const fetchUserFx = createEffect(async (limit = 0) => {
  if (limit >= 4) {
    if (window?.location?.pathname?.includes('welcome')) {
      // showEv({
      //   isOpen: true,
      //   title: 'Something went wrong',
      //   message: `We are unable to fetch your account details. Please try again later`,
      //   btnText: 'GO TO DASHBOARD',
      //   img: undefined,
      //   goToDashboard: true,
      // });
      showEv({
        isOpen: true,
        title: 'Congratulations!!',
        message: `You can now explore the "Pro" features.`,
        btnText: 'GO TO DASHBOARD',
        img: undefined,
        goToDashboard: true,
      });
    }

    return Promise.resolve(false);
  }

  const userId = getUserId();
  const user = await getUser(String(userId)).catch(() => null);

  if (user?.pro) {
    if (!user?.isActivated) {
      showEv({
        isOpen: true,
        title: 'Congratulations!!',
        message: `You can now explore the "Pro" features. As the first step, we recommend you to activate your account by verifying your email address.`,
        btnText: 'GO TO DASHBOARD',
        img: undefined,
        goToDashboard: true,
      });
    } else {
      showEv({
        isOpen: true,
        title: 'Congratulations!!',
        message: `You can now explore the "Pro" features. As the first step, we recommend you start setting your new site.`,
        btnText: 'SET UP WEBSITE',
        img: undefined,
        goToDashboard: undefined,
      });
    }

    return Promise.resolve(true);
  }

  // sleep for 3 seconds
  await new Promise((resolve) => setTimeout(resolve, 3000));

  fetchUser(limit + 1);
});
