import React from 'react';
import FormikInput from 'components/primitives/FormikInput';
import FormikTextArea from 'components/primitives/FormikTextArea';
import { Flex, Stack, useTheme } from '@chakra-ui/react';

import {
  css,
  fontSize,
  labelProps,
  brandColor,
} from '../MobileCreateCustomMission.view';
import { LocationDetails } from 'routes/pilot/CreateCustomMission/LocationDetails';
import { LiveOnsiteSection } from 'routes/pilot/CreateCustomMission/LiveOnsiteSection';
import { isMobile } from 'react-device-detect';
import FormikCalendar from 'components/primitives/FormikCalendar';

const MissionDetailsTab = () => {
  const theme = useTheme();

  return (
    <Flex flexDirection="column" align="center">
      <LocationDetails />
      <Stack flex={1}>
        <FormikInput
          type="text"
          name="clientDetails.industry"
          label="Industry"
          labelProps={labelProps}
          containerProps={{ flex: 1 }}
        />
        <FormikInput
          type="text"
          name="clientDetails.budget"
          label="Budget"
          labelProps={labelProps}
          containerProps={{ flex: 1 }}
        />
        <FormikTextArea
          name="missionDetails"
          label="Mission Details"
          placeholder="Mission details here, e.g., number of pictures, type of pictures and project details"
          labelProps={labelProps}
          size="lg"
          bg="#F3F3F3"
          css={css}
          minH="150px"
          fontSize={fontSize}
        />
        <FormikCalendar
          css={css}
          fontSize={fontSize}
          brandColor={brandColor}
          labelProps={labelProps}
          inputSize="md"
          isMobile={true}
          checkBoxProps={{ size: 'sm', alignItems: 'center' }}
        />
        <FormikInput
          name="package"
          type="text"
          label="Mission Name"
          labelProps={labelProps}
          containerProps={{ flex: 1 }}
        />
        <FormikInput
          name="workSampleURL"
          label="URL Work Samples"
          labelProps={labelProps}
          fontSize={fontSize}
          containerProps={{ flex: 1, mt: 0 }}
        />
        <LiveOnsiteSection isMobile={isMobile} />
      </Stack>
    </Flex>
  );
};

export default MissionDetailsTab;
