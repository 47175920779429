import React, { forwardRef, useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Stack,
  Modal,
  Center,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton,
} from '@chakra-ui/react';

import { hideTutorialEvent } from 'effector/session/events';
import { getIsAccountActivatedStatus } from 'effector/session/store';

import TutorialScreenContent from './TutorialScreenContent';

import { INTRODUCTIONS_CONTENT_MAP } from './utils';
import { isMobile } from 'react-device-detect';

const IntroTutorial = forwardRef((_, ref) => {
  const { isOpen, onOpen, onClose: _onClose } = useDisclosure();

  const onClose = () => {
    hideTutorialEvent();

    _onClose();
  };

  useEffect(() => {
    const isActivated = getIsAccountActivatedStatus();

    if (!Boolean(isActivated)) {
      onOpen();
    }
  }, []);

  const [index, setIndex] = useState<number>(0);
  const onNext = () => {
    setIndex(index + 1);
  };
  const onPrevious = () => {
    setIndex(index - 1);
  };

  const activeIntro = INTRODUCTIONS_CONTENT_MAP[index];

  const isFirst = index === 0;

  const isLast = index === INTRODUCTIONS_CONTENT_MAP.length - 1;
  const isFarEnd = isFirst || isLast;

  const handleButtonClick = () => (isLast ? onClose() : onNext());

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      size="xl">
      <ModalOverlay />
      <ModalContent
        transition="opacity 0.3s ease"
        alignItems={'center'}
        borderRadius="34px"
        minH={isMobile ? '650px' : '350px'}
        maxW={isMobile ? '90vw' : '650px'}
        maxH="350px">
        <Flex mt={10} px={2} alignItems={'center'} justifyContent={'center'}>
          <ModalCloseButton />

          <TutorialScreenContent
            intro={activeIntro}
            isLast={isLast}
            isFarEnd={isFarEnd}
            isFirst={isFirst}
            onNext={onNext}
            onPrevious={onPrevious}
            handleButtonClick={handleButtonClick}
          />
        </Flex>
        <Center>
          {/* Page/Slide Indicators */}
          <Stack
            maxWidth={400}
            direction={'row'}
            justifyContent={'space-between'}
            spacing={4}
            position="fixed"
            bottom={isMobile ? '20%' : '286px'}>
            {INTRODUCTIONS_CONTENT_MAP.map((intro, idx) => (
              <Box
                key={`${intro.title}-${idx}`}
                w="10px"
                h="10px"
                borderRadius="50%"
                textAlign="center"
                fontWeight="bold"
                fontSize="14px"
                bg={index === idx ? '#737373' : '#D9D9D9'}
                color="white"
              />
            ))}
          </Stack>
        </Center>
      </ModalContent>
    </Modal>
  );
});
export default IntroTutorial;
