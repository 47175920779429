import React, { useRef, useState } from 'react';

import {
  Box,
  Flex,
  Text,
  Button,
  Center,
  Spinner,
  Image,
  SimpleGrid,
  IconButton,
  Stack,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  chakra,
} from '@chakra-ui/react';

import { MissionAssetsGeneratedProps } from './MissionAssets.props';
import { format } from 'date-fns';
import { AssetStatus, MissionAsset } from 'types/mission';
import { useMutation, useQueryCache } from 'react-query';
import { deleteAsset } from 'api/mission';
import placeholder from 'res/placeholder.png';
import { useStore } from 'effector-react';
import { SessionStore } from 'effector/session/store';
import { AccountTypes } from 'types/accountTypes';
import { formatBytes } from 'utils/formatBytes';
import { BiDownArrowCircle } from 'react-icons/bi';
import { MdDelete, MdInsertDriveFile } from 'react-icons/md';
import { assetStatusIconsMap, assetStatusMap } from 'constants/assetStatusMap';

import JSZip from 'jszip';
import axios from 'axios';
import { saveAs } from 'file-saver';
import ProgressModal from './ProgressModal';
import { EventRegister } from 'utils/customEvent';

const videoFileTypes = ['video/mp4', 'video/quicktime', 'video/x-matroska'];

const Video = chakra('video');

const AssetPilotActions = ({
  asset,
  missionId,
  type = 0,
}: {
  asset: MissionAsset;
  missionId: string;
  type?: 0 | 1;
}) => {
  const toast = useToast();
  const cancelRef = useRef(null);
  const queryCache = useQueryCache();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteSubmit, { isLoading }] = useMutation(
    async () => deleteAsset(missionId, asset.id, type),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: `Asset ${asset.fileName} deleted`,
          isClosable: true,
          duration: 9000,
        });
        queryCache.invalidateQueries(`fetch-missions-assets-${missionId}`);
      },
    },
  );

  return (
    <Flex alignItems="center" p={2} justifyContent="space-between">
      <Stack isInline alignItems="center">
        <Text>{assetStatusMap[asset.status as AssetStatus]}</Text>
        {assetStatusIconsMap[asset.status as AssetStatus]}
      </Stack>
      <IconButton
        aria-label="delete"
        variant="ghost"
        size="xs"
        isLoading={isLoading}
        onClick={onOpen}
        icon={<MdDelete color="red" size="18px" />}
      />
      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={isOpen}
        isCentered
        leastDestructiveRef={cancelRef}
        onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Asset
            </AlertDialogHeader>
            <AlertDialogBody whiteSpace="pre-wrap" flexWrap="wrap">
              Are you sure you want to delete
              <Text fontWeight="bold" flexWrap="wrap">
                {' '}
                {asset.fileName}.{asset.fileType.split('/')[1]}?
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                size="sm"
                variant="outline"
                ref={cancelRef}
                onClick={onClose}>
                CANCEL
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  deleteSubmit();
                  onClose();
                }}
                ml={3}>
                DELETE
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

const Asset = ({
  asset,
  missionId,
  isClient,
  type = 0,
}: {
  asset: MissionAsset;
  missionId: string;
  isClient: boolean;
  type?: 0 | 1;
}) => {
  const size = formatBytes(asset.fileSize);

  const fileName = `${asset.fileName}.${asset.fileType.split('/')[1]}`;

  const renderImage = () => {
    const isIconImag =
      asset.fileType.endsWith('dng') || asset.fileType.endsWith('cr3');
    return (
      <>
        {isIconImag ? (
          <MdInsertDriveFile fill={'#373737'} size="xs" />
        ) : (
          <Image
            w="100%"
            h="100%"
            bg="#afafaf"
            objectFit="contain"
            src={asset?.signedUrl}
            fallbackSrc={placeholder}
          />
        )}
      </>
    );
  };

  const handleFileDownload = async () => {
    try {
      const response = await fetch(asset.signedUrl, {
        headers: {
          Accept: 'application/octet-stream',
          'Content-Type': 'application/octet-stream',
          'Access-Control-Allow-Origin': '*',
          'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const blobURL = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobURL;
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      w="200px"
      h="100%"
      maxH="240px"
      bg="#fff"
      shadow="sm"
      borderWidth="1px"
      borderColor="#afafaf">
      <Center w="100%" h="125px" position="relative">
        {videoFileTypes.includes(asset.fileType) ? (
          <Video
            w="100%"
            h="100%"
            bg="#afafaf"
            objectFit="contain"
            src={asset?.signedUrl}
            controls
          />
        ) : (
          renderImage()
        )}
      </Center>
      <Flex
        direction="column"
        flex={1}
        pt={1}
        fontWeight="700"
        fontSize="xs"
        color="textColor">
        <Text px={2} py={1} noOfLines={1}>
          {fileName}
        </Text>
        <Flex
          px={2}
          py={1}
          bg="#f1f1f1"
          alignItems="center"
          justifyContent="space-between">
          <Text>{size}</Text>
          <Text>{format(new Date(asset.createdAt || ''), 'dd MMMM yyyy')}</Text>
        </Flex>
        {isClient ? (
          <Center py={2}>
            <Button
              size="xs"
              variant="ghost"
              onClick={handleFileDownload}
              leftIcon={<BiDownArrowCircle size="18px" />}>
              DOWNLOAD
            </Button>
          </Center>
        ) : (
          <AssetPilotActions asset={asset} missionId={missionId} type={type} />
        )}
      </Flex>
    </Box>
  );
};

const MissionAssetsView = ({
  containerProps = {},
  ...props
}: MissionAssetsGeneratedProps) => {
  const toast = useToast();
  const sessionStore = useStore(SessionStore);

  const progressModalRef = useRef<any>(null);

  const isClient =
    sessionStore?.session?.user.accountType === AccountTypes.client;

  const [isLoading, setIsLoading] = useState(false);

  async function downloadFilesAsZip(assets: MissionAsset[]): Promise<void> {
    if (assets.length === 0) {
      return;
    }

    progressModalRef?.current?.onOpen?.();

    setIsLoading(true);

    try {
      const zip = new JSZip();

      const urlPromises = assets.map(async (asset, index) => {
        const response = await axios.get(asset.signedUrl, {
          responseType: 'blob',
          headers: {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/octet-stream',
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          },
          onDownloadProgress: (progressEvent) => {
            EventRegister.emit(`progress-${asset.id}`, {
              progress: progressEvent,
            });
          },
        });

        // Extract file extension from the signed URL.
        const fileExtension = asset.signedUrl.split('.').pop()?.split('?')[0];

        // Create a Blob with the appropriate content type based on the file extension.
        const contentType = fileExtension ? `.${fileExtension}` : '';
        const blob = new Blob([response.data], { type: contentType });

        // Add the file to the zip archive.
        zip.file(`${asset.fileName}${contentType}`, blob);
      });

      await Promise.all(urlPromises);

      const content = await zip.generateAsync({ type: 'blob' });

      saveAs(content, `mission-${props.missionId}-assets.zip`);

      setIsLoading(false);

      progressModalRef?.current?.onClose?.();
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error',
        isClosable: true,
        duration: 4000,
        description: 'something went wrong while trying to download all files',
      });

      setIsLoading(false);

      progressModalRef?.current?.onClose?.();
    }
  }

  if (props.isLoading)
    return (
      <Center>
        <Spinner />
      </Center>
    );

  if (props.assets?.length === 0) {
    const message = isClient
      ? 'Assets will be uploaded soon'
      : 'No assets uploaded yet';

    return (
      <Center p={4}>
        <Text>{message}</Text>
      </Center>
    );
  }

  return (
    <Flex p={4} flex={1} direction="column" bg="#F2F2F2" {...containerProps}>
      <Flex
        mt="5px"
        px={5}
        justifyContent="space-between"
        alignItems="center"
        bg="grey.200">
        <Text fontSize="lg" fontWeight="bold" color="primaryColor">
          {props.package}
        </Text>
        {isClient && (
          <Button
            isLoading={isLoading}
            size="sm"
            onClick={() => downloadFilesAsZip(props.assets)}>
            Download All
          </Button>
        )}
      </Flex>
      <SimpleGrid mt={4} spacing="10px" columns={[1, 2, 3, 5]}>
        {props.assets.map((asset, index) => (
          <Asset
            key={`${asset.id}-${index}`}
            asset={asset}
            missionId={props.missionId}
            isClient={isClient}
            type={props.type}
          />
        ))}
      </SimpleGrid>

      <ProgressModal ref={progressModalRef} assets={props.assets} />
    </Flex>
  );
};

export default MissionAssetsView;
