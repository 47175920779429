import { differenceInDays, isToday, isTomorrow } from 'date-fns';

export function isTodayOrTomorrow(date: Date): boolean {
  return isToday(date) || isTomorrow(date);
}

export function isDifferenceGreaterThanTwoDays(date1: Date, date2: Date) {
  const distanceInDays = differenceInDays(date2, date1);

  return Math.abs(distanceInDays) >= 2;
}
