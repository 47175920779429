import React, { FC } from 'react';

import { CircularProgress, Flex, Text, Tooltip } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';
import { useResponsive } from 'utils/hooks';

import { MotionLinkProps } from './MotionLink.props';

const MotionFlex = motion.custom(Flex);

const MotionLinkView: FC<MotionLinkProps> = (props) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { isMobile } = useResponsive();

  return (
    <>
      {props.isLoading ? (
        <Flex
          position="relative"
          bg="tertiaryColor"
          cursor="not-allowed"
          py={[4, 5]}
          pl="50px">
          <Flex opacity={0.2} color="secondaryColor">
            {props.icon}
          </Flex>
          <Text ml={2} opacity={0.2}>
            {props.name}
          </Text>
          {props.isLoading && (
            <CircularProgress
              isIndeterminate
              size="15px"
              position="absolute"
              right={4}
              color="brand.500"
            />
          )}
        </Flex>
      ) : props.isDisabled ? (
        <Tooltip
          p={2}
          hasArrow
          aria-label="tooltip"
          label="Premium Feature"
          fontSize="xs"
          fontWeight="600">
          <Flex bg="tertiaryColor" cursor="not-allowed" py={[4, 5]} pl="50px">
            <Flex opacity={0.2} color="secondaryColor">
              {props.icon}
            </Flex>
            <Text ml={2} opacity={0.2}>
              {props.name}
            </Text>
          </Flex>
        </Tooltip>
      ) : (
        <MotionFlex
          py={[4, 5]}
          pl="50px"
          cursor="pointer"
          onClick={() => {
            if (props.onClick) {
              props.onClick();
            } else if (props.link === 'https://help.droneadair.com/') {
              window.open('https://help.droneadair.com/');
            } else if (props.link?.includes('https')) {
              window.open(props.link, '_blank');
            } else {
              props.link && push(props.link);
            }

            if (isMobile && props.onToggle) {
              props.onToggle();
            }
          }}
          alignItems="center"
          bg={pathname === props.link ? 'primaryColor' : 'tertiaryColor'}
          color={pathname === props.link ? '#fff' : 'primaryColor'}
          _hover={{ bg: 'primaryColor', color: '#fff' }}
          {...props.buttonProps}>
          <Flex color="secondaryColor">{props.icon}</Flex>
          <Text ml={2} fontSize="xs">
            {props.name}
          </Text>
          {pathname === props.link ? null : props.renderBellIcon?.()}
        </MotionFlex>
      )}
    </>
  );
};

export default MotionLinkView;
