import React, { useEffect, useState } from 'react';

import { Button, IconButton } from '@chakra-ui/button';
import { Select } from '@chakra-ui/select';
import { useToast } from '@chakra-ui/toast';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Checkbox } from '@chakra-ui/checkbox';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { useMediaQuery } from '@chakra-ui/media-query';

import { set } from 'date-fns';
import { Form, Formik } from 'formik';

import { useStore } from 'effector-react';
import { SessionStore } from 'effector/session/store';

import { MissionStatuses } from 'types/mission';

import {
  MdFirstPage,
  MdLastPage,
  MdChevronLeft,
  MdChevronRight,
  MdClose,
} from 'react-icons/md';
import { SiGooglemaps } from 'react-icons/si';
import { BsFillPeopleFill } from 'react-icons/bs';
import { IoIosDocument, IoMdAddCircleOutline } from 'react-icons/io';
import { AiOutlinePlaySquare, AiTwotoneCalendar } from 'react-icons/ai';

import { MissionSideBar } from '../MissionSideBar';
import { AddDayDate } from 'components/primitives/AddDayDate';
import { TitleWithIcon } from 'components/primitives/TitleWithIcon';
import { CustomCalendar } from 'components/primitives/Calendar/Calendar.style';
import { ResposiveContainerWithSidebar } from 'routes/client/Dashboard/Dashboard.style';

import Icon from '@chakra-ui/icon';
import FormikInput from 'components/primitives/FormikInput';
import FormikTextAreaView from 'components/primitives/FormikTextArea';
import FormikDatePicker from 'components/primitives/FormikDatePicker';

import {
  FormValues,
  setTabSelected,
  setFormValues,
} from 'routes/client/Dashboard/Dashboard.utils';

import {
  MissionDetailsSchema,
  getDefaultFormDetails,
} from '../MissionDetailsPanel/MissionDetailsPanel.utils';

import { MissionDetailsPanelProps } from './MissionDetailsPanel.props';
import { MobileMissionCreationFooter } from '../MobileMissionCreationFooter';

const MissionDetailsPanelView = (
  props: MissionDetailsPanelProps,
): JSX.Element => {
  const toast = useToast();
  const formValues = useStore(FormValues);
  const uid = SessionStore.getState()?.session?.user.id;
  const [isSmall] = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    window.scrollTo({
      top: 10,
    });
  }, []);

  const [isTouched, setIsTouched] = useState(false);
  const handleBlur = () => {
    return setIsTouched(true);
  };

  return (
    <ResposiveContainerWithSidebar w="100%" justifyContent="space-evenly">
      <Flex
        w={2 / 3}
        mb="20px"
        mr={[0, '20px', '20px', '20px', 0]}
        p={['30px', '30px 50px', '30px 50px', '30px 50px']}
        background="#fff"
        borderRadius="10px"
        border="1px"
        borderColor="#ccc"
        flexDirection="column">
        <Formik
          initialValues={getDefaultFormDetails(formValues)}
          enableReinitialize
          validateOnMount
          validationSchema={MissionDetailsSchema}
          onSubmit={(values) => {
            let contact = null;
            let meetupTime: any[] = [];
            let eventStart = values.eventStart;
            let dueDate = values.dueDate;

            if (values.liveEvent) {
              eventStart = values.liveDay;
              dueDate = values.liveDay;

              eventStart = set(new Date(eventStart), {
                hours:
                  (values.liveStartMeridian === 'am'
                    ? +values.liveStartHour
                    : +values.liveStartHour + 12) || 0,
              }).toISOString();

              dueDate = set(new Date(dueDate), {
                hours:
                  (values.liveEndMeridian === 'am'
                    ? +values.liveEndHour
                    : +values.liveEndHour + 12) || 0,
              }).toISOString();

              if (values.onsite) {
                contact = {
                  name: values.contactName,
                  phone: values.contactPhoneNumber,
                  email: values.contactEmail,
                };
              }
            }

            if (!values.liveEvent && values.onsite) {
              meetupTime = [
                set(new Date(values.liveDay1), {
                  hours:
                    values.liveStartMeridian1 === 'am'
                      ? +values.liveStartHour1
                      : +values.liveStartHour1 + 12,
                }).toISOString(),
                set(new Date(values.liveDay2), {
                  hours:
                    values.liveStartMeridian2 === 'am'
                      ? +values.liveStartHour2
                      : +values.liveStartHour2 + 12,
                }).toISOString(),
              ];

              if (values.addDay) {
                meetupTime = [
                  ...meetupTime,
                  set(new Date(values.liveDay3), {
                    hours:
                      values.liveStartMeridian3 === 'am'
                        ? +values.liveStartHour3
                        : +values.liveStartHour3 + 12,
                  }).toISOString(),
                ];
              }

              contact = {
                name: values.contactName,
                phone: values.contactPhoneNumber,
                email: values.contactEmail,
              };
            }

            setFormValues({
              ...formValues,
              ...values,
              eventStart,
              dueDate,
              contact,
              meetupTime,
            });

            props.missionCheckoutHandler({
              city: formValues?.city || '',
              state: formValues?.state || '',
              gps: formValues?.gps,
              location: formValues?.location,
              package: formValues?.package,
              missionIndustry: formValues?.missionIndustry,
              status: MissionStatuses.FOR_PAYMENT,
              eventStart,
              dueDate,
              client: uid,
              rush: values?.rush,
              restricted: formValues?.restricted,
              liveEvent: values?.liveEvent,
              onsite: values?.onsite,
              notes: values?.notes,
              meetupTime,
              contact,
              // hard-coding some values for now
              additionalServices: formValues?.additionalServices,
              pilot: null,
              saveCard: true,
              locationDetail: formValues?.locationDetail,
              model: 'uber',
            });
            props.clientSecretRef.current = '';
            setTabSelected(2);
          }}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Text w="100%" fontSize="sm" mb="10px" ml="-15px">
                  Select the date, details for your request, or add additional
                  details for the job.
                </Text>
                <Flex w="100%" flexDir="column" mb="20px">
                  <TitleWithIcon
                    text="Is this a live event?"
                    TextIcon={<AiOutlinePlaySquare />}
                  />
                  <RadioGroup
                    display="flex"
                    colorScheme="brand"
                    defaultValue={values.liveEvent ? '1' : '0'}
                    onChange={(value) =>
                      setFieldValue('liveEvent', value == 1)
                    }>
                    <Radio value="1" mr="20px">
                      <Text fontSize="xs">Yes</Text>
                    </Radio>
                    <Radio value="0">
                      <Text fontSize="xs">No</Text>
                    </Radio>
                  </RadioGroup>
                </Flex>
                <Flex w="100%" flexDir="column" mb="20px">
                  <TitleWithIcon
                    text="Do you need to be on the site?"
                    TextIcon={<BsFillPeopleFill />}
                  />
                  <RadioGroup
                    display="flex"
                    colorScheme="brand"
                    defaultValue={values.onsite ? '1' : '0'}
                    onChange={(value) => setFieldValue('onsite', value == 1)}>
                    <Radio value="1" mr="20px">
                      <Text fontSize="xs">Yes</Text>
                    </Radio>
                    <Radio value="0">
                      <Text fontSize="xs">No</Text>
                    </Radio>
                  </RadioGroup>
                </Flex>
                <TitleWithIcon text="Date" TextIcon={<AiTwotoneCalendar />} />
                {values.liveEvent && (
                  <>
                    <Text w="100%" fontSize="sm" mb="10px">
                      Select the date, time and duration of the event
                    </Text>
                    <Flex flexDirection="column">
                      <Flex
                        flexDirection="column"
                        borderColor="red"
                        borderWidth="1px"
                        borderRadius="5px"
                        mb="10px"
                        p="15px">
                        <Flex w="120px" mb="10px">
                          <FormikDatePicker
                            name="liveDay"
                            label="Day"
                            disablePastDates={true}
                          />
                        </Flex>
                        <Flex>
                          <Flex mr="15px">
                            <Flex flexDirection="column" mr="10px">
                              <Text fontSize="xs" fontWeight="700" mb="10px">
                                Start Hour
                              </Text>
                              <Select
                                fontSize="xs"
                                size="md"
                                mr="10px"
                                onChange={(e) =>
                                  setFieldValue('liveStartHour', e.target.value)
                                }>
                                {[...Array(12)].map((n, i) => (
                                  <option
                                    value={i + 1}
                                    key={`liveStartHour-${i + 1}`}>
                                    {String(i + 1).length === 1
                                      ? `0${i + 1}`
                                      : i + 1}
                                  </option>
                                ))}
                              </Select>
                            </Flex>
                            <RadioGroup
                              mt="25px"
                              display="flex"
                              defaultValue="0"
                              onChange={(value) =>
                                setFieldValue('liveStartMeridian', value)
                              }>
                              <Radio value="am" mr="20px">
                                <Text fontSize="xs">AM</Text>
                              </Radio>
                              <Radio value="pm">
                                <Text fontSize="xs">PM</Text>
                              </Radio>
                            </RadioGroup>
                          </Flex>
                          <Flex>
                            <Flex flexDirection="column" mr="10px">
                              <Text fontSize="xs" fontWeight="700" mb="10px">
                                End Hour
                              </Text>
                              <Select
                                fontSize="xs"
                                size="md"
                                mr="10px"
                                onChange={(e) =>
                                  setFieldValue('liveEndHour', e.target.value)
                                }>
                                {[...Array(12)].map((n, i) => (
                                  <option
                                    value={i + 1}
                                    key={`liveEndHour-${i + 1}`}>
                                    {String(i + 1).length === 1
                                      ? `0${i + 1}`
                                      : i + 1}
                                  </option>
                                ))}
                              </Select>
                            </Flex>
                            <RadioGroup
                              mt="25px"
                              display="flex"
                              defaultValue="0"
                              onChange={(value) =>
                                setFieldValue('liveEndMeridian', value)
                              }>
                              <Radio value="am" mr="20px">
                                <Text fontSize="xs">AM</Text>
                              </Radio>
                              <Radio value="pm">
                                <Text fontSize="xs">PM</Text>
                              </Radio>
                            </RadioGroup>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </>
                )}
                {!values.liveEvent && !values.onsite && (
                  <Text w="100%" fontSize="sm" mb="10px">
                    Select either the date range or specific date that you need
                    the mission completed.
                  </Text>
                )}
                {!values.liveEvent && (
                  <Flex w="100%" alignItems="flex-start" mb="15px">
                    <Checkbox
                      size="md"
                      display="flex"
                      alignItems="flex-start"
                      colorScheme="brand"
                      onChange={(e) =>
                        setFieldValue('rush', !!e.target.checked)
                      }
                      defaultIsChecked={values.rush}>
                      <Text fontSize="sm">
                        I need the mission to be done in the next 48 hours
                      </Text>
                    </Checkbox>
                  </Flex>
                )}
                {!values.liveEvent && !values.rush && (
                  <div>
                    <Flex onBlur={handleBlur} w="100%" mb="15px">
                      <CustomCalendar
                        onChange={(dates: Date | Date[]) => {
                          if (!Array.isArray(dates)) {
                            dates = [dates];
                          }
                          /**
                           * If there is only one date, dueDate = eventStart
                           */
                          const [eventStart, dueDate = eventStart] = dates;

                          setFieldValue(
                            'eventStart',
                            new Date(eventStart).toISOString(),
                          );

                          setFieldValue(
                            'dueDate',
                            new Date(dueDate).toISOString(),
                          );
                          console.log(dates);
                        }}
                        allowPartialRange
                        minDate={new Date()}
                        selectRange={true}
                        returnValue="range"
                        prevLabel={
                          <MdChevronLeft
                            size="20px"
                            style={{ marginLeft: 4 }}
                          />
                        }
                        nextLabel={
                          <MdChevronRight
                            size="20px"
                            style={{ marginRight: 4 }}
                          />
                        }
                        next2Label={<MdLastPage size="20px" />}
                        prev2Label={<MdFirstPage size="20px" />}
                      />
                    </Flex>
                    {errors && isTouched && (
                      <Text color="red.500" w="100%" fontSize="sm" mb="10px">
                        {errors?.dueDate}
                      </Text>
                    )}
                  </div>
                )}
                {!values.liveEvent && values.onsite && (
                  <>
                    <Text w="100%" fontSize="sm" mb="10px">
                      Select up to 3 days and the time when you will be on site
                      to meet the pilot, add them in order of preference and
                      enter at least 2 options.
                    </Text>
                    <AddDayDate
                      name="liveDay1"
                      onChangeHour={(e) =>
                        setFieldValue('liveStartHour1', e.target.value)
                      }
                      onChangeMeridian={(value) =>
                        setFieldValue('liveStartMeridian1', value)
                      }
                    />
                    <AddDayDate
                      name="liveDay2"
                      onChangeHour={(e) =>
                        setFieldValue('liveStartHour2', e.target.value)
                      }
                      onChangeMeridian={(value) =>
                        setFieldValue('liveStartMeridian2', value)
                      }
                    />
                    {values.addDay ? (
                      <Box position="relative">
                        <AddDayDate
                          name="liveDay3"
                          onChangeHour={(e) =>
                            setFieldValue('liveStartHour3', e.target.value)
                          }
                          onChangeMeridian={(value) =>
                            setFieldValue('liveStartMeridian3', value)
                          }
                        />
                        <IconButton
                          aria-label="close-btn"
                          icon={<MdClose />}
                          size="xs"
                          position="absolute"
                          right={2}
                          top={2}
                          onClick={() => {
                            setFieldValue('addDay', false);
                          }}
                        />
                      </Box>
                    ) : (
                      <Flex w="100%" mb="15px">
                        <Button
                          leftIcon={
                            <Icon as={IoMdAddCircleOutline} w="18px" h="18px" />
                          }
                          variant="solid"
                          w="100%"
                          onClick={(_) => {
                            setFieldValue('addDay', true);
                            setFieldValue('onsite', true);
                          }}
                          fontSize="xs">
                          ADD ANOTHER DAY
                        </Button>
                      </Flex>
                    )}
                  </>
                )}
                {values.onsite && (
                  <Flex flexDirection="column">
                    <TitleWithIcon
                      text="OnSite Contact Information"
                      TextIcon={<IoIosDocument />}
                    />
                    <Flex flexDirection="column">
                      <Text fontSize="xs" fontWeight="700">
                        Name
                      </Text>
                      <FormikInput
                        name="contactName"
                        placeholder=""
                        w="100%"
                        size="md"
                        mb="10px"
                        fontSize="xs"
                        defaultValue={values.contactName}
                        type="text"
                      />
                      <Text fontSize="xs" fontWeight="700">
                        Phone Number
                      </Text>
                      <FormikInput
                        name="contactPhoneNumber"
                        placeholder=""
                        w="100%"
                        size="md"
                        mb="10px"
                        fontSize="xs"
                        defaultValue={values.contactPhoneNumber}
                        type="number"
                      />
                      <Text fontSize="xs" fontWeight="700">
                        Email
                      </Text>
                      <FormikInput
                        name="contactEmail"
                        placeholder=""
                        w="100%"
                        size="md"
                        mb="10px"
                        fontSize="xs"
                        defaultValue={values.contactEmail}
                        type="email"
                      />
                    </Flex>
                  </Flex>
                )}
                <TitleWithIcon
                  text="Mission Notes"
                  TextIcon={
                    <SiGooglemaps size="10px" height="10px" width="10px" />
                  }
                />
                <FormikTextAreaView
                  name="notes"
                  w="100%"
                  h="150px"
                  placeholder="(ie. Specific site directions, parking information, other instructions)"
                  fontSize="xs"
                  mb="20px"
                  defaultValue={values.notes}
                />
                <Checkbox
                  w="100%"
                  mb="20px"
                  size="md"
                  display="flex"
                  alignItems="flex-start"
                  colorScheme="brand"
                  onChange={(e) =>
                    setFieldValue('acknowledgeWeather', !!e.target.checked)
                  }
                  defaultIsChecked={values.acknowledgeWeather}>
                  <Text fontSize="xs">
                    I acknowledge that inclement weather may occur that may
                    cause rescheduling or postponement.
                  </Text>
                </Checkbox>
                <Flex justifyContent="flex-end" w="100%">
                  {isSmall ? (
                    <MobileMissionCreationFooter>
                      <Flex>
                        <Button
                          w="100px"
                          mr="10px"
                          fontSize="xs"
                          variant="outline"
                          onClick={() => setTabSelected(0)}>
                          GO BACK
                        </Button>
                        <Button type="submit" mx="5px" fontSize="xs" w="110px">
                          CHECKOUT
                        </Button>
                      </Flex>
                    </MobileMissionCreationFooter>
                  ) : (
                    <Flex textAlign="right">
                      <Button
                        w="100px"
                        fontSize="xs"
                        variant="outline"
                        onClick={() => setTabSelected(0)}>
                        GO BACK
                      </Button>
                      <Button
                        type="submit"
                        mx="5px"
                        fontSize="xs"
                        w="110px"
                        isDisabled={!values.acknowledgeWeather}>
                        CHECKOUT
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Flex>
      <MissionSideBar packages={props.packages} />
    </ResposiveContainerWithSidebar>
  );
};

export default MissionDetailsPanelView;
