import React, { FC, useRef } from 'react';

import {
  Text,
  Modal,
  Button,
  Textarea,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';

import { selectors } from 'effector/session';

import { AccountTypes } from 'types/accountTypes';
import { CancelMissionModalGeneratedProps } from './CancelMissionModal.props';

const CancelMissionModalView: FC<CancelMissionModalGeneratedProps> = ({
  isOpen,
  onClose,
  isLoading,
  handleSubmit,
}) => {
  const accountType = selectors.useAccountType();
  const isClient = accountType === AccountTypes.client;

  const valueRef = useRef<string>('');

  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="500" textAlign="center" mx="20px">
          Are you sure you want to request to cancel this mission?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="18px" textAlign="center">
          <Text fontSize="14px" mx="20px">
            Please select fill the your reasons in the field below. Your{' '}
            {isClient ? 'pilot' : 'client'} will get a notification request of
            your response.
          </Text>

          <Textarea
            mt="20px"
            placeholder="Put your cancellation request reason here"
            onChange={(e) => {
              valueRef.current = e.target.value;
            }}
          />
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            variant="outline"
            mr={2}
            onClick={onClose}
            isDisabled={isLoading}>
            GO BACK
          </Button>
          <Button
            onClick={() => handleSubmit(valueRef.current)}
            isLoading={isLoading}>
            SEND REQUEST
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelMissionModalView;
