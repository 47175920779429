import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';

import withStorage from 'effector-storage/sync';

import { logoutEvent } from 'effector/session/events';

const setCmsEvent = createEvent<string>();
const resetEvent = createEvent();
const saveEmail = createEvent<string>();

const createCmsStore = withStorage(createStore, createEvent);

const createEmailStore = withStorage(createStore, createEvent);

const $email = createEmailStore('', { key: 'email-store' })
  .on(saveEmail, (_, email) => email)
  .reset(resetEvent)
  .reset(logoutEvent);

const $cmspath = createCmsStore('', { key: 'cms-store' })
  .on(setCmsEvent, (_, path) => path)
  .reset(resetEvent)
  .reset(logoutEvent);

const useCms = () => useStore($cmspath);
const useSavedEmail = () => useStore($email);

export const cms = {
  useCms,
  setCmsEvent,
  resetEvent,
  saveEmail,
  useSavedEmail,
};
