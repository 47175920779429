import React, { createRef, FC, useEffect, useState } from 'react';

import {
  Box,
  Tag,
  Text,
  Flex,
  Image,
  chakra,
  Button,
  Center,
  Checkbox,
  useToast,
  TagLabel,
  SimpleGrid,
  TagRightIcon,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

import { useDropzone } from 'react-dropzone';

import { useCropFilesQueue } from 'utils/hooks';

import { uploadAsset } from 'api/mission';
import { useMutation, useQueryCache } from 'react-query';

import placeholder from 'res/placeholder.png';

import { formatBytes } from 'utils/formatBytes';
import { formatElapsedTime } from 'utils/formatEllapsedTime';

import { Prompt } from 'react-router-dom';

import { Cropper } from '../PreviousWorkSection/Cropper';

import {
  AssetProps,
  ImageUploadProps,
  UploadAssetsProps,
  MissionAssetsUploadGeneratedProps,
} from './MissionAssetsUpload.props';

const sx = {
  '.chakra-checkbox__control[data-checked]': {
    background: '#6728BB',
    borderColor: '#6728BB',
    color: '#fff',
  },
  '.chakra-checkbox__control[data-focus]': {
    boxShadow: 'none',
  },
};

const acceptableVideoTypes = [
  'video/mp4',
  'video/quicktime',
  'video/x-matroska',
];

const acceptableFiles = ['image/*', '.CR3', '.DNG', ...acceptableVideoTypes];

const Video = chakra('video');

const uploadedRef = createRef<string[]>();

const ImageUploadZone: FC<ImageUploadProps> = ({
  files,
  setFiles,
  allowCropping,
}) => {
  const {
    cropperRef,
    getSelectedFiles,
    onCroppedSuccess,
  } = useCropFilesQueue(files, (_files) =>
    setFiles((_prev) => [..._prev, ..._files]),
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptableFiles,
    onDrop: (files) => {
      const videoFiles = files.filter((file) =>
        acceptableVideoTypes.includes(file.type),
      );

      if (videoFiles.length) {
        setFiles((prev) => [...prev, ...videoFiles]);
      }

      const imageFiles = files.filter((file) => /^image\/.+/.test(file.type));

      if (allowCropping) {
        getSelectedFiles(imageFiles);
      } else {
        files = files.map((f) => {
          const isCR3File = f.name.toLowerCase().endsWith('.cr3');
          const isDNGFile = f.name.toLowerCase().endsWith('.dng');

          let customType = f.type;

          if (isCR3File) {
            customType = 'image/x-canon-cr3';
          } else if (isDNGFile) {
            customType = 'image/x-adobe-dng';
          }

          return new File([f], f.name, { type: customType }) as typeof f;
        });

        setFiles((prev) => [...prev, ...files]);
      }
    },
  });

  return (
    <>
      <Flex
        p={5}
        flex={1}
        bg="#6728BB20"
        cursor="pointer"
        userSelect="none"
        borderWidth="2px"
        borderRadius="5px"
        borderColor="brand.500"
        borderStyle="dashed"
        justifyContent="center"
        direction="column"
        {...getRootProps()}>
        <Text
          textAlign="center"
          color="brand.500"
          fontFamily="Monument Extended">
          Drag and Drop your files here
        </Text>
        <Text
          my={1}
          textAlign="center"
          color="brand.500"
          fontFamily="Monument Extended">
          or
        </Text>
        <Text
          textAlign="center"
          color="brand.500"
          fontFamily="Monument Extended">
          Select Files
        </Text>
        <input {...getInputProps()} />
      </Flex>
      <Cropper ref={cropperRef} onSuccess={onCroppedSuccess} />
    </>
  );
};

const Asset: FC<AssetProps> = ({
  asset,
  uploaded,
  type = 0,
  missionId,
  removeAsset,
  onUploadSuccess,
}) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const [speed, setSpeed] = useState(0);
  const [progress, setProgress] = useState(0);
  const [timeEllapsed, setTimeEllapsed] = useState(0);
  const [secondsRemaining, setSecondsRemaining] = useState(0);

  const [submit, { isLoading, isSuccess }] = useMutation(
    async () => {
      const started_at = new Date().getTime();

      const onProgress = (e: ProgressEvent<EventTarget>) => {
        const { total, loaded } = e;

        const progress = Math.round((loaded / total) * 100);

        const seconds_elapsed = (new Date().getTime() - started_at) / 1000;
        const bytes_per_second = seconds_elapsed ? loaded / seconds_elapsed : 0;
        const Kbytes_per_second = bytes_per_second / 1024;
        const remaining_bytes = total - loaded;
        const seconds_remaining = Number(remaining_bytes / bytes_per_second);

        setProgress(progress);
        setSpeed(Kbytes_per_second);
        setTimeEllapsed(seconds_elapsed);
        setSecondsRemaining(seconds_remaining);
      };

      return uploadAsset(asset, missionId, onProgress, type);
    },
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Asset uploaded succesfully',
          isClosable: true,
          duration: 9000,
        });

        onUploadSuccess(asset.name);

        queryCache.invalidateQueries(`fetch-missions-assets-${missionId}`);
      },
    },
  );

  const size = formatBytes(asset.size);
  const isAssetUploaded = isSuccess || uploaded.includes(asset.name);

  useEffect(() => {
    if (!isAssetUploaded) {
      return;
    }

    const timeoutId = setTimeout(() => {
      removeAsset(asset.name);
    }, 8000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isAssetUploaded]);

  useEffect(() => {
    if (uploadedRef.current?.includes(asset.name)) {
      return;
    } else {
      submit();

      (uploadedRef as any).current = [
        ...(uploadedRef.current || []),
        asset.name,
      ];
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isLoading) {
        e.preventDefault();
        e.returnValue =
          'Are you sure you want to leave? The upload is still in progress.';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isLoading]);

  return (
    <Box
      w="200px"
      h="100%"
      maxH="360px"
      bg="#fff"
      shadow="sm"
      borderWidth="1px"
      borderColor="#afafaf">
      <Center w="100%" h="125px" position="relative">
        {acceptableVideoTypes.includes(asset.type) ? (
          <Video
            w="100%"
            h="100%"
            bg="#afafaf"
            objectFit="contain"
            src={asset.preview}
            opacity={isLoading ? 0.2 : 1}
            controls
          />
        ) : (
          <Image
            w="100%"
            h="100%"
            bg="#afafaf"
            objectFit="contain"
            src={asset.preview}
            fallbackSrc={placeholder}
            opacity={isLoading ? 0.2 : 1}
          />
        )}
        {isLoading ? (
          <CircularProgress
            value={progress}
            color="buttonBgColor"
            position="absolute">
            <CircularProgressLabel fontWeight="bold">
              {progress}%
            </CircularProgressLabel>
          </CircularProgress>
        ) : null}
      </Center>
      <Flex
        direction="column"
        flex={1}
        pt={1}
        fontWeight="700"
        fontSize="xs"
        color="textColor">
        <Text px={2} py={1} noOfLines={1}>
          {asset.name}
        </Text>
        <Text px={2} py={1} bg="#f1f1f1">
          {size}
        </Text>
        <Box borderTop="1px solid #ccc" pt={2} px={2} spacing={0}>
          <Text>Upload Speed</Text>
          <Text>{speed.toFixed(2)} KB/s</Text>
        </Box>
        <Box px={2} spacing={0} my={1}>
          <Text>Remaining Time</Text>
          <Text>{formatElapsedTime(secondsRemaining)}</Text>
        </Box>
        <Box px={2} spacing={0} my={1}>
          <Text>Ellapsed Time</Text>
          <Text>{formatElapsedTime(timeEllapsed)}</Text>
        </Box>
        <Center py={2}>
          {isAssetUploaded ? (
            <Tag size="md" colorScheme="green">
              <TagLabel>Asset Uploaded</TagLabel>
              <TagRightIcon as={FaCheckCircle} />
            </Tag>
          ) : (
            <Button
              id={asset?.preview ? `upload-btn-${asset?.preview}` : undefined}
              variant="outline"
              size="xs"
              onClick={() => submit()}
              isLoading={isLoading}>
              Upload Asset
            </Button>
          )}
        </Center>
      </Flex>
      <Prompt
        when={isLoading}
        message="Are you sure you want to leave? The upload is still in progress."
      />
    </Box>
  );
};

const UploadAssets: FC<UploadAssetsProps> = ({
  missionId,
  type = 0,
  files = [],
  removeAsset,
}) => {
  const [uploaded, setUploaded] = useState<string[]>([]);

  return (
    <SimpleGrid mt={4} spacing="10px" columns={[1, 2, 3, 5]}>
      {files.map((asset, index) => {
        return (
          <Asset
            type={type}
            asset={asset}
            key={`${asset.name}-${index}`}
            missionId={missionId}
            removeAsset={removeAsset}
            uploaded={uploaded}
            onUploadSuccess={(name) =>
              setUploaded([...uploaded.filter((u) => u !== name), name])
            }
          />
        );
      })}
    </SimpleGrid>
  );
};

const MissionAssetsUploadView = (props: MissionAssetsUploadGeneratedProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(true);

  useEffect(() => {
    // clear asset uploaded ref on un-mount
    return () => {
      (uploadedRef as any).current = [];
    };
  }, []);

  const onRemoveAsset = (name: string) => {
    setFiles((prev) => prev.filter((file) => file.name !== name));
  };

  return (
    <Flex p={4} flex={1} direction="column" bg="#F2F2F2">
      {/* <Box mt={3} mb={5}>
        <Checkbox
          sx={sx}
          isChecked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}>
          <Flex gridGap="5px" flexWrap="wrap" align="flex-end">
            <Text fontWeight="bold">Allow Image Cropping</Text>
            <Text fontSize="12px" fontStyle="italic">
              (Uncheck if you directly want to upload your assets without
              cropping)
            </Text>
          </Flex>
        </Checkbox>
      </Box> */}
      <ImageUploadZone
        files={files}
        setFiles={setFiles}
        allowCropping={false}
      />
      <UploadAssets
        files={files}
        type={props.type}
        removeAsset={onRemoveAsset}
        missionId={props?.mission?.id?.toString() ?? ''}
      />
    </Flex>
  );
};

export default MissionAssetsUploadView;
