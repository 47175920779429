import { useQuery } from 'react-query';
import { getCustomMissions } from 'api/custom-mission';

export const useCustomMissions = () => {
  const queryKey = [`fetch-custom-missions-open`, true, 1];
  const queryFn = () =>
    getCustomMissions({
      page: 1,
      isClient: true,
      limit: 5,
    });

  const query = useQuery(queryKey, queryFn);

  return query;
};
