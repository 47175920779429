import { useEffect, useRef, useState } from 'react';

import * as session from 'effector/session';
import { getIsAccountActivatedStatus, getUserId } from 'effector/session/store';
import { getUser } from 'api/auth';
import { updateUserEvent } from 'effector/session/events';
import { useQueryCache } from 'react-query';
import { CMS } from 'types/cms';
import { getMyCMS } from 'api/cms';

const useAccountActivationStatus = () => {
  const limitRef = useRef(0);
  const queryCache = useQueryCache();

  const user = session.selectors.useUser();

  const status = !!user?.isActivated;

  const [isActivated, setIsActivated] = useState(status);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (limitRef.current >= 6) {
          return;
        }

        const saveUserId = getUserId();

        if (!saveUserId) {
          return;
        }

        const data = await getUser(saveUserId).catch(() => null);

        if (data && data.isActivated) {
          setIsActivated(true);
          queryCache.invalidateQueries([`fetch-client-missions`]);

          const isAccActivated = getIsAccountActivatedStatus();

          if (!isAccActivated) {
            try {
              const cmsData = await getMyCMS('DRAFT');

              if (cmsData && cmsData?.username) {
                const premiumWebsiteURL = `${process.env.REACT_APP_URL}/cms/${cmsData?.username}`;

                window.open(premiumWebsiteURL, '_blank');
              }
            } catch (error) {
              console.log(error);
            }
          }

          updateUserEvent(data);
        } else {
          await new Promise((res) => setTimeout(res, 5000));

          fetchUserData();
          limitRef.current = limitRef.current + 1;
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchUserData();
  }, []);

  return { isActivated };
};
export default useAccountActivationStatus;
