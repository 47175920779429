import React, { memo, useState } from 'react';

import { useToast } from '@chakra-ui/toast';

import { createEquipementItem } from 'api/cms';
import { useMutation, useQueryCache } from 'react-query';

import { Props } from './EquipmentInput.props';

import EquipmentInputView from './EquipmentInput.view';

const Container = memo<Props>((props) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const [text, setText] = useState('');

  const [create, { isLoading }] = useMutation(createEquipementItem, {
    onSuccess: () => {
      setText('');

      toast({
        status: 'success',
        title: 'Item Created',
        isClosable: true,
        duration: 2000,
      });

      queryCache.invalidateQueries(`fetch-my-cms`, {
        exact: true,
      });

      queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
        exact: true,
      });
    },
  });

  return (
    <EquipmentInputView
      {...props}
      text={text}
      onChange={setText}
      isLoading={isLoading}
      onCreate={() =>
        create({
          type: props.name,
          fileName: text,
        })
      }
    />
  );
});

export default Container;
