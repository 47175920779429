import React, { useEffect } from 'react';

import { CustomMissionProps } from './CustomMission.props';

import { markNotificationAsRead, useCustomMission } from 'utils/hooks';

import CustomMissionView from './CustomMission.view';
import { useMutation, useQueryCache } from 'react-query';
import { useToast } from '@chakra-ui/react';
import { deleteCustomMission } from 'api/custom-mission';

const Container = (props: CustomMissionProps) => {
  const missionId = props.match.params.id;

  const toast = useToast();
  const queryCache = useQueryCache();

  const { data, isLoading } = useCustomMission(missionId);

  const [onMissionDelete, { isLoading: isDeleting }] = useMutation(
    deleteCustomMission,
    {
      onSuccess: () => {
        toast({ title: 'Mission Deleted' });

        props.history.replace('/');

        queryCache.invalidateQueries([`fetch-custom-mission-${missionId}`]);

        queryCache.invalidateQueries([`fetch-custom-missions-open`]);
        queryCache.invalidateQueries([`fetch-custom-missions-open`]);
      },
    },
  );

  useEffect(() => {
    markNotificationAsRead(missionId, 1);
  }, []);

  return (
    <CustomMissionView
      mission={data}
      missionId={missionId}
      isLoading={isLoading}
      isDeleting={isDeleting}
      onMissionDelete={onMissionDelete}
    />
  );
};

export default Container;
