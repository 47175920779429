import { PixelCrop } from 'react-image-crop';

const TYPE = 'image/jpeg';
const IMAGE_QUALITY = 1;

export function getCroppedImg(
  sourceImage: HTMLImageElement,
  cropConfig: PixelCrop,
  name?: string,
): Promise<{
  fileUrl: string;
  file: File;
}> {
  const canvas = document.createElement('canvas');
  const scaleX = sourceImage.naturalWidth / sourceImage.width;
  const scaleY = sourceImage.naturalHeight / sourceImage.height;
  canvas.width = Math.floor(cropConfig.width * scaleX);
  canvas.height = Math.floor(cropConfig.height * scaleY);
  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.imageSmoothingQuality = 'high';
    ctx.imageSmoothingEnabled = true;
  }

  ctx?.save();

  ctx?.drawImage(
    sourceImage,
    cropConfig.x * scaleX,
    cropConfig.y * scaleY,
    cropConfig.width * scaleX,
    cropConfig.height * scaleY,
    0,
    0,
    cropConfig.width * scaleX,
    cropConfig.height * scaleY,
  );

  ctx?.restore();

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          return;
        }

        const fileUrl = window.URL.createObjectURL(blob);

        const file = new File([blob], name || '', {
          type: blob.type,
          lastModified: new Date().getMilliseconds(),
        });

        resolve({
          file,
          fileUrl,
        });
      },
      TYPE,
      IMAGE_QUALITY,
    );
  });
}
