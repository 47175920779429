import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Image,
  Text,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import alertCreateMission from 'res/skip-character.png';
import {
  RefType,
  UnableCreateMissionsModalGeneratedProps,
} from './UnableCreateMissionsModal.props';

const UnableCreateMissionsModalView = forwardRef<
  RefType,
  UnableCreateMissionsModalGeneratedProps
>((props, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { cmsUrl } = props;

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    [],
  );

  const onClickHandle = () => {
    let urlwebsite = '';
    if (!cmsUrl) {
      return toast({
        status: 'error',
        title: 'Error',
        description: 'No published site yet. Please, publish your website.',
        isClosable: true,
        duration: 9000,
      });
    } else {
      urlwebsite = `${window.location.origin}/cms/${cmsUrl}`;
    }
    onClose();
    window.open(urlwebsite);
  };
  return (
    <Modal isCentered size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex align="center" direction="column" p="30px">
            <Image maxH="150px" objectFit="contain" src={alertCreateMission} />
            <Text my="20px" textAlign="center" fontSize="22px">
              You are currently in a preview mode.
            </Text>
            <Button w="400px" mt="10px" variant="solid" onClick={onClickHandle}>
              GO TO THE PILOT WEBSITE
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export default UnableCreateMissionsModalView;
