import React, { FC, useState } from 'react';

import { Image, SimpleGrid } from '@chakra-ui/react';

import { motion } from 'framer-motion';

import { useCMS } from './Context';
import { MyWorkTabs } from './MyWorkTabs';

import { CLOUD_FRONT_URL } from 'constants/cloudFronUrl';

import './premium-website-font.css';
import { isMobile } from 'react-device-detect';

const MotionImage = motion.custom(Image);

export const AeroView: FC = () => {
  const { cms } = useCMS();

  const previousWork = cms?.previousWork || [];

  const [index, setIndex] = useState<0 | 1>(0);

  const myWork =
    previousWork.map((p) => ({
      ...p,
      src: `${CLOUD_FRONT_URL}/users/portfolio/${p?.user}/${p?.keyName}?format=jpeg&width=1200`,
    })) || [];

  const videos = myWork.filter((item) => 'url' in item);
  const photos = myWork.filter((item) => !('url' in item));

  const items = index === 0 ? photos : videos;

  return (
    <>
      <SimpleGrid
        alignSelf="center"
        minH="18vw"
        columns={items.length > 4 ? 4 : items.length}
        gap={0}
        row={2}>
        {items.map((item) => (
          <MotionImage
            key={item.src}
            src={item.src}
            alt="Gallery Image"
            objectFit={isMobile ? 'cover' : 'contain'}
            h={isMobile ? 48 : ''}
            initial={{
              opacity: 0,
              scale: 0.9,
            }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            exit={{
              opacity: 0,
              scale: 0.9,
            }}
            transition={{
              duration: 0.5,
            }}
            onClick={() => {
              if (!item?.url) {
                return;
              }

              const isHttp = item.url.includes('http');

              if (isHttp) {
                window.open(item.url, '_blank');
              } else {
                window.open(`https://${item.url}`, '_blank');
              }
            }}
          />
        ))}
      </SimpleGrid>

      <MyWorkTabs
        index={index}
        setIndex={setIndex}
        containerProps={{
          mt: '0',
          position: 'absolute',
          bottom: 0,
          alignSelf: 'center',
        }}
      />
    </>
  );
};
