import React, { useRef } from 'react';

import authApi, { googleLogin, googleSignUp } from 'api/auth';
import { useMutation } from 'react-query';
import { loginEvent, signupEvent } from 'effector/session/events';
import { fetchActionRangeAddress } from 'effector/session/effects';

import { useHistory } from 'react-router-dom';
import { AccountTypes } from 'types/accountTypes';

import { LoginProps } from './Login.props';

import LoginView from './Login.view';

const LoginContainer = (props: LoginProps): JSX.Element => {
  const { push, replace } = useHistory();

  const rememberMeRef = useRef<boolean>(false);

  const onLoginSuccess = (data: any) => {
    loginEvent({
      session: data,
      rememberMe: rememberMeRef.current,
    });

    const range = data?.user?.range;

    if (
      range &&
      Boolean(range?.coordinates) &&
      data?.user?.accountType === AccountTypes.pilot
    ) {
      fetchActionRangeAddress({
        lat: range.coordinates?.[1],
        lng: range.coordinates?.[0],
      });
    }

    const search = new URLSearchParams(props.location.search);
    const redirect = search.get('redirect');

    const state = props.location.state;

    if (redirect && !!state) {
      replace(redirect, state);

      return;
    }

    if (data.user.accountType === AccountTypes.pilot) {
      replace('/pilot/dashboard');
    } else {
      replace('/dashboard');
    }
  };

  const [login, { isLoading }] = useMutation(authApi.login, {
    onSuccess: onLoginSuccess,
  });

  const [googleAPILogin, { isLoading: isGoogleLoading }] = useMutation(
    googleLogin,
    {
      onSuccess: onLoginSuccess,
    },
  );

  const [googleAPISignup, { isLoading: isGoogleSignUpLoading }] = useMutation(
    googleSignUp,
    {
      onSuccess: (data: any) => {
        signupEvent(data);

        const search = new URLSearchParams(props.location.search);
        const redirect = search.get('redirect');

        const state = props.location.state;

        if (redirect) {
          replace(redirect, state);

          return;
        }

        const accountType = data?.user?.accountType;

        if (accountType === AccountTypes.pilot) {
          //history.push('/stripe-connect');

          push('/pilot/dashboard');
        } else {
          push('/dashboard');
        }

        history.go(0);
      },
    },
  );

  const onSignupPress = () => {
    push('/register');
  };

  const search = new URLSearchParams(props.history.location?.search);
  const denySignUp = Boolean(search.get('denySignUp'));

  const onCheckboxChange = (checked: boolean) => {
    rememberMeRef.current = checked;
  };

  return (
    <LoginView
      onSubmit={login}
      onCheckboxChange={onCheckboxChange}
      isLoading={isLoading || isGoogleLoading || isGoogleSignUpLoading}
      onSignupPress={onSignupPress}
      denySingUp={denySignUp}
      onGoogleLogin={googleAPILogin}
      onGoogleSignup={googleAPISignup}
    />
  );
};

export default LoginContainer;
