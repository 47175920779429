import React, { FC, useState } from 'react';

import logo from 'res/tutorial/rocket.png';

import {
  Box,
  Text,
  Stack,
  Flex,
  Image,
  Button,
  Center,
  Heading,
  Checkbox,
  useToast,
  useDisclosure,
  chakra,
} from '@chakra-ui/react';
import { MdAddCircle, MdEdit } from 'react-icons/md';

import { Form, Formik } from 'formik';

import FormikInput from 'components/primitives/FormikInput';
import AddSocialModal from 'components/modules/AddSocialModal';

import { updateCMS } from 'api/cms';
import { useMutation, useQueryCache } from 'react-query';

import { socialIconsMap } from 'utils/socialItemsMap';
import { animationConfig, MotionFlex } from './Motion';

import { CommonProps } from '../OnboardingModal.props';
import FormikTextEditorView from 'components/primitives/FormikTextEditor';
import { DaysSection } from 'components/modules/WebsiteForm/DaysSection';
import { AboutItem } from 'types/cms';

const Span = chakra.span;
const labelProps = {
  fontSize: '16px',
  color: '#707070',
  fontWeight: 'bold',
};

const URL_PREFIX = `${process.env.REACT_APP_URL}`;

const BusinessDetails: FC<CommonProps> = ({ cms, setIndex }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const queryKey = `fetch-cms-company-${cms?.username}`;

  const initialValues = {
    socials: cms?.socials || [],
    colorScheme: cms?.stylings?.colorScheme,
    companyName: cms?.about?.companyName || '',
    customUrl: `${URL_PREFIX}/cms/${cms?.username || ''}`,
    about: {
      alwaysAvailable: true,
      dayAvailability: cms?.about?.dayAvailability || [],
    },
    introduction: cms?.about?.introduction,
  };

  const toast = useToast();
  const queryCache = useQueryCache();

  const [update, { isLoading }] = useMutation(
    async (_payload: any) => {
      await updateCMS({
        companyName: cms?.about?.companyName,
        payload: {
          ..._payload,
          status: 'DRAFT',
        },
      });

      await updateCMS({
        companyName: cms?.about?.companyName,
        payload: {
          ..._payload,
          status: 'PUBLISH',
        },
      });
    },
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Business Details Updated',
          isClosable: true,
          duration: 5000,
        });

        queryCache.invalidateQueries(queryKey);
        setIndex(3);
      },
    },
  );

  return (
    <MotionFlex {...animationConfig} flex={1}>
      <Center gridGap="20px">
        <Image src={logo} maxH="180px" transform="rotateY(180deg)" />
        <Flex direction="column" gridGap="20px" w="40%">
          <Heading fontSize="22px">Business details</Heading>
          <Text whiteSpace="pre-line">
            We need this information to be able to help you set up your account
            the easiest way possible.{'\n'}
            <strong>Remember you can change this information later.</strong>
          </Text>
        </Flex>
      </Center>

      <Formik
        //enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          update({
            pilot: typeof cms?.pilot === 'string' ? cms?.pilot : cms?.pilot?.id,
            about: {
              alwaysAvailable: values?.about?.dayAvailability.length === 0,
              dayAvailability: values?.about?.dayAvailability,
            } as any,
            stylings: {
              ...cms?.stylings,
              colorScheme: values?.colorScheme,
            },
            socials: values?.socials,
            location: cms?.location,
            gps: {
              coordinates: cms?.gps?.coordinates,
            },
          });
        }}>
        {({ values, isValid, setFieldValue }) => {
          const isLinksPresent = values?.socials?.length > 0;

          return (
            <Form>
              <Flex px="50px" gridGap="20px" flex={1} direction="column">
                <Flex gridGap="40px" align="flex-start">
                  <FormikInput
                    name="companyName"
                    label="Company Name"
                    isRequired
                    isDisabled
                  />
                  <FormikInput
                    name="customUrl"
                    label="Custom URL"
                    isRequired
                    isDisabled
                  />
                </Flex>

                <Flex gridGap="40px" align="flex-start">
                  <Flex flex={1} direction="column">
                    <FormikInput
                      type="color"
                      name="colorScheme"
                      label="Select Your Website Color"
                      isRequired
                      p="4px"
                    />
                    <Flex direction="column" gridGap="10px">
                      <Flex mt={3} gridGap="20px" align="center">
                        <Text fontSize="14px" fontWeight="bold">
                          Social Media
                          <Span
                            ml={2}
                            color="red.500"
                            fontSize="14px"
                            fontWeight="bold">
                            *
                          </Span>{' '}
                        </Text>
                        {isLinksPresent && (
                          <Button
                            w="80px"
                            size="sm"
                            variant="ghost"
                            alignSelf="flex-end"
                            leftIcon={<MdEdit />}
                            onClick={onOpen}
                            _focus={{ outline: 'none' }}>
                            Edit
                          </Button>
                        )}
                      </Flex>
                      {isLinksPresent ? (
                        <Flex
                          mb={4}
                          bg="#F1F1F1"
                          py={4}
                          px={6}
                          borderRadius="5px">
                          <Stack
                            isInline
                            alignItems="center"
                            flexWrap="wrap"
                            spacing={4}>
                            {values.socials.map((social: any, index) => (
                              <Box
                                maxH="35px"
                                borderRadius="5px"
                                key={`social-${index}`}
                                boxShadow="0 0 10px 0 rgba(0, 0, 0, 0.15)">
                                {socialIconsMap(social.type as any)}
                              </Box>
                            ))}
                          </Stack>
                        </Flex>
                      ) : (
                        <Button
                          w="260px"
                          variant="ghost"
                          leftIcon={<MdAddCircle size="20px" />}
                          onClick={onOpen}>
                          Add Social Media Links
                        </Button>
                      )}

                      <AddSocialModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onSave={() => {
                          console.log;
                        }}
                      />
                    </Flex>
                  </Flex>

                  <Flex flex={1} direction="column">
                    <DaysSection hideUpdateButton={true} />
                  </Flex>
                </Flex>

                <Center gridGap="20px">
                  <Button
                    w="200px"
                    variant="outline"
                    isDisabled={isLoading}
                    onClick={() => setIndex(1)}>
                    GO BACK
                  </Button>
                  <Button
                    w="200px"
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!isValid}>
                    SAVE
                  </Button>
                </Center>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </MotionFlex>
  );
};

export default BusinessDetails;
