import React, { useRef, forwardRef, useImperativeHandle } from 'react';

import {
  Text,
  Flex,
  Modal,
  Stack,
  Button,
  ModalBody,
  Textarea,
  ModalOverlay,
  ModalContent,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';

import { reportMission } from 'api/custom-mission';
import { useMutation, useQueryCache } from 'react-query';

type RefType = {
  onOpen: () => void;
};

type ReportMissionProps = {
  customMissionId: string;
};

export const ReportModal = forwardRef<RefType, ReportMissionProps>(
  ({ customMissionId }, ref) => {
    const toast = useToast();
    const queryCache = useQueryCache();

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { isOpen, onOpen, onClose } = useDisclosure();

    useImperativeHandle(ref, () => ({ onOpen }), []);

    const [submit, { isLoading }] = useMutation(
      (_message: string) => reportMission(customMissionId, _message),
      {
        onSuccess: () => {
          queryCache.invalidateQueries(
            `fetch-custom-mission-${customMissionId}`,
          );

          onClose();

          toast({
            status: 'success',
            title: 'Success',
            description: 'Your report has been sent successfully',
            isClosable: true,
            duration: 5000,
          });
        },
      },
    );

    const onSend = () => {
      let message = '';

      const inputValue = inputRef.current?.value || '';

      if (inputValue.trim()?.length <= 0) {
        inputRef.current?.focus?.();

        return;
      } else {
        message = inputValue;
      }

      submit(message);
    };

    return (
      <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex p={6} flex={1} direction="column">
              <Text mb={4} fontSize="22px" fontWeight="bold" textAlign="center">
                Report Mission
              </Text>
              <Text mb={2} fontSize="14px" textAlign="center">
                Please enter the reason why you want to report this mission
                briefly.
              </Text>
              <Stack my={2}>
                <Textarea
                  ref={inputRef}
                  autoFocus
                  placeholder="Enter your message here..."
                />
              </Stack>
              <Stack my={4} justify="center" isInline>
                <Button
                  w="200px"
                  isDisabled={isLoading}
                  variant="outline"
                  onClick={onClose}>
                  CANCEL
                </Button>
                <Button isLoading={isLoading} w="200px" onClick={onSend}>
                  SEND
                </Button>
              </Stack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  },
);
