import React, { FC } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import * as session from 'effector/session';

import MissionAssets from '../MissionAssets';
import { MissionAssetsUpload } from '../MissionAssetsUpload';

import { Mission } from 'types/mission';
import { AccountTypes } from 'types/accountTypes';

const labelProps = {
  fontSize: '13px',
  fontWeight: 'bold',
  color: 'primaryColor',
};

const Assets: FC<{ mission: Mission }> = ({ mission }) => {
  const userType = session.selectors.useAccountType();
  const isPilot = userType === AccountTypes.pilot;

  return (
    <Flex p="20px" pb="100px" gridGap="15px" flex={1} direction="column">
      {isPilot && <MissionAssetsUpload mission={mission} />}

      <Text mt="10px" {...labelProps}>
        Uploaded Assets
      </Text>
      <MissionAssets
        missionId={String(mission.id).toString()}
        package={mission.package?.name ?? ''}
      />
    </Flex>
  );
};

export default Assets;
