import React, { FC } from 'react';

import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';

import MissionList from '../MissionList';
import MissionListBanner from '../MissionListBanner';
import TabPanelWithShadow from 'components/primitives/TabPanelWithShadow';

import { MissionTabsProps } from './MissionTabs.props';

const tabSpacing = [5, 8, 8];
const DEFAULT_TITLE = 'ACCEPTED MISSIONS';

const MissionTabsView: FC<MissionTabsProps> = (props) => {
  return (
    <>
      <Tabs mt="30px" isLazy variant="enclosed">
        <TabList>
          <Tab
            _focus={{ borderColor: 'none' }}
            _selected={{ backgroundColor: 'secondaryColor', color: 'white' }}
            fontSize="md"
            fontWeight="bold"
            px={tabSpacing}
            py="3">
            CURRENT MISSIONS
          </Tab>
        </TabList>
        <MissionListBanner
          highlightedTitle={DEFAULT_TITLE}
          unhighlightedTitle="LIST"
        />
        <TabPanels bg="white" border="0.5px solid #E2E8F0" borderRadius="20px">
          <TabPanelWithShadow boxShadow="none" p={0} m={0}>
            <MissionList />
          </TabPanelWithShadow>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default MissionTabsView;
