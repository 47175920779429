import React, { forwardRef, useImperativeHandle } from 'react';

import {
  Text,
  Flex,
  Image,
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import announcement from 'res/payment-success.png';

import { FreePlanModalProps, RefType } from './FreePlanModal.props';

const FreePlanModalView = forwardRef<RefType, FreePlanModalProps>(
  (props, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useImperativeHandle(
      ref,
      () => ({
        onOpen,
      }),
      [],
    );

    return (
      <Modal size="lg" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" align="center" p="50px 20px">
              <Image maxH="200px" objectFit="contain" src={announcement} />
              <Text
                mt="20px"
                textAlign="center"
                fontSize="22px"
                fontWeight="bold">
                Not Ready to Subscribe?
              </Text>
              <Text textAlign="center" fontSize="16px">
                No worries, here’s a Free 30-Day Trial on Us!
              </Text>

              <Button
                w="200px"
                mt="20px"
                onClick={() => {
                  onClose();
                  props.onProceed();
                }}>
                PROCEED
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  },
);

export default FreePlanModalView;
