import { useEffect, useRef } from 'react';

/**
 * The useTimeoutId function returns a ref object that holds the ID of a timeout, which can be used to
 * clear the timeout when the component unmounts.
 * @returns The `useTimeoutId` function returns a `timeoutIdRef` object.
 */
const useTimeoutId = () => {
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return timeoutIdRef;
};

export default useTimeoutId;
