import React, { FC, useEffect, useRef, useState } from 'react';

import {
  Box,
  Text,
  Flex,
  Stack,
  Button,
  Center,
  Heading,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { MdEdit, MdArrowBack, MdNotifications } from 'react-icons/md';

import { Form, Formik } from 'formik';

import { useHistory } from 'react-router';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { AnimatePresence, motion } from 'framer-motion';

import { updateUserData } from 'api/auth';
import { useMutation, useQueryCache } from 'react-query';

import * as session from 'effector/session';
import { updateUserEvent } from 'effector/session/events';

import { useIsMobile } from 'utils/hooks';

import { EventRegister } from 'utils/customEvent';

import { UserAvatar } from 'components/modules/UserAvatar';
import { DeleteAccountButton } from 'components/modules/DeleteAccountButton';

import PilotHeader from 'components/modules/PilotHeader';
import CmsCompanyNameInput from 'components/modules/CmsCompanyNameInput';

import FormikInputView from 'components/primitives/FormikInput';
import FormikDatePicker from 'components/primitives/FormikDatePicker';
import PlacesAutocompleteView from 'components/primitives/PlacesAutocomplete';

import { settingsValidationSchema } from './Settings.utils';
import { max } from 'date-fns';

const MotionButton = motion.custom(Button);

const animation = {
  initial: {
    opacity: 0,
    transition: { duration: 0.05 },
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.05 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.05 },
  },
};

export const UserDataForm: FC = () => {
  const toast = useToast();
  const history = useHistory();
  const queryCache = useQueryCache();
  const user = session.selectors.useUser();

  const isMobile = useIsMobile();

  const userAvatarRef = useRef<any>(null);

  const [isEditing, setIsEditing] = useState(false);

  const queryKey = `fetch-user-${user?.id}`;

  const [updateUser, { isLoading: isUpdating }] = useMutation(updateUserData);

  useEffect(() => {
    const listener = (event: any) => {
      setIsEditing(event?.detail?.isEditing);
    };

    EventRegister.subscribe('change_is_editing', listener);

    return () => {
      EventRegister.unsubscribe('change_is_editing', listener);
    };
  }, []);

  return (
    <>
      {isMobile ? null : (
        <Stack
          style={{
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 20,
            marginRight: 20,
            padding: 10,
          }}>
          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 20,
            }}>
            {/* <IconButton
              mb="2px"
              mr={3}
              size="xs"
              color="primaryColor"
              variant="ghost"
              aria-label="close-tabs"
              icon={<MdNotifications size="4vh" />}
              onClick={() => console.log('notifications')}
            /> */}
            <PilotHeader name={user?.firstName || ''} />
          </Stack>
          <Heading
            fontFamily="Monument Extended"
            color="primaryColor"
            fontWeight="200"
            fontSize="xl">
            <IconButton
              mb="2px"
              mr={3}
              size="xs"
              color="primaryColor"
              variant="ghost"
              aria-label="close-tabs"
              icon={<MdArrowBack size="30px" />}
              onClick={() => history.goBack()}
            />
            Profile
          </Heading>
        </Stack>
      )}
      <Formik
        initialValues={{
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: user?.email || '',
          address: user?.address || '',
          pilotCertNumber: user?.pilotCertNumber || '',
          pilotCertExpire: user?.pilotCertExpire,
          phone: user?.phone || '',
          state: user?.state || '',
          zipcode: user?.zipcode || '',
        }}
        validationSchema={settingsValidationSchema}
        onSubmit={(values) => {
          delete (values as any).email;

          updateUser(
            {
              ...values,
              id: user?.id || '',
            },
            {
              onSuccess: (_user) => {
                updateUserEvent(_user);

                toast({
                  status: 'success',
                  title: 'Success',
                  description: 'Data saved successfully',
                  isClosable: true,
                  duration: 2000,
                });

                queryCache.invalidateQueries(queryKey, { exact: true });

                setIsEditing(false);
              },
            },
          );
        }}>
        {({ values, setFieldValue, resetForm, handleSubmit }) => (
          <Form>
            <Flex px={[0, 4, 6]} direction="column" flex={1}>
              <Flex
                mt={[0, 0, 6, 6]}
                p={10}
                bg="#fff"
                flexWrap="wrap"
                justifyContent={'center'}
                fontFamily="Open Sans"
                boxShadow={
                  isMobile
                    ? undefined
                    : '0px 0px 20px 0px rgba(103, 40, 187, .1)'
                }>
                <Flex
                  flex={1}
                  flexDir={'column'}
                  align="center"
                  alignSelf="center"
                  direction="column"
                  maxW={400}
                  maxH={isMobile ? 40 : 400}>
                  <UserAvatar
                    ref={userAvatarRef}
                    givenWidht={isMobile ? 180 : 160}
                    givenHeight={isMobile ? 180 : 160}
                  />
                  <Text
                    mt={4}
                    fontSize="16px"
                    fontWeight="bold"
                    color="brand.500"
                    cursor="pointer"
                    userSelect="none"
                    onClick={() => {
                      userAvatarRef?.current?.onOpen?.();
                    }}>
                    Change Picture
                  </Text>
                  <Flex h="200px" />
                </Flex>
                <Flex
                  direction="column"
                  flex={1}
                  mt={{ base: '100px', md: '0' }}>
                  {isMobile ? null : (
                    <AnimatePresence>
                      {!isEditing ? (
                        <MotionButton
                          {...animation}
                          variant="ghost"
                          borderRadius="10px"
                          alignSelf="flex-end"
                          type="button"
                          w="150px"
                          bg="#ECE3F8"
                          leftIcon={<MdEdit />}
                          onClick={() => {
                            setIsEditing(true);
                          }}>
                          EDIT
                        </MotionButton>
                      ) : (
                        <Box h="40px" />
                      )}
                    </AnimatePresence>
                  )}
                  <Flex flexWrap="wrap">
                    <Flex flex={1} direction="column" minW="200px">
                      <FormikInputView
                        name="firstName"
                        label="First Name"
                        isDisabled={!isEditing}
                      />
                      <FormikInputView name="email" label="Email" isDisabled />
                      <FormikDatePicker
                        name="pilotCertExpire"
                        label="Pilot Certificate Expiration"
                        labelProps={{ mt: 4 }}
                        disablePastDates
                        textFieldProps={{
                          isDisabled: !isEditing,
                          bg: 'inputColor',
                        }}
                      />
                      <FormikInputView
                        name="phone"
                        label="Phone"
                        isDisabled={!isEditing}
                      />
                      <PlacesAutocompleteView
                        inputStyle={{
                          bg: 'inputColor',
                          isDisabled: !isEditing,
                          mb: '0',
                        }}
                        labelProps={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                        noPlaceholder
                        value={values?.address || ''}
                        name="address"
                        labelText="Address"
                        searchOptions={{
                          componentRestrictions: {
                            country: 'us',
                          },
                        }}
                        setFieldValue={setFieldValue}
                        onSelect={async (address: string) => {
                          const result = await geocodeByAddress(address);
                          const { lat, lng } = await getLatLng(result?.[0]);

                          const state =
                            result?.[0]?.address_components?.find((i) =>
                              i.types.includes('administrative_area_level_1'),
                            )?.long_name ?? '';

                          const zipcode =
                            result?.[0]?.address_components?.find((i) =>
                              i.types.includes('postal_code'),
                            )?.long_name ?? '';

                          setFieldValue('state', state);
                          setFieldValue('zipcode', zipcode);
                          setFieldValue('address', address);
                          setFieldValue('latitude', lat);
                          setFieldValue('longitude', lng);
                        }}
                      />
                    </Flex>
                    <Flex w="20px" h="20px" />
                    <Flex flex={1} direction="column" minW="200px">
                      <FormikInputView
                        name="lastName"
                        label="Last Name"
                        isDisabled={!isEditing}
                      />
                      <FormikInputView
                        type="number"
                        name="pilotCertNumber"
                        label="Remote Pilot Certificate Number"
                        isDisabled={!isEditing}
                      />
                      <Flex w="100%">
                        <Flex flexDir="column">
                          <FormikInputView
                            name="state"
                            label="State"
                            isDisabled={!isEditing}
                          />
                        </Flex>
                        <Flex w="20px" h="20px" />
                        <Flex flexDir="column">
                          <FormikInputView
                            type="number"
                            name="zipcode"
                            label="Zip Code"
                            isDisabled={!isEditing}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Center gridGap="20px" mt={8} minH="50px" flexWrap="wrap">
                    <AnimatePresence>
                      {isEditing ? (
                        <>
                          <MotionButton
                            {...animation}
                            w="200px"
                            variant="outline"
                            isDisabled={isUpdating}
                            onClick={() => {
                              resetForm();
                              setIsEditing(false);
                            }}>
                            CANCEL
                          </MotionButton>
                          <MotionButton
                            {...animation}
                            w="200px"
                            isLoading={isUpdating}
                            onClick={() => {
                              handleSubmit();
                            }}>
                            SAVE
                          </MotionButton>
                        </>
                      ) : (
                        !isMobile && <DeleteAccountButton />
                      )}
                    </AnimatePresence>
                  </Center>
                </Flex>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};
