import React from 'react';
import { Box, Button, Flex, Heading, Link, Text } from '@chakra-ui/react';

import { ConterFormContainerProps } from './ConterFormContainer.props';

const CenterFormContainer = (props: ConterFormContainerProps): JSX.Element => {
  return (
    <Flex justify="center" px={5} width="100%" flex={1} mx="auto" bg="bgColor">
      <Flex
        mt="20px"
        maxWidth="1200px"
        width="100%"
        align="center"
        flexDir="column">
        <Heading
          fontFamily="Monument Extended"
          textAlign="center"
          size="md"
          mt={[5, 10]}
          mb={[10, 20]}>
          {props.title}
        </Heading>
        {props.children}
      </Flex>
    </Flex>
  );
};

export default CenterFormContainer;
