import React, { FC } from 'react';

import { Avatar, Center, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { MdCall } from 'react-icons/md';

import * as session from 'effector/session';

import { Mission } from 'types/mission';
import { AccountTypes } from 'types/accountTypes';

const labelProps = {
  fontSize: '13px',
  fontWeight: 'bold',
  color: 'primaryColor',
};

const Pilot: FC<{ mission: Mission }> = ({ mission }) => {
  const userType = session.selectors.useAccountType();
  const isPilot = userType === AccountTypes.pilot;

  if (isPilot) {
    return (
      <Flex p="20px" pb="100px" gridGap="15px" flex={1} direction="column">
        <Stack spacing={0}>
          <Text {...labelProps}>Name:</Text>
          <Text>
            {mission?.client?.firstName || ''} {mission?.client?.lastName || ''}
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text {...labelProps}>Email:</Text>
          <Text>{mission?.client?.email || ''}</Text>
        </Stack>
        <Button
          aria-label="Call Client"
          size="md"
          variant="primary"
          backgroundColor="#33b864"
          color="white"
          fontWeight={'500'}
          leftIcon={<MdCall />}
          letterSpacing={0.1}
          onClick={() => {
            window.location.href = `tel:${mission?.client?.phone}`;
          }}>
          Call Client
        </Button>
      </Flex>
    );
  }

  if (!mission?.pilot) {
    return (
      <Center
        minH="60vh"
        p="20px"
        pb="100px"
        gridGap="5px"
        flex={1}
        justifyContent="center"
        flexDirection="column">
        <Text fontWeight="500">Status updates</Text>
        <Text>A pilot will be assigned soon</Text>
      </Center>
    );
  }

  return (
    <Flex p="20px" pb="100px" gridGap="15px" flex={1} direction="column">
      <Stack align="center">
        <Avatar h="100px" w="100px" />
      </Stack>
      <Stack pb="20px" align="center" spacing={0} borderBottom="1px solid #ccc">
        <Text {...labelProps}>Pilot Name</Text>
        <Text>
          {mission?.pilot?.firstName || ''} {mission?.pilot?.lastName || ''}
        </Text>
      </Stack>
      <Stack pb="20px" align="center" spacing={0} borderBottom="1px solid #ccc">
        <Text {...labelProps}>Industries</Text>
        {mission?.cms?.industries?.map((ind) => (
          <Text
            key={ind}
            color="buttonBgColor"
            fontWeight="bold"
            textTransform="capitalize">
            {ind.toLowerCase()}
          </Text>
        ))}
      </Stack>
      <Stack pb="20px" align="center" spacing={0} borderBottom="1px solid #ccc">
        <Text {...labelProps}>Missions</Text>
        <Text>-</Text>
      </Stack>
      <Stack pb="20px" align="center" spacing={0} borderBottom="1px solid #ccc">
        <Text {...labelProps}>Ratings</Text>
        <Text>-</Text>
      </Stack>
    </Flex>
  );
};

export default Pilot;
