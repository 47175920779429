import React from 'react';

import { Box, Text, Flex, Stack, Button, StackProps } from '@chakra-ui/react';

import {
  MdList,
  MdPlace,
  MdZoomIn,
  MdPeople,
  MdPerson,
  MdListAlt,
  MdReceipt,
  MdPlayArrow,
  MdDateRange,
  MdNavigation,
  MdViewCarousel,
} from 'react-icons/md';

import { format } from 'date-fns';

import { goToMaps } from 'utils/goToMaps';

import { useStore } from 'effector-react';
import { SessionStore } from 'effector/session/store';

import GoogleMaps from 'components/primitives/GoogleMaps';

import { AccountTypes } from 'types/accountTypes';
import { MissionOverviewProps } from './MissionOverview.props';
import { MissionStatuses, PackageLineItems } from 'types/mission';

const NormalText = ({ children }: { children: React.ReactNode }) => (
  <Text fontWeight="500" color="black">
    {children}
  </Text>
);

const IconWithText = ({ text, Icon }: { text: string; Icon?: any }) => {
  return (
    <Stack isInline alignItems="center" spacing={1}>
      {Icon && <Icon size={15} />}
      <Text>{text}</Text>
    </Stack>
  );
};

const ShadowCard = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & StackProps) => (
  <Stack
    my={2}
    mx={1}
    boxShadow="0px 0px 5px 0px rgba(0,0,0,0.1)"
    borderRadius="5px"
    bg="white"
    p={2}
    flex={1}
    {...props}>
    {children}
  </Stack>
);

const formatDate = (date?: Date) => {
  if (!date) {
    return '';
  }

  return format(new Date(date), 'dd MMMM, yyyy');
};

const MissionOverviewPilot = (props: MissionOverviewProps): JSX.Element => {
  const isPilot =
    useStore(SessionStore)?.session?.user?.accountType === AccountTypes.pilot;

  const mission = props.mission;

  const isComplete = mission?.status === MissionStatuses.COMPLETED;

  return (
    <Flex
      width="100%"
      bg="white"
      letterSpacing={0.5}
      fontSize="12px"
      color="#92835C"
      fontWeight="600">
      <Stack px={6} py={4} flex={1} maxW="300px">
        <Box>
          <Text>Mission ID</Text>
          <Text fontSize="24px" color="#343835" isTruncated maxWidth={200}>
            #{mission?.id}
          </Text>
        </Box>
        <Box>
          <Text>Mission Status</Text>
          <Text textTransform="capitalize" fontSize="24px" color="#343835">
            {mission?.status === MissionStatuses.FOR_PAYMENT
              ? 'Payment Pending'
              : (mission?.status || '-')?.toLowerCase()}
          </Text>
        </Box>
        <Box h="100%">
          <IconWithText Icon={MdViewCarousel} text="Pack Requested" />
          <Text color="black">{mission?.package?.name}</Text>
          <Text color="black" fontWeight="500">
            {mission?.package?.lineItems.map(
              (item: PackageLineItems, index: number) => (
                <React.Fragment key={`${item.name}-${index}`}>
                  - {item.count ? `(${item.count})` : ''} {item.name}
                  <br />
                </React.Fragment>
              ),
            )}
            {mission?.package?.additionalServices.map(
              (item: PackageLineItems, index: number) => (
                <React.Fragment key={`${item.name}-${index}`}>
                  - {item.name}
                  <br />
                </React.Fragment>
              ),
            )}
          </Text>
        </Box>
        <Flex>
          {isComplete && props.buttonSection ? props.buttonSection : null}
        </Flex>
      </Stack>
      <Flex flex={1} bg="#f2f2f2">
        <ShadowCard flex={1.5} maxW="350px">
          <IconWithText Icon={MdPlace} text="Location" />
          <Text color="black" fontWeight="500">
            {mission?.location}
          </Text>
          <Box
            w={['100%']}
            height="200px"
            mb="10px"
            background="#fff"
            border="1px"
            borderColor="#ccc"
            bg="inputColor">
            <GoogleMaps
              mapContainerStyle={{
                width: '100%',
                height: '200px',
              }}
              initialCenter={{
                lat: mission?.gps?.coordinates[1] || 0,
                lng: mission?.gps?.coordinates[0] || 0,
              }}
              marker={{
                lat: mission?.gps?.coordinates[1] || 0,
                lng: mission?.gps?.coordinates[0] || 0,
              }}
              zoom={13}
            />
          </Box>
          <Button
            p={2}
            size="xs"
            variant="ghost"
            alignSelf="flex-end"
            leftIcon={<MdZoomIn size={15} />}>
            ENLARGE
          </Button>
          <Button
            as="a"
            p={2}
            size="xs"
            variant="ghost"
            alignSelf="flex-end"
            leftIcon={<MdNavigation size={15} />}
            onClick={() =>
              goToMaps({
                lat: mission?.gps?.coordinates[1] || 0,
                lng: mission?.gps?.coordinates[0] || 0,
              })
            }>
            START NAVIGATION
          </Button>
        </ShadowCard>
        <ShadowCard maxW="250px" spacing={4}>
          <Box>
            <IconWithText Icon={MdDateRange} text="Due Date" />
            {formatDate(mission?.eventStart) ===
            formatDate(mission?.dueDate) ? (
              <NormalText>{formatDate(mission?.eventStart)}</NormalText>
            ) : (
              <NormalText>
                {formatDate(mission?.eventStart)} -{' '}
                {formatDate(mission?.dueDate)}{' '}
              </NormalText>
            )}
          </Box>
          <Box>
            <IconWithText Icon={MdPlayArrow} text="Live Event" />
            <NormalText>{mission?.liveEvent ? 'Yes' : 'No'}</NormalText>
          </Box>
          {mission?.liveEvent && (
            <Box>
              <IconWithText text="Event Starts At" />

              <NormalText>{formatDate(mission?.eventStart)}</NormalText>
            </Box>
          )}
          {mission?.onsite && (
            <Box>
              <IconWithText Icon={MdPeople} text="Contact on Site" />
              <NormalText>{mission?.onsite ? 'Yes' : 'No'}</NormalText>
            </Box>
          )}
          {mission?.meetupTime && (
            <Box>
              <IconWithText text="Time for Meeting" />
              <NormalText>
                {mission?.meetupTime?.[0]
                  ? formatDate(new Date(mission?.meetupTime?.[0]))
                  : ''}
              </NormalText>
            </Box>
          )}
        </ShadowCard>
        <ShadowCard maxW="250px" justifyContent="space-between">
          <Box>
            <Box maxH="250px" overflowY="hidden">
              <IconWithText Icon={MdList} text="Mission Notes" />
              <NormalText>
                {mission?.notes || 'No Notes written for this mission'}
              </NormalText>
            </Box>
            <Box mt={4} maxH="250px" overflowY="hidden">
              <IconWithText Icon={MdList} text="Location Details" />
              <NormalText>
                {mission?.locationDetail ||
                  'No extra location details written for this mission'}
              </NormalText>
            </Box>
            {mission?.contact && (
              <Box mt={6}>
                <IconWithText Icon={MdPerson} text="Contact" />
                <NormalText>Name: {mission?.contact?.name || ''}</NormalText>
                <NormalText>Email: {mission?.contact?.email || ''}</NormalText>
                <NormalText>Phone: {mission?.contact?.phone || ''}</NormalText>
              </Box>
            )}
          </Box>
          {props.renderStatus ? (
            props.renderStatus
          ) : (
            <Box>
              {mission?.additionalServices &&
                mission?.additionalServices?.length > 0 && (
                  <Box my={2}>
                    <IconWithText Icon={MdListAlt} text="Additional Services" />
                    {mission.additionalServices?.map((a, i) => (
                      <Stack
                        fontSize="12px"
                        fontWeight="500"
                        isInline
                        key={`${a.name}-${i}`}>
                        <NormalText>- {a.name}</NormalText>
                        <NormalText>${a.price}</NormalText>
                      </Stack>
                    ))}
                  </Box>
                )}
              <IconWithText
                Icon={MdReceipt}
                text={isPilot ? 'Total Pay' : 'Total Charged'}
              />
              <Text fontSize="24px" color="#343835">
                ${mission?.totalCharge}
              </Text>
            </Box>
          )}
        </ShadowCard>
      </Flex>
      {props.buttonSection && !isComplete ? (
        <Stack
          py={4}
          px={2}
          bg="white"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          minW="200px">
          {props.buttonSection}
        </Stack>
      ) : null}
    </Flex>
  );
};

export default MissionOverviewPilot;
