import React, { FC, useRef } from 'react';

import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react';
import { MdClose, MdSearch } from 'react-icons/md';

import debounce from 'lodash.debounce';

import { SearchbarProps } from './Searchbar.props';
import { useSearchParamState } from 'utils/hooks';

const SearchbarView: FC<SearchbarProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [search, setSearch, clearSearch] = useSearchParamState('search');

  return (
    <InputGroup maxW="600px">
      <InputLeftAddon bg="#fff">
        <MdSearch color="#6418C3" size="22px" />
      </InputLeftAddon>
      <Input
        bg="#fff"
        ref={inputRef}
        defaultValue={search}
        placeholder="Search Mission"
        onChange={debounce((e) => {
          setSearch(e.target.value, {
            valueToClear: 'page',
          });
        }, 500)}
      />

      <InputRightAddon
        bg="#fff"
        as="button"
        type="button"
        onClick={() => {
          clearSearch();

          if (inputRef.current) {
            inputRef.current.value = '';
          }
        }}>
        <MdClose color="#6418C3" size="22px" />
      </InputRightAddon>
    </InputGroup>
  );
};

export default SearchbarView;
