import React, { useState } from 'react';

import { Button, Flex, IconButton, Stack } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import PilotsTabs from 'components/modules/PilotsTabs';
import ContentContainer from 'components/layouts/ContentContainer';
import ClientMissionTabs from 'components/modules/ClientMissionTabs';

import { MissionQuoteTabs } from 'components/modules/MissionQuoteTabs';

import { MissionsGeneratedProps } from './Missions.props';
import ClientHeader from 'components/modules/ClientHeader';
import { SessionStore } from 'effector/session/store';
import { MdNotifications } from 'react-icons/md';

const useIsCustomOpen = () => {
  const history = useHistory();
  const location = history.location;
  const search = new URLSearchParams(location.search);

  const custom = search.get('type');

  return custom == '1';
};

const RegularMissionsTabs = () => {
  return (
    <>
      <ClientMissionTabs />
      <PilotsTabs />
    </>
  );
};

const MissionsView = (props: MissionsGeneratedProps) => {
  const history = useHistory();
  const isCustomOpen = useIsCustomOpen();
  const name = SessionStore.getState()?.session?.user?.firstName ?? '';
  const [index, setIndex] = useState<0 | 1>(isCustomOpen ? 1 : 0);

  const handleChangeIndex = (index: 0 | 1) => {
    const location = history.location;

    setIndex(index);
    history.push({
      state: location.state,
      search: `?type=${index}`,
      pathname: location.pathname,
    });
  };

  return (
    <ContentContainer>
      <>
        <Flex flexDirection={'row-reverse'}>
          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 20,
            }}>
            {/* <IconButton
              mb="2px"
              mr={3}
              size="xs"
              color="primaryColor"
              variant="ghost"
              aria-label="close-tabs"
              icon={<MdNotifications size="4vh" />}
              onClick={() => console.log('notifications')}
            /> */}
            <ClientHeader name={name} />
          </Stack>
        </Flex>
        <Flex flexDirection="column" flex={1}>
          <Flex pb={8}>
            <Button
              mr={3}
              variant="outline"
              isActive={index === 0}
              onClick={() => handleChangeIndex(0)}>
              Package Missions
            </Button>
            <Button
              variant="outline"
              isActive={index === 1}
              onClick={() => handleChangeIndex(1)}>
              Custom Missions
            </Button>
          </Flex>
          {index === 0 && <RegularMissionsTabs />}
          {index === 1 && <MissionQuoteTabs />}
        </Flex>
      </>
    </ContentContainer>
  );
};

export default MissionsView;
