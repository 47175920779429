import React, { useEffect } from 'react';
import { Center, Spinner, useToast } from '@chakra-ui/react';
import useActivateAccount from 'utils/hooks/useActivateAccount';
import { useHistory, useLocation } from 'react-router-dom';
import ROUTES, { routesSettings } from 'constants/routes';
import { ActivateAccountProps } from './ActivateAccount.props';
import { logoutEvent } from 'effector/session/events';
import { getUserId } from 'effector/session/store';

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ActivateAccountContainer = (props: ActivateAccountProps) => {
  const history = useHistory();
  const query = useQuery();
  const toast = useToast();

  const userId = query.get('userId');
  const token = query.get('token');
  const type = query.get('type');

  const {
    activateUserAccount,
    isSuccess,
    isDispatched,
    isUserLogged,
    isPilot,
  } = useActivateAccount(userId);

  const onSuccess = () => {
    toast({
      status: 'success',
      title: 'Success',
      description: 'Account activated successfully',
      isClosable: true,
      duration: 2000,
    });

    type PathType =
      | ROUTES.AUTH_LOGIN
      | ROUTES.PILOT_DASHBOARD
      | ROUTES.CLIENT_DASHBOARD;

    let redirectPath: PathType = ROUTES.AUTH_LOGIN;

    if (isUserLogged) {
      if (isPilot) {
        redirectPath = ROUTES.PILOT_DASHBOARD;
      } else {
        redirectPath = ROUTES.CLIENT_DASHBOARD;
      }

      history.push(routesSettings[redirectPath].path);
    } else {
      history.push('/login');
    }
  };

  const onFailure = () => {
    // TODO: handle failure
    console.log('failure');
  };

  useEffect(() => {
    if (Boolean(token)) {
      const url = `/reset-password/${token}${
        Boolean(type) ? `?type=${type}` : ''
      }`;

      if (Boolean(type)) {
        logoutEvent();
      }

      setTimeout(() => {
        history.push(url);
      }, 600);

      if (Boolean(type)) {
        return;
      }
    }

    let timeoutId: NodeJS.Timeout;

    if (Boolean(userId)) {
      const savedUserId = getUserId();

      if (Boolean(savedUserId)) {
        if (savedUserId !== userId) {
          toast({
            status: 'error',
            title: 'Error',
            description:
              'You are already logged in with another account. please logout and try again from the email link',
            isClosable: true,
            duration: 10000,
          });

          return;
        }
      }

      activateUserAccount();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (Boolean(isDispatched)) {
      if (isSuccess) {
        onSuccess();
        return;
      }

      onFailure();
    }
  }, [isSuccess, isDispatched]);

  return (
    <Center flex={1} minH="500px">
      <Spinner color="brand.500" size="lg" thickness="4px" />
    </Center>
  );
};

export default ActivateAccountContainer;
