import api from 'api/instance';
import { ApiResponse, Mission } from 'types/mission';
import { Package } from 'types/cms';

export interface GetPackageAPIParams {
  packageId?: string;
}

const URL = process.env.REACT_APP_API_URL;

export const getPackages = async (
  payload: GetPackageAPIParams,
): Promise<ApiResponse<Package[]>> => {
  const { data } = await api.get<ApiResponse<Package[]>>(`${URL}/packages`);
  return data;
};

export default { getPackages };
