import React from 'react';

import { useToast } from '@chakra-ui/react';

import { selectors } from 'effector/session';
import { updateUserEvent } from 'effector/session/events';

import { updateUserData } from 'api/auth';
import { useMutation, useQueryCache } from 'react-query';

import { SettingsProps } from './Settings.props';

import SettingsView from './Settings.view';

const SettingsContainer = (_: SettingsProps) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const user = selectors.useUser();

  const queryKey = `fetch-user-${user?.id}`;

  const [updateUser, { isLoading }] = useMutation(updateUserData);

  return (
    <SettingsView
      user={user}
      isLoading={isLoading}
      onSubmit={(values, _onSuccess) => {
        delete values.email;

        updateUser(
          {
            ...values,
            id: user?.id || '',
          },
          {
            onSuccess: (_user) => {
              updateUserEvent(_user);

              toast({
                status: 'success',
                title: 'Success',
                description: 'Data saved successfully',
                isClosable: true,
                duration: 2000,
              });

              queryCache.invalidateQueries(queryKey, { exact: true });

              _onSuccess();
            },
          },
        );
      }}
    />
  );
};

export default SettingsContainer;
