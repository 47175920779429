import React from 'react';

import { ProMissionsProps } from './ProMissions.props';
import ProMissionsView from './ProMissions.view';

const ProMissionsContainer = (_: ProMissionsProps) => {
  return <ProMissionsView />;
};

export default ProMissionsContainer;
