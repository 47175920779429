import { useBreakpointValue } from '@chakra-ui/react';

/**
 *
 * @description Returns true if the screen size is mobile
 *
 * @returns {boolean} isMobile
 */
const useIsMobile = () => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    x: false,
  });

  return Boolean(isMobile);
};

export default useIsMobile;
