import React from 'react';

import {
  Center,
  chakra,
  Flex,
  Image,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';

import mobileInfoChar from 'res/mobile_info_char.png';

import { useIsMobile } from 'utils/hooks';

import { SessionStore } from 'effector/session/store';

import { WebsiteForm } from 'components/modules/WebsiteForm';

import PilotHeader from 'components/modules/PilotHeader';

import { MyWebsiteGeneratedProps } from './MyWebsite.props';
import { MdNotifications } from 'react-icons/md';

const Span = chakra.span;

const MyWebsiteView = ({
  data,
  isLoading,
  isNewCMS,
  onSubmit,
}: MyWebsiteGeneratedProps) => {
  const isMobile = useIsMobile();

  const name = SessionStore.getState()?.session?.user?.firstName ?? '';

  if (isMobile) {
    return (
      <Flex
        h="100%"
        flex={1}
        align="flex-start"
        justify="flex-start"
        direction="column"
        p="20px"
        pt="100px">
        <Text textAlign="center">
          We are very happy to be able to offer you this exclusive premium
          benefit, but we ask that you design{' '}
          <Span fontWeight="bold">Your Website</Span> from a computer.
        </Text>
        <Image
          alignSelf="center"
          mt="50px"
          src={mobileInfoChar}
          maxW="300px"
          objectFit="contain"
        />
        <Text mt="50px" textAlign="center">
          We wanted to give you many tools to make it the ideal page.
        </Text>
      </Flex>
    );
  }

  return (
    <Flex
      w="100%"
      h="100%"
      bg="#EFEDEA"
      flex={1}
      direction="column"
      alignSelf="flex-start">
      <Stack
        mx={5}
        style={{
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 30,
        }}>
        <Stack
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 20,
          }}>
          <PilotHeader name={name} />
        </Stack>
      </Stack>
      {isLoading ? (
        <Center h="100vh">
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="brand.500"
          />
        </Center>
      ) : (
        <WebsiteForm
          initialValues={data}
          onSubmit={onSubmit}
          name={name}
          isNewCMS={isNewCMS}
        />
      )}
    </Flex>
  );
};

export default MyWebsiteView;
