import React, { memo, useMemo } from 'react';

import { Input } from '@chakra-ui/input';

import { useField } from 'formik';

import debounce from 'lodash.debounce';

import { editTitle } from 'api/cms';
import { useMutation, useQueryCache } from 'react-query';

import { PreviousWorkItem } from 'types/cms';

const colorScheme = '#6728BB';

export const TitleInput = memo<{ item: PreviousWorkItem }>(({ item }) => {
  const { id = '', title = '' } = item;

  const { value: stylings } = useField<any>('stylings')[0];

  const color = stylings?.colorScheme || colorScheme;

  const queryCache = useQueryCache();

  const [edit] = useMutation(editTitle, {
    onSuccess: () => {
      queryCache.invalidateQueries('fetch-my-cms', {
        exact: true,
      });
      queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
        exact: true,
      });
    },
    onError: () => {
      console.log('');
    },
  });

  const key = useMemo(() => `input-${title}-${id}`, [title]);

  return (
    <Input
      h="50px"
      w="100%"
      bg={`${color}10`}
      borderRadius="0 0 10px 10px"
      color={color}
      defaultValue={title}
      key={key}
      placeholder="Add title"
      _placeholder={{
        color: color,
        opacity: 0.45,
      }}
      _focus={{
        border: `2px dashed ${color}`,
      }}
      border={`2px dashed ${color}`}
      onChange={debounce(({ target: { value } }) => {
        edit({ id, title: value });
      }, 3000)}
    />
  );
});
