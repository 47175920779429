import React from 'react';

function DroneAdairLogo2Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="94"
      height="25"
      fill="none"
      viewBox="0 0 94 25">
      <g>
        <path
          fill="#525252"
          d="M35.798 22.223h-5.35l-.89 2.027h-3.601l4.83-10.36h4.668l4.816 10.36h-3.587l-.89-2.027h.004zm-1.037-2.338l-1.63-3.714-1.646 3.714h3.276z"></path>
        <path
          fill="#525252"
          d="M53.286 19.07c0 3.523-2.356 5.18-4.934 5.18h-7.633V13.89h7.633c2.578 0 4.934 1.658 4.934 5.18zm-3.26 0c0-2.22-1.364-2.426-2.786-2.426h-3.26v4.855h3.26c1.422 0 2.786-.206 2.786-2.426"></path>
        <path
          fill="#525252"
          d="M62.61 22.223h-5.35l-.89 2.027h-3.6l4.83-10.36h4.667l4.816 10.36h-3.587l-.89-2.027h.005zm-1.036-2.338l-1.631-3.714-1.645 3.714h3.276z"></path>
        <path fill="#525252" d="M70.79 13.89h-3.259v10.36h3.26V13.89z"></path>
        <path
          fill="#525252"
          d="M81.898 21.23l1.867 3.02h-3.853l-1.542-2.59h-3.306v2.59h-3.26V13.89h8.15c2.358 0 3.973 1.57 3.973 3.878 0 1.598-.77 2.857-2.03 3.465m-6.833-2.324h4.15c.606 0 1.453 0 1.453-1.139s-.844-1.124-1.453-1.124h-4.15v2.263z"></path>
        <path
          fill="#525252"
          d="M38.526 6.078c0 3.523-2.357 5.18-5.158 5.18h-7.411V.898h7.411c2.803 0 5.158 1.658 5.158 5.18zm-1.778 0c0-2.96-1.364-3.7-4.267-3.7h-4.744v7.4h4.744c2.905 0 4.267-.74 4.267-3.7z"></path>
        <path
          fill="#525252"
          d="M49.574 8.063l1.987 3.197h-2.076L47.62 8.24h-5.81v3.02h-1.778V.898h8.153c2.357 0 3.971 1.363 3.971 3.671 0 1.822-.993 3.05-2.578 3.494M41.81 6.76h5.632c1.126 0 2.934 0 2.934-2.192 0-2.19-1.809-2.19-2.934-2.19H41.81V6.76z"></path>
        <path
          fill="#525252"
          d="M53.258 6.064c0-3.3 1.956-5.314 6.521-5.314 4.566 0 6.522 2.028 6.522 5.314 0 3.287-1.97 5.344-6.522 5.344-4.55 0-6.521-2.028-6.521-5.344zm11.263 0c0-2.856-1.571-3.834-4.744-3.834s-4.741.961-4.741 3.834 1.585 3.864 4.741 3.864c3.156 0 4.744-.993 4.744-3.864z"></path>
        <path
          fill="#525252"
          d="M81.17.898v10.36h-2.564L69.581 2.6v8.658H67.8V.898h2.564l9.026 8.66V.897h1.78z"></path>
        <path
          fill="#525252"
          d="M84.811 2.378v2.96h9.187v1.48h-9.187v2.96h9.187v1.48H83.031V.898h10.967v1.48h-9.187z"></path>
        <path
          fill="#525252"
          d="M23.38 15.348l-.092.327a12.17 12.17 0 01-1.372 3.04c-.501.809-1.104 1.565-1.784 2.248l-.007.006a12.71 12.71 0 01-2.195 1.746 12.378 12.378 0 01-3.088 1.396l-.325.097h-.005-.016a.063.063 0 01-.04-.013.06.06 0 01-.026-.053v-2.665c0-.029.016-.053.043-.062l.037-.014.13-.046a9.648 9.648 0 001.334-.593 9.683 9.683 0 003.984-4.006c.22-.41.41-.839.569-1.283l.064-.17a.064.064 0 01.062-.041h2.666c.02 0 .041.008.054.027a.064.064 0 01.01.058"></path>
        <path
          fill="#525252"
          d="M18.076 16.56a.065.065 0 01-.019.048l-2.365 2.363a.073.073 0 01-.048.018.064.064 0 01-.046-.018l-1.167-1.166-.103-.101c-.013-.013-.027-.035-.027-.054 0-.012 0-.027.006-.04 0-.005.006-.01.008-.014l.114-.115 1.058-1.057 1.192-1.19s.006-.007.012-.007l.004-.004a.063.063 0 01.077.01l1.283 1.282a.064.064 0 01.019.046"></path>
        <path
          fill="#525252"
          d="M8.96 21.48v2.661s0 .015-.004.021a.068.068 0 01-.023.031.077.077 0 01-.041.015h-.019l-.327-.095a12.199 12.199 0 01-3.082-1.393 12.61 12.61 0 01-2.21-1.76l-.008-.008a12.546 12.546 0 01-2.972-4.74 9.575 9.575 0 01-.174-.537l-.098-.325c-.006-.018 0-.041.01-.057a.066.066 0 01.055-.027h2.67c.028 0 .053.018.062.043l.06.168a9.665 9.665 0 002.272 3.59 9.716 9.716 0 003.616 2.288l.167.062a.068.068 0 01.042.041c0 .007.004.015.004.021z"></path>
        <path
          fill="#525252"
          d="M9.011 17.642a.066.066 0 01-.018.048l-.036.035L7.71 18.97c-.012.014-.029.018-.047.018a.064.064 0 01-.046-.018l-1.192-1.191-1.173-1.172a.064.064 0 010-.093l1.27-1.27c.027-.026.08-.036.104-.01l1.173 1.17 1.192 1.191a.064.064 0 01.02.046"></path>
        <path
          fill="#525252"
          d="M8.955.997A.052.052 0 008.93.952c-.016-.013-.039-.019-.057-.01l-.325.096a12.093 12.093 0 00-3.042 1.368c-.812.501-1.568 1.1-2.248 1.783-.005.004-.005.006-.009.008A12.509 12.509 0 001.5 6.39 12.278 12.278 0 00.103 9.474L.01 9.8c-.006.018 0 .04.01.057a.066.066 0 00.054.027h2.66a.065.065 0 00.062-.043l.062-.168c.166-.463.366-.907.596-1.33A9.731 9.731 0 015.133 6.08 9.704 9.704 0 017.46 4.36c.412-.219.84-.41 1.285-.568l.168-.06a.06.06 0 00.041-.052V.997z"></path>
        <path
          fill="#525252"
          d="M9.003 7.495V7.5c0 .019 0 .04-.013.052l-.035.035L7.8 8.743 6.625 9.917a.068.068 0 01-.046.019h-.016a.04.04 0 01-.031-.017L5.25 8.638a.064.064 0 010-.093L6.426 7.37l1.19-1.189a.064.064 0 01.093 0l1.271 1.27c.01.01.02.028.025.045"></path>
        <path
          fill="#B65BA2"
          d="M18.11 5.814l.022.023-.023-.023zm0 0l.022.023-.023-.023z"></path>
        <path
          fill="#525252"
          d="M23.387 9.77l-.064-.21-.033-.112a12.388 12.388 0 00-1.416-3.117 12.54 12.54 0 00-1.736-2.168l-.042-.042a12.55 12.55 0 00-5.25-3.108l-.328-.095a.082.082 0 00-.058.01.065.065 0 00-.026.054v2.66c0 .029.018.054.043.062l.168.064c.436.155.857.341 1.26.554a9.662 9.662 0 012.306 1.685 9.59 9.59 0 011.711 2.275c.238.434.443.89.613 1.365l.06.167a.063.063 0 00.062.043h2.668a.065.065 0 00.064-.085M18.13 5.838l-.02-.02.02.02z"></path>
        <path
          fill="#525252"
          d="M18.145 8.609l-1.27 1.269c-.013.012-.035.027-.054.027-.019 0-.042 0-.054-.015L15.544 8.67 14.43 7.556l-.029-.028a.067.067 0 010-.093l.03-.03 1.254-1.252a.064.064 0 01.093 0l2.365 2.36a.064.064 0 010 .094"></path>
        <path
          fill="#908361"
          d="M16.73 13.687l-2.488 2.485c-.021.02-.052.02-.07 0l-2.474-2.47a.051.051 0 00-.07 0L9.14 16.186c-.02.021-.052.021-.07 0l-2.488-2.484a.048.048 0 010-.07l5.046-5.04a.051.051 0 01.07 0l5.031 5.025a.051.051 0 010 .07z"></path>
      </g>
    </svg>
  );
}

export default DroneAdairLogo2Icon;
