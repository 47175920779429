import React, { useRef, useState } from 'react';

import { Button, chakra, Checkbox, Flex, Text } from '@chakra-ui/react';

import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import {
  phoneRegExp,
  responsiveSpacing,
  responsiveFlexDir,
} from 'constants/registration';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import FormikImageUpload from '../FormikImageUpload';
import FormikInput from 'components/primitives/FormikInput';
import FormikDatePicker from 'components/primitives/FormikDatePicker';
import PlacesAutocompleteView from 'components/primitives/PlacesAutocomplete';
import { useHistory } from 'react-router-dom';

import { PilotRegistrationProps } from './PilotRegistration.props';
import PilotCertificationModalView from './PilotCertificationModal';

const Span = chakra('span');

const firstName = /^[a-zA-Z]+(?: [a-zA-Z]+)?$/;
const lastName = /^[a-zA-Z]+$/;
const regZipCode = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;

const sx = {
  '.chakra-checkbox__control[data-checked]': {
    background: '#6728BB',
    borderColor: '#6728BB',
  },
  '.chakra-checkbox__control[data-focus]': {
    boxShadow: 'none',
  },
};

const PilotRegistrationSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string().required('Required'),
  phone: Yup.string().trim().matches(phoneRegExp, 'Phone number is invalid'),
  address: Yup.string(),
  state: Yup.string(),
  isNotPilotCertNumber: Yup.boolean(),
  zipcode: Yup.string()
    .matches(regZipCode, 'Please enter a valid zip code')
    .required('Please enter the zip code'),
  pilotCertNumber: Yup.string().when('isNotPilotCertNumber', {
    is: false,
    then: Yup.string()
      .trim()
      .required('Required')
      .length(7, 'should be 7 digits'),
    otherwise: Yup.string().notRequired(),
  }),
  pilotCertExpire: Yup.string().when('isNotPilotCertNumber', {
    is: false,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  companyName: Yup.string()
    .trim()
    .min(2, 'Company Name should be more then 2 characters')
    .matches(/^[A-Za-z0-9 ]+$/, 'Company Name cannot be symbols')
    .required('Company Name is required'),
});

const PilotRegistrationView = (props: PilotRegistrationProps): JSX.Element => {
  const history = useHistory();
  const certificationAlertModalRef = useRef<any>(null);

  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          phone: '',
          address: '',
          state: '',
          zipcode: undefined,
          isNotPilotCertNumber: false,
          pilotCertNumber: '',
          pilotCertExpire: new Date().toISOString(),
          companyName: '',
        }}
        validationSchema={PilotRegistrationSchema}
        onSubmit={props.onSubmit}>
        {({ values, setFieldValue, setTouched, errors }) => (
          <Form autoComplete="off">
            <Flex
              flexDir={['column-reverse', 'column-reverse', 'row']}
              justifyContent="space-between">
              <Flex flexDir="column" w="100%">
                <Flex flexDir="row" justifyContent="space-between" w="100%">
                  <FormikInput
                    useTextArea
                    name="firstName"
                    label="First Name"
                    autoComplete="new-password"
                    width="90%"
                    isRequired
                  />
                  <FormikInput
                    useTextArea
                    name="lastName"
                    label="Last Name"
                    autoComplete="new-password"
                    width="100%"
                    isRequired
                  />
                </Flex>
                {!values.isNotPilotCertNumber && (
                  <>
                    <FormikInput
                      type="number"
                      name="pilotCertNumber"
                      label="Remote Pilot Certificate Number*"
                      onBlur={() =>
                        setTouched({
                          pilotCertNumber: true,
                        })
                      }
                    />
                    <FormikDatePicker
                      name="pilotCertExpire"
                      label="Pilot Certificate Expiration*"
                      labelProps={{
                        mt: 4,
                        fontSize: 14,
                      }}
                      disablePastDates
                    />
                  </>
                )}
                <Checkbox
                  mt={3}
                  name="isNotPilotCertNumber"
                  colorScheme="purple"
                  isChecked={values.isNotPilotCertNumber}
                  alignItems="flex-start"
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setTouched({
                        pilotCertNumber: true,
                      });
                      certificationAlertModalRef.current?.onOpen();
                    }
                    setFieldValue(
                      'isNotPilotCertNumber',
                      e.currentTarget.checked,
                    );
                  }}>
                  <Text fontSize={12}>
                    I don't have a remote pilot certificate yet
                  </Text>
                </Checkbox>
                <FormikInput
                  useTextArea
                  name="email"
                  label="Email Address"
                  autoComplete="off"
                  isRequired
                />
                <FormikInput
                  useTextArea
                  name="phone"
                  label="Phone"
                  autoComplete="new-password"
                  isRequired
                />
              </Flex>
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                mt={[10, 10, 0]}
                w="100%">
                <FormikImageUpload
                  name="image"
                  uploadHandler={() => null}
                  height={100}
                  width={100}
                  buttonText="Select Picture"
                />
              </Flex>
            </Flex>

            <Flex flexDir={responsiveFlexDir}>
              <Flex flexDir="column" w="100%">
                <PlacesAutocompleteView
                  useTextArea
                  isRequired
                  value={values?.address || ''}
                  name="address"
                  labelText="Address"
                  labelProps={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                  setFieldValue={setFieldValue}
                  noPlaceholder
                  searchOptions={{
                    componentRestrictions: {
                      country: 'us',
                    },
                  }}
                  inputStyle={{
                    autoComplete: 'off',
                    onBlur: () => '',
                  }}
                  onSelect={async (address: string) => {
                    const result = await geocodeByAddress(address);
                    const { lat, lng } = await getLatLng(result?.[0]);

                    const state =
                      result?.[0]?.address_components?.find((i) =>
                        i.types.includes('administrative_area_level_1'),
                      )?.long_name ?? '';

                    const zipcode =
                      result?.[0]?.address_components?.find((i) =>
                        i.types.includes('postal_code'),
                      )?.long_name ?? '';

                    setFieldValue('state', state);
                    setFieldValue('zipcode', zipcode);
                    setFieldValue('address', address);
                    setFieldValue('latitude', lat);
                    setFieldValue('longitude', lng);
                  }}
                />
              </Flex>

              <Flex flexDir={responsiveFlexDir} w="100%">
                <Flex
                  flexDir="column"
                  ml={responsiveSpacing}
                  mr={responsiveSpacing}>
                  <FormikInput
                    name="state"
                    label="State"
                    autoComplete="new-password"
                    useTextArea
                  />
                </Flex>

                <Flex flexDir="column">
                  <FormikInput
                    type="number"
                    name="zipcode"
                    label="Zip Code"
                    autoComplete="new-password"
                    useTextArea
                  />
                </Flex>
              </Flex>
            </Flex>

            <Flex flexDir={responsiveFlexDir}>
              <Flex flexDir="column" w="100%">
                <FormikInput
                  name="companyName"
                  label="Company Name"
                  w="100%"
                  autoComplete="new-password"
                  useTextArea
                  isRequired
                />
              </Flex>
              <Flex
                style={{ visibility: 'hidden' }}
                flexDir="column"
                w="100%"
              />
            </Flex>

            <Flex flexDir={responsiveFlexDir} justifyContent="space-between">
              <Flex flex={1} flexDir="column">
                <FormikInput
                  type="password"
                  name="password"
                  label="Password"
                  isRequired
                  autoComplete="new-password"
                  id={'password' + Date.now()}
                />
              </Flex>
              <Flex ml={responsiveSpacing} flex={1} flexDir="column">
                <FormikInput
                  type="password"
                  name="confirmPassword"
                  isRequired
                  label="Password Confirmation"
                />
              </Flex>
            </Flex>
            <Checkbox
              sx={sx}
              mt="30px"
              onChange={(e) => {
                setIsChecked(e.target.checked);
              }}>
              By creating an account, I accept DroneAdair{' '}
              <Span
                fontWeight="bold"
                color="brand.500"
                as="a"
                target="_blank"
                href="https://app.termly.io/document/terms-of-service/4299c155-a916-4bcc-bf7a-8fd04124a525">
                terms of service
              </Span>
            </Checkbox>
            <Flex justify={['center', 'center', 'flex-end']}>
              <Button
                variant="outline"
                onClick={() => history.push('/login')}
                size="sm"
                px={10}
                mt={10}
                mr={[0, 5]}>
                Cancel
              </Button>

              <Button
                size="sm"
                px={10}
                type="submit"
                mt={10}
                isDisabled={!isChecked}
                isLoading={props.isLoading}>
                Create
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
      <PilotCertificationModalView ref={certificationAlertModalRef} />
    </>
  );
};

export default PilotRegistrationView;
