import { useToast } from '@chakra-ui/react';
import reportProblem from 'api/report';
import React from 'react';
import { useMutation } from 'react-query';

import { ReportModalProps } from './ReportModal.props';
import ReportModalView from './ReportModal.view';

const ReportModalContainer = (props: ReportModalProps): JSX.Element => {
  const { isOpen, onClose } = props;

  const toast = useToast();

  const [onReportProblem, { isLoading }] = useMutation(reportProblem, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Report',
        description: 'Successfullly submitted.',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    },
  });

  const onSubmit = (message: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    onReportProblem({ missionId: props.missionId!, message });
    onClose();
  };

  return (
    <ReportModalView
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};

export default ReportModalContainer;
