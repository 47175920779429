import React, { FC, useState } from 'react';

import {
  Text,
  Flex,
  Stack,
  Image,
  Center,
  Button,
  Textarea,
} from '@chakra-ui/react';

import success from 'res/req_change_success.png';

import { useMutation } from 'react-query';
import { getUserId } from 'effector/session/store';
import { requestChanges } from 'api/custom-mission';

type RequestChangeProps = {
  onSelect: (_status: Status) => void;
  customMissionId: string;
};

type Status = 'accept_mission' | 'request_changes' | 'reject';

export const RequestChange: FC<RequestChangeProps> = ({
  onSelect,
  customMissionId,
}) => {
  const [message, setMessage] = useState('');

  const [submit, { isLoading, isSuccess }] = useMutation(() => {
    const client = getUserId();

    return requestChanges(message, customMissionId, client);
  });

  if (isSuccess) {
    return (
      <Flex py={10} px={6} direction="column" flex={1}>
        <Flex flexWrap="wrap">
          <Image w="200px" h="200px" mr={4} src={success} objectFit="cover" />
          <Stack mt={4} flex={1} minW="200px">
            <Text fontSize="18px" fontWeight="bold">
              Request change sent!
            </Text>
            <Text fontSize="14px">
              Thank you for your request! We've received it and will do our best
              to accommodate your needs. Thank you for choosing us, and we
              appreciate your patience while we work on your request.
            </Text>
          </Stack>
        </Flex>
        <Button
          mt={6}
          w="150px"
          alignSelf="center"
          onClick={() => onSelect(undefined as any)}>
          CLOSE
        </Button>
      </Flex>
    );
  }

  return (
    <Flex p={6} flex={1} direction="column">
      <Text textAlign="center" fontSize="22px" fontWeight="bold">
        Request Change
      </Text>
      <Text my={4} fontSize="14px" textAlign="center">
        Do you need something changed, added or removed? Please submit your
        requests below so we can get your missions adjusted accordingly.
      </Text>
      <Textarea
        mb={4}
        autoFocus
        minH="150px"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Center flexWrap="wrap">
        <Button
          mt={2}
          w="150px"
          variant="outline"
          mr={2}
          isDisabled={isLoading}
          onClick={() => onSelect(undefined as any)}>
          CANCEL
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={message.trim().length === 0}
          mt={2}
          w="150px"
          onClick={() => submit()}>
          SEND
        </Button>
      </Center>
    </Flex>
  );
};
