import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { MdInfo } from 'react-icons/md';

import { Box, Flex, Input, Stack, Text, Tooltip } from '@chakra-ui/react';

import { useTimeoutId } from 'utils/hooks';
import { useFormikContext } from 'formik';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { Asterik } from './Asterick';
import { DaysSection } from './DaysSection';
import { SocialNetworks } from './SocialNetworks';
import { RadiusModal, RadiusModalRef } from './RadiusModal';

import CmsCompanyNameInput from '../CmsCompanyNameInput';
import FormikInput from 'components/primitives/FormikInput';
import PlacesAutocomplete from 'components/primitives/PlacesAutocomplete';
import FormikTextEditorView from 'components/primitives/FormikTextEditor/FormikTextEditor.view';

import { CMS } from 'types/cms';
import { METERS } from 'constants/common';

const CHARACTER_LIMIT = 400;
const labelProps = {
  fontSize: '16px',
  color: '#707070',
  fontWeight: 'bold',
};

type Props = {
  onSave: (shouldPublish?: boolean) => void;
};

export const BusinessDetails: FC<Props> = ({ onSave }) => {
  const timeoutIdRef = useTimeoutId();
  const radiusModalRef = useRef<RadiusModalRef>(null);

  const { values, initialValues, setFieldValue } = useFormikContext<CMS>();

  const currentIntroValue = values?.about?.introduction;
  const initialIntroValue = initialValues?.about?.introduction;

  const currentZipValue = values?.location?.zipcode;
  const initialZipValue = initialValues?.location?.zipcode;

  const radius = (values?.radius / METERS).toFixed(0);

  const fillFormAddressFieldsWithGoogleSearch = async (
    addressSelected: string | null,
  ) => {
    if (!Boolean(addressSelected)) return;

    const result = await geocodeByAddress(addressSelected as string);
    const geometry = await getLatLng(result[0]);

    const address = result?.[0]?.formatted_address || addressSelected;

    const city =
      result?.[0]?.address_components?.find((i) => i.types.includes('locality'))
        ?.long_name ?? '';

    const state =
      result?.[0]?.address_components?.find((i) =>
        i.types.includes('administrative_area_level_1'),
      )?.long_name ?? '';

    const zipcode =
      result?.[0]?.address_components?.find((i) =>
        i.types.includes('postal_code'),
      )?.long_name ?? '';

    const formattedLocation = {
      address,
      city,
      state,
      zipcode,
    };

    setFieldValue('location', formattedLocation);
    setFieldValue('gps.coordinates', [
      geometry.lng.toString(),
      geometry.lat.toString(),
    ]);

    timeoutIdRef.current = setTimeout(() => {
      onSave();
    }, 500);
  };
  return (
    <Flex flex={1} direction="column">
      <Flex gridGap="50px">
        <CmsCompanyNameInput
          containerProps={{ mt: 0, flex: 1 }}
          renderLabel={() => (
            <Text {...labelProps} mb="10px">
              Business name <Asterik />
            </Text>
          )}
        />

        <Stack flex={1}>
          <Flex>
            <Text {...labelProps}>
              Customized URL <Asterik />
            </Text>
            <Tooltip label="You can edit your business name, which will auto-update the URL">
              <Box ml="10px">
                <MdInfo fill={values?.stylings?.colorScheme} size="22px" />
              </Box>
            </Tooltip>
          </Flex>
          <Flex align="center" gridGap="10px">
            <Text fontSize="14px">{process.env.REACT_APP_URL}/cms/</Text>
            <Input isReadOnly value={initialValues?.username} />
          </Flex>
        </Stack>
      </Flex>

      <Flex mt="10px" gridGap="50px" pb="30px">
        <Flex flex={1} mt={1} flexDir="column">
          <FormikTextEditorView
            h="160px"
            w="100%"
            style={{
              height: '120px',
              maxWidth: '500px',
              maxHeight: '120px',
            }}
            maxW="500px"
            label="Introduction"
            name="about.introduction"
            labelProps={labelProps}
            onBlur={() => {
              if (currentIntroValue === initialIntroValue) {
                return;
              }
              onSave();
            }}
          />
        </Flex>

        <Flex flex={1} direction="column">
          <PlacesAutocomplete
            isRequired
            name="location.address"
            labelText="Add location"
            labelProps={labelProps}
            value={values?.location?.address || ''}
            onChange={(addressChanged) => {
              setFieldValue('location.address', addressChanged);
            }}
            onSelect={fillFormAddressFieldsWithGoogleSearch}
            searchOptions={{
              componentRestrictions: {
                country: ['us', 'ca'],
              },
              types: ['address'],
            }}
          />

          <FormikInput
            type="number"
            isRequired
            label="Zip Code"
            name="location.zipcode"
            labelProps={labelProps}
            onBlur={() => {
              if (currentZipValue === initialZipValue) {
                return;
              }

              onSave();
            }}
          />

          <>
            <FormikInput
              name="radius"
              label="Radius"
              isRequired
              isReadOnly
              value={radius + ' mi'}
              labelProps={labelProps}
              containerProps={{ mt: '15px' }}
              onClick={() => radiusModalRef?.current?.onOpen()}
            />
            <RadiusModal ref={radiusModalRef} onSave={onSave} />
          </>

          <SocialNetworks onSave={onSave} />
        </Flex>
      </Flex>

      <Flex>
        <DaysSection onSave={onSave} hideUpdateButton={false} />
      </Flex>
    </Flex>
  );
};
