import React, { FC } from 'react';

import logo from 'res/success.png';

import { Button, Center, Heading, Image, Text } from '@chakra-ui/react';

import { animationConfig, MotionFlex } from './Motion';

const Success = () => {
  return (
    <MotionFlex {...animationConfig} flex={1}>
      <Image src={logo} maxH="300px" objectFit="contain" />
      <Heading textAlign="center" fontSize="22px">
        Your website was successfully set up!
      </Heading>
      <Center>
        <Text textAlign="center" maxW="60%">
          We are happy to have you onboard, you can check your website in more
          depth in “My Website” section in the sidebar. To be able to view your
          website with the new information please click on the refresh button
        </Text>
      </Center>

      <Center gridGap="20px">
        <Button
          w="300px"
          variant="outline"
          onClick={() => window.location.assign('/')}>
          GO TO DASHBOARD
        </Button>
        <Button
          w="300px"
          onClick={() => {
            window.location.assign(
              `${window.location.origin}${window.location.pathname}`,
            );
          }}>
          REFRESH WEBSITE
        </Button>
      </Center>
    </MotionFlex>
  );
};

export default Success;
