import React, { FC } from 'react';

import { useQuery } from 'react-query';

import { getMyCMS } from 'api/cms';
import { getMissions } from 'api/mission';
import { getCustomMissions } from 'api/custom-mission';

import { selectors } from 'effector/session';

import { MobileDashboardProps } from './MobileDashboard.props';

import MobileDashboardView from './MobileDashboard.view';

const MISSIONS_PER_PAGE = 5;

const MobileDashboardContainer: FC<MobileDashboardProps> = () => {
  const user = selectors.useUser();

  const { data: cms, isLoading } = useQuery(
    `fetch-my-cms-published`,
    () => getMyCMS('PUBLISH'),
    {
      retry: 0,
      onError: () => console.log(''),
    },
  );

  const cmsId = cms?.id ? cms.id : '';
  const queryKey = [`fetch missions accepted`, cmsId];
  const queryFn = () =>
    getMissions({
      page: 1,
      cms: cmsId,
      pilot: user?.id,
      limit: MISSIONS_PER_PAGE,
      type: 0,
      dueDate: new Date().toISOString(),
    });

  const options = {
    enabled: !!cmsId,
    keepPreviousData: true,
    // refetch after 5 minutes
    refetchInterval: 1000 * 60 * 3,
  };

  const customQueryKey = [`fetch-custom-missions-open`, 0];
  const customQueryFn = () =>
    getCustomMissions({
      page: 1,
      isClient: false,
      status: 'REQUESTED',
      limit: MISSIONS_PER_PAGE,
    });
  const customOptions = {
    keepPreviousData: true,
    // refetch after 5 minutes
    refetchInterval: 1000 * 60 * 3,
  };

  const {
    data: customMissionsData,
    isFetching: isCustomMissionsFetching,
  } = useQuery(customQueryKey, customQueryFn, customOptions);

  const { data, isFetching } = useQuery(queryKey, queryFn, options);

  const missions = data?.results || [];
  const customMissions = customMissionsData?.results || [];

  return (
    <MobileDashboardView
      missions={missions}
      isSitePublished={cms?.status === 'PUBLISH' ? true : false}
      customMissions={customMissions}
      isLoadingMissions={isLoading || isFetching}
      isLoadingCustomMissions={isLoading || isCustomMissionsFetching}
    />
  );
};

export default MobileDashboardContainer;
