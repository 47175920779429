import React, { FC } from 'react';

import { MobilePacksCarousel } from '../MobilePacksCarousel';
import { MobileCarouselProps } from './MobileCarousel.props';

const MobileCarouselView: FC<MobileCarouselProps> = ({
  cms,
  brandColor,
  fontColor,
  onPackSelect,
}) => {
  const myPacks = cms?.packages || [];

  return (
    <MobilePacksCarousel
      packs={myPacks}
      fontColor={fontColor}
      brandColor={brandColor}
      onPackSelect={onPackSelect}
    />
  );
};

export default MobileCarouselView;
