import api from 'api/instance';
import { Notification } from 'types/notification';

const URL = `${process.env.REACT_APP_API_URL}/notifications`;

export type ResponseType = {
  limit: number;
  page: number;
  results: Notification[];
  totalPages: number;
  totalResults: number;
};

export const getNotifications = async (type: 0 | 1 | undefined) => {
  const { data } = await api.get<ResponseType>(`${URL}/list`, {
    params: {
      type,
    },
  });

  return data;
};

export const markAsRead = async (notificationId: string) => {
  const { data } = await api.get(`${URL}/read/${notificationId}`);

  return data;
};
