import React, { FC, useEffect, useRef, useState } from 'react';

import { SketchPicker } from 'react-color';

import { useTheme } from '@chakra-ui/system';
import { useOutsideClick, Text } from '@chakra-ui/react';
import { Box, Stack } from '@chakra-ui/layout';
import { CloseButton } from '@chakra-ui/close-button';

import { useField } from 'formik';

type ColorPickerProps = {
  onSave: (shouldPublish?: boolean) => void;
};

export const ColorPicker: FC<ColorPickerProps> = ({ onSave }) => {
  const theme = useTheme();
  const pickerRef = useRef<HTMLDivElement>(null);
  const [
    { value = theme.colors.buttonBgColor },
    { initialValue },
    field,
  ] = useField('stylings.colorScheme');
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    if (!initialValue) {
      field.setValue('#6728BB');
    }
  }, []);

  useOutsideClick({
    ref: pickerRef,
    handler: (e: any) => {
      if (!showPicker) {
        return;
      }

      setShowPicker(false);

      if (value === initialValue) {
        return;
      }

      const shouldPublish = e?.target?.id === 'website-publish-btn';

      onSave(shouldPublish);
    },
  });

  return (
    <Stack mr={6} mb={4}>
      <Box>
        {!showPicker ? (
          <Box
            h="40px"
            w="500px"
            bg="#fff"
            borderRadius="5px"
            borderWidth="1px"
            cursor="pointer"
            p="5px"
            onClick={() => setShowPicker(true)}>
            <Box w="100%" h="100%" bg={value} borderRadius="5px" />
          </Box>
        ) : (
          <Stack
            ref={pickerRef}
            isInline
            spacing={1}
            position="absolute"
            zIndex={5}>
            <SketchPicker
              color={value}
              onChangeComplete={(col) => field.setValue(col.hex)}
            />
            <CloseButton
              color="white"
              borderWidth="1px"
              bg={'buttonBgColor'}
              _hover={{ bg: 'solidButtonBgHoverColor' }}
              onClick={() => {
                setShowPicker(false);

                if (value === initialValue) {
                  return;
                }

                onSave();
              }}
            />
          </Stack>
        )}

        {!initialValue && (
          <Text mt="5px" fontSize="sm" fontWeight="bold" color="red.500">
            template color is required
          </Text>
        )}
      </Box>
    </Stack>
  );
};
