import React, { FC } from 'react';

import { Flex, Stack, Text } from '@chakra-ui/react';

import { Mission } from 'types/mission';

export const LocationDetails: FC<{ mission?: Mission }> = ({ mission }) => {
  return (
    <Flex flex={1} p={3} fontSize="14px">
      <Flex pr={3} direction="column" borderRight="1px solid #ccc">
        <Text textDecoration="underline">Coordinates</Text>
        <Stack isInline>
          <Text fontWeight="bold">
            Latitude: {mission?.gps?.coordinates?.[1]}
          </Text>
          <Text fontWeight="bold">
            Longitude: {mission?.gps?.coordinates?.[0]}
          </Text>
        </Stack>
        <Text mt={2} textDecoration="underline">
          Overview
        </Text>
        <Text fontWeight="bold">
          {typeof mission?.airspace?.overview === 'string'
            ? mission?.airspace?.overview || ''
            : mission?.airspace?.overview?.full || ''}
        </Text>
        <Text textDecoration="underline" mt={2}>
          Classes
        </Text>
        <Text fontWeight="bold">
          {mission?.airspace?.classes?.join(', ') || ''}
        </Text>
      </Flex>
      <Flex pl={3} direction="column" flex={1}>
        <Text textDecoration="underline">Advisories</Text>
        {mission?.airspace?.advisories?.map((a) => {
          return (
            <Text fontWeight="bold" key={JSON.stringify(a)}>
              {' '}
              - {a?.name || ''} {a?.value ? `: ${a?.value || ''}` : ''}
            </Text>
          );
        })}
      </Flex>
    </Flex>
  );
};
