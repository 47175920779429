import { getPackages } from 'api/package';
import { QueryResult, useQuery } from 'react-query';
import { Package } from 'types/cms';
import { ApiResponse } from 'types/mission';

const usePackagesList = (): QueryResult<ApiResponse<Package[]>, unknown> => {
  const useQueryResult: QueryResult<
    ApiResponse<Package[]>,
    unknown
  > = useQuery('fetch packages', async () => getPackages({}));

  return useQueryResult;
};

export default usePackagesList;
