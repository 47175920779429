import { createEvent, createStore, forward } from 'effector';

import { useStore } from 'effector-react';
import { logoutEvent } from 'effector/session/events';

import { SubscriptionItem } from 'types/chargebee';

import { getSubscriptionsFx } from './effects';

export const fetchCbSubsEv = createEvent();

forward({
  from: fetchCbSubsEv,
  to: getSubscriptionsFx,
});

const $subscriptions = createStore<SubscriptionItem[]>([])
  .on(getSubscriptionsFx.doneData, (_, payload) => payload)
  .reset(logoutEvent);

const $pending = getSubscriptionsFx.pending;
const $subId = $subscriptions.map((s) => s?.[0]?.subscription?.id, '');

export const selectors = {
  useCbSubId: () => useStore($subId),
  useIsCbFetching: () => useStore($pending),
  useAllCbSubs: () => useStore($subscriptions),
  useCbSub: () => {
    const subs = useStore($subscriptions);
    const _sub = subs?.[0] || null;

    return _sub;
  },
  useIsSubscribed: () => {
    let isSubExist = false;

    const subs = useStore($subscriptions);

    if (subs?.length == 0) {
      return isSubExist;
    }

    isSubExist = subs.some((s) => {
      const isInvalid = ['cancelled', 'non_renewing', 'paused'].includes(
        s.subscription.status,
      );

      return !isInvalid;
    });

    return isSubExist;
  },
};
