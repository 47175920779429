import React, { FC, useEffect } from 'react';

import { Image } from '@chakra-ui/image';
import { Button } from '@chakra-ui/button';
import { CircularProgress } from '@chakra-ui/progress';
import { Badge, Center, Flex, Heading, Text } from '@chakra-ui/layout';

import stripeText from 'res/stripe.png';

import { useHistory } from 'react-router';

import * as stripe from 'effector/stripe';
import * as session from 'effector/session';

import { useIsMobile } from 'utils/hooks';

import { useMutation } from 'react-query';

import {
  createConnectAccount,
  getConnectDashboardUrl,
  updateConnectedAcc,
} from 'api/stripe';
import { updateUserEvent } from 'effector/session/events';

const PATH = '/settings?redirect=true';

export const StripeConnect: FC = () => {
  const history = useHistory();

  const isMobile = useIsMobile();

  const isNull = stripe.selectors.useIsNull();
  const isPending = stripe.selectors.useIsPending();
  const isEnabled = stripe.selectors.useIsEnabled();
  const isAccConnected = stripe.selectors.useIsConnected();

  const stripeId = session.selectors.useStripeId();

  const [updateLink, { isLoading }] = useMutation(
    async () => {
      let _stripeId = stripeId;

      if (!_stripeId) {
        const stripeAccount = await createConnectAccount();

        _stripeId = stripeAccount.id;

        updateUserEvent({ stripeId: _stripeId } as any);
      }

      return updateConnectedAcc(PATH, _stripeId);
    },
    {
      onSuccess: (stripeUrl: string) => {
        window.open(stripeUrl, '_self');
      },
    },
  );

  const [getDashboardLink, { isLoading: isLoadingLink }] = useMutation(
    () => getConnectDashboardUrl(stripeId || ''),
    {
      onSuccess: (stripeUrl: string) => {
        window.open(stripeUrl, '_blank');
      },
    },
  );

  useEffect(() => {
    const onStripeRedirect = async () => {
      history.replace({ search: '' });
    };

    const search = new URLSearchParams(history.location?.search);
    const redirect = search.get('redirect');

    if (redirect !== 'true') {
      return;
    }

    onStripeRedirect();
  }, []);

  return (
    <Flex
      mb={['100px', '100px', 0, 0, 0]}
      mt={[2, 4, 14]}
      px={[4, 6]}
      direction="column"
      flex={1}>
      <Heading
        fontFamily="Monument Extended"
        color="primaryColor"
        fontWeight="200"
        fontSize="xl">
        Stripe Connect
      </Heading>
      <Flex
        mt={6}
        p={[2, 4, 6, 10]}
        bg="#fff"
        direction="column"
        boxShadow={
          isMobile ? undefined : '0px 0px 20px 0px rgba(103, 40, 187, .1)'
        }>
        {isPending ? (
          <Center flexDirection="column">
            <CircularProgress color="brand.500" isIndeterminate />
            <Text mt={4} fontSize="18px">
              Please wait... this might take a few moments
            </Text>
          </Center>
        ) : isNull ? (
          <Center>
            <Text fontSize="18px">
              Please Refresh the page to fetch the latest data
            </Text>
          </Center>
        ) : (
          <>
            <Flex justify="space-between" align="flex-end">
              <Badge
                w="auto"
                px={6}
                py={1}
                borderRadius="5px"
                fontFamily="Monument Extended"
                fontWeight="200"
                fontSize="xl"
                colorScheme={
                  isAccConnected ? (isEnabled ? 'green' : 'yellow') : 'red'
                }
                textTransform="capitalize">
                {isAccConnected
                  ? isEnabled
                    ? 'Active'
                    : 'Profile Incomplete'
                  : 'Not Connected'}
              </Badge>
              {!isMobile && (
                <>
                  {isAccConnected ? (
                    isEnabled ? (
                      <Button
                        variant="ghost"
                        color="#fff"
                        bg="#3AC917"
                        leftIcon={<Text>Go to</Text>}
                        rightIcon={<Text>Dashboard</Text>}
                        isLoading={isLoadingLink}
                        onClick={() => getDashboardLink()}>
                        <Image src={stripeText} h="22px" />
                      </Button>
                    ) : (
                      <Button
                        variant="ghost"
                        color="#fff"
                        bg="yellow.500"
                        leftIcon={<Text>Update</Text>}
                        rightIcon={<Text>Information</Text>}
                        isLoading={isLoading}
                        onClick={() => updateLink()}>
                        <Image src={stripeText} h="22px" />
                      </Button>
                    )
                  ) : (
                    <Button
                      variant="ghost"
                      color="#fff"
                      bg="#2672F2"
                      rightIcon={<Image src={stripeText} h="22px" />}
                      isLoading={isLoading}
                      onClick={() => updateLink()}>
                      Connect
                    </Button>
                  )}
                </>
              )}
            </Flex>
            <Text mt={6} fontWeight="500" fontSize={['16px', '20px']}>
              {isAccConnected
                ? isEnabled
                  ? 'Your Stripe is successfully connected, if you need to change your info, please click the button.'
                  : 'Your Stripe Account is created, however you have not submitted all the required details. Kindly click on the "Update Information" button and submit all the required details.'
                : 'You need to connect Stripe so we can send you payments, please click the button and set up Stripe.'}
            </Text>

            {isMobile && (
              <Flex direction="column" mt="20px">
                {isAccConnected ? (
                  isEnabled ? (
                    <Button
                      variant="ghost"
                      color="#fff"
                      bg="#3AC917"
                      leftIcon={<Text>Go to</Text>}
                      rightIcon={<Text>Dashboard</Text>}
                      isLoading={isLoadingLink}
                      onClick={() => getDashboardLink()}>
                      <Image src={stripeText} h="22px" />
                    </Button>
                  ) : (
                    <Button
                      variant="ghost"
                      color="#fff"
                      bg="yellow.500"
                      leftIcon={<Text>Update</Text>}
                      rightIcon={<Text>Information</Text>}
                      isLoading={isLoading}
                      onClick={() => updateLink()}>
                      <Image src={stripeText} h="22px" />
                    </Button>
                  )
                ) : (
                  <Button
                    variant="ghost"
                    color="#fff"
                    bg="#2672F2"
                    rightIcon={<Image src={stripeText} h="22px" />}
                    isLoading={isLoading}
                    onClick={() => updateLink()}>
                    Connect
                  </Button>
                )}
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};
