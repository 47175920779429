import React, { FC } from 'react';

import { Flex } from '@chakra-ui/layout';

import { Props } from './MobileMissionCreationFooter.props';

const View: FC<Props> = (props) => {
  const { children } = props;

  return (
    <Flex
      bg="#fff"
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      h="70px"
      justify="center"
      align="center"
      direction="column">
      {children}
    </Flex>
  );
};

export default View;
