import { useHistory, useLocation } from 'react-router-dom';

type SearchParamType = 'page' | 'search' | 'status';

const useSearchParamState = <T extends SearchParamType>(searchParam: T) => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get(searchParam) || '';

  const clearSearch = () => {
    searchParams.delete(searchParam);

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const setSearchValue = (
    value: string,
    options?: {
      valueToClear?: Exclude<SearchParamType, T>;
    },
  ) => {
    if (value.trim() === '') {
      clearSearch();

      return;
    }

    if (
      options &&
      options.valueToClear &&
      searchParams.get(options.valueToClear)
    ) {
      searchParams.delete(options.valueToClear);
    }

    searchParams.set(searchParam, value);

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return [searchValue, setSearchValue, clearSearch] as const;
};

export default useSearchParamState;
