import React, { FC, useState } from 'react';

import { Center, Divider, Stack, Text } from '@chakra-ui/layout';

import { MdInfoOutline } from 'react-icons/md';

import { useField } from 'formik';

import { NormalText } from 'components/primitives/NormalText';
import { EquipmentInput } from 'components/primitives/EquipmentInput';

import { Row, Container } from './EquipmentsSection.style';

import { GeneratedProps } from './EquipmentsSection.props';

import Testimonials from './Testimonials.view';
import { Testimony } from 'types/cms';
import { useDisclosure } from '@chakra-ui/react';
import { queryCache, useMutation } from 'react-query';
import authApi from 'api/auth';
import { uploadFileBySignedURL } from 'api/media';

const colorScheme = '#6728BB';

const View: FC<GeneratedProps> = ({ isNewCMS, onSave, isLoading }) => {
  const { value } = useField('equipments')[0];

  //Individual testimony
  const [input, , testimonyHelper] = useField<Partial<Testimony>>('testimony');
  const { value: testimony } = input;
  const { setValue: setTestimony } = testimonyHelper;

  //Array of testimonies
  const [testimonialsInput, , helpers] = useField<any>('testimonials');
  const { value: testimonials = [] } = testimonialsInput;
  const { setValue: setTestimonials } = helpers;

  const addNewTestimony = async () => {
    const newTestimony = {
      name: testimony.name,
      position: testimony.position,
      testimony: testimony.testimony,
      imageUrl: testimony.imageUrl?.name,
      testimonyFile: testimony.imageUrl,
    };

    setTestimonials([...testimonials, newTestimony]);
    setTestimony({});
  };

  const onDeleteTestimony = (t: Testimony) => {
    const newTestimonials = testimonials
      .map((x: Testimony) => {
        if (x.name !== t.name) return x;
        if (x.position !== t.position) return x;
        if (x.testimony !== t.testimony) return x;
        return;
      })
      .filter(Boolean);

    setTestimonials(newTestimonials);
    onSave();
    setTimeout(() => {
      queryCache.invalidateQueries(`fetch-my-cms`, {
        exact: true,
      });

      queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
        exact: true,
      });
    }, 500);
  };

  const { value: stylings } = useField<any>('stylings')[0];

  const color = stylings?.colorScheme || colorScheme;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [enabledTestimonials] = useField('enableTestimonials');

  const drones: any[] = value?.filter((e: any) => e.type === 'DRONE');
  const camera: any[] = value?.filter((e: any) => e.type === 'CAMERA');
  const others: any[] = value?.filter(
    (e: any) => !['DRONE', 'CAMERA'].includes(e.type),
  );

  return (
    <Container id="equipments">
      <NormalText fontSize="14px" textColor="#707070" fontWeight="bold">
        Equipments
      </NormalText>
      <Divider w="100%" borderWidth={1} my={2} />
      {isNewCMS ? (
        <Center
          p={2}
          bg="#fff"
          borderRadius="10px"
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.08)">
          <MdInfoOutline color={color} />
          <Text ml={1} fontSize="12px" fontWeight="bold" color={color}>
            Please enter a company name in order to edit this field
          </Text>
        </Center>
      ) : (
        <Row>
          <Stack>
            <EquipmentInput
              name="CAMERA"
              label="Cameras"
              placeholder="camera name"
            />
            {camera?.map((d) => (
              <EquipmentInput
                key={d.id}
                id={d.id}
                value={d.fileName}
                name="CAMERA"
              />
            ))}
          </Stack>
          <Stack>
            <EquipmentInput
              name="DRONE"
              label="Drones"
              placeholder="drone name"
            />
            {drones?.map((d) => (
              <EquipmentInput
                key={d.id}
                id={d.id}
                value={d.fileName}
                name="DRONE"
              />
            ))}
          </Stack>
          <Stack>
            <EquipmentInput
              name="SENSOR"
              label="Others"
              placeholder="others name"
            />
            {others?.map((d) => (
              <EquipmentInput
                key={d.id}
                id={d.id}
                value={d.fileName}
                name="SENSOR"
              />
            ))}
          </Stack>
        </Row>
      )}
      <Testimonials
        testimonials={testimonials}
        addNewTestimony={addNewTestimony}
        isEnabledTestimonials={enabledTestimonials.value}
        isOpen={isOpen}
        onOpen={onOpen}
        color={color}
        onClose={onClose}
        onSaveTestimonial={onSave}
        onDeleteTestimony={onDeleteTestimony}
      />
    </Container>
  );
};

export default View;
