import React from 'react';

import { CollapsibleMenuProps } from './CollapsibleMenu.props';
import CollapsibleMenuView from './CollapsibleMenu.view';
import { useHistory } from 'react-router-dom';
import { logoutEvent } from 'effector/session/events';
import { queryCache } from 'react-query';
import { SessionStore } from 'effector/session/store';

const CollapsibleMenuContainer = (props: CollapsibleMenuProps): JSX.Element => {
  const { push } = useHistory();
  const userName = SessionStore.getState()?.session?.user?.firstName ?? '';

  const menuActions = (item: any) => {
    if (item.name === 'Support') {
      return window.open(item.link);
    }
    if (item.name === 'Profile') {
      return push(item.link);
    }
    if (item.name === 'Log out') {
      logoutEvent();

      queryCache.removeQueries();

      return push(item.link);
    }
  };

  return <CollapsibleMenuView menuActions={menuActions} userName={userName} />;
};

export default CollapsibleMenuContainer;
