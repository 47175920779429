import React from 'react';

import { Box, Text, Flex, Stack, Center } from '@chakra-ui/react';

import { MdVideocam, MdCameraAlt, MdInfoOutline } from 'react-icons/md';

import { ImagePicker } from './ImagePicker';
import { VideoPicker } from './VideoPicker';
import { NormalText } from 'components/primitives/NormalText';
import { useFormikContext } from 'formik';

const colorScheme = '#6728BB';

const PreviousWorkSectionView = ({
  isNewCMS = false,
}: {
  isNewCMS?: boolean;
}): JSX.Element => {
  const { values } = useFormikContext<any>();
  const color = values?.stylings?.colorScheme || colorScheme;

  return (
    <Stack pt="20px" spacing={4} id="previous_work" mb={4} maxW="1000px">
      {isNewCMS ? (
        <Center
          p={2}
          bg="#fff"
          borderRadius="10px"
          boxShadow="0px 0px 10px 0px rgba(0,0,0,0.08)">
          <MdInfoOutline color={color} />
          <Text ml={1} fontSize="12px" fontWeight="bold" color={color}>
            Please enter a company name in order to edit this field
          </Text>
        </Center>
      ) : (
        <>
          <Stack isInline>
            <Box as={MdCameraAlt} color={color} size="20px" />
            <NormalText>Photos</NormalText>
          </Stack>
          <ImagePicker />
          <Stack isInline pt={4}>
            <Box as={MdVideocam} color={color} size="20px" />
            <NormalText>Videos</NormalText>
          </Stack>
          <VideoPicker />
        </>
      )}
    </Stack>
  );
};

export default PreviousWorkSectionView;
