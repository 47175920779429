import React, { FC, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { MdAddCircle, MdClose, MdPeople, MdPlayArrow } from 'react-icons/md';
import FormikDatePicker from 'components/primitives/FormikDatePicker';
import { addDays } from 'date-fns';
import TimePicker from 'components/primitives/TimePicker';
import FormikInput from 'components/primitives/FormikInput';

type Props = {
  isMobile?: boolean;
};

const brandColor = '#6728BB';
const labelProps = {
  fontSize: '12px',
  color: '#4f4f4f',
};
const fontSize = '12px';
const css = {
  '::placeholder': {
    fontStyle: 'italic',
    fontSize: '14px',
  },
};

const HourInput: FC<{
  type: 'Start' | 'End';
  timeName: string;
}> = ({ type, timeName }) => {
  const [, meta, helpers] = useField(timeName);

  return (
    <Stack>
      <Text fontSize="12px" fontWeight="bold">
        {type} Hour
      </Text>
      <Flex>
        <TimePicker
          brandColor={brandColor}
          isInvalid={Boolean(meta.touched && meta.error)}
          onClick={() => helpers.setTouched(true)}
          onChange={(time) => helpers.setValue(time)}
        />
      </Flex>
    </Stack>
  );
};

export const LiveOnsiteSection = (props: Props) => {
  const { isMobile } = props;
  const { values, setFieldValue } = useFormikContext<any>();

  const [days, setDays] = useState<number>(1);

  const isLive = values?.liveEvent;

  return (
    <Flex
      mt={4}
      pb={4}
      pt={0}
      direction="column"
      border={isMobile ? 'nobe' : '1px solid #ccc'}
      borderRadius="5px">
      <Flex px={4} flexWrap="wrap">
        <Flex
          mt={4}
          flexDir="row"
          alignContent="space-between"
          justifyContent="space-between">
          <Stack isInline align="center">
            <Box
              maxH="18px"
              border={
                !isMobile ? `1px solid ${brandColor}` : `1px solid #908361`
              }
              borderRadius="2px">
              <MdPlayArrow color={'#908361'} />
            </Box>
            <Text
              fontSize={fontSize}
              fontWeight="bold"
              color={isMobile ? '#908361' : '#373737'}>
              Is this a live event?
            </Text>
          </Stack>
          {isMobile && <Box w={'65px'} />}
          <RadioGroup
            defaultValue={'0'}
            onChange={(e) => {
              setFieldValue('liveEvent', Boolean(parseInt(e as any)));
            }}
            sx={{
              '.chakra-radio__control[data-checked]': {
                background: brandColor,
                borderColor: brandColor,
              },
              '.chakra-radio__control[data-focus]': {
                boxShadow: 'none',
              },
            }}>
            <Stack direction="row">
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Box w="100px" h="10px" />
        <Flex mt={4}>
          <Stack isInline align="center">
            <Box>
              <MdPeople color={isMobile ? '#908361' : brandColor} />
            </Box>
            <Text
              fontSize={fontSize}
              fontWeight="bold"
              color={isMobile ? '#908361' : '#373737'}>
              Do you need to be on site?
            </Text>
          </Stack>
          {isMobile && <Box w={5} />}
          <RadioGroup
            defaultValue={'0'}
            onChange={(e) => {
              setFieldValue('onsite', Boolean(parseInt(e as any)));
            }}
            sx={{
              '[data-focus]': { boxShadow: 'none' },
              '.chakra-radio__control[data-checked]': {
                background: brandColor,
                borderColor: brandColor,
              },
            }}>
            <Stack direction="row">
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </Stack>
          </RadioGroup>
        </Flex>
      </Flex>
      {(isLive || (!isLive && values.onsite)) && (
        <Flex mt={4} pt={4} px={4} borderTop="1px solid #ccc" flexWrap="wrap">
          <FormikDatePicker
            name="liveDay"
            label="Day"
            labelProps={labelProps}
            disablePastDates={true}
            textFieldProps={{
              css,
              w: '280px',
              fontSize: '14px',
            }}
            containerProps={{ maxW: '280px' }}
            minDate={addDays(new Date(), 1)}
          />
          <Box w="20px" h="10px" />
          <HourInput type="Start" timeName="liveStartTime" />
          <Box w="20px" h="10px" />
          <HourInput type="End" timeName="liveEndTime" />
        </Flex>
      )}
      {(isLive || (!isLive && values.onsite)) && days > 1 && (
        <Flex mt={4} pt={4} px={4} flexWrap="wrap" align="flex-end">
          <FormikDatePicker
            name="liveDay2"
            label="Day"
            labelProps={labelProps}
            disablePastDates={true}
            textFieldProps={{
              css,
              w: '280px',
              fontSize: '14px',
            }}
            containerProps={{ maxW: '280px' }}
          />
          <Box w="20px" h="10px" />
          <HourInput type="Start" timeName="liveStartTime2" />
          <Box w="20px" h="10px" />
          <HourInput type="End" timeName="liveEndTime2" />
          {days == 2 && (
            <IconButton
              ml={4}
              mb={1}
              isRound
              size="sm"
              aria-label="close-2-btn"
              icon={<MdClose />}
              borderColor={brandColor}
              bg={brandColor}
              _hover={{
                bg: `${brandColor}10`,
              }}
              _active={{
                bg: `${brandColor}30`,
              }}
              onClick={() => {
                setDays(1);
                setFieldValue('liveDay2', undefined);
              }}
            />
          )}
        </Flex>
      )}
      {(isLive || (!isLive && values.onsite)) && days > 2 && (
        <Flex mt={4} pt={4} px={4} flexWrap="wrap" align="flex-end">
          <FormikDatePicker
            name="liveDay3"
            label="Day"
            labelProps={labelProps}
            disablePastDates={true}
            textFieldProps={{
              css,
              w: '280px',
              fontSize: '14px',
            }}
            containerProps={{ maxW: '280px' }}
          />
          <Box w="20px" h="10px" />
          <HourInput type="Start" timeName="liveStartTime3" />
          <Box w="20px" h="10px" />
          <HourInput type="End" timeName="liveEndTime3" />
          <IconButton
            ml={4}
            mb={1}
            isRound
            size="sm"
            aria-label="close-2-btn"
            icon={<MdClose />}
            borderColor={brandColor}
            bg={brandColor}
            _hover={{
              bg: `${brandColor}10`,
            }}
            _active={{
              bg: `${brandColor}30`,
            }}
            onClick={() => {
              setDays(2);
              setFieldValue('liveDay3', undefined);
            }}
          />
        </Flex>
      )}
      {days <= 3 && !isLive && values.onsite ? (
        <Center mt={6}>
          <Button
            size="sm"
            onClick={() => {
              setDays((prev) => (prev == 3 ? prev : prev + 1));
            }}
            leftIcon={<MdAddCircle size="20px" />}
            variant="outline"
            borderColor={brandColor}
            color={brandColor}
            _hover={{
              bg: `${brandColor}10`,
            }}
            _active={{
              bg: `${brandColor}30`,
            }}>
            ADD ANOTHER DAY
          </Button>
        </Center>
      ) : null}
      {values.onsite && (
        <Flex flexDirection="column" p={4}>
          <Stack isInline align="center">
            <MdPeople color={brandColor} />
            <Text fontSize={fontSize} fontWeight="bold">
              On Site Contact Information
            </Text>
          </Stack>
          <Flex
            my={2}
            w="100%"
            flex={1}
            gridGap="30px"
            align="center"
            flexWrap={{
              base: 'wrap',
              sm: 'wrap',
              md: 'wrap',
              lg: 'nowrap',
            }}>
            <FormikInput
              name="contactName"
              label="Name"
              placeholder="Your name here"
              minW="250px"
              labelProps={labelProps}
            />
            <FormikInput
              name="contactEmail"
              label="Email"
              placeholder="Your email here"
              minW="250px"
              labelProps={labelProps}
            />
            <FormikInput
              type="number"
              name="contactPhoneNumber"
              label="Phone number"
              placeholder="Your phone number here"
              minW="250px"
              labelProps={labelProps}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
