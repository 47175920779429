import React, { FC } from 'react';

import {
  Box,
  Stack,
  Flex,
  Text,
  Center,
  Spinner,
  Button,
  Avatar,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  VisuallyHidden,
} from '@chakra-ui/react';
import {
  MdInfo,
  MdNewReleases,
  MdCheckCircle,
  MdChevronRight,
} from 'react-icons/md';

import useMissionsList from 'utils/hooks/useMissions';
import calculateDueDate from 'utils/calculateDueDate';
import { getIndustryName } from 'constants/industriesMap';
import { missionStatusMap } from 'constants/missionStatusMap';
import { MissionStatuses } from 'types/mission';
import { useHistory } from 'react-router-dom';
import { usePilotCollaborations } from 'utils/hooks';

// import Pagination from 'components/primitives/Pagination';

const tabPaddingX = [1, 1, 5];
const tabPaddingY = [1, 1, 2];
const responsiveContainer = ['auto', 'auto', '500px', '600px', '900px'];

type Props = {
  type: '0' | '1';
};

export const PilotList: FC<Props> = ({ type }) => {
  const { push } = useHistory();

  const { data, isLoading: isLoadingPilots } = usePilotCollaborations(type);
  const { data: missions, isLoading: isLoadingMissions } = useMissionsList({
    limit: 1000,
    type: null,
    dueDate: new Date().toISOString(),
  });

  const isLoading = isLoadingMissions || isLoadingPilots;

  return (
    <Box px="15px">
      <Accordion as="div" mt={4} mx="10px" borderColor="#fff">
        <AccordionItem>
          <AccordionButton
            p={0}
            px="2"
            flex={1}
            border="none"
            _focus={{ outline: 'none' }}>
            <Flex shadow="sm" align="center" flex={1} color="black">
              <Box px={tabPaddingX} py={tabPaddingY} w="100px">
                <VisuallyHidden>
                  <Avatar />
                </VisuallyHidden>
              </Box>
              <Stack flex={1} px={tabPaddingX} py={tabPaddingY}>
                <Text fontWeight="bold" fontSize="md" w="100%">
                  Name
                </Text>
              </Stack>
              <Stack flex={1.5} px={tabPaddingX} py={tabPaddingY}>
                <Text fontWeight="bold" textAlign="left" fontSize="md">
                  Company
                </Text>
              </Stack>
              <Stack
                flex={1}
                align="flex-end"
                px={tabPaddingX}
                py={tabPaddingY}>
                <Box w="150px" />
              </Stack>
            </Flex>

            <Box w="35px" />
          </AccordionButton>
        </AccordionItem>
      </Accordion>

      <Box px="10px" maxH="76vh" overflowY="auto" pb={4}>
        {isLoading ? (
          <Center p="20px">
            <Spinner />
          </Center>
        ) : data && data?.length > 0 ? (
          <>
            {data?.map(({ pilot, cms }: any) => {
              const pilotMissions = missions?.results?.filter(
                (m) => m?.pilot?.id === pilot?._id,
              );

              return (
                <Accordion
                  key={(pilot as any)._id}
                  allowToggle
                  allowMultiple
                  flex={1}
                  my={2}>
                  <AccordionItem
                    borderRadius="5px"
                    borderWidth="1px"
                    _focus={{ borderWidth: 0 }}>
                    <AccordionButton p={0} px="2" flex={1}>
                      <Flex shadow="sm" align="center" flex={1} color="black">
                        <Box px={tabPaddingX} py={tabPaddingY} w="100px">
                          <Avatar />
                        </Box>
                        <Stack flex={1} px={tabPaddingX} py={tabPaddingY}>
                          <Text fontSize="md" w="100%">
                            {pilot?.firstName || ''} {pilot?.lastName || ''}
                          </Text>
                        </Stack>
                        <Stack flex={1.5} px={tabPaddingX} py={tabPaddingY}>
                          <Text textAlign="left" fontSize="md">
                            {pilot?.companyName || ''}
                          </Text>
                        </Stack>
                        <Stack
                          flex={1}
                          align="flex-end"
                          px={tabPaddingX}
                          py={tabPaddingY}>
                          <Button
                            size="sm"
                            w="150px"
                            onClick={() => {
                              const cmsPath = (cms?.stylings?.url as string)
                                ?.split?.('/')
                                ?.slice?.(3, 5)
                                ?.join?.('/');

                              const path = cms?.username || cmsPath;

                              const cmsUrl = `${window.location.origin}/cms/${path}`;

                              window.open(cmsUrl);
                            }}>
                            Book Again
                          </Button>
                        </Stack>
                      </Flex>

                      <AccordionIcon mr={2} />
                    </AccordionButton>
                    <AccordionPanel py={4} borderTopWidth="1px" flex={1}>
                      {pilotMissions?.map((mission) => {
                        return (
                          <Box
                            fontSize={[10, 10, 12]}
                            shadow="sm"
                            borderWidth="1px"
                            borderRadius="5px"
                            mb={2}
                            cursor="pointer"
                            key={mission.id}
                            display={'flex'}
                            flexDirection={'row'}
                            onClick={() =>
                              push(`mission/details/${mission.id}`)
                            }>
                            <Box
                              px="10px"
                              w="100%"
                              overflowX="auto"
                              minWidth={responsiveContainer}
                              onClick={() => {
                                console.log('');
                              }}>
                              <Flex pt={2} flex={1} color="grey">
                                <Box
                                  px={tabPaddingX}
                                  py={tabPaddingY}
                                  w="100px">
                                  <Text isTruncated>{mission.id}</Text>
                                </Box>
                                <Box
                                  minW="250px"
                                  flex={2}
                                  px={tabPaddingX}
                                  py={tabPaddingY}>
                                  <Text fontSize="md" noOfLines={1}>
                                    {mission.location}
                                  </Text>
                                </Box>
                                <Box
                                  flex={2.5}
                                  px={tabPaddingX}
                                  py={tabPaddingY}>
                                  {mission.eventStart === mission.dueDate ? (
                                    <Text fontSize="md">{`${calculateDueDate(
                                      mission.dueDate,
                                    )}`}</Text>
                                  ) : (
                                    <Text fontSize="md">{`${calculateDueDate(
                                      mission.eventStart,
                                    )} - ${calculateDueDate(
                                      mission.dueDate,
                                    )}`}</Text>
                                  )}
                                </Box>
                                <Box
                                  flex={1.5}
                                  px={tabPaddingX}
                                  py={tabPaddingY}>
                                  <Text fontSize="md" isTruncated>
                                    {getIndustryName(
                                      mission?.missionIndustry || '',
                                    )}
                                  </Text>
                                </Box>
                                <Box flex={1} px={tabPaddingX} py={tabPaddingY}>
                                  <Text fontSize="md" isTruncated>
                                    {
                                      missionStatusMap[
                                        mission?.status || 'DEFAULT'
                                      ]
                                    }
                                  </Text>
                                </Box>
                                <Box flex={1} px={tabPaddingX} py={tabPaddingY}>
                                  <Flex>
                                    {mission.status ===
                                    MissionStatuses.COMPLETED ? (
                                      <MdCheckCircle
                                        size="18px"
                                        color="#34D17D"
                                      />
                                    ) : [
                                        MissionStatuses.SCHEDULED,
                                        MissionStatuses.ASSETS_UPLOADED,
                                        MissionStatuses.FLIGHT_COMPLETED,
                                        MissionStatuses.ORDERED,
                                      ].includes(
                                        mission.status as MissionStatuses,
                                      ) ? (
                                      <MdNewReleases
                                        size="18px"
                                        color="#018DE1"
                                      />
                                    ) : (
                                      <MdInfo size="18px" color="#E9243Fed" />
                                    )}
                                    <MdChevronRight
                                      size="18px"
                                      color="#6728BB"
                                    />
                                  </Flex>
                                </Box>
                              </Flex>
                            </Box>
                          </Box>
                        );
                      })}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            })}
          </>
        ) : (
          <Center p={5}>
            <Text>No Assigned Pilots</Text>
          </Center>
        )}
      </Box>

      {/* <Box py={3}>
        <Pagination
          totalPages={1}
          currentPage={1}
          onPreviousClick={() => console.log('')}
          onNextClick={() => console.log('')}
          onPageNumberClick={(pageNo) => console.log('')}
        />
      </Box> */}
    </Box>
  );
};
