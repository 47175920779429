import React from 'react';
import { Text } from '@chakra-ui/layout';

import { NormalTextProps } from './NormalText.props';
import { useTheme } from '@chakra-ui/system';

export const NormalTextView = ({
  children,
  ...props
}: NormalTextProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Text
      fontSize="xs"
      fontWeight="600"
      color={theme.colors.textColor}
      {...props}>
      {children}
    </Text>
  );
};

export default NormalTextView;
