import { differenceInDays, format } from 'date-fns';
import { FormValuesProps } from 'routes/client/Dashboard/Dashboard.props';
import * as Yup from 'yup';

export const MissionDetailsSchema = Yup.object().shape({
  liveEvent: Yup.boolean(),
  onsite: Yup.boolean(),
  rush: Yup.boolean(),
  eventStart: Yup.date().required(),
  dueDate: Yup.date(),
  notes: Yup.string(),
  liveDay: Yup.date(),
  addDay: Yup.boolean(),
  liveDay1: Yup.date(),
  liveStartHour1: Yup.string(),
  liveStartMeridian1: Yup.string(),
  liveDay2: Yup.date(),
  liveStartHour2: Yup.string(),
  liveStartMeridian2: Yup.string(),
  liveDay3: Yup.date(),
  liveStartHour3: Yup.string(),
  liveStartMeridian3: Yup.string(),
  liveEndHour: Yup.string(),
  liveEndMeridian: Yup.string(),
  contactName: Yup.string().when('onsite', {
    is: true,
    then: Yup.string().required('Contact Name is Required'),
  }),
  contactPhoneNumber: Yup.string().when('onsite', {
    is: true,
    then: Yup.string().required('Contact Phone Number is Required'),
  }),
  contactEmail: Yup.string().when('onsite', {
    is: true,
    then: Yup.string().email().required('Contact Email is Required'),
  }),
  acknowledgeWeather: Yup.boolean()
    .oneOf([true], 'You must acknowledge that inclement weather may occur!')
    .required('This is required'),
  meetupTime: Yup.array().of(Yup.date()).nullable(true),
  locationDetail: Yup.string(),
});

export const getDefaultFormDetails = (values: FormValuesProps): any => ({
  liveEvent: values.liveEvent ?? false,
  onsite: values?.onsite ?? false,
  rush: values?.rush ?? false,
  eventStart: values.eventStart ?? format(new Date(), 'MM/dd/yyyy'),
  dueDate: values?.dueDate ?? format(new Date(), 'MM/dd/yyyy'),
  notes: values?.notes ?? '',
  liveDay: format(new Date(), 'MM/dd/yyyy'),
  addDay: false,
  liveDay1: format(new Date(), 'MM/dd/yyyy'),
  liveStartHour1: '',
  liveStartMeridian1: '',
  liveDay2: format(new Date(), 'MM/dd/yyyy'),
  liveStartHour2: '',
  liveStartMeridian2: '',
  liveDay3: format(new Date(), 'MM/dd/yyyy'),
  liveStartHour3: '',
  liveStartMeridian3: '',
  liveEndHour: '',
  liveEndMeridian: '',
  contactName: '',
  contactPhoneNumber: '',
  contactEmail: '',
  acknowledgeWeather: false,
  meetupTime: [],
  locationDetail: '',
});

export const missionTypeMap: any = {
  // REAL_ESTATE: 'Real Estate',
  // PROPERTY_MANAGEMENT: 'Property Management',
  // CONSTRUCTION: 'Construction',
  OTHERS: 'Request a Quote',
};

export const packMap: any = {
  basic: 'Basic Pack',
  professional: 'Professional Pack',
  full: 'Full Pack',
  complete: 'Complete HD Pack',
};

export const packMapItems: any = {
  basic: ['15 High Resolution Aerial Photos'],
  professional: [
    '15 High Resolution Aerial Photos',
    '(1) 360 Interactive Panorama',
  ],
  full: [
    '15 High Resolution Aerial Photos',
    '(1) 360 Interactive Panorama',
    '(5) 4K Aerial Video Files',
  ],
  complete: [
    '15 High Resolution Aerial Photos',
    '(1) 360 Interactive Panorama',
    '(5) 4K Aerial Video Files',
    '(1) 1 Minute Edited Video with text, logo and music',
  ],
};

export const priceMap: any = {
  basic: 180,
  professional: 299,
  full: 399,
  complete: 499,
};
