import React from 'react';

import createMissionFunc from './services/CreateMission';

import MobileCreateCustomMissionView from './MobileCreateCustomMission.view';

const MobileCreateCustomMissionContainer = (): JSX.Element => {
  const isLoading = createMissionFunc().isLoading;
  const onCreateMission = createMissionFunc().onCreateMission;
  const createMissionError = createMissionFunc().createMissionError;

  return (
    <MobileCreateCustomMissionView
      onCreateMission={onCreateMission}
      isLoading={isLoading}
      createMissionError={createMissionError}
    />
  );
};

export default MobileCreateCustomMissionContainer;
