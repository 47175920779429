import api from 'api/instance';
import {
  AloftResponse,
  ApiResponse,
  Mission,
  MissionAsset,
  MissionStatuses,
} from 'types/mission';

export interface GetMissionsAPIParams {
  missionIndustry?: string;
  type: 0 | 1 | null;
  liveEvent?: boolean;
  rush?: boolean;
  page?: number;
  limit?: number;
  missionId?: string;
  cms?: string;
  client?: string;
  pilot?: string;
  dueDate: string;
  search?: string;
  status?: string;
}

const URL = process.env.REACT_APP_API_URL;

export const createMission = async (payload: any) => {
  const data = await api.post(`${URL}/missions`, payload);
  return data;
};

export const updateMission = async (payload: any, missionId: any) => {
  const data = await api.patch(`${URL}/missions/${missionId}`, payload);

  return data;
};

export const declineMission = async (payload: any, missionId: any) => {
  const data = await api.patch(
    `${URL}/missions/${missionId}/mission-decline`,
    payload,
  );
  return data;
};

export const getMissions = async (
  payload: GetMissionsAPIParams,
): Promise<ApiResponse<Mission[]>> => {
  const { data } = await api.get(`${URL}/missions`, {
    params: {
      ...payload,
      sortBy: 'createdAt:desc',
    },
  });

  return data;
};

type ResponseType = {
  results: Mission[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
};

type RequestParams = {
  date?: string;
  limit: number;
  lat?: number;
  lng?: number;
  radius?: number;
  page?: number;
};

export const getAllMissions = async (
  params: RequestParams,
): Promise<Mission[]> => {
  const {
    data: { results },
  } = await api.get<ResponseType>(`${URL}/missions`, {
    params,
  });

  return results;
};

export const getNearMissions = async (params: RequestParams) => {
  const { data } = await api.get<ResponseType>(`${URL}/missions/near`, {
    params,
  });

  return data;
};

export const getMission = async (missionId: string): Promise<Mission> => {
  const { data } = await api.get(`${URL}/missions/${missionId}`);
  return data;
};

export const uploadAsset = async (
  file: File,
  missionId: string,
  onProgress?: (a: ProgressEvent) => void,
  type: 0 | 1 = 0,
): Promise<any> => {
  const { data } = await api.post<MissionAsset>(
    `${URL}/missions/${missionId}/assets`,
    {
      fileName: file.name.replace(/\.[^/.]+$/, ''),
      fileType: file.type,
      fileSize: file.size,
      type,
    },
  );

  await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', data.signedUrl, true);
    xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }
    xhr.onload = () => {
      resolve(data.id);
    };
    xhr.onerror = () => {
      if (xhr.status !== 200) {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`,
          ),
        );
      }
    };
    xhr.send(file);
  });

  const confirmAssetResponse = await confirmAssetUpload(data.id);

  return confirmAssetResponse;
};

export const confirmAssetUpload = async (
  assetId: string,
): Promise<MissionAsset> => {
  const { data } = await api.patch<MissionAsset>(
    `${URL}/missions/${assetId}/confirmed`,
  );

  return data;
};

export const getMissionAssets = async (
  missionId: string,
): Promise<ApiResponse<MissionAsset[]>> => {
  const { data } = await api.get<ApiResponse<MissionAsset[]>>(
    `${URL}/missions/${missionId}/assets`,
    {
      params: {
        limit: 100,
      },
    },
  );

  return data;
};

export const getAsset = async ({
  missionId,
  assetId,
  type = 0,
}: {
  missionId: string;
  assetId: string;
  type: 0 | 1;
}): Promise<MissionAsset> => {
  const { data } = await api.get<MissionAsset>(
    `${URL}/missions/${missionId}/assets/${assetId}`,
    {
      params: {
        type,
      },
    },
  );
  return data;
};

export const cancelMission = async (missionId: string): Promise<any> => {
  const { data } = await api.patch(`${URL}/missions/${missionId}`, {
    status: MissionStatuses.CANCELLED,
  });

  return data;
};

export const deleteAsset = async (
  missionId: string,
  assetId: string,
  type: 0 | 1 = 0,
): Promise<any> => {
  const { data } = await api.delete(
    `${URL}/missions/${missionId}/assets/${assetId}`,
    {
      params: {
        type,
      },
    },
  );

  return data;
};

export const createMissionWithRegistration = async (
  payload: any,
): Promise<{
  clientSecret: string;
  mission: {};
  resetPasswordToken: string;
  publicKey: string;
}> => {
  const { data } = await api.post(`${URL}/missions/lite-registration`, payload);

  return data;
};

export const aloftCheck = async (payload: {
  latitude: number;
  longitude: number;
}) => {
  const { data } = await api.post<AloftResponse>(
    `${URL}/missions/alfot-check`,
    payload,
  );

  return data;
};

export default { createMission };

export const getPilotCollaborations = async (type: '0' | '1') => {
  const { data } = await api.post(
    `${URL}/missions/pilot-collabrations?type=${type}`,
  );

  return data?.pilots;
};

export const cancelMissionRequest = async ({
  missionId,
  ...payload
}: {
  missionId: string;
  requestedBy?: 'CLIENT' | 'PILOT';
  reason?: string;
  status?: 'ACCEPTED' | 'REJECTED';
}) => {
  const { data } = await api.post(
    `${URL}/missions/${missionId}/cancellation`,
    payload,
  );

  return data;
};

export const deleteMission = async (missionId: string) => {
  const { data } = await api.delete(`${URL}/missions/${missionId}`);

  return data;
};
