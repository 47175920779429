import React, { createContext, useContext } from 'react';

import { CMS } from 'types/cms';

type CMSContextType = {
  cms?: CMS;
  brandColor: string;
  fontColor: string;
};

const initial: CMSContextType = {
  cms: undefined,
  brandColor: '',
  fontColor: '',
};

export const CMSContext = createContext<CMSContextType>(initial);

export const useCMS = (): CMSContextType => useContext(CMSContext);
