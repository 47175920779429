import React from 'react';

import { PreviousWorkSectionProps } from './PreviousWorkSection.props';
import PreviousWorkSectionView from './PreviousWorkSection.view';

const PreviousWorkSectionContainer = (props: PreviousWorkSectionProps) => {
  return <PreviousWorkSectionView {...props} />;
};

export default PreviousWorkSectionContainer;
