import React from 'react';

import { MissionQuoteTabsProps } from './MissionQuoteTabs.props';
import MissionQuoteTabsView from './MissionQuoteTabs.view';

const MissionQuoteTabsContainer = (props: MissionQuoteTabsProps) => {
  return <MissionQuoteTabsView renderCustomButton={props.renderCustomButton} />;
};

export default MissionQuoteTabsContainer;
