import * as Yup from 'yup';

import { isEqual } from 'date-fns';

import { CustomMission } from 'types/mission';

const URL_REGEX = /(https:\/\/)?(http:\/\/)?(www\.)?[A-Za-z0-9]+\.[a-z]{2,3}/gm;

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  missionIndustry: Yup.string().required('Industry is required'),
  url: Yup.string().matches(URL_REGEX, 'Please enter a valid url'),
  description: Yup.string().required('Description is required'),
  lineItems: Yup.array().min(1, 'At least 1 deliverable item is required'),
  pilotChosenDate: Yup.string().required('Date is required'),
});

export const getInitialValues = (values?: CustomMission) => {
  const lineItems =
    values?.package?.lineItems?.map(({ description, ...rest }) => rest) ||
    ([] as any[]);

  const additionalServices =
    values?.package?.additionalServices?.map(
      ({ description, ...rest }) => rest,
    ) || ([] as any[]);

  let isEqualDates = false;

  if (values?.eventStart && values?.dueDate) {
    isEqualDates = isEqual(
      new Date(values?.dueDate),
      new Date(values?.eventStart),
    );
  }

  return {
    name: values?.package?.name || '',
    missionIndustry: values?.missionIndustry || '',
    description: values?.package?.description || '',
    lineItems,
    additionalServices,
    workSampleURL: values?.workSampleURL || '',
    totalCharge: values?.totalCharge ? String(values?.totalCharge) : '',
    pilotChosenDate: isEqualDates
      ? String(values?.dueDate)
      : values?.pilotChosenDate
      ? String(values?.pilotChosenDate)
      : new Date().toISOString(),
  };
};
