import React from 'react';

import { Flex } from '@chakra-ui/react';

import { SupportGeneratedProps } from './Support.props';

const SupportView = (props: SupportGeneratedProps): JSX.Element => (
  <Flex>Support</Flex>
);

export default SupportView;
