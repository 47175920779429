import React, { FC } from 'react';

import { ClientMissionTabsProps } from './ClientMissionTabs.props';
import ClientMissionTabsView from './ClientMissionTabs.view';

const ClientMissionTabsContainer: FC<ClientMissionTabsProps> = () => {
  return <ClientMissionTabsView />;
};

export default ClientMissionTabsContainer;
