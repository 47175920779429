import React, {
  FC,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Box,
  Flex,
  Text,
  Modal,
  Button,
  Center,
  Radio,
  Stack,
  Image,
  useToast,
  Textarea,
  RadioGroup,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import success from 'res/req_change_success.png';

import { selectors } from 'effector/session';
import * as stripe from 'effector/stripe';

import {
  markAsComplete,
  pilotRejectMission,
  generateClientSecret,
  clientRejectMission,
} from 'api/custom-mission';
import { useMutation, useQuery, useQueryCache } from 'react-query';

import { useMissionAssets } from 'utils/hooks/useMissionAssets';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { PaymentModal } from './PaymentModal';

import { CustomMission, CustomStatus } from 'types/mission';

const options = [
  'Budget Concern',
  "Timeframe Doens't Work",
  'Safety Concern',
  'Not A Service We Provide',
  'Others',
];

type Props = {
  customMissionId: string;
  onClose: () => void;
  onSubmitClick: () => void;
  mission: CustomMission;
};

type ButtonProps = {
  customMissionId: string;
};

type RejectRef = {
  onOpen: () => void;
};

type ModalProps = {
  customMissionId: string;
  onSuccess?: () => void;
};

export const RejectModal = forwardRef<RejectRef, ModalProps>(
  ({ customMissionId, onSuccess }, ref) => {
    const queryCache = useQueryCache();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [index, setIndex] = useState<number>(0);

    const inputRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        onOpen,
      }),
      [],
    );

    const [submit, { isLoading, isSuccess }] = useMutation(
      (_message: string) => pilotRejectMission(_message, customMissionId),
      {
        onSuccess: () => {
          queryCache.invalidateQueries(
            `fetch-custom-mission-${customMissionId}`,
          );

          onClose();

          onSuccess?.();
        },
      },
    );

    const onSend = () => {
      let message = options[index];

      if (index === 4) {
        const inputValue = inputRef.current?.value || '';

        if (inputValue.trim()?.length <= 0) {
          inputRef.current?.focus?.();

          return;
        } else {
          message = inputValue;
        }
      }

      submit(message);
    };

    return (
      <>
        <Modal
          size="2xl"
          isCentered
          scrollBehavior="inside"
          isOpen={isOpen}
          onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              textAlign="center"
              fontWeight="500"
              fontFamily="Monument Extended">
              {isSuccess ? 'Rejection reasons sent!' : 'Reject Mission'}
            </ModalHeader>
            <ModalBody>
              {isSuccess ? (
                <Flex py={10} px={6} direction="column" flex={1}>
                  <Flex flexWrap="wrap">
                    <Image
                      w="200px"
                      h="200px"
                      mr={4}
                      src={success}
                      objectFit="cover"
                    />
                    <Stack mt={4} flex={1} minW="200px">
                      <Text fontSize="14px">
                        Thank you again for choosing us, and we hope that we can
                        work together in the future to meet your drone mission
                        needs. If you have any further questions or concerns,
                        please do not hesitate to reach out to us.
                      </Text>
                    </Stack>
                  </Flex>
                  <Button mt={6} w="150px" alignSelf="center" onClick={onClose}>
                    CLOSE
                  </Button>
                </Flex>
              ) : (
                <Flex px={6} flex={1} direction="column">
                  <Text fontSize="14px" textAlign="center">
                    Please select one of the reasons below to send to client. If
                    none are relevant put your rejection or change request in
                    the "Other" field. Your client will get a notification of
                    your response.
                  </Text>
                  <RadioGroup
                    my={4}
                    sx={{
                      '.chakra-radio__control[data-checked]': {
                        background: '#6728BB',
                        borderColor: '#6728BB',
                      },
                      '.chakra-radio__control[data-focus]': {
                        boxShadow: 'none',
                      },
                    }}
                    value={index.toString()}
                    onChange={(e) => {
                      setIndex(Number(e));
                    }}>
                    <Stack>
                      {options.map((option, index) => (
                        <Radio key={option} value={index.toString()}>
                          <Text fontWeight="bold">{option}</Text>
                        </Radio>
                      ))}
                      {index === 4 && (
                        <Textarea
                          ref={inputRef}
                          autoFocus
                          placeholder="Put your request change here"
                        />
                      )}
                    </Stack>
                  </RadioGroup>
                  <Center my={4} flexWrap="wrap" gridGap="10px">
                    <Button
                      isDisabled={isLoading}
                      onClick={onClose}
                      variant="outline"
                      w="200px">
                      CANCEL
                    </Button>
                    <Button isLoading={isLoading} w="200px" onClick={onSend}>
                      SEND
                    </Button>
                  </Center>
                </Flex>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  },
);

export const PayButton: FC<Pick<Props, 'customMissionId' | 'mission'>> = ({
  mission,
  customMissionId,
}) => {
  const modalRef = useRef<any>(null);
  const user = selectors.useUser();

  const stripeId = (mission?.pilot as any)?.stripeId || '';

  const queryKey = [
    `fetch-client-secret`,
    `custom-mission-details`,
    customMissionId,
  ];
  const queryFn = () =>
    generateClientSecret({
      stripeId: stripeId || '',
      customMissionId,
      userId: user?.id || '',
      userEmail: user?.email || '',
    });

  const { data, isSuccess, isFetching, refetch } = useQuery(queryKey, queryFn, {
    enabled: false,
    onSuccess: () => {
      setTimeout(() => {
        modalRef?.current?.onOpen?.();
      }, 500);
    },
  });

  const clientSecret = data?.clientSecret || '';
  const publishableKey = data?.publishableKey || '';

  const options = {
    stripeAccount: stripeId,
  };

  return (
    <>
      <Button
        isDisabled={isSuccess}
        isLoading={isFetching}
        onClick={() => refetch()}>
        COMPLETE PAYMENT
      </Button>
      {Boolean(publishableKey) && Boolean(stripeId) ? (
        <Elements stripe={loadStripe(publishableKey, options)}>
          <PaymentModal
            ref={modalRef}
            clientSecret={clientSecret}
            customMissionId={customMissionId}
          />
        </Elements>
      ) : null}
    </>
  );
};

export const MissionActions: FC<Props> = ({
  mission,
  onSubmitClick,
  customMissionId,
}) => {
  const toast = useToast();
  const queryCache = useQueryCache();
  const userType = selectors.useUser();
  const rejectModalRef = useRef<RejectRef>(null);
  const isAccConnected = stripe.selectors.useIsConnected();

  const { data } = useMissionAssets(customMissionId);

  const status = mission.status;

  const isClient = userType?.accountType === 'CLIENT';
  const isPilot = userType?.accountType === 'PILOT';

  const [onComplete, { isLoading }] = useMutation(
    () => markAsComplete(customMissionId),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Mission marked as Complete',
          isClosable: true,
          duration: 3000,
        });

        queryCache.invalidateQueries([
          `fetch-custom-mission-${customMissionId}`,
        ]);

        queryCache.invalidateQueries([`fetch-custom-missions-open`]);
        queryCache.invalidateQueries([`fetch-custom-missions-open`]);
      },
    },
  );

  const [onCancel, { isLoading: isCancelling }] = useMutation(
    () => clientRejectMission('Mission Cancelled', customMissionId),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Mission cancelled',
          isClosable: true,
          duration: 3000,
        });

        queryCache.invalidateQueries([
          `fetch-custom-mission-${customMissionId}`,
        ]);

        queryCache.invalidateQueries([`fetch-custom-missions-open`]);
        queryCache.invalidateQueries([`fetch-custom-missions-open`]);
      },
    },
  );

  if (!isAccConnected && isPilot) {
    return (
      <Center flex={1} py="20px">
        <Text
          fontWeight="bold"
          fontSize="18px"
          color="blue.500"
          whiteSpace="pre">
          ⓘ{'  '}Please Connect your Stripe account in order to Accept the
          Custom Mission Request
        </Text>
      </Center>
    );
  }

  const renderClientActions = () => {
    if (status === CustomStatus.PILOT_REJECTED) {
      return (
        <Center flex={1} py={10}>
          <Text>Pilot rejected your mission request</Text>
        </Center>
      );
    }

    if (status === CustomStatus.CLIENT_REJECTED) {
      return (
        <Center flex={1} py={10}>
          <Text>You rejected the custom quote sent by the pilot</Text>
        </Center>
      );
    }

    if (status === CustomStatus.PENDING_PAYMENT) {
      return (
        <Center flex={1} py={10} flexDirection="column">
          <Text mb="4">Please complete the payment for the mission.</Text>
          <Center>
            <Button
              w="200px"
              mr={3}
              variant="outline"
              isLoading={isCancelling}
              onClick={() => onCancel()}>
              CANCEL
            </Button>
            <PayButton mission={mission} customMissionId={customMissionId} />
          </Center>
          <Box h="20px" w="20px" />
        </Center>
      );
    }

    if (status === CustomStatus.PILOT_ACCEPTED) {
      return (
        <Center flex={1} py={10} flexDirection="column">
          <Text mb="4"></Text>
          <Center>
            <Button
              w="200px"
              mr={3}
              variant="outline"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_URL}/cms/${
                    (mission?.cms as { username: string })?.username
                  }?custom-mission-id=${customMissionId}`,
                  '_blank',
                );
              }}>
              EXPLORE PACKAGE
            </Button>
          </Center>
          <Box h="20px" w="20px" />
        </Center>
      );
    }

    return <Flex />;
  };

  const renderPilotActions = () => {
    if (
      status === CustomStatus.ORDERED ||
      status === CustomStatus.ASSETS_UPLOADED
    ) {
      const onMarkAsComplete = () => {
        if (data === undefined) {
          return;
        }

        const isAssets = data?.results?.length > 0;

        if (isAssets === false) {
          toast({
            status: 'error',
            title: 'Please upload assets first to mark mission as complete',
            isClosable: true,
            duration: 4000,
          });

          return;
        }

        onComplete();
      };

      return (
        <Center flex={1} py={10}>
          <Button isLoading={isLoading} onClick={onMarkAsComplete}>
            MARK AS COMPLETE
          </Button>
        </Center>
      );
    }

    if (status === CustomStatus.PILOT_REJECTED) {
      return (
        <Center flex={1} py={10}>
          <Text>You rejected this mission request</Text>
        </Center>
      );
    }

    if (status === CustomStatus.CLIENT_REJECTED) {
      return (
        <Center flex={1} py={10}>
          <Text>The client rejected your custom quote</Text>
        </Center>
      );
    }

    if (status === CustomStatus.REQUESTED) {
      return (
        <Center flex={1} py={10}>
          <Button
            onClick={() => {
              rejectModalRef.current?.onOpen?.();
            }}
            variant="outline"
            w="220px">
            REJECT
          </Button>
          <Box h="20px" w="20px" />
          <Button onClick={onSubmitClick}>CREATE CUSTOM MISSION</Button>
        </Center>
      );
    }

    if (status === CustomStatus.CLIENT_CHANGE_REQUESTED) {
      return (
        <Center flex={1} py={10}>
          <Button
            onClick={() => {
              rejectModalRef.current?.onOpen?.();
            }}
            variant="outline"
            w="220px">
            REJECT
          </Button>
          <Box h="20px" w="20px" />
          <Button onClick={onSubmitClick}>UPDATE CUSTOM MISSION</Button>
        </Center>
      );
    }

    return <Flex />;
  };

  return (
    <Flex>
      {isClient ? renderClientActions() : renderPilotActions()}
      <RejectModal ref={rejectModalRef} customMissionId={customMissionId} />
    </Flex>
  );
};
