import React, { FC, useState } from 'react';

import { Tab, Tabs, TabList, TabPanel, TabPanels } from '@chakra-ui/react';

import { useIsMobile } from 'utils/hooks';

import { selectors } from 'effector/session';

import { OpenRequests } from './OpenRequests';
import { SentRequests } from './SentRequests';

import { Filters } from '../Filters';
import MissionListBanner from '../MissionListBanner';
import TabPanelWithShadow from 'components/primitives/TabPanelWithShadow';

import { MissionQuoteTabsGeneratedProps } from './MissionQuoteTabs.props';

const tabSpacing = [5, 8, 8];

const View: FC<MissionQuoteTabsGeneratedProps> = (props) => {
  const userType = selectors.useUser();

  const isMobile = useIsMobile();

  const [index, setIndex] = useState<number>(0);

  const isClient = userType?.accountType === 'CLIENT';

  if (isMobile) {
    return (
      <Tabs
        mt={3}
        isFitted
        isLazy
        variant="enclosed"
        index={index}
        onChange={setIndex}
        width="100%"
        alignItems="center"
        borderBottomRadius="20px">
        <TabList>
          <Tab
            backgroundColor="#E0E0E0"
            color="#7A7A7A"
            justifyContent="center"
            _focus={{ borderColor: 'none' }}
            _selected={{ backgroundColor: '#F5F5F5', color: 'secondaryColor' }}
            fontSize="md"
            fontWeight="bold"
            py={4}>
            OPEN REQUESTS
          </Tab>
          <Tab
            backgroundColor="#E0E0E0"
            color="#7A7A7A"
            _focus={{ borderColor: 'none' }}
            _selected={{ backgroundColor: '#F5F5F5', color: 'secondaryColor' }}
            fontSize="md"
            fontWeight="bold"
            py={4}>
            SENT REQUESTS
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel borderRadius="10px">
            <OpenRequests isClient={isClient} />
          </TabPanel>
          <TabPanel>
            <SentRequests isClient={isClient} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }

  return (
    <Tabs
      mt={2}
      isLazy
      variant="enclosed"
      index={index}
      onChange={setIndex}
      overflowX="hidden">
      <TabList>
        <Tab
          _focus={{ borderColor: 'none' }}
          _selected={{ backgroundColor: 'secondaryColor', color: 'white' }}
          fontSize="md"
          fontWeight="bold"
          px={tabSpacing}
          py="3">
          OPEN REQUESTS
        </Tab>
        <Tab
          _focus={{ borderColor: 'none' }}
          _selected={{ backgroundColor: 'secondaryColor', color: 'white' }}
          fontSize="md"
          fontWeight="bold"
          px={tabSpacing}
          py="3">
          {isClient ? 'COMPLETED REQUESTS' : 'SENT REQUESTS'}
        </Tab>
        <Tab
          flex={1}
          fontSize="md"
          style={{ visibility: 'hidden' }}
          fontWeight="bold"
          color="white"
          px={tabSpacing}
          py="3">
          Tab Placeholder
        </Tab>

        <Filters type="custom_mission" />
      </TabList>
      <MissionListBanner
        highlightedTitle={`CUSTOM MISSIONS ${isClient ? '' : 'REQUESTS'}`}
        customButton={props.renderCustomButton?.()}
      />
      <TabPanels bg="white">
        <TabPanelWithShadow minH="500px">
          <OpenRequests isClient={isClient} />
        </TabPanelWithShadow>
        <TabPanelWithShadow minH="500px">
          <SentRequests isClient={isClient} />
        </TabPanelWithShadow>
      </TabPanels>
    </Tabs>
  );
};

export default View;
