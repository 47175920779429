import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { CircularProgress, CircularProgressLabel } from '@chakra-ui/progress';

const colorScheme = '#6728BB';

export const Progress = forwardRef((_, ref) => {
  const [progress, setProgress] = useState(0);

  useImperativeHandle(
    ref,
    () => ({
      setProgress,
    }),
    [],
  );

  return (
    <CircularProgress value={progress} color={colorScheme}>
      <CircularProgressLabel fontWeight="bold" color="#fff">
        {progress}%
      </CircularProgressLabel>
    </CircularProgress>
  );
});
