import React from 'react';

import { useToast } from '@chakra-ui/toast';

import { getMyCMS, updateCMS } from 'api/cms';
import { useMutation, useQuery, useQueryCache } from 'react-query';

import { MyWebsiteProps } from './MyWebsite.props';

import MyWebsiteView from './MyWebsite.view';

const MyWebsiteContainer = (_: MyWebsiteProps) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const status = 'DRAFT';

  const { data, isLoading } = useQuery(
    `fetch-my-cms-DRAFT`,
    () => getMyCMS(status),
    {
      retry: 0,
      onError: () => {
        console.log('error');
      },
    },
  );

  const isNewCMS = !data;

  const [update] = useMutation(updateCMS, {
    onSuccess: (_, { payload }: any) => {
      toast({
        status: 'success',
        title: payload?.status
          ? 'Data Published Successfully'
          : 'Data Updated Succesfully',
        isClosable: true,
        duration: 5000,
      });

      queryCache.invalidateQueries(`fetch-my-cms`, {
        exact: true,
      });

      queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
        exact: true,
      });

      queryCache.invalidateQueries(`fetch-my-cms-published`, {
        exact: true,
      });
    },
  });

  return (
    <MyWebsiteView
      data={data}
      isNewCMS={isNewCMS}
      isLoading={isLoading}
      onSubmit={async (values) => {
        await update({
          payload: values,
          companyName: data?.about.companyName || '',
        });
      }}
    />
  );
};

export default MyWebsiteContainer;
